import { Shipment } from '@wearewarp/types/data-model'
import _ from 'underscore'

export default class Utils {
    public static countIdentified(item) {
        if (item.isMissing) return 0
        if (item.internalBarcode) return 1
        if (!item.children) return 0
        return _.reduce(item.children.map(Utils.countIdentified), (a,b) => a + b, 0)
    }

    public static countItems(item, packaging) {
        if (item.isMissing) return 0
        if (item.qtyUnit !== packaging) return 0
       // if (!item.children) return item.qty || 1
        // const sub = _.reduce(item.children.map(it => Utils.countItems(it, packaging)), (a,b) => a + b, 0)
        // if (sub < 1) return item.qty
        // return sub
        return item.qty || 1
    }

    public static countWeight(item) {
        if (item.actualWeight) return item.actualWeight
        if (!item.children || !item.children.length) return (item.weightPerUnit || 0) * (item.qty || 1)
        return _.reduce(item.children.map(Utils.countWeight), (a,b) => a + b, 0)
    }

    public static getLocationName(deliveryInfo) {
        if (deliveryInfo.locationName) return deliveryInfo.locationName
        const { addr } = deliveryInfo || {}
        const { city, state, zipcode} = addr || {}
        return `${city} ${state} ${zipcode}`
    }

    public static getStatusTimeLine(shipment, type) {
        if (!shipment) return null
        const { statusHistories } = shipment
        if (!statusHistories) return null
        const timeline = statusHistories.filter(it => it.to === type)[0]
        return timeline?.changeWhen
    }

    public static getDeliveryInfo(shipment: Shipment, type: string) {
        return (shipment?.deliveryInfos || []).filter(it => it.type === type)[0]
    }

    public static getDeliveryInfoTime(deliveryInfo) {
        const { appointmentInfo, windows } = deliveryInfo
        const { from, to } = appointmentInfo || {}
        if (from) return from
        return (windows||[])[0]?.from
    }

    public static getDeliveryInfoWindow(deliveryInfo) {
        const { appointmentInfo, windows } = deliveryInfo
        const { from, to } = appointmentInfo || {}
        if (from) return appointmentInfo
        return (windows||[])[0]
    }

    public static getShipmentDeliveryInfoTime(shipment: Shipment, type: string) {
        return Utils.getDeliveryInfoTime(Utils.getDeliveryInfo(shipment, type))
    }
}