<form nz-form *ngIf="formInput" [formGroup]="formInput" class="form-detail">
    <div nz-row [nzGutter]="16">
      <div nz-col nzSpan="24">
        <div class="box-with-title">
          <div class="box-title">Inventories</div>
          <div class="box-content">
            <ng-container *ngFor="let groupKey of ['inventories']" [formArrayName]="groupKey">
              <form *ngFor="let item of getArrayControls(groupKey); let i = index" nz-form [formGroupName]="i">
                <div nz-row [nzGutter]="16">
                  <div nz-col *ngFor="let col of row1.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
                    <div *ngIf="col.key !== 'action'">
                      <div class="form-label-v2">
                        {{getLabel(fullKey(groupKey, col.key))}}
                        <span *ngIf="isRequired(fullKey(groupKey, col.key))" class="label-mark-required"></span>
                      </div>
                      <nz-form-item>
                        <nz-form-control>
                          <ng-container *ngIf="col.key !== 'type'">
                            <input nz-input
                              [formControlName]="col.key"
                              [type]="getInputType(fullKey(groupKey, col.key))"
                              [placeholder]="getPlaceHolder(fullKey(groupKey, col.key))"
                              (input)="onInputChanged($event, col.key)"
                              (keypress)="onInputKeyPress($event, col.key)"
                            >
                          </ng-container>
                          <ng-container *ngIf="col.key === 'type'">
                            <nz-select nzMode="multiple" [formControlName]="col.key" [nzPlaceHolder]="getPlaceHolder(fullKey(groupKey, col.key))">
                              <nz-option *ngFor="let type of types" [nzValue]="type.value" [nzLabel]="type.label"></nz-option>
                            </nz-select>
                          </ng-container>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
  
                    <div *ngIf="col.key === 'action' && (isEditing || isCreateNew)" [ngClass]="col.key">
                      <div class="form-label-v2">&nbsp;</div>
                      <nz-form-item>
                        <nz-form-control>
                          <nz-space class="group-button">
                            <ng-container *ngIf="getArrayControls(groupKey).length > 1 && !isAdminReadOnlyRole && (isCreateNew || isEditing)">
                              <span *nzSpaceItem
                                nz-icon nzType="minus-circle"
                                nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                                (click)="removeItemInFormArray(groupKey, i)"
                              ></span>
                            </ng-container>
                          </nz-space>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                </div>
              </form>
              <button *ngIf="!isAdminReadOnlyRole && (isCreateNew || isEditing)" nz-button nzType="dashed"
                (click)="addItemToFormArray(groupKey)"><i nz-icon nzType="plus"></i>Add a inventory
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  
    <div style="height: 50px;">
      <div *ngIf="!isAdminReadOnlyRole" detail-footer-buttons [isCreateNew]="isCreateNew" [isEditing]="isEditing"
        [onProgress]="onProgress" [needUpdate]="needUpdate" (onEdit)="onBtnEdit()" (onSave)="onBtnSave()"
        (onCancel)="onBtnCancel()">
      </div>
    </div>
  </form>
  