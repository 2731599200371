<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">Book Appointment</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form class="form-detail" [formGroup]="formInput" nz-form style="padding: 26px;">
  <div nz-row>
    <div nz-col *ngFor="let col of row.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
      <div class="form-label-v2 single-line fix-height">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key) && getLabel(col.key).length > 0" class="label-mark-required"></span></div>
      <nz-form-item>
        <nz-form-control>
          <nz-date-picker *ngIf="col.key == 'date'" [formControlName]="col.key" nzFormat="yyyy-MM-dd" style="width: 100%;"></nz-date-picker>
          <nz-time-picker [nzMinuteStep]="15" *ngIf="col.key != 'date'" [formControlName]="col.key" nzFormat="HH:mm"></nz-time-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" [canClickCancel]="true" 
  nzIconOK="save" labelOK="Save" (onOK)="onBtnSave()" 
  (onCancel)="onBtnCancel()"></div>