import { Component, Output, EventEmitter } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NotificationDispatchService } from '@app/drawers/notification-dispatch.service';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@abstract/BaseComponent';
import { AppConst } from '@app/const.generated';
import { Log } from '@services/log';
import { getDashboard } from '@services/index';
import { environment } from '@env/environment';
import { Utils } from '@services/utils';
import { EnvType } from '@env/type';
import { sideMenu } from './side-menu';
import { getFeatureFlags } from '@services/feature-flag.service';
import { PermissionManager } from '@services/permission-manager';

@Component({
  selector: '[app-sidebar]',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent {
  private contextMenuOpened: string = null;
  @Output() onExpadCollapse = new EventEmitter<any>();

  public menuList = sideMenu;
  protected notifyDispathService: NotificationDispatchService;
  private notifyDispathChangeSubscription: Subscription;
  @Output() notifyPageTitle: EventEmitter<any> = new EventEmitter<any>();

  constructor(private nzContextMenuService: NzContextMenuService) {
    super();
    this.notifyDispathService = this.injector.get(NotificationDispatchService);
    this.notifyDispathChangeSubscription = this.notifyDispathService.onNotificationsNumberChanged.subscribe(value => {
      this._setNotifyDispathNumber(value);
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hilightMenuItem(event.url);
      }
    });
    this.assignIdForMenuItems();
  }

  private timerBadgeCount;
  ngOnInit() {
    this.setupMenuList();
    super.ngOnInit();
    this._setNotifyDispathNumber(this.badgeNotificationDispatchNumber);
  }

  private setupMenuList() {
    getFeatureFlags().isFlagSetNonBlocking('PLANNING_TOOL').subscribe((res) => {
      for (let m of this.menuList) {
        if (m.link === 'planning') {
          for (let child of m.children) {
            // Ngoại lệ với routing-ltl, vì lúc trước không trong label planning
            if (child.link !== 'routing-ltl') child.hidden = !res;
          }
        }
      }
    }, (err) => {
      for (let m of this.menuList) {
        if (m.link === 'planning') {
          for (let child of m.children) {
            if (child.link !== 'routing-ltl') child.hidden = false;
          }
        }
      }
    })
    this.hilightMenuItem(this.router.url);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.notifyDispathChangeSubscription?.unsubscribe();
    clearInterval(this.timerBadgeCount);
  }

  protected didLogout() {
    clearInterval(this.timerBadgeCount);
  }

  protected didLogin(): void {
    this.setupMenuList();
  }

  private assignIdForMenuItems() {
    // Đã gắn trực tiếp id cho item khi khởi tạo
    // Hiện giờ thì id chỉ dùng để check xem item nào có sub menu đang mở để ẩn tooltip của nó đi
    for (let i = 0; i < this.menuList.length; i++) {
      this.menuList[i].collapse = true;
      if (!this.menuList[i].children) {
        continue;
      }
      for (let j = 0; j < this.menuList[i].children.length; j++) {
        this.menuList[i].children[j].id = this.menuList[i].id + '/' + this.menuList[i].children[j].link;
      }
    }
  }

  private hilightMenuItem(urlStr: string) {
    let url = urlStr.split('?')[0];
    if (!url.endsWith('/')) {
      url += '/';
    }
    let foundHilightItem = false;
    let pageTitle;
    for (let i = 0; i < this.menuList.length; i++) {
      // các màn đã nhóm group hết nên chỉ xét các children item
      for (let j = 0; j < this.menuList[i].children.length; j++) {
        let path = this.getRouterLink(this.menuList[i], this.menuList[i].children[j]);
        let itemLink = `${this.routeDashboard}/${path}`;
        this.menuList[i].children[j].hilight = url.indexOf(itemLink) === 0;
        if (this.menuList[i].children[j].hilight && this.canAccessMenuItem(this.menuList[i].children[j])) {
          foundHilightItem = true;
          this.menuList[i].collapse = false;
          pageTitle = this.menuList[i].children[j].name;
          if (this.menuList[i].id === 'cross-dock' && pageTitle === 'Shipments') {
            pageTitle = 'Cross Dock - Shipments';
          }
        }
      }
    }
    let isDashboardUrl = url.startsWith(this.routeDashboard);
    if (isDashboardUrl && !foundHilightItem) {
      Log.e('Found no item to hilight on sidebar menu -> this might be an illegal accessing by entering url');
      getDashboard()?.doInitialRouting();
    }
    if (foundHilightItem) {
      this.notifyPageTitle.emit(pageTitle);
    }
  }

  protected setupLanguage() {
    if (!this.menuList) {
      return;
    }
    for (let item of this.menuList) {
      if (item.key_text) {
        item.name = this.text(item.key_text);
      }
      if (item.children && item.children.length > 0) {
        for (let child of item.children) {
          if (child.key_text) {
            child.name = this.text(child.key_text);
          }
        }
      }
    }
  }

  private _isSmallWidth: boolean = false;

  get isSmallWidth(): boolean {
    return this._isSmallWidth;
  }

  set isSmallWidth(value) {
    if (value != this._isSmallWidth) {
      this.toggleSidebar();
    }
  }

  get version(): string {
    return `Version ${AppConst.Version}`;
  }
  get buildWhen(): string {
    if (environment.type == EnvType.prod) {
      return '';
    }
    return `Build ${AppConst.BuildWhen}`;
  }
  get devFeature(): string {
    return AppConst.devFeatureBranch;
  }
  get isDevFeature(): boolean {
    return /^dev[0-9]+$/.test(environment.type);
  }

  toggleSidebar() {
    this._isSmallWidth = !this._isSmallWidth;
    this.onExpadCollapse.emit(this._isSmallWidth);
    let sidebarEl = document.querySelector(".sidebar-container .ant-affix") as HTMLElement
    if (sidebarEl) {
      let sidebarWidth = this._isSmallWidth ? "50px" : "250px";
      sidebarEl.style.width = sidebarWidth;
    }
    let sidebarEl2 = document.querySelector(".sidebar-container nz-affix") as HTMLElement
    if (sidebarEl2) {
      let sidebarWidth = this._isSmallWidth ? "50px" : "250px";
      sidebarEl2.style.width = sidebarWidth;
    }
  }

  getMenuItemName(item) {
    return this.isSmallWidth ? '' : item.name;
  }
  getMenuItemTooltip(item) {
    if (item && this.isSmallWidth) return item.name;
    return '';
  }

  public shouldShowTooltip(item): boolean {
    if (!this.isSmallWidth) return false;
    if (item.children && item.children.length > 0 && this.contextMenuOpened && this.contextMenuOpened.startsWith(item.id)) return false;   // sub menu đang mở thì ko hiện tooltip
    return true;
  }

  isHttpLink(link: string): boolean {
    return Utils.isUrl(link);
  }

  // Chỉ dùng cho trường hợp menu có children
  // Nếu item có 1 thằng con đang hilight thì khi collapse lại sẽ hilight item đó
  // Khi expand ra thì lại chuyển hilight cho thằng con
  shouldHilightItem(item): boolean {
    if (!this.isSmallWidth && !item.collapse) {
      return false;
    }
    if (!item.children || item.children.length == 0) {
      return item.hilight;
    }
    for (const child of item.children) {
      if (child.hilight) {
        return true;
      }
    }
    return false;
  }

  shouldShowMenuItem(item: SidebarMenuItem) {
    if (item.hidden) {
      return false;
    }
    //if one of the children is visible, then the parent should be visible
    if (item.children && item.children.length > 0) {
      for (const child of item.children) {
        if (this.shouldShowMenuChildItem(child)) {
          return true;
        }
      }
      return false;
    }
  }

  shouldShowMenuChildItem(item: SidebarMenuChildItem) {
    if (item.hidden) {
      return false;
    }
    return this.canAccessMenuItem(item);
  }

  private canAccessMenuItem(item: SidebarMenuChildItem | SidebarMenuItem): boolean {
    if (!item.permissions || item.permissions.length == 0) return true;
    //all permissions are required
    for (const permission of item.permissions) {
      if (!PermissionManager.userHasPermission(this.authUser, permission)) {
        return false;
      }
    }
    return true;
  }

  // Dùng cho trường hợp sidebar đang ở trạng thái small và sub menu hiển thị dạng pop-up
  onSubMenuItemClicked(event) {
    this.router.navigate([event.item.link]);
  }

  // giá trị lấy từ file _common.scss
  private sidebarWidthSm: number = 50;

  showSubMenu(event, item, menu: NzDropdownMenuComponent) {
    event.stopPropagation();
    item.collapse = !item.collapse;
    if (this.isSmallWidth) {
      // Không tạo submenu tại vị trí click mà đặt ra rìa sidebar
      let placement = {
        x: this.sidebarWidthSm,
        y: event.y
      }
      this.nzContextMenuService.create(placement, menu);
      this.contextMenuOpened = item.id;
    }
  }

  public get badgeNotificationDispatchNumber() {
    return this.notifyDispathService.getNotificationsNumber();
  }

  private _setNotifyDispathNumber(value) {
    for (let i = 0; i < this.menuList.length; i++) {
      if (this.menuList[i].name == 'Dispatch') {
        this.menuList[i].badge = value;
        return;
      }
    }
  }

  public shouldShowBadgeNumber(item) {
    let url = this.router.url.split('?')[0];
    let menuLink = `${this.routeDashboard}/${item.link}`;
    if (url.indexOf(menuLink) == 0) {
      return false;
    }
    return true;
  }

  getRouterLink(item, child) {
    if (child.specificParentPath) {
      // Nếu có specific path thì dùng luôn thay thế cho parent path (item.link) 
      return `${child.specificParentPath}/${child.link}`;
    }
    return child.isUseParentPath ? `${item.link}/${child.link}` : child.link;
  }

}
