<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>


<form *ngIf="!isLoading && formInput" [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
  <div class="tdm-row cell-spacing">
    <div *ngFor="let mode of taskSettings;" [formArrayName]="mode.type" class="tdm-col tdm-col-1">
      <label class="title">{{getLabel(fullKey(mode.type))}} </label>
    
      <div *ngFor="let item of getArrayControls(mode.type); let i = index" class="form-setting index-{{ i }}">
        <form nz-form [formGroupName]="i">
          <div class="tdm-row cell-spacing">
            <div class="tdm-col tdm-col-2" *ngFor="let childKey of ['taskType', 'levels'];">
              <div>
                <ng-container *ngIf="i === 0">
                  <div class="form-label-v2">
                    {{getLabel(fullKey(mode.type, childKey))}}
                    <span *ngIf="isRequired(fullKey(mode.type, childKey))" class="label-mark-required"></span>
                  </div>
                </ng-container>
                <ng-container [ngSwitch]="childKey">
                  <div *ngSwitchCase="'taskType'" [ngClass]="childKey">
                    <label>{{ getTaskName(mode.type, i, childKey) }}</label>
                    <input nz-input [formControlName]="childKey"
                      [type]="getInputType(childKey)"
                      [placeholder]="getPlaceHolder(childKey)"
                      (input)="onInputChanged($event, childKey)"
                      (keypress)="onInputKeyPress($event, childKey)" 
                    />
                  </div>
  
                  <div *ngSwitchCase="'levels'" [ngClass]="childKey">
                    <form nz-form [formGroupName]="childKey" class="group-crossdock">
                      <div class="item-crossdock" *ngFor="let key of getLevels(mode, i, 'taskType')">
                        <nz-form-item>
                          <nz-form-control>
                            <label nz-checkbox [formControlName]="key">{{getLabel(fullKey(mode.type, childKey, key))}}</label>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </form>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</form>

<!-- <div class="text-right top20">
  <button nz-button nzType="primary" (click)="onBtnSave()" [disabled]="onProgress">
    Save
    <i nz-icon nzType="loading" nzTheme="outline" *ngIf="onProgress"></i>
  </button>
  <button class="left10" nz-button nzType="secondary" (click)="onCancel()" [disabled]="onProgress">Cancel</button>
</div> -->