import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalHelper } from "@wearewarp/ng-antd";
import { NzModalRef } from "ng-zorro-antd/modal";
import { WarehouseTaskSettingDialog } from "./dialog";

@Component({
  selector: '[warehouse-task-setting-icon]',
  templateUrl: './icon.html',
  styleUrls: ['./index.scss']
})
export class WarehouseTaskSettingIcon extends BaseComponent {
  @Input() shipment: any;
  @Input() warehouseId: string;
  @Output() onUpdated: EventEmitter<string> = new EventEmitter<string>();
  modal: NzModalRef = null;

  constructor(protected activatedRoute: ActivatedRoute, private modalHelper: ModalHelper) {
    super(activatedRoute);
  }

  closeDialog(refreshShipment = false) {
    this.modal?.close();
    if(refreshShipment) this.onUpdated.emit();
  }

  ngOnInit(): void {
    this.closeDialog = this.closeDialog.bind(this)
  }

  showDialog() {    
    this.modal = this.modalHelper.open(WarehouseTaskSettingDialog, {
      nzClassName: 'modal-no-padding modal-md',
      nzCentered: true,
      nzCancelText: null,
      nzFooter: null,
      nzComponentParams: {
        model: { ...this.shipment },
        warehouseId: this.warehouseId,
        closeDialog: this.closeDialog
      },
    })
  }
}