import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { environment } from '@env/environment';
import { Utils } from '@services/utils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[report-list-screen]',
  template: '<report-list></report-list>',
})
export class ReportListScreen extends BaseComponent {
    constructor(protected activatedRoute: ActivatedRoute) {
        super();
    }
}