import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { SharedModule } from '@app/admin/shared/shared.module';
import { MarkRequiredModule } from '@app/admin/base/mark-required/module';
import { FormShipmentLocation } from '.';
import { FormShipmentLocationTemplateDefault } from './template-default';
import { FormShipmentLocationTemplateMobile } from './template-mobile';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzCollapseModule,
    NzSelectModule,
    NzCheckboxModule,
    NzInputModule,
    NzIconModule,
    NzDatePickerModule,
    NzTimePickerModule,
    SharedModule,
    MarkRequiredModule,
    ScrollingModule
  ],
  declarations: [
    FormShipmentLocation,
    FormShipmentLocationTemplateDefault,
    FormShipmentLocationTemplateMobile,
  ],
  exports: [
    FormShipmentLocation,
    FormShipmentLocationTemplateDefault,
    FormShipmentLocationTemplateMobile,
  ]
})
export class FormShipmentLocationModule {}