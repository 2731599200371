import { BaseList } from "../base/list";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "pool-list",
  templateUrl: "./list.html",
  styleUrls: ["../list.scss"],
})
export class PoolList extends BaseList {
  protected getApiUrl(): string {
    // throw new Error("Method not implemented.");
    return ""
  }

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.router.navigate([this.routeAdminPools, 'carrier'], { replaceUrl: true });
  }
}
