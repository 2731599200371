    <td [class]="[_item.status, 'STATUS']" (nzCheckedChange)="onSelect()" [nzChecked]="selected" (click)="stopPropergate($event)">
        <!-- <span *ngIf="needsAttention"><span class="danger" nz-icon nzType="warning" nzTheme="outline"></span></span> -->
    </td>
    <td [class]="[_item.status, 'STATUS']">{{ status }}</td>
    <!-- <td>{{ createdDate }}</td> -->
    <td><a *ngIf="order" target="_blank" [routerLink]="[routeAdminShipmentList, order.id]">S-{{ main?.warpId }}</a></td>
    <td>{{ clientName }}</td>
    <td>
        <code *ngFor="let po of (_item.POs || [])" class="po-number clickable" (click)="copyToClipboard($event, po)">{{ po }}</code>
        <span class="copy-button clickable" *ngIf="_item.POs && _item.POs.length" (click)="copyToClipboard($event, _item.POs.join(' '))"><span nz-icon nzType="copy" nzTheme="outline"></span></span>
    </td>
    <td>
        <div [class]="{'allIdentified': _item.palletCount === identifiedPalletCount, 'partiallyIdentified': identifiedPalletCount && identifiedPalletCount < _item.palletCount}">
            <span *ngIf="_item.palletCount > 1" class="right5"><span class="font-medium">{{ _item.palletCount}}</span> Pallets</span>
            <span *ngIf="_item.palletCount === 1" class="right5"><span class="font-medium">1</span> Pallet</span>
            <span *ngIf="identifiedPalletCount">, <span class="font-medium">{{identifiedPalletCount}}</span> identified</span>
        </div>
        <div *ngIf="_item.inventory">
            <code class="inventory-code" *ngFor="let inventory of _item.inventory">
                {{ inventory.name }}
            </code>
        </div>
    </td>
    <td>
        <span *ngIf="totalWeight"><span class="font-medium">{{totalWeight.toFixed(0)}}</span> lbs</span>
    </td>
    <td>
        <div *ngFor="let shipment of inbound">
            <div>
                S-{{ shipment.warpId }}
                <a *ngIf="shipment.lastJobId" target="_blank" [routerLink]="[routeAdminDispatchList, shipment.lastJobId]"><i nz-icon nzType="rocket" nzTheme="outline"></i></a>
                <span class="copy-button clickable" (click)="copyToClipboard($event, shipment.warpId)"><span nz-icon nzType="copy" nzTheme="outline"></span></span>
            </div>
            <div [class]="['font-medium', 'small-text', 'status-' + shipment.status]">{{ shipment.status }}</div>
        </div>
    </td>
    <td>
        <div *ngFor="let shipment of outbound">
            <div>
                S-{{ shipment.warpId }}
                <a *ngIf="shipment.lastJobId" target="_blank" [routerLink]="[routeAdminDispatchList, shipment.lastJobId]"><i nz-icon nzType="rocket" nzTheme="outline"></i></a>
                <span class="copy-button clickable" (click)="copyToClipboard($event, shipment.warpId)"><span nz-icon nzType="copy" nzTheme="outline"></span></span>
            </div>
            <div [class]="['font-medium', 'small-text', 'status-' + shipment.status]">{{ shipment.status }}</div>
        </div>
    </td>
    <td>
        <div *ngIf="scheduledPickup">{{scheduledPickup}}</div>
        <!-- <div *ngIf="scheduledPickup" class="small-text">{{scheduledPickup[1]}}</div> -->
    </td>

    <td>
        <div *ngIf="pickupDate">{{pickupDate}}</div>
        <!-- <div *ngIf="pickupDate" class="small-text">{{pickupDate[1]}}</div> -->
    </td>
    <td>
        <div *ngIf="pickupLocation">
            <div class="font-medium">{{ pickupLocation.locationName }}</div>
            <div *ngIf="pickupLocation.addr" class="small-text">{{ pickupLocation.addr.city }}, {{ pickupLocation.addr.state }} {{ pickupLocation.addr.zipcode }}</div>
        </div>
    </td>
    <td>
        <div *ngIf="scheduledRelease">{{ scheduledRelease }}</div>
    </td>
    <td>
        <div *ngIf="deliverLocation">
            <div class="font-medium">{{ deliverLocation.locationName }}</div>
            <div *ngIf="deliverLocation.addr" class="small-text">{{ deliverLocation.addr.city }}, {{ deliverLocation.addr.state }} {{ deliverLocation.addr.zipcode }}</div>
        </div>
    </td>
    <td>
        <div *ngIf="injectionDate">{{injectionDate[0]}}</div>
        <div *ngIf="injectionDate" class="small-text">{{injectionDate[1]}}</div>
    </td>
    <td>
        <div *ngIf="releaseDate">{{releaseDate[0]}}</div>
        <div *ngIf="releaseDate" class="small-text">{{releaseDate[1]}}</div>
    </td>
