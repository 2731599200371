<ng-template #tplHeader>
  <div class="center-vertical">
    <i nz-icon nzType="close" nzTheme="outline" class="icon-close" (click)="closeDrawer()"></i>
    <div class="flex1">
      <div class="txt-title">Shipment Management</div>
    </div>
    <button nz-button class="right10" (click)="closeDrawer()" [disabled]="!canCancel">Cancel</button>
    <button nz-button nzType="primary" (click)="onBtnSave()" [disabled]="!canSave">Continue</button>
  </div>
</ng-template>

<ng-template #tplContent>
  <div class="add-new">
    <nz-select nzBackdrop="true" [ngModel]="newShipment" (ngModelChange)="onSelectNewShipment($event)"
      [nzServerSearch]="true" nzShowSearch [nzLoading]="false" (nzOnSearch)="searchNewShipment($event)"
      nzPlaceHolder="Search WARP ID To Add" style="width: 350px;" [nzOptionHeightPx]="162">
      <ng-container *ngFor="let shipment of newShipmentOptions">
        <nz-option nzCustomContent [nzLabel]="shipment.getWarpId()" [nzValue]="shipment">
          <div routing-shipment-item [model]="shipment" [canRemove]="false"></div>
        </nz-option>
      </ng-container>
    </nz-select>
  </div>
  <div class="current-shipment">
    <nz-table #nzTable [nzData]="shipments" nzBordered="true" nzSize="small" [nzFrontPagination]="false"
      [nzShowPagination]="false" [nzLoading]="isLoading" [nzShowPagination]="false">
      <thead>
        <tr>
          <th>ID</th>
          <th>Pickup Address</th>
          <th>Dropoff Address</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let shipment of nzTable.data">
          <td>{{ shipment.getWarpId() }}</td>
          <td>
            <b>{{shipment?.pickInfo?.locationName}}</b><br />
            {{shipment?.pickInfo?.addressText}}
          </td>
          <td>
            <b>{{shipment?.dropInfo?.locationName}}</b><br />
            {{shipment?.dropInfo?.addressText}}
          </td>
          <td>
            <button nzDanger nz-button nzType="text" nz-popconfirm
              nzPopconfirmTitle="Are you sure to remove this shipment from the route?"
              (nzOnConfirm)="onRemoveShipment(shipment)"
              >
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div>

  </div>
</ng-template>
<ng-template #tplRoutingDone>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <img class="ic_complete bottom20">
    <div class="bottom10" style="font-size: 18px; font-weight: 600; line-height: 27px;">Route shipments changed.</div>
    <div style="font-size: 16px; font-weight: 400; line-height: 27px;">Please reorder the route manually so you can apply to the load on the dispatch screen.</div>
    <button nz-button nzType="primary" style="width: 100%; margin-top: 16px;" (click)="closeDialogRoutingDone()">Close</button>
  </div>
</ng-template>