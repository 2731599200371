import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BaseFormItem } from '@app/admin/base/form-item';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';
import { LtlRoutingFilter, RoutingRegionSelectMode } from '../../interface';
import { RoutingService } from '../../services/routing.service';

type WarpIdMode = 'normal' | 'range';
const MAX_RANGE_WARPIDS = 400;

@Component({
  selector: '[routing-filter-shipment]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../../../../styles/form-v2.scss']
})
export class RoutingFilterShipment extends BaseFormItem {
  static readonly labelPickDateRange = 'Pickup Date Range'
  protected formGroupDeclaration: FormGroupDeclaration = {
    warpIds: { label: 'WARP ID', placeHolder: 'Enter WARP ID', notAcceptEmpty: true },
    clientIds: { label: 'Customer', placeHolder: 'Select from list', notAcceptEmpty: true },
    pickupWarehouseId: { label: 'Pickup From', placeHolder: 'Select warehouse', notAcceptEmpty: true },
    dropoffWarehouseId: { label: 'Deliver To', placeHolder: 'Select warehouse', notAcceptEmpty: true },
    pickDateRange: { label: RoutingFilterShipment.labelPickDateRange, notAcceptEmpty: true },
  }

  allWarehouses = [];
  allClients = [];
  isLoadingFilterData: boolean = true;
  
  warpIdMode: WarpIdMode = 'normal';
  maxNumWarpId = 200;

  @Input() disabled = false;
  onProgress = false;

  constructor(private routingService: RoutingService) {
    super();
    // this.onWarpIdModeChange('range');
  }

  get isCreateNew(): boolean {
    return true;
  }

  get hintWarpId() {
    if (this.warpIdMode == 'range') {
      return 'Please enter the first & last WARP IDs of the range';
    } else {
      return 'Can be multiple WARP IDs';
    }
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.init()
  };

  async init() {
    this.isLoadingFilterData = true;
    const { clients, warehouses } = await this.routingService.getDataForFilter();
    this.allClients = clients;
    this.allWarehouses = warehouses;
    this.isLoadingFilterData = false;
  }

  onCrossDockChange(key: string, warehouseId) {
    let oppsiteKey;
    switch (key) {
      case 'pickupWarehouseId':
        oppsiteKey = 'dropoffWarehouseId';
        break;
      case 'dropoffWarehouseId':
        oppsiteKey = 'pickupWarehouseId';
        break;
    }
    if (oppsiteKey) {
      // Filter warehouse thì chỉ cho chọn 1 trong 2 (hoặc pickup hoặc dropoff)
      if (warehouseId) {
        this.setItemValue(oppsiteKey, null);
        this.formInput.get(oppsiteKey).disable();
      } else {
        this.formInput.get(oppsiteKey).enable();
      }
    }
    let timezone;
    if (warehouseId) {
      let warehouse = this.allWarehouses.filter(it => it._id == warehouseId)[0];
      timezone = warehouse?.address?.metadata?.timeZoneStandard;
    }
    let label = RoutingFilterShipment.labelPickDateRange;
    if (timezone) {
      label += ` (${timezone})`;
    }
    this.formGroupDeclaration.pickDateRange.label = label;
  }

  private getTimeZone() {
    return this.formGroupDeclaration.pickDateRange.label.substring(RoutingFilterShipment.labelPickDateRange.length).replace(/[\s\(\)]*/g, '');
  }

  resetFilter(disabled = false) {
    this.formInput = null;
    this.createFormInput();
    if (disabled) {
      setTimeout(() => this.setEnableFormGroup(false), 50);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!event.ctrlKey) {
      return;
    }
    switch (event.key) {
      case 'F':
      case 'f':
        this.onBtnSave();
        break;
    }
  }

  onWarpIdModeChange(mode: WarpIdMode) {
    if (this.warpIdMode == mode) {
      return;
    }
    this.warpIdMode = mode;
    this.setItemValue('warpIds', null);
    switch (mode) {
      case 'normal':
        this.formGroupDeclaration.warpIds.label = 'WARP ID';
        this.maxNumWarpId = 200;
        break;
      case 'range':
        this.formGroupDeclaration.warpIds.label = 'WARP ID (Range Mode)';
        this.maxNumWarpId = 2;
        break;
    }
  }

  getNameWarpIdMode(mode: WarpIdMode) {
    switch (mode) {
      case 'normal': return 'Normal Mode';
      case 'range': return 'Range Mode';
      default: return mode;
    }
  }

  protected setEnableFormGroup(enabled: boolean): void {
    if (this.disabled) {
      super.setEnableFormGroup(false);
    } else {
      super.setEnableFormGroup(enabled);
    }
  }

  private isFilterValid(data) {
    if (data.warpIds || data.pickDateRange) return true;
    if (data.clientIds && (data.pickupWarehouseId || data.dropoffWarehouseId)) return true;
    return false;
  }

  onBtnSave(): void {
    let timezone = this.getTimeZone();
    let json: any = this.getFormData_JSON(this.isCreateNew);
    if (!this.isFilterValid(json)) {
      return this.showDialog(`Please enter at least ${this.formGroupDeclaration.warpIds.label}, ${this.formGroupDeclaration.pickDateRange.label} or ${this.formGroupDeclaration.clientIds.label} with cross dock warehouse to search shipments`);
    }
    if (json.warpIds) {
      let warpIds = this.validateWarpIds(json.warpIds);
      if (typeof warpIds == 'string') {
        return this.showDialog(warpIds);
      }
      json.warpIds = warpIds;
    }
    if (json.pickDateRange) {
      let fromDate = <Date>json.pickDateRange[0];
      let toDate = <Date>json.pickDateRange[1];
      fromDate = DateUtil.toBeginOfDay(fromDate);
      toDate = DateUtil.toEndOfDay(toDate);
      if (timezone) {
        fromDate = DateUtil.convertLocalTime(fromDate, timezone);
        toDate = DateUtil.convertLocalTime(toDate, timezone);
      }
      json.pickupFromDate = fromDate.toISOString();
      json.pickupToDate = toDate.toISOString();
      delete json.pickDateRange;
    }
    this.onFilter(json);
  }

  private validateWarpIds(warpIds: any[]): string | number[] {
    let arr: number[] = [];
    for (let item of warpIds) {
      if (typeof item == 'number') {
        arr.push(item);
      } else if (typeof item == 'string') {
        if (!/[0-9]+/g.test(item)) {
          return `Invalid WARP ID <b>${item}</b>`;
        }
        let num = Number(item);
        if (isNaN(num) || num == 0) {
          return `Invalid WARP ID <b>${item}</b>`;
        }
        arr.push(num);
      }
    }
    if (this.warpIdMode == 'range') {
      // Nếu chọn range thì tự động tạo 1 mảng các WARP ID dựa vào giá trị đầu và giá trị cuối
      if (arr[0] >= arr[1]) {
        return 'Invalid WARP ID range, the last value must be greater than the first value';
      }
      if (arr[1] - arr[0] > MAX_RANGE_WARPIDS) {
        return `WARP ID range should not be greater than ${MAX_RANGE_WARPIDS}`;
      }
      let range = [];
      for (let i = arr[0]; i <= arr[arr.length - 1]; i++) {
        range.push(i);
      }
      return range;
    }
    return arr;
  }

  
  async onFilter(condition: LtlRoutingFilter) {
    let selectRegionMode;
    // Trên màn filter sẽ chỉ được chọn 1 trong 2 dropoffCrossDockId hoặc pickupCrossDockId (không thể chọn đồng thời cả 2)
    if (condition.dropoffWarehouseId) {
      // Nếu chọn tất cả các shipment đến 1 cross dock => nhiều pickup, 1 dropoff => select region bằng cách chọn các điểm pickup
      selectRegionMode = RoutingRegionSelectMode.byPickUp;
    } else if (condition.pickupWarehouseId) {
      // Nếu chọn tất cả các shipment xuất phát từ 1 cross dock => 1 pickup, nhiều dropoff => select region bằng cách chọn các điểm dropoff
      selectRegionMode = RoutingRegionSelectMode.byDropOff;
    } else {
      // Mặc định là select region bằng cách chọn các điểm dropoff
      selectRegionMode = RoutingRegionSelectMode.byDropOff;
    }
    this.onProgress = true;
    this.routingService.getListShipmentFilter({ condition, selectRegionMode });
    this.onProgress = false;
  }
   warpIdsInput : any;
   handleSearch(event: any){
    if(event.keyCode == 13){
      let handledInput = [];
      let currentInput = this.warpIdsInput;
      for(let i=0;i<currentInput.length;i++){
        if(currentInput[i].includes(',') || currentInput[i].includes(' ')){
          let tmp = currentInput[i].split(/[\s,]+/);
          handledInput.push(...tmp);
        }else{
          handledInput.push(currentInput[i]);
        }
      }
      this.warpIdsInput = handledInput;
    }

   }
}
