import { Component } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: '[book-appointment]',
  templateUrl: './book-appointment.html',
  styleUrls: ['../../dialogs/dialogs.scss', '../../../styles/form-v2.scss']
})
export class FormBookAppointment extends BaseFormDialog1 {
  public static get declaration(): FormGroupDeclaration {return {
    date: {label: 'Select Date', type: 'date', required: false, getValue: v => v},
    fromTime: {label: 'From', type: 'time', required: false, getValue: v => v},
    toTime: {label: 'To', type: 'time', required: false, getValue: v => v},
    // timezone: {label: 'Time zone', required: true}
  }}
  protected formGroupDeclaration: FormGroupDeclaration = FormBookAppointment.declaration;

  public row: FormRow = {columns: [
    {key: 'date', span: 10, offset: 0},
    {key: 'fromTime', span: 6, offset: 1},
    {key: 'toTime', span: 6, offset: 1},
  ]}

  get isCreateNew(): boolean {
    return !this.model;
  }

  listTimezones = DateUtil.listTimezones;
  public onSave: (data) => void = () => {}

  onBtnSave() {
    let data: any = this.getFormData_JSON(true);
    this.onSave(data);
    this.closeDialog();
  }

  onBtnCancel() {
    this.closeDialog();
  }
}