import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
    selector: '[short-location-info]',
    templateUrl: './short_location.html',
    styleUrls: ['./styles.scss']
})
export class ShortLocationInfo extends Component {
    @Input() info: any = null
}