<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">{{strTitle}}</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form class="form-detail shipment-item" [formGroup]="formInput" nz-form style="padding: 26px;">
  <div class="name-id">
    <div *ngFor="let key of ['name', 'itemId']; let i = index" [ngClass]="key">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item>
        <nz-form-control>
          <input nz-input [formControlName]="key">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row *ngFor="let row of [row1, row2]" style="max-width: 520px;">
    <ng-container *ngTemplateOutlet="tplRow1;context:{row}"></ng-container>
  </div>
  <ng-template #tplRow1 let-row="row">
    <div nz-col *ngFor="let col of row.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
      <ng-container *ngIf="shouldShowField(col.key)">
        <div class="form-label-v2 single-line fix-height">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key) && getLabel(col.key).length > 0" class="label-mark-required"></span></div>
        <nz-form-item>
          <nz-form-control>
            <ng-container [ngSwitch]="col.key">
              <nz-select nzBackdrop="true" *ngSwitchCase="'qtyUnit'" [formControlName]="col.key" (ngModelChange)="onModelChange(col.key)" [ngClass]="col.key">
                <nz-option *ngFor="let item of qtyUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
              </nz-select>
              <nz-select nzBackdrop="true" *ngSwitchCase="'weightUnit'" [formControlName]="col.key" [ngClass]="col.key">
                <nz-option *ngFor="let item of weightUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
              </nz-select>
              <nz-select nzBackdrop="true" *ngSwitchCase="'sizeUnit'" [formControlName]="col.key" [ngClass]="col.key">
                <nz-option *ngFor="let item of sizeUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
              </nz-select>
              <input *ngSwitchDefault nz-input [formControlName]="col.key" [ngClass]="col.key"
                [type]="getInputType(col.key)" 
                [placeholder]="getPlaceHolder(col.key)"
                (input)="onInputChanged($event, col.key)" 
                (keypress)="onInputKeyPress($event, col.key)">
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </div>
  </ng-template>

  <div class="flex-column">
    <ng-container *ngFor="let key of ['isStackable', 'isTemperatureControlled', 'temperatureControlled', 'isHazardous', 'hazardous']">
      <div *ngIf="isBool(key) && shouldShowField(key)" nz-checkbox [formControlName]="key"
        style="margin-left: 0; margin-bottom: 15px; width: fit-content;">
        {{getLabel(key)}}
      </div>
      <div *ngIf="!isBool(key) && getCheckValueFor(key) === true">
        <form nz-form *ngIf="key == 'temperatureControlled'" [formGroupName]="key">
          <div nz-row>
            <div nz-col *ngFor="let col of row3.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
              <div class="form-label-v2 single-line fix-height">{{getLabel(fullKey(key, col.key))}}<span *ngIf="isRequired(fullKey(key, col.key)) && getLabel(fullKey(key, col.key)).length > 0" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="col.key"
                    [type]="getInputType(fullKey(key, col.key))" 
                    [placeholder]="getPlaceHolder(fullKey(key, col.key))"
                    (input)="onInputChanged($event, col.key)" 
                    (keypress)="onInputKeyPress($event, col.key)">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
        <form nz-form *ngIf="key == 'hazardous'" [formGroupName]="key">
          <div nz-row *ngFor="let row of rowsHazardous">
            <div nz-col *ngFor="let col of row.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
              <div class="form-label-v2 single-line fix-height">{{getLabel(fullKey(key, col.key))}}<span *ngIf="isRequired(fullKey(key, col.key)) && getLabel(fullKey(key, col.key)).length > 0" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="col.key"
                    [type]="getInputType(fullKey(key, col.key))" 
                    [placeholder]="getPlaceHolder(fullKey(key, col.key))"
                    (input)="onInputChanged($event, col.key)" 
                    (keypress)="onInputKeyPress($event, col.key)">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
  </div>

</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" [canClickCancel]="true" 
  [nzIconOK]="null" [labelOK]="strButtonSubmit" (onOK)="onBtnSave()" 
  [nzIconCancel]="null" (onCancel)="onBtnCancel()"></div>
