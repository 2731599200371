<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Add Shipment</div>
  </div>
</ng-template>
<div>
  <div class="flex">
    <div class="flex1">
      <div class="f16m bottom10">Pickup Location</div>
      <div #formPickup form-shipment-location type="PICKUP"></div>
    </div>
    <div class="border"></div>
    <div class="flex1">
      <div class="f16m bottom10">Delivery Location</div>
      <div #formDropoff form-shipment-location type="DROPOFF"></div>
    </div>
  </div>
  <div #formItems form-order-items></div>
</div>
<div class="separator h top30"></div>
<div form-footer class="no-border"
  [canClickOK]="true" 
  [canClickCancel]="true"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Add" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>