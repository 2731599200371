<div class="header">Shipment {{ shipmentCode }}</div>
<div class="content">
    <div *ngIf="pickupDate"><span class="label">Pickup:</span> {{ pickupDate }}</div>
    <div *ngIf="deliverDate"><span class="label">Deliver:</span> {{ deliverDate }}</div>
    <!-- <div class="bottom5">
        <span class="label">Track:</span> {{ shipment.trackingCode }}
    </div> -->
    <div class="bottom10"><span class="label">Status: </span><span class="title status" [class]="'status-' + shipment.status">{{ status }}</span></div>
    <div class="bottom10" *ngIf="shipment?.externalId">
        3rd party: <span third-party-info-icon [shipment]="shipment"></span>
    </div>
    <div *ngIf="shipment?.metadata?.traffic.distance"><span class="label">Mileage: </span> <span class="title">{{ (shipment?.metadata?.traffic.distance / 1609.34).toFixed(1) }}</span></div>
    <div *ngIf="shipment?.metadata?.traffic.time"><span class="label">Hours: </span> <span class="title">{{ (shipment?.metadata?.traffic.time / 3600).toFixed(1) }}</span></div>

    <!-- <div *ngIf="shipment?.cost?.grandTotal"><span class="label">Revenue: </span><span class="title">{{ shipment?.cost?.grandTotal }}</span></div>
    <div *ngIf="shipment?.carrierRate"><span class="label">Cost: </span><span class="title">{{ shipment?.carrierRate }}</span></div> -->
    <div *ngIf="shipment?.finance?.revenueAllocation"><span class="label">Revenue allocation:</span> $<span class="title">{{ shipment?.finance?.revenueAllocation.toFixed(2) }}</span></div>
    <div *ngIf="shipment?.finance?.costAllocation"><span class="label">Cost allocation:</span> $<span class="title">{{ shipment?.finance?.costAllocation.toFixed(2) }}</span></div>
</div>

<div *ngIf="contextMenu" class="content">
    <div class="bottom10">
        <!-- <button (click)="onClick('SHOW_DETAIL')" style="width: 100%" nz-button>View Details</button> -->
        <!-- <a [routerLink]="[routeAdminShipmentList, shipment._id]" target="_blank">View Details</a> -->
    </div>
    <div class="bottom10" *ngIf="shipment.lastJobId">
        <a [routerLink]="[routeAdminDispatchList, shipment.lastJobId]" target="_blank">View Attached Route</a>
    </div>
    <ng-container *ngIf="showEvents">
        <div class="clickable" (click)="showEventHistory('SHIPMENT', shipment.id)">
            <span class="right5" nz-icon nzType="history" nzTheme="outline"></span> Show Event History
        </div>
    </ng-container>

    <!-- <div class="bottom10">
        <button (click)="onClick('INSERT_CROSSDOCK')" style="width: 100%" nz-button>Insert Crossdock</button>
    </div> -->
    <div class="bottom10" *ngIf="!viewOnly">
        <button *ngIf="shipment.parentId" (click)="confirming='DUPLICATE'" style="width: 100%" nz-button>Duplicate Leg</button>
    </div>
</div>    

<div *ngIf="confirming" class="confirming">
    <div class="bg"></div>
    <div class="body" *ngIf="confirming=='DUPLICATE'">
        <div class="bottom20">
            Please confirm you want to duplicate shipment {{ shipment?._id }} into a new leg:
        </div>
        <div class="bottom10">
            <button nzType="primary" (click)="onClick('DUPLICATE')" style="width: 100%" nz-button>CONFIRM</button>
        </div>
        <div class="bottom10">
            <button nzType="danger" (click)="confirming=null" style="width: 100%" nz-button>CANCEL</button>
        </div>
    </div>
</div>

