import { Component } from '@angular/core';
import { DrawerService } from '@app/drawers/drawer.service';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';
import { Utils } from '@services/utils';
import { BaseList } from '../../base/list';
import { AllOrder } from './all-orders';

@Component({
  selector: 'routing-problem-list',
  templateUrl: './list.html',
  styleUrls: ['./style.scss', '../../list.scss']
})
export class RoutingProblemList extends BaseList {
  get limit() {
    return 15;
  }

  constructor() {
    super();
  }

  protected getApiUrl(): string {
    return Const.APIURI_LTL_ROUTING('problems');
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    super.handleNavigationEnd(url, prevQueryParam);
  }

  protected getItemId(item) {
    return item.id;
  }

  protected confirmDeleteMessage(item): string {
    return `Delete the problem <b>${item.id}</b> (which includes ${item.num_shipments} shipments)?`;
  }

  protected notifyItemDeletedMessage(item: any): string {
    return `Problem <b>${item.id}</b> has been deleted.`;
  }
  
  getCreatedDate(item) {
    return DateUtil.format(item._created, "ddd MMM DD HH:mm");
  }

  getDeliveryDate(item) {
    return DateUtil.format(item.dropoff_earliest_ts, "ddd MMM DD");
  }

  shouldShowListClient(item) {
    return Utils.isArrayNotEmpty(item.clients);
  }

  shouldShowListShipment(item) {
    return Utils.isArrayNotEmpty(item.shipmentIds);
  }

  getShipmentIds(item) {
    let str: string;
    if (Utils.isArrayNotEmpty(item.shipmentWarpIds)) {
      let shipmentIds = item.shipmentWarpIds;
      str = `${shipmentIds[0]}`;
      if (shipmentIds.length > 1) {
        str += ` (+${shipmentIds.length - 1})`;
      }
    } else {
      str = `${item.num_shipments}`;
    }
    return str;
  }

  shouldShowShipmentIds(item) {
    return Utils.isArrayNotEmpty(item.shipmentIds);
  }

  seeAllOrder(item) {
    DrawerService.openFormDrawer(AllOrder, {
      nzContentParams: {
        listOrderIds: item.shipmentWarpIds
      },
    });
  }

}