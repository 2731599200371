import { Component, Input } from "@angular/core";

@Component({
  selector: 'div[batch-location-side-indicator]',
  templateUrl: './view.html',
  styleUrls: [
    './style.scss',
  ],
  // host: {
  //   class: 'label-mark-required'
  // }
})
export class BatchLocationSideIndicator {
  @Input() isDone = false;
}