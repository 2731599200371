<ng-container *ngIf="isEmpty">
  <empty-info></empty-info>
</ng-container>
<ng-container *ngIf="customerAndEquipment">
  <customer-equipment-info [model]="customerAndEquipment"></customer-equipment-info>
</ng-container>
<div *ngIf="!isEmpty">
  <div [infos]="locations" batch-map ></div>
</div>
<drag-and-drop-locations [batchShipmentType]="modelDraft?.shipmentEntryMode" [locations]="locations" (onChange)="onChangeLocations($event)"></drag-and-drop-locations>
