import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { BatchMap } from './components/shipment-info/map';
import { SharedModule } from '@app/admin/shared/shared.module';
import { DragAndDropLocations } from './components/shipment-batch/drag-and-drop-locations';
import { DialogsModule } from '@dialogs/dialogs.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BatchBigMapModal } from './components/shipment-info/big-map';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzIconModule,
    NzButtonModule,
    SharedModule,
    DialogsModule,
    DragDropModule
    // DispatchModule
  ],
  declarations: [
    BatchMap,
    DragAndDropLocations,
    BatchBigMapModal,
  ],
  exports: [
    BatchMap,
    DragAndDropLocations,
    BatchBigMapModal,
  ],
  providers: [
  ]
})
export class ShipmentEntryShareModule {}