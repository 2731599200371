import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input, OnInit } from "@angular/core";
import { BizUtil } from "@services/biz";

@Component({
  selector: "items-info",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class ItemsInfo implements OnInit {
  @Input("items") listItems: Array<any> = [];

  ngOnInit(): void {}

  get hasItems(): boolean {
    return this.listItems != null && this.listItems?.length != 0;
  }
  getItemName(item, i) {
    return item?.name || `Item ${i + 1}`;
  }
  getItemSize(item) {
    if (!item?.sum?.desc) {
      const sum = BizUtil.getItemSumary(item);
      return sum.desc;
    }
    return item?.sum?.desc;
  }
}
