<div class="flex batch-location-side-indicator-container" *ngFor="let item of uiCollapseState.shipments; let i = index;">
  <div batch-location-side-indicator [isDone]="false"></div>
  <nz-collapse class="flex1 bottom20 collapse-form-location">
    <nz-collapse-panel [nzShowArrow]="false" [nzHeader]="tplHeader" [(nzActive)]="item.isExpand">
      <ng-template #tplHeader>
        <div class="flex1 flex">
          <div class="flex1 f16m center-vertical">Shipment {{i + 1}}</div>
          <button *ngIf="countShipments > 1" nz-button nzSize="large" nzType="text" nzDanger (click)="onBtnRemoveShipment(i);$event.stopPropagation();" class="right10">
            <span nz-icon nzType="close-square" nzTheme="outline"></span>
            Remove
          </button>
          <button *ngIf="!item.isExpand" nz-button nzSize="large" [disabled]="item.isLoading">Edit</button>
          <button *ngIf="item.isExpand" nz-button nzSize="large" [disabled]="item.isLoading" class="right10">Close</button>
          <button *ngIf="item.isExpand" nz-button nzSize="large" nzType="primary" (click)="onBtnSaveShipment(i);$event.stopPropagation();" [nzLoading]="item.isLoading">Save</button>
        </div>
      </ng-template>
      <div class="flex">
        <div class="flex1">
          <div class="f16m bottom10">Pickup Location</div>
          <div #formPickup form-shipment-location type="PICKUP" [model]="item.data.pickInfo" predefinedTemplate="mobile"></div>
        </div>
        <div class="border"></div>
        <div class="flex1">
          <div class="f16m bottom10">Delivery Location</div>
          <div #formDropoff form-shipment-location type="DROPOFF" [model]="item.data.dropInfo" predefinedTemplate="mobile"></div>
        </div>
      </div>
      <div class="list-items-header">
        <div>Items</div>
        <button nz-button nzType="link" (click)="onBtnUploadListItems(i)">+ Upload Item List (excel/csv)</button>
      </div>
      <div #formItems form-order-items [model]="item.data"></div>
    </nz-collapse-panel>
  </nz-collapse>
</div>

<button nz-button nzSize="large" (click)="onBtnAddShipment()" style="margin-left: 40px;">Add Another Shipment</button>