<div class="flex-column" style="height: 100%;">
  <div class="flex-column" style="flex: 1">
    <div class="title">Route Sorting</div>
    <div class="tasks">
      <div class="task-title" nz-tooltip="Select markers on the map to add tasks.">
        Route Details: {{this.selecteds.length}}/{{this.totalTasks()}} tasks
      </div>
      <div class="task-list" *ngIf="this.selecteds.length">
        <div cdkDropListGroup>
          <div class="example-container">
            <div cdkDropList [cdkDropListData]="selecteds" class="list" (cdkDropListDropped)="drop($event)">
              <div class="map-reorder-task-item" *ngFor="let item of selecteds" cdkDrag [ngClass]="item.type">
                <div class="content" nz-tooltip="Drag to change the task order." nzTooltipPlacement="right">
                  <div class="title">
                    <nz-tag [nzColor]="item.type == 'DROPOFF'? 'green': 'purple'">
                      {{item.type}} {{showWarpId(item)}}
                    </nz-tag>
                    <div class="stops" *ngIf="item.stopNum" nz-tooltip="Stops">{{item.stopNum}}</div>
                  </div>
                  <div class="description">
                    <div class="address">
                      {{item?.info?.addressText}}
                    </div>
                    <div class="invalidTask" *ngIf="!isValidTask(item)">
                      The position of the task is not valid. It could be because the DROPOFF task is already before the
                      PICKUP task. Please check again.
                    </div>
                  </div>
                </div>
                <div class="miles">
                  <nz-tag>
                    {{(item.distance / 1609.34).toFixed(2).toLocaleString()}} miles,
                    {{(item.time/ 3600).toFixed(2).toLocaleString()}} hours</nz-tag>
                </div>
                <div class="hide-dashed-line"></div>
              </div>
            </div>
          </div>
          <!-- 
          <div class="example-container">
            <h2>Done</h2>

            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
              <div class="example-box" *ngFor="let item of selecteds" cdkDrag>{{item}}</div>
            </div>
          </div> -->
        </div>
      </div>
      <div *ngIf="!this.selecteds.length" class="nodata">
        <i nz-icon nzType="search" nzTheme="outline"></i>
        <br />
        No task is selected
        <div class="small-text">
          Please select the tasks by clicking on markers on the map.
        </div>
      </div>
    </div>
  </div>
</div>