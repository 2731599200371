<form [formGroup]="formInput" nz-form>
  <div style="display: flex; flex-wrap: wrap;">
    <ng-container *ngFor="let key of formInputKeys">
      <div class="form-item {{key}}">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <ng-container [ngSwitch]="key">
          <nz-input-group *ngSwitchCase="'warpIds'" [nzSuffix]="tplWarpIdOptions" [ngClass]="{'disabled': disabled}">
            <nz-select
              nzBackdrop="true"
              class="warpIds"
              style="min-width: 150px"
              nzMode="tags"
              [nzMaxMultipleCount]="maxNumWarpId"
              [nzDropdownClassName]="key"
              [formControlName]="key"
              [nzPlaceHolder]="getPlaceHolder(key)"
              (keydown)="handleSearch($event)"
              [(ngModel)]="warpIdsInput"
            >
              <nz-option [nzLabel]="hintWarpId" nzValue="" nzDisabled></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #tplWarpIdOptions>
            <div nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight" [nzDisabled]="disabled" style="cursor: pointer;">
              <span nz-icon nzType="more"></span>
            </div>
            <nz-dropdown-menu #menu1="nzDropdownMenu">
              <ul nz-menu>
                <li *ngFor="let mode of ['normal', 'range']" 
                  nz-menu-item 
                  (click)="onWarpIdModeChange(mode)">{{getNameWarpIdMode(mode)}}
                  <span *ngIf="warpIdMode == mode" nz-icon nzType="check" nzTheme="outline" style="margin-left: 15px;"></span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </ng-template>
          
          <nz-select nzBackdrop="true" *ngSwitchCase="'clientIds'" style="min-width: 150px;"
            nzMode="multiple" nzAllowClear
            [nzLoading]="true"
            [nzDropdownClassName]="key"
            [formControlName]="key" 
            [nzPlaceHolder]="getPlaceHolder(key)" >
            <nz-option *ngFor="let item of allClients" [nzLabel]="item.name" [nzValue]="item.id">
            </nz-option>
          </nz-select>

          <nz-range-picker *ngSwitchCase="'pickDateRange'" [formControlName]="key"></nz-range-picker>

          <nz-select nzBackdrop="true" *ngSwitchDefault style="min-width: 150px;"
            nzMode="default" nzAllowClear
            [nzLoading]="isLoadingFilterData"
            [nzDropdownClassName]="key"
            [formControlName]="key" 
            [nzPlaceHolder]="getPlaceHolder(key)"
            [nzShowSearch]="true"
            (ngModelChange)="onCrossDockChange(key, $event)" >
            <nz-option *ngFor="let item of allWarehouses" [nzLabel]="item.name" [nzValue]="item.id">
            </nz-option>
          </nz-select>

          <!-- <input *ngSwitchDefault nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)"> -->
        </ng-container>
      </div>
    </ng-container>

    <div class="form-item">
      <div class="form-label-v2"></div>
      <button nz-button nzType="default" 
        (click)="onBtnSave()" [disabled]="disabled || onProgress || !needUpdate" 
        [nzLoading]="onProgress">
        <span nz-icon nzType="filter" nzTheme="outline"></span>
        Filter Shipments
      </button>
    </div>
    
  </div>
</form>