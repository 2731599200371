<div *ngIf="!hasAddress(form)" class="no-address">Address has not been entered yet</div>
<ng-container *ngIf="hasAddress(form)">
  <div class="flex">
    <div class="location-name">{{locationName(form)}}</div>
    <div class="location-address">{{locationAddress(form)}}</div>
  </div>
  <div *ngIf="!hasTimeWindows(form)" class="no-address">Time window has not been entered yet</div>
  <ng-container *ngIf="hasTimeWindows(form)">
    <div *ngFor="let s of timeWindows(form)">{{s}}</div>
  </ng-container>
</ng-container>