<div class="dashboard-child-container no-padding list">
  <div class="list-header small">
    <div report-pod-header-filter #reportPodHeaderFilter></div>
  </div>
  
  <div class="pod-data">
    <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    <div *ngIf="!isLoading">
      <div class="flex bottom5">
        <div>Number of Pod Uploaded:</div>
        <div class="left5">{{ displayInfo?.numberOfPodUploaded }}</div>
      </div>
      <div class="flex bottom5">
        <div>Number of Pod Uploaded Wrong:</div>
        <div class="left5">{{ displayInfo?.numberOfPodUploadedWrong }}</div>
      </div>
      <div class="flex bottom5">
        <div>Number of Pod Confirmed:</div>
        <div class="left5">{{ displayInfo?.numberOfPodConfirmed }}</div>
      </div>
      <div class="top20">
        <div *ngIf="dataChart" chart-bar id="chart-data-pod" [options]="dataChart"></div>
      </div>
    </div>
  </div>
</div>