<div style="padding: 20px;">
    <div class="top10">
        <div class="bottom10">
            <span>Currency: {{cost?.currency?.type || 'USD'}}</span>
            <span *ngIf="editMode" class="clickable" (click)="onBtnEdit()"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
        </div>
        <div nz-row [nzGutter]="{ sm: 16 }" *ngIf="cost?.currency?.type && cost.currency.type !== 'USD'"
            style="margin-bottom: 5px;">
            <div nz-col nzSm="6" nzMd="6">
                <span>FX Rate</span>
            </div>
            <div nz-col nzSm="6" nzMd="6">
                <span>{{cost?.currency?.fxRate || 'N/A'}}</span>
            </div>
        </div>
        <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 5px;">
            <div nz-col nzSm="11" nzMd="11"></div>
            <div nz-col nzSm="5" nzMd="5">Rate</div>
            <div nz-col nzSm="3" nzMd="3">Qty</div>
            <div nz-col nzSm="5" nzMd="5">Total</div>
        </div>
        <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 15px;">
            <div nz-col nzSm="11" nzMd="11">Transit Cost</div>
            <div nz-col nzSm="5" nzMd="5" decimal [decimals]="2" [value]="cost?.transitCost?.rate" default="0.0"></div>
            <div nz-col nzSm="3" nzMd="3">{{cost?.transitCost?.qty || ''}}</div>
            <div nz-col nzSm="5" nzMd="5" decimal [decimals]="2" [value]="cost?.transitCost?.total" default="0.0"></div>
        </div>
        <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 15px;" *ngIf="cost?.volumeDiscount">
            <div nz-col nzSm="11" nzMd="11">
                <span>Volume Discount</span>
                <span style="margin-left: 5px;">({{getVolumeDiscountType(cost)}})</span>
            </div>
            <div nz-col nzSm="5" nzMd="5">
                <ng-container *ngIf="cost.volumeDiscount?.type == 'percentage'">
                    <span>{{cost?.volumeDiscount?.percentage || ''}}%</span>
                </ng-container>
                <ng-container *ngIf="cost.volumeDiscount?.type == 'flatRate'">
                    <span>{{cost?.volumeDiscount?.flatRate || ''}}</span>
                </ng-container>
            </div>
            <div nz-col nzSm="3" nzMd="3">
                <ng-container *ngIf="cost?.volumeDiscount?.type == 'percentage'">
                    <span>-</span>
                </ng-container>
                <ng-container *ngIf="cost?.volumeDiscount?.type == 'flatRate'">
                    <span>{{cost?.volumeDiscount?.qty || ''}}</span>
                </ng-container>
            </div>
            <div nz-col nzSm="5" nzMd="5">
                <span decimal [decimals]="2" [value]="cost?.volumeDiscount?.total" default="0.0"></span>
            </div>
        </div>
        <div class="separator h bottom10"></div>
        <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 15px;">
            <div nz-col nzSm="11" nzMd="11">
                <span class="semibold">Sub Total</span>
            </div>
            <div nz-col nzSm="5" nzMd="5" nzOffset="8">
                <span class="semibold" decimal [decimals]="2" [value]="cost?.subTotal" default="0.0"></span>
            </div>
        </div>
        <div class="separator h bottom10"></div>
        <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 15px;">
            <div nz-col nzSm="11" nzMd="11">
                <span>Fuel Cost</span>
                <span style="margin-left: 5px;"></span>
            </div>
            <div nz-col nzSm="5" nzMd="5">
                <ng-container *ngIf="cost?.fuelCost?.type == 'rpm'">
                    <span decimal [decimals]="2" [value]="cost?.fuelCost?.rpm" default="0.0"></span>
                </ng-container>
                <ng-container *ngIf="cost?.fuelCost?.type == 'percentage'">
                    <span>{{cost?.fuelCost?.percentage || ''}}%</span>
                </ng-container>
            </div>
            <div nz-col nzSm="3" nzMd="3">
                <ng-container *ngIf="cost?.fuelCost?.type == 'rpm'">
                    <span>{{cost?.fuelCost?.qty || ''}}</span>
                </ng-container>
                <ng-container *ngIf="cost?.fuelCost?.type == 'percentage'">
                    <span>-</span>
                </ng-container>
            </div>
            <div nz-col nzSm="5" nzMd="5">
                <span decimal [decimals]="2" [value]="cost?.fuelCost?.total" default="0.0"></span>
            </div>
        </div>
        <div class="separator h bottom10"></div>
        <div style="margin-bottom: 5px;">Service Options:</div>
        <div *ngFor="let item of cost?.serviceOptions" nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 3px;">
            <div nz-col nzSm="11" nzMd="11">
                <span>{{getServiceOptionName(item._id)}}</span>
            </div>
            <div nz-col nzSm="5" nzMd="5">
                <span decimal [decimals]="2" [value]="item.rate"></span>
            </div>
            <div nz-col nzSm="3" nzMd="3">
                <span>{{item?.qty}}</span>
            </div>
            <div nz-col nzSm="5" nzMd="5">
                <span decimal [decimals]="2" [value]="item?.total"></span>
            </div>
        </div>
        <div class="separator h top10 bottom10"></div>
        <div nz-row [nzGutter]="{ sm: 16 }" style="margin-top: 15px;">
            <div nz-col nzSm="19">
                <span class="grand-total">Grand Total ({{cost?.currency?.type || 'USD'}}):</span>
            </div>
            <div nz-col nzSm="5">
                <span class="grand-total" decimal [decimals]="2" [value]="cost?.grandTotal" default="0.0"></span>
            </div>
        </div>
        <ng-container *ngIf="cost?.currency?.type && cost.currency.type !== 'USD'">
            <div class="separator h top10 bottom10"></div>
            <div nz-row [nzGutter]="{ sm: 16 }" style="margin-top: 15px;">
                <div nz-col nzSm="19">
                    <span class="grand-total">USD Conversion:</span>
                </div>
                <div nz-col nzSm="5">
                    <span class="grand-total" decimal [decimals]="2" [value]="cost?.usdConversion" default="0.0"></span>
                </div>
            </div>
        </ng-container>
    </div>
</div>