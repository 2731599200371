import { RoutingColor } from "@app/admin/routing-tool/color";
import { Const } from "@const/Const";
import { getInjector } from "@services/index";
import { MapService } from "../../map.service";
import { RoutingService } from "../../routing.service";
import { ShipmentManagement } from "../../shipmentManagement";
import { MapLine, MapMarker } from "../map";
import { Route } from "../route";
import { BaseRouteFeature } from "./baseFeature";
import { Utils } from "@services/utils";


export class RoutingRouteReOrder extends BaseRouteFeature {
  private route: Route;
  private newSort = [];
  public selectedTasks: any[] = []
  private newSortLine: MapLine;

  //properties for UI
  public showMarkerPopup = false;
  public editData = {
    addressInfo: {},
    tasks: [],
  }

  constructor(route) {
    super();
    this.route = route;
    this.start();
  }

  public start() {
    this.mapService.reset();
    this.route.addToMap({ color: "#ccc" })

    const tasks = this.route.getTasks();
    tasks.map((task, index) => {
      const lnglat = [task.location.longitude, task.location.latitude];
      this.mapService.addMarker({
        location: lnglat,
        data: task
      })
        .onClick((marker: MapMarker) => this.onMarkerClick(marker))
        .setPaint({ color: RoutingColor.getMarkerColor(task.type) })
    });

    this.newSortLine = this.mapService.addLine({
      id: `${this.route.getId()}-sorting`,
      coordinates: []
    }).setPaint({
      'line-color': RoutingColor.getColorByNumber(this.route.getRouteIndex()),
      'line-width': 2
    });
    this.routingService.isEditing = true;
    this.routingService.setEditFeature(this);
  }

  private getTasksFromMarker(marker: MapMarker) {
    const tasks = marker.getExtraData().data || [];
    return tasks;
  }

  private getMarkerFromTask(task) {
    if (!task) return;
    const markerKey = `${task?.location?.latitude}, ${task?.location?.longitude}`
    return this.mapService.getMarker(markerKey);
  }

  public canSelectTask(task): boolean {
    if (task.type == Const.TaskType.PICKUP) return true;
    //nếu task drop-off mà chưa chọn task pickup trước đó thì không được chọn.
    if (this.selectedTasks.filter(selectedTask =>
      selectedTask.type == Const.TaskType.PICKUP
      && selectedTask.shipmentId == task.shipmentId).length > 0)
      return true;

    return false
  }


  private canSelectMarker(marker) {
    const tasks = this.getTasksFromMarker(marker);
    for (let task of tasks) {
      if (!this.canSelectTask(task)) return {
        result: false,
        detail: {
          shipmentId: task.shipmentId
        }
      }
    }
    return {
      result: true
    }
  }

  private isTaskSelected(task) {
    return this.selectedTasks.find(item => item.type == task.type && item.shipmentId == task.shipmentId);
  }

  private onMarkerClick(marker: MapMarker) {
    const tasks = this.getTasksFromMarker(marker);
    const addressInfo = this.addressManagement.get(marker.getKey());

    //sắp xếp lại thứ tự các task hiển thị. ưu tiên các task có thể select lên trên, drop ở trên pick.
    let sortedTasks = [];
    for (let task of tasks) {
      let index = 0;
      if (this.isTaskSelected(task)) {
        if (!this.canRemoveTask(task)) index -= 2;
      }
      else {
        if (!this.canSelectTask(task)) index -= 2;
      }
      if (task.type == Const.TaskType.PICKUP) index--;
      task.index = index;
    }
    sortedTasks = tasks.slice().sort((a, b) => b.index - a.index);

    //tự động select luôn các task có thể select
    tasks.map(task => this.onSortChange(task, true));
    this.reDraw();
    //truyền data ra UI
    this.editData = {
      addressInfo: addressInfo,
      tasks: [...sortedTasks]
    }
    this.showMarkerPopup = true;
  }

  public canRemoveTask(task) {
    const marker = this.getMarkerFromTask(task);
    const markerKey = marker?.getKey();

    const lastTask = this.selectedTasks?.[this.selectedTasks.length - 1];
    const lastMarker = this.getMarkerFromTask(lastTask);
    const lastMarkerKey = lastMarker?.getKey();
    if (markerKey !== lastMarkerKey) {
      return false;
    }
    //nếu là pickup thì kiểm tra drop-off
    if (task.type == Const.TaskType.PICKUP) {
      const isDropoffSelected = this.selectedTasks?.find(item => item.type == Const.TaskType.DROPOFF && item.shipmentId == task.shipmentId)
      if (isDropoffSelected) return false;
    }
    return true;
  }

  public onSortChange(task, isSelect) {
    const marker = this.getMarkerFromTask(task);

    //trường hợp xoá marker
    if (!isSelect) {
      if (!this.canRemoveTask(task)) {
        this.routingService.uiControlSubject.next({
          event: "showErr",
          data: {
            message: `The option cannot be changed because the shipment is not in the last step.`
          }
        })
        return;
      }
      //allow remove
      this.selectedTasks = this.selectedTasks.filter(item => item.type != task.type || item.shipmentId != task.shipmentId);
      marker.unSelected(); //cứ tạm thời unselect marker, cuối function sẽ check lại sau.
    }
    else {
      const isSelected = this.selectedTasks.find(item => item.type == task.type && item.shipmentId == task.shipmentId);
      if (isSelected) return;
      //kiểm tra xem task có được phép chọn không.
      let canSelectTask = this.canSelectTask(task);
      if (!canSelectTask) {
        return;
      }
      this.selectedTasks.push(task);
    }
  }


  public canSave() {
    const allSelected = this.selectedTasks?.length == this.route.getTasks().length;
    let allValid = true;
    for (let task of this.selectedTasks) {
      if (!this.isValidTask(task)) {
        allValid = false;
        break
      }
    };
    return allSelected && allValid
  }

  public async onSave() {
    const tasks = this.selectedTasks
    await this.routingService.routeReSort({ routeId: this.route.getId(), tasks });
    await this.problemManagement.reloadProblem();
    this.routingService.isEditing = false;
    this.routingService.setEditFeature(undefined)
  }
  public async onCancel() {
    await this.problemManagement.reloadProblem();
    this.routingService.isEditing = false;
    this.routingService.setEditFeature(undefined)

  }

  public async reDraw() {
    this.getTraffic();
    const markerList = this.selectedTasks.map(task => this.getMarkerFromTask(task));
    markerList.map(marker => marker.selected());
    this.newSortLine.setCoordinates(markerList.map(marker => marker.getCoordinates().toArray()))
  }

  public isValidTask(task) {
    if (task.type == Const.TaskType.PICKUP) return true;

    const taskIndex = this.selectedTasks.findIndex(item => item.type == task.type && item.shipmentId == task.shipmentId);
    const pickupIndex = this.selectedTasks.findIndex(item => item.type == Const.TaskType.PICKUP && item.shipmentId == task.shipmentId);
    if (pickupIndex != -1 && taskIndex > pickupIndex) return true;
    return false;
  }

  public getStatusText() {
    const tasks = this.route.getTasks();
    const totalPickupTasks = tasks.filter(task => task.type == Const.TaskType.PICKUP).length;
    const totalDropoffTasks = tasks.length - totalPickupTasks;
    const selectedPickupTasks = this.selectedTasks.filter(task => task.type == Const.TaskType.PICKUP).length;
    const selectedDropoffTasks = this.selectedTasks.length - selectedPickupTasks;
    const totalDistance = this.selectedTasks.reduce((total, task) => total + (task.distance || 0), 0);
    const totalTime = this.selectedTasks.reduce((total, task) => total + (task.time || 0), 0);
    return `
      <div class="flex-space-between">
        <div>
          <b>PICKUP:</b> ${selectedPickupTasks}/${totalPickupTasks} shipments <br />
          <b>DROPOFF:</b> ${selectedDropoffTasks}/${totalDropoffTasks} shipments
        </div>
        <div> 
          <b>Total Distance:</b> ${(totalDistance / 1609.34).toFixed(2).toLocaleString()} Miles <br />
          <b>Total Time:</b> ${(totalTime / 3600.00).toFixed(2).toLocaleString()} Hours <br />
        </div>
      </div>
      `
  }

  public getTotalTask() {
    return this.route.getTasks().length
  }

  async getTraffic() {
    const tasks = this.selectedTasks;
    if (tasks.length < 2) return;
    let listFrom = tasks.slice(0, tasks.length - 1).map(task => ({ lat: task?.location?.latitude, lng: task?.location?.longitude }));
    let listTo = tasks.slice(1, tasks.length).map(task => ({ lat: task?.location?.latitude, lng: task?.location?.longitude }));
    const resp = await this.routingService.getTraffic({
      listFrom,
      listTo
    })

    const costs = resp?.costs || [];
    let stop = 1;
    let prevMiles = -1;
    for (let i = 0; i < costs.length; i++) {
      if (!this.selectedTasks[i]) break;
      this.selectedTasks[i].distance = costs[i].distance;//(costs[i].distance / 1609.34).toFixed(2).toLocaleString();
      this.selectedTasks[i].time = costs[i].time;//(costs[i].time / 3600.00).toFixed(2).toLocaleString();
      if (prevMiles != 0) {
        this.selectedTasks[i].stopNum = stop++;
      }
      prevMiles = this.selectedTasks[i].distance;
    }
    //last-task
    if (prevMiles != 0) {
      this.selectedTasks[this.selectedTasks.length - 1].stopNum = stop++;
    }
  }
}