import { Component } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";
import { Const } from "@wearewarp/universal-libs";

@Component({
  selector: '[warehouse-task-setting-dialog]',
  templateUrl: './dialog.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './index.scss']
})
export class WarehouseTaskSettingDialog extends BaseDialog {
  model: any
  warehouseId: string
  type: string
  closeDialog: any

  warehouseTasks: any[] = [];

  ngOnInit(): void {
    const { warehouseTasks = [], deliveryInfos = [] } = this.model
    const delivery = deliveryInfos.find(it => it.warehouseId === this.warehouseId);
    this.type = delivery?.type === Const.TaskType.DROPOFF ? 'inbound' : 'outbound';
    this.warehouseTasks = warehouseTasks.filter(it => it.warehouseId === this.warehouseId);
  }
}