<div class="shipment bottom10 flex header">
  <div class="route-index background-color-{{data.colorIndex}} bottom10">Route {{data.routeIndex}}</div>
  <div class="right-buttons" *ngIf="requirePermissions([PermissionCode.routing_ltl.update])">
    <button nz-button nzType="link" nzSize="small" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
     >
      Edit Route <span nz-icon nzType="edit" nzTheme="outline"></span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="onAdjustOrder()" nz-tooltip
        nzTooltipPlacement="right"
          nzTooltipTitle="You can adjust the order of this route by clicking on the locations (Pickup/dropoff) on the map.">
          Adjust the route sorting</li>
        <li nz-menu-item (click)="onAdjustShipments()"
        nz-tooltip
        nzTooltipPlacement="right"
          nzTooltipTitle="You can add or remove shipments of a route."
        >Adjust the shipments</li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
<div class="shipment bottom10 flex">
  <div class="label3" style="width: 150px;" nz-popover nzPopoverTrigger="click"
    [nzPopoverOverlayClassName]="'popover-list-shipments'" [nzPopoverTitle]="'WARP ID'"
    [nzPopoverContent]="tplListShipments1">
    {{showShipmentCount(data.shipmentIds)}}
    <span nz-icon nzType="info-circle" nzTheme="outline" style="margin-left: 3px; font-size: 13px;"></span>
  </div>
  <ng-template #tplListShipments1>
    <div>{{data.shipmentIdsText}}</div>
  </ng-template>
  <div class="label3" style="flex: 1;">{{data.miles}} / {{data.time}}</div>
  <div class="label3" style="width: 70px; text-align: end;">{{showStopCount(data.stopCount)}}</div>
</div>
<div class="flex bottom10">
  <div class="icon-direction noselect">
    <div class="dot">⬤</div>
    <div class="line"></div>
    <div class="dot triangle">▼</div>
  </div>
  <div class="flex1">
    <div class="pick-addr bottom5" nz-popover nzPopoverPlacement="right" [nzPopoverContent]="data.firstStop.address">
      {{data.firstStop.locationName || data.firstStop.address}}
    </div>
    <div class="pick-addr" nz-popover nzPopoverPlacement="right" [nzPopoverContent]="data.lastStop.address">
      {{data.lastStop.locationName || data.lastStop.address}}
    </div>
  </div>
</div>