<div *ngIf="hasItems" class="box">
  <span class="title">Items</span>
  <ng-container *ngFor="let item of listItems; let i = index">
    <div class="item-box">
      <span class="item-name">{{getItemName(item, i)}}</span>
      <span class="item-size">{{getItemSize(item)}}</span>
    </div>
  </ng-container>
  <div class="line-divider"></div>
</div>
