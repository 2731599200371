<div class="nodata" *ngIf="isLoading"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div class="pod" *ngIf="!isLoading">
    <div class="top-comfirm-pod">
      <div>
        <a target="_blank" [routerLink]="routeAdminDispatchList" [queryParams]="{search: model.warpId}">
          Order: <code>{{ showOrderWarpId(model.warpId) }}</code><span *ngIf="isMultiStop" style="margin-left: 5px;">(multi-stop)</span>
        </a>
        <span *ngIf="!isCarrierBillMatches" class="warning-generate-invoice">
          <!-- To confirm POD, you need to match dispatch screen to Carrier Bill first -->
          To generate Invoice, you need to match dispatch screen to Carrier Bill first
        </span>
      </div>
      <div class="flex">
        <ng-container *ngIf="!isAdminReadOnlyRole" >
          <button class="right10" nz-button nzType="primary"
            [nzLoading]="isGeneratingInvoice"
            [disabled]="!canGenerateInvoice || !isCarrierBillMatches"
            (click)="onGenerateInvoice()">
            {{genInvoiceButtonTxt}}
          </button>
          <button nz-button nzType="default" (click)="closeDialog()">
            Close
          </button>
        </ng-container>
      </div>
    </div>
    <div class="pod-content">
        <div class="list-overview">
          <ng-container *ngFor="let group of podGroups; let groupIndex = index">
            <!-- <div *ngIf="isMultiStop || isParentSubShipment" class="group-title"> -->
            <div class="group-title">
              <a target="_blank" 
                [routerLink]="routeAdminDispatchList" 
                [queryParams]="{search: group.warpId}">
                Shipment: <code>{{ showShipmentWarpId(group.warpId) }}</code>
              </a>
              <a style="margin-left: 15px;" [href]="gotoPodConfirmation(group.jobId)" target="_blank" nz-tooltip
              nzTooltipTitle="Go to POD Confirmation"><span nz-icon nzType="login" nzTheme="outline"></span></a>
              <div *ngIf="groupIndex == 0">
                <button nz-button nzType="text" (click)="onBtnCorrectPod()" nz-tooltip nzTooltipTitle="Refresh">
                  <span nz-icon nzType="sync" nzTheme="outline"></span>
                </button>
              </div>
            </div>
            <div *ngIf="group.podArr.length > 0" class="attached-pod-box overlay-container">
              <ng-container
                *ngFor="let podItem of group.podArr; let podIndex = index"
                [ngTemplateOutlet]="podSmallItem"
                [ngTemplateOutletContext]="{podItem, podIndex, groupIndex}">
             </ng-container>
            </div>
            <div *ngIf="group.podArr.length == 0" style="padding: 10px;" class="danger">
              <ng-container *ngIf="isCanceled(group)">Canceled</ng-container>
              <ng-container *ngIf="!isCanceled(group)">Please provide POD</ng-container>
            </div>
          </ng-container>
        </div>
        <div class="main-view-pod">
          <ng-container>
            <div class="body-pod-main">
              <ng-container *ngFor="let group of podGroups; let groupIndex = index">
                <ng-container *ngFor="let podItem of group.podArr; let podIndex = index"
                  [ngTemplateOutlet]="podBigItem" [ngTemplateOutletContext]="{podItem, podIndex, groupIndex, group}">
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
    </div>
</div>

<ng-template #podSmallItem let-podItem="podItem" let-podIndex="podIndex" let-groupIndex="groupIndex">
  <div class="pod-item-small" (click)="viewPodItem(groupIndex, podIndex)">
    <div class="image">
      <img width="100" *ngIf="!isPdf(podItem)" class="attached-pod" [src]="podItem.imgUrl" alt="">
      <div *ngIf="isPdf(podItem)" class="attached-pod pdf overlay-container">
        <canvas id="pod-{{podIndex}}"></canvas>
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="podInfo" [ngTemplateOutletContext]="{podItem, podIndex}"></ng-container>
  </div>
</ng-template>

<ng-template #podBigItem let-podItem="podItem" let-podIndex="podIndex" let-groupIndex="groupIndex" let-warpId="group.warpId">
  <div class="pod-item-big" id="pod-img-{{groupIndex}}-{{podIndex}}">
    <div class="wrap-inline-content">
      <img *ngIf="!isPdf(podItem)" class="attached-pod" ngxViewer
       [src]="podItem.imgUrl" alt=""
       [viewerOptions]="{
        inline: true,
        transition: true
       }"
       style="max-height: calc(100vh - 100px); opacity: 0;"
       >
      <div *ngIf="isPdf(podItem)" class="attached-pod pdf overlay-container">
        <pdf-viewer [fit-to-page]="1" [zoom-scale]="'page-height'" [src]="attachedFileUrl(podItem)" style="height: 100%"></pdf-viewer>
      </div>
      <div class="info-absolute">
        <div class="info-sticky">
          <div class="info">
            <ng-container [ngTemplateOutlet]="podInfo" [ngTemplateOutletContext]="{podItem, podIndex, groupIndex, warpId}"></ng-container>
          </div>
          <div class="notes">
            <nz-tabset>
              <nz-tab nzTitle="Issues">
                <div notes-component
                  [subjectId]="podItem._id" [subjectType]="'pod'"
                  [allowDeletion]="true" [allowDeleteItemNotMine]="true"
                  (fetchDataDone)="onNoteFetchingDone(podItem, $event)"
                  (itemAdded)="onNoteItemAdded(podItem, $event)"
                  (itemRemoved)="onNoteItemRemoved(podItem, $event)">
                </div>
              </nz-tab>
            </nz-tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #podInfo let-podItem="podItem" let-podIndex="podIndex" let-groupIndex="groupIndex" let-warpId="warpId">
  <div class="info">
    <div class="info-text" *ngIf="warpId">
      <div class="name">WARP ID: <code>{{ showShipmentWarpId(warpId) }}</code></div>
      <a style="margin-left: 15px;" [href]="gotoPodConfirmation(podItem.jobId)" target="_blank" nz-tooltip
      nzTooltipTitle="Go to POD Confirmation"><span nz-icon nzType="login" nzTheme="outline"></span></a>
    </div>
    <div class="info-text">
      <i nz-icon nzType="upload" nzTheme="outline"></i>
      <div>
        <div class="date">{{formatDate(podItem.insert?.when)}}</div>
        <div class="name">
          <warp-when-by [model]="podItem?.insert" [viewTemplate]="tplPODCreateWhenBy"></warp-when-by>
        </div>
      </div>
    </div>
    <div class="action">
      <div class="confirm">
        <button nz-button nzType="primary" [nzLoading]="podItem.onProgress"
          (click)="onBtnConfirm(podItem)" *ngIf="!isConfirmPOD(podItem)">Confirm</button>
         
        <div class="confirmed" *ngIf="isConfirmPOD(podItem)">
          <i nz-icon nzType="check" nzTheme="outline"></i>
          <div class="text">
            {{formatDate(podItem.podConfirmed.when)}}<br />
            <warp-when-by [model]="podItem?.podConfirmed" [viewTemplate]="tplPODConfirmedWhenBy"></warp-when-by>
          </div>
        </div>
        <button nz-button nzGhost nzDanger class="btn-un-confirm"
          *ngIf="canUnconfirmPOD(podItem)" (click)="onBtnUnConfirm(podItem)">Un-confirm</button>
      </div>
      <div *ngIf="isHasIssue(podItem)" class="hasIssue">
        <i nz-icon nzType="warning" nzSize="large" nzTheme="outline" class="warning"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tplPODCreateWhenBy let-hasData="hasData" let-displayInfo="displayInfo">
  <span *ngIf="hasData" class="medium">
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
      style="color: #000000;"
      target="_blank">
      {{displayInfo.createdBy}}
    </a>
  </span>
</ng-template>
<ng-template #tplPODConfirmedWhenBy let-hasData="hasData" let-displayInfo="displayInfo">
  <span *ngIf="hasData">
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
      style="color: #3f9714;"
      target="_blank">
      {{displayInfo.createdBy}}
    </a>
  </span>
</ng-template>
