import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from "@angular/core";
import { RoutingService } from "@app/admin/routing-tool/services/routing.service";
import { ShipmentManagement } from "@app/admin/routing-tool/services/shipmentManagement";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import { WarpId } from "@wearewarp/universal-libs";

@Component({
  selector: '[routing-problem-reorder-maker-popup]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class RoutingProblemReorderMarkerPopup {

  constructor(private shipmentManagement: ShipmentManagement, private routingService: RoutingService) {
  }
  private _forceSelectAll = false; //sử dụng biến này để fix binding khi click select all trên UI.
  public get addressInfo() {
    return this.getFeature()?.editData?.addressInfo || {}
  }
  public get selecteds() {
    return this.getFeature()?.selectedTasks || []
  }
  public set selecteds(value) {
    this.getFeature().selectedTasks = value
  }
  public get tasks() {
    return this.getFeature()?.editData?.tasks || []
  }

  private getFeature() {
    return this.routingService.getEditFeature();
  }
  public isSelectedAll() {
    if (this._forceSelectAll) {
      this._forceSelectAll = false;
      return true;
    }
    return this.tasks.filter(task => !this.isSelected(task)).length == 0
  }
  public isIndeterminate() {
    let selectedCount = this.tasks.filter(task => this.isSelected(task)).length;
    return selectedCount > 0 && selectedCount < this.tasks.length
  }

  public onAllChecked(event) {
    this._forceSelectAll = true;
    this.tasks.map(task => this.onSelect(task, event, false))
    this.getFeature().reDraw();
  }

  public isSelected(item) {
    const selectedItem = this.selecteds.find(task => task.type == item.type && task.shipmentId == item.shipmentId);
    if (selectedItem) return true;
    return false;
  }

  public canChange(item) {
    if (this.isSelected(item)) return this.getFeature().canRemoveTask(item);
    return this.getFeature().canSelectTask(item);
  }

  public onSelect(item, isSelect, reDraw = true) {
    if (!this.canChange(item)) return;
    this.getFeature().onSortChange(item, isSelect);
    if (reDraw) {
      this.getFeature().reDraw();
    }
  }

  public showWarpId(task) {
    const shipmentId = task.shipmentId;
    const shipment = this.shipmentManagement.getShipment(shipmentId);
    return WarpId.showShipment(shipment.getWarpId())
  }
  public onBtnClose() {
    this.getFeature().showMarkerPopup = false;
  }
}