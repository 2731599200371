<div #mapElm id="routing-problem-map" style="width: 100%; height: 100%;"></div>
<div class="map-custom-controls-container" *ngIf="requirePermissions([PermissionCode.routing_ltl.create, PermissionCode.routing_ltl.update])">
  <!-- <div *ngIf="shouldShowRegionButton"
    class="draw-mode-select center-children btn right10"
    [ngClass]="{'selected': isRegionState}" (click)="onBtnRegion()">
    <span nz-icon nzType="environment" nzTheme="outline" class="right10"></span> {{isRegionState ? "Hide Region" : "Show Region"}} (R)
  </div> -->
  <div *ngIf="shouldShowSelectButton" class="draw-mode-select center-children btn"
    [ngClass]="{'selected': isEnabledDrawMode}" (click)="onBtnSelect()">
    <span nz-icon nzType="share-alt" nzTheme="outline" class="right10"></span> {{txtButtonSelect}} (S)
  </div>
</div>
<div routing-problem-map-selection-popup #popupRegion
  [ngClass]="{'visible': popupSelectionVisible === true, 'invisible': popupSelectionVisible === false}"
  [shipmentIds]="selectedShipmentIds" [selectMode]="selectRegionMode" (updateRegion)="fnUpdateRegion($event)"
  (close)="hidePopupSelection()"></div>
<div routing-map-bottom-bar></div>

<!-- UI cho việc re-order tasks -->
<div *ngIf="reOrderFeature" routing-problem-reorder-task-list></div>
<ng-container *ngIf="reOrderFeature?.showMarkerPopup">
  <div routing-problem-reorder-maker-popup></div>
</ng-container>