import { Component, ElementRef, forwardRef, Inject, Injector, Input, ViewChild } from '@angular/core';
import { FormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExtendValidators } from '@app/admin/base/validator';
import { Const } from '@const/Const';
import { ApiService } from '@services/api.service';
import { AttachedFileUtil } from '@services/attached-file-util';

@Component({
    selector: 'setting-version-file',
    templateUrl: './index.html',
    styleUrls: ['./style.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SettingVersionFile), multi: true }],
})
export class SettingVersionFile {
    private onChange: any = () => { };
    private onTouch: any = () => { };
    public isDisabled: boolean = false;

    public _isLoading: boolean = false;
    get isLoading() {
        return this._isLoading;
    }
    set isLoading(value) {
        if (value) {
            this.control.addValidators(this.validateLoading);
            this.control.updateValueAndValidity();
        }
        else {
            this.control.removeValidators(this.validateLoading);
        }
        this._isLoading = value
    }

    selected: any;
    control: FormControl;
    api;
    @Input() appKey = "";

    @ViewChild('inputFile') inputFile: ElementRef<HTMLInputElement>;
    constructor(@Inject(Injector) private injector: Injector, api: ApiService) {
        this.api = api;
        this.validateLoading = this.validateLoading.bind(this);
    }
    ngOnInit(): void {

    }

    validateLoading(control) {
        return {
            'isLoading': 'uploading...'
        }
    }

    get value() {
        return this.selected;
    }
    set value(value: any) {
        this.selected = value;
        this.onChange(value);
        this.onTouch(value);
    }

    get fileDesc() {
      if (this.value?.file) {
        return AttachedFileUtil.fileDesc(this.value.file);
      }
      return '';
    }

    writeValue(value: any): void {
        this.value = value;
    }
    registerOnChange(fn: any): void {
        //gán ở đây để fix vấn đề remove form tạo lại
        this.getControl();
        this.initData();
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
    private getControl() {
        const injectedControl = this.injector.get(NgControl);
        switch (injectedControl.constructor) {
            case NgModel: {
                const { control, update } = injectedControl as NgModel;
                this.control = control;
                break;
            }
            case FormControlName: {
                this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
                break;
            }
            default: {
                this.control = (injectedControl as FormControlDirective).form as FormControl;
                break;
            }
        }
    }

    async validate(value) {
        if (!value?.file) return;
        if (value.versionCode) return; //đối với value có versionCode là dữ liệu đã parse, không validate lại nữa, tránh lặp vô hạn
        try {
            this.isLoading = true;
            let formData = new FormData();
            formData.append('appFile', value.file);
            formData.append('appKey', this.appKey);
            const result = await this.api.postFormData(Const.APIURI_SETTINGS + '/validate-files', formData).toPromise();
            this.isLoading = false;
            this.value = {
                file: value.file,
                appKey: this.appKey,
                ...result?.data
            }
        }
        catch (e) {
            this.isLoading = false;
            return e.message
        }
    }
    private initData() {
        this.control.setAsyncValidators(ExtendValidators.asyncValidator("validateFile", this.validate.bind(this)));
    }

    onFileSelected(files: FileList) {
        if (files.length == 0) {
            return;
        }
        let file = files[0];
        this.value = {
            file
        };
        this.inputFile.nativeElement.value = '';
    }

    delFile() {
      this.value = undefined;
    }
}