import { BaseList } from '../base/list';
import { Component } from '@angular/core';
import { Const } from '@app/const/Const';
import { ActivatedRoute } from '@angular/router';
import { WarehouseFormV2 } from './warehouse-form-v2';
import { DrawerService } from '@app/drawers/drawer.service';
import { ApiService } from '@services/api.service';
import { WarehouseTask } from './warehouse-task';

@Component({
  selector: 'warehouse-list',
  templateUrl: './list.html',
  styleUrls: ['../list.scss', './list.scss']
})
export class WarehouseList extends BaseList {
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSES;
  }

  private updateWarehousesSucceeded(resp) {
    let newData = resp.data;
    for (let i = 0; i < this.listData.length; i++) {
      if (this.listData[i].id == newData.id) {
        this.listData[i] = newData;
        break;
      }
    }
  }

  private createWarehousesSucceeded(resp) {
    if (this.currentPage == 1) {
      this.onBtnRefresh();
    } else {
      this.routeWithQueryUrl({ page: 1 });
    }
  }

  // item null means create new
  onBtnDetail(item = null) {
    DrawerService.openFormDrawer1(WarehouseFormV2, {
      nzContentParams: {
        createSuccess: this.createWarehousesSucceeded.bind(this),
        updateSuccess: this.updateWarehousesSucceeded.bind(this),
        model: item,
        closeOnSuccess: true
      },
      nzWidth: 1000,
    });
  }

  protected confirmDeleteMessage(item): string {
    return `Delete location <b>${item.name}</b>?`;
  }

  public getWarehouseTypeText(type) {
    return Const.getWarehouseTypeText(type);
  }

  public onBtnInventory(item) {
    this.router.navigate([this.routeAdminLocationList, 'inventory', item.id]);
  }

  protected getItemId(item) {
    return item.id;
  }

  public onBtnSettingTasks(item) {
    let saving: boolean = false;
    let modalRef: any = null;
    modalRef = this.modalService.create({
      nzTitle: `Setting Cross-Dock’s Tasks For ${item.name}`,
      nzContent: WarehouseTask,
      nzClassName: "modal-xl",
      nzComponentParams: {
        myModel: item
      },
      nzFooter: [
        {
          label: 'Save',
          type: 'primary',
          loading: () => saving,
          disabled: (comp) => comp.formInput?.status === "INVALID" || saving,
          onClick: (comp) => {
            saving = true;
            let data: any = comp.getFormData_JSON(true);
            this.onBtnSaveItem({ ...item, tmp: { warehouseTasks: data?.warehouseTasks || [] }});
            modalRef?.destroy();
            this.showSuccess('Setting Cross-Dock’s Tasks updated successfully!')
          }
        },
        {
          label: 'Cancel',
          type: 'default',
          disabled: () => saving,
          onClick: () => modalRef?.destroy()
        },
      ]
    })
  }

  public isCrossdockType(item) {
    return item.warehouseType === Const.WarehouseTypes.crossdock
  }

  public getClientName(clients = []) {
    if (!clients.length) return '';
    return clients[0].name;
  }

  public getListClientNames(clients = []) {
    const names = [];
    clients.forEach((client, index) => {
      if (index > 0) {
        names.push(client.name);
      }
    });
    return names.join(', ');
  }

  public isExporting = false;
  onBtnExport() {
    this.isExporting = true;
    let query = this.prepareParamGetList();
    query.isDownload = true;
    this.api.postExport(`${Const.APIURI_WAREHOUSES}/export`, query).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }

  gotoWarehouseJobs(item: any) {
    if (!item || item.delete) return undefined;
    return this.router.navigate([Const.routeAdminCrossdockWarehouseJob], { queryParams: { warehouseId: item.id } });
  }

  getDefaultFilter = () => {
    return {orgId: 'ORG_0'}
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    if (!this.queryParams.filter && !this.queryParams.search) {
      let filter = JSON.stringify(this.getDefaultFilter());
      return this.routeWithQueryUrl({filter});
    }
    super.handleNavigationEnd(url, prevQueryParam);
  }
}