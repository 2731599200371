<div style="background-color: white; position: relative;">

  <div *ngIf="isLoading || errTxt"  class="center-children" style="height: 150px; top: 0; flex-direction: column;">
    <ng-container *ngIf="isLoading">
      <i nz-icon nzType="loading" nzTheme="outline" class="loading lg primary"></i>
    </ng-container>
    <ng-container *ngIf="errTxt">
      <span class="danger">{{errTxt}}</span>
      <a style="margin-top: 20px;" (click)="onBtnRetry()">
        <i nz-icon nzType="sync" nzTheme="outline" style="margin-right: 10px;"></i>Retry
      </a>
    </ng-container>
  </div>

  <div *ngIf="!isLoading && !errTxt" style="display: flex; flex-direction: column; margin-top: 50px;">
    <button *ngIf="modelDraft" nz-button (click)="onBtnContinueDraft()" class="bottom20">Continue last draft ({{draftType}})</button>
    <button nz-button (click)="onBtnCreateSingle()" class="bottom20">Create Single Shipment</button>
    <button nz-button (click)="onBtnCreateBatch()">Create Batch Shipment</button>
  </div>

</div>