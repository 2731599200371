import { ChangeDetectorRef, Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Const } from '@const/Const';
import { Log } from '@services/log';
import { Utils } from '@services/utils';
import { BaseDetail } from '../../base/detail';
import { LtlRoutingDeliveryInfoViewData, LtlRoutingFilter, LtlRoutingShipmentViewData, RoutingRegionSelectMode } from '../interface';
import { RoutingFilterShipment } from './filter-shipment';
import { RoutingProblemMap } from './map';
import { RoutingProblemVehicles } from './vehicles';
import { RoutingRouteShipment } from './route-shipment';
import { BizUtil } from '@services/biz';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DialogService } from '@dialogs/dialog.service';
import { FillShipmentWindow } from './fill-shipment-window';
import { Problem } from '../services/objects/problem';
import { ProblemManagement } from '../services/problemManagement';
import { RoutingService } from '../services/routing.service';
import { Subscription } from 'rxjs';
import { MapService } from '../services/map.service';
import { ShipmentManagement } from '../services/shipmentManagement';
import { TimeWindow } from '@wearewarp/types/data-model';

@Component({
  selector: '[routing-problem-detail]',
  templateUrl: './detail.html',
  styleUrls: ['./style.scss']
})
export class RoutingProblemDetail extends BaseDetail {
  @ViewChild('filterComp') filterComp: RoutingFilterShipment;
  @ViewChild('map') mapComponent: RoutingProblemMap;
  @ViewChild('vehicleComponent') vehicleComponent: RoutingProblemVehicles;
  @ViewChild('routeShipmentComponent') routeShipmentComponent: RoutingRouteShipment;

  private txtRequireFetchData = 'Please filter LTL shipments first';
  public subProblems = [];
  public listShipments = [];
  public get countShipments() {
    let countShipments = this.problem?.getData()?.num_shipments ?? this.listShipments?.length ?? 0;
    let countSubProblems = this.problem?.getData()?.children?.length ?? 0;
    let str = Utils.displayCount(countShipments, 'Shipment');
    if (countSubProblems > 0) {
      str += `, ${Utils.displayCount(countSubProblems, 'Sub Problem')}`
    }
    return str;
  }
  public problem: Problem;
  public isCreatingProblem = false;
  public isLoadingShipments = false;    // filter
  public isLoadingProblemDetail = false;

  get canExcludeShipmentFromRouting(): boolean {
    return this.isCreateNew;
  }
  get shouldFillDateTime() {
    return this.isCreateNew && this.isThereDateTimeMissing
  }
  get canCreateProblem() {
    return this.isCreateNew && !this.isThereDateTimeMissing && !this.isThereRoutedShipment;
  }

  private routeCount;

  get shouldShowConfirmRoutes() {
    return this.routeCount > 0
  }
  get shouldShowSubProblems() {
    return this.subProblems.length > 0
  }
  get isNoData() {
    return this.isCreateNew ? this.listShipments.length == 0 : false;
  }
  get shouldShowNoData() {
    return !this.isLoadingProblemDetail && this.isNoData;
  }
  get shouldShowSideLoading() {
    return (this.isLoadingProblemDetail);
  }
  get shouldShowButtonsCreateCancel() {
    return this.isCreateNew && this.listShipments.length > 0;
  }
  get isRoutingInProgress() {
    let subProblems = this.problem.getData()?.children ?? []
    for (let subProblem of subProblems) {
      if (subProblem.status == 'IN_PROGRESS') {
        return true;
      }
    }
    return false;
  }

  get noDataText() {
    if (!this.isCreateNew) return 'No Data'
    const filterData = this.routingService.getFilterData();
    if (filterData.condition) return 'No Data'
    return this.txtRequireFetchData
  }

  constructor(protected activatedRoute: ActivatedRoute,
    private problemManagement: ProblemManagement,
    private routingService: RoutingService,
    private mapService: MapService,
    private shipmentManagement: ShipmentManagement
  ) {
    super(activatedRoute);
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
    this.initProblem()
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initProblem()
    this.initShipmentManagement();
    this.initResizeSensor();
  }

  private observerResize: ResizeObserver;
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
    this.problemSubscription.unsubscribe();
    this.destroyResizeSensor();
  }

  // //window resize thì tính toán lại chiều cao của dispatch window
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.toggleDetailContainerHeight();
  }

  initResizeSensor() {
    const elem = document.querySelector('.problem-detail .list-header');
    if (!elem) return;
    this.observerResize = new ResizeObserver(() => this.toggleDetailContainerHeight())
    this.observerResize.observe(elem)
  }

  destroyResizeSensor() {
    const elem = document.querySelector('.problem-detail .list-header');
    if (!elem) return;
    this.observerResize?.unobserve(elem)
  }

  toggleDetailContainerHeight() {
    const headerDom = document?.querySelector(".problem-detail .list-header");
    const windowHeight = window.innerHeight;
    const contentHeaderHeight = headerDom?.clientHeight;
    const containerHeight = windowHeight - 130 - contentHeaderHeight;
    document?.querySelectorAll(".problem-detail .problem-dynamic-height").forEach((elem => elem.setAttribute("style", `height: ${containerHeight}px;`)));
    this.mapService?.resize();
  }

  problemSubscription = new Subscription()
  async initProblem() {
    this.problemSubscription.unsubscribe();
    this.problemSubscription = new Subscription();
    this.problemManagement.initProblem(this.id !== 'add' ? this.id : undefined);

    this.problemSubscription.add(
      this.problemManagement.problemSubject.subscribe(problem => {

        this.problem = problem;

        this.problemSubscription.add(this.problem.dataSubject.subscribe(() => {
          this.subProblems = this.problem.getSubProblem()
          this.routeCount = this.problem.getRouteCount();
          this.mapService.waitMapReady(() => {
            this.problem.addToMap();
            this.mapService.fitBounds()
          })
        }));
        this.problemSubscription.add(this.problem.isLoading.subscribe(value => this.isLoadingProblemDetail = value))
      })
    )
  }

  async initShipmentManagement() {
    this.subscription.add(this.shipmentManagement.shipmentSubject.subscribe(shipments => {
      //tạm dụng hàm getShipmentsData để get data raw. chưa sử dụng instance vì chưa có thời gian optimize
      let shipmentData = this.shipmentManagement.getShipmentsData();
      this.listShipments = [...shipmentData];

      this.mapService.waitMapReady(() => {
        this.shipmentManagement.addToMap();
        this.mapService.fitBounds()
      })
    }));

    this.subscription.add(this.routingService.uiControlSubject.subscribe(data => {
      this.uiControlAction(data);
    }))
  }

  protected getData() {
    return;
  }

  uiControlAction({ event, data }: { event: string, data?: any }) {
    switch (event) {
      case 'showDialogRoutingDone':
        this.showDialogRoutingDone();
        break;
      case 'addMoreVehicle':
        this.addMoreVehicle();
        break;
      case 'adjustRouteShipment':
        this.adjustRouteShipment(data);
        break;
      case 'showErr':
        this.showErr(data?.message);
        break;
      case 'showSuccess':
        this.showSuccess(data.message);
    }
  }

  addMoreVehicle() {
    this.vehicleComponent?.openDialogAddItem();
  }
  adjustRouteShipment(data) {
    this.routeShipmentComponent?.setData(data)
    this.routeShipmentComponent?.openDrawer();
  }

  onBtnVehicles() {
    this.vehicleComponent?.openDrawer();
  }

  onBtnCancel(): any {
    this.routingService.resetFilterData();
    this.filterComp?.resetFilter();
    // this.mapComponent?.resetAllData();
  }

  // Check xem có shipment nào bị thiếu ngày giờ PICKUP/DROPOFF không
  private get isThereDateTimeMissing(): boolean {
    for (let item of this.listShipments) {
      let viewData: LtlRoutingShipmentViewData = item.viewData;
      if (viewData?.pick?.isWindowError || viewData?.drop?.isWindowError) {
        return true;
      }
    }
    return false;
  }

  //check xem có shipment nào đã có route hay không.
  private get isThereRoutedShipment(): boolean {
    for (let item of this.listShipments) {
      if (item.lastJobId) {
        return true;
      }
    }
    return false;
  }

  onBtnFillTimeWindow() {
    // Nhóm các location theo cùng timezone, mỗi lần update batch thì tất cả các location trong 1 batch phải cùng timezone
    let mapByTimezone = {};
    for (let item of this.listShipments) {
      let viewData: LtlRoutingShipmentViewData = item.viewData;
      for (let key of ['pick', 'drop']) {
        let deliveryInfoViewData: LtlRoutingDeliveryInfoViewData = viewData[key];
        if (deliveryInfoViewData.isWindowError) {
          let tz = deliveryInfoViewData.tz;
          if (!mapByTimezone[tz]) {
            mapByTimezone[tz] = {};
          }
          if (!mapByTimezone[tz][key]) {
            mapByTimezone[tz][key] = [];
          }
          mapByTimezone[tz][key].push(item);
        }
      }
    }
    let data = { pick: [], pickTz: '', drop: [], dropTz: '' };
    for (let tz of Object.keys(mapByTimezone)) {
      if (mapByTimezone[tz].pick && data.pick.length == 0) {
        data.pick = mapByTimezone[tz].pick;
        data.pickTz = tz;
      }
      if (mapByTimezone[tz].drop && data.drop.length == 0) {
        data.drop = mapByTimezone[tz].drop;
        data.dropTz = tz;
      }
      if (data.pick.length > 0 && data.drop.length > 0) {
        break;
      }
    }
    DialogService.openFormDialog1(FillShipmentWindow, {
      nzClosable: true,
      nzComponentParams: {
        data,
        updateSuccess: this.onFillTimeWindowSucceeded.bind(this)
      }
    });
  }

  onFillTimeWindowSucceeded(data: { orderIds: number[], type: string, window: TimeWindow }) {
    this.routingService.updateListShipments();
  }

  async onBtnCreate() {
    if (this.listShipments.length == 0) {
      return this.showDialog(this.txtRequireFetchData);
    }
    for (let item of this.listShipments) {
      // Lỗi sẽ được check ở component RoutingShipmentItem
      let errMsg = item.viewData?.pick?.errMsg ?? item.viewData?.drop?.errMsg;
      if (errMsg) {
        return this.showDialog(`Found error in the shipment <b>${this.getShipmentId(item)}</b>. Please fix it then try again later.<br/><font color="red">${errMsg}.</font>`);
      }
    }
    let listSimpleShipments = this.listShipments.map(it => it.shipmentSimpleInfo);
    const filterData = this.routingService.filterData.getValue()
    let data = {
      selectRegionMode: filterData.selectRegionMode,
      shipments: listSimpleShipments,
      start_date: filterData.startDate,
      end_date: filterData.endDate,
      // vehicles: problemVehicles || [],
      // number_of_iterations,
      // number_of_threads,
      // regions: selectedRegions,
      // client_ids: selectedClients,
      // scanned_client_ids: selectedScannedClients,
      // scanned_start: scannedStart,
      // scanned_end: scannedEnd,
      // extra_regions: selectedMultiRegions,
      // extra_region_clients: selectedMultiRegionsClients,
    };
    this.isCreatingProblem = true;
    try {
      const responseData = await this.routingService.createProblem(data);
      const problemId = this.getProblemId(responseData);
      let url = this.getCurrentUrlPath().replace(/\/add$/, '');
      this.router.navigate([url, problemId]);
      this.filterComp?.resetFilter(true);
    }
    catch (e) {
      console.error(e)
      this.showErr(e)
    }
    this.isCreatingProblem = false;
  }

  private getProblemId(problem) {
    return problem.id;
  }

  private getShipmentId(shipment) {
    return shipment._id;
  }

  @ViewChild('tplRoutingDone') tplRoutingDone: TemplateRef<any>;
  private dlgRoutingDone: NzModalRef;
  private isDialogShowing = false;
  private showDialogRoutingDone() {

    if (this.isDialogShowing) return;
    this.isDialogShowing = true;

    this.dlgRoutingDone = this.modalService.create({
      nzContent: this.tplRoutingDone,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzWrapClassName: 'dialog-confirm-route-done'
    });
  }

  closeDialogRoutingDone() {
    this.dlgRoutingDone?.close();
    this.isDialogShowing = false;
  }

}