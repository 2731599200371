import { Component, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ValidateOptions } from "@app/admin/base/form-base";
import { FormDataBatchLocationsMultiDrop } from "@app/admin/shipment-entry/interface";
import { DialogService } from "@dialogs/dialog.service";
import { FormShipmentLocation } from "../../shipment-location";
import { FormOrderItems } from "../../order-items";
import { ValidationErrors } from "@angular/forms/forms";
import { ShipmentEntryForm } from "../../shipment-entry-form";
import { BatchShipmentUICollapseState } from "../interface";
import { FormDataBatchLocationsItem, FormDataShipmentEntryDraftBatch } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";

@Component({
  selector: '[form-shipment-location-batch-multi-drop]',
  templateUrl: './view.html',
  styleUrls: ['../style.scss'],
  host: {
    class: 'location-batch'
  }
})
export class FormShipmentLocationBatchMultiDrop extends ShipmentEntryForm<FormDataBatchLocationsMultiDrop> {

  @ViewChild('formPickup') formPickup: FormShipmentLocation;
  @ViewChildren('formDropoff') formDropoffs: QueryList<FormShipmentLocation>;
  @ViewChildren('formItems') formItems: QueryList<FormOrderItems>;

  readonly uiCollapseState: {pick: BatchShipmentUICollapseState, drops: BatchShipmentUICollapseState[]} = {
    pick: {},
    drops: []
  }
  get countDropInfos() { return this.uiCollapseState.drops.length }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.extractDataModelDraft();
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
  }

  private extractDataModelDraft() {
    let _data = <FormDataBatchLocationsMultiDrop>this.context?.modelDraft;
    this.uiCollapseState.pick.data = _data?.pickInfo;
    this.uiCollapseState.drops = [];
    for (let item of _data?.dropInfos ?? []) {
      this.uiCollapseState.drops.push({data: item});
    }
    if (this.uiCollapseState.drops.length == 0) {
      this.uiCollapseState.drops.push({});
    }
  }

  protected onModelDraftChange(data: FormDataShipmentEntryDraftBatch): void {
    this.extractDataModelDraft();
  }

  private validateFormPickup(options?: ValidateOptions) {
    let err = this.formPickup.validate(options);
    if (err) {
      if (options?.scrollToErrorField) {
        this.uiCollapseState.pick.isExpand = true;
      }
      return err;
    }
    return null;
  }

  private validateFormDropoff(index: number, options?: ValidateOptions) {
    let formDropoff = this.formDropoffs.get(index);
    let formItems = this.formItems.get(index);
    for (let form of [formDropoff, formItems]) {
      let err = form.validate(options);
      if (err) {
        if (options?.scrollToErrorField) {
          this.uiCollapseState.drops[index].isExpand = true;
        }
        return err;
      }
    }
    return null;
  }

  /**
   * Validate tất cả các form dropoff trước index (từ 0 đến index - 1)
   * Cần tạo error message báo phù hợp
   */
  private validateFormDropoffsPrev(index: number) {
    if (index > 0) {
      for (let i = 0; i < index; i++) {
        let msg = `In order to save <b>Delivery ${index + 1}</b>, you must complete all previous Deliveries. So please complete <b>Delivery ${i + 1}</b> first.`;
        let err = this.validateFormDropoff(i, {showDialogMessage: msg, scrollToErrorField: true});
        if (err) {
          return err;
        }
      }
    }
    return null;
  }

  public validate(options?: ValidateOptions): ValidationErrors {
    let err = this.validateFormPickup(options);
    if (err) {
      return err;
    }
    for (let i = 0; i < this.countDropInfos; i++) {
      err = this.validateFormDropoff(i);
      if (err) {
        return err;
      }
    }
    return null;
  }

  public getFormData(): FormDataBatchLocationsMultiDrop {
    let formData: FormDataBatchLocationsMultiDrop = {
      pickInfo: this.formPickup.getFormData(),
      dropInfos: []
    };
    for (let i = 0; i < this.countDropInfos; i++) {
      let formDataDrop = this.formDropoffs.get(i).getFormData();
      let formDataItems = this.formItems.get(i).getFormData();
      let dropInfo: FormDataBatchLocationsItem = {...formDataDrop, items: formDataItems.items }
      formData.dropInfos.push(dropInfo);
    }
    return formData;
  }

  genIdPick = () => {
    // Trường hợp multi-dropoff chỉ nhập 1 pickup đại diện thì không thể có id chung được, khi nào tách ra mới tạo id riêng cho từng pickup
    return 'dummy_id';
  }

  onBtnSavePickInfo() {
    if (this.validateFormPickup({showDialogMessage: true, scrollToErrorField: true})) {
      return;
    }
    const pickInfo = this.formPickup.getFormData();
    this.context.saveDeliveryInfo(pickInfo, (stt, data) => {
      switch (stt) {
        case 'inProgress':
          this.uiCollapseState.pick.isLoading = true;
          break;
        case 'succeeded':
        case 'failed':
          this.uiCollapseState.pick.isLoading = false;
          break
      }
    });
  }

  onBtnAddDropInfo() {
    this.uiCollapseState.drops.push({})
  }

  onBtnSaveDropInfo(index: number) {
    if (this.validateFormDropoffsPrev(index)) {
      return;
    }
    if (this.validateFormDropoff(index, {showDialogMessage: true, scrollToErrorField: true})) {
      return;
    }
    let formDropoff = this.formDropoffs.get(index);
    let formItems = this.formItems.get(index);
    const dropInfo = formDropoff.getFormData();
    const itemData = formItems.getFormData();
    this.context.saveDeliveryInfos({...dropInfo, items: itemData.items}, index, (stt, data) => {
      switch (stt) {
        case 'inProgress':
          this.uiCollapseState.drops[index].isLoading = true;
          break;
        case 'succeeded':
        case 'failed':
          this.uiCollapseState.drops[index].isLoading = false;
          break;
      }
    });
  }

  onBtnRemoveDropInfo(index: number) {
    DialogService.confirmDeletion({
      message: `Remove <b>Delivery ${index + 1}</b>?`,
      txtBtnOk: 'Remove',
      fnOk: () => this.uiCollapseState.drops.splice(index, 1)
    })
  }

  onBtnUploadListItems(index: number) {
    DialogService.comingSoon();
  }

}