import { Component, QueryList, ViewChildren } from "@angular/core";
import { ValidateOptions } from "@app/admin/base/form-base";
import { FormDataBatchLocationsMultiPickDrop, FormDataBatchLocationsShipment } from "@app/admin/shipment-entry/interface";
import { FormShipmentLocation } from "../../shipment-location";
import { FormOrderItems } from "../../order-items";
import { DialogService } from "@dialogs/dialog.service";
import { ValidationErrors } from "@angular/forms/forms";
import { ShipmentEntryForm } from "../../shipment-entry-form";
import { BatchShipmentUICollapseState } from "../interface";
import { Utils } from "@services/utils";

@Component({
  selector: '[form-shipment-location-batch-multi-pick-drop]',
  templateUrl: './view.html',
  styleUrls: ['../style.scss', './style.scss'],
  host: {
    class: 'location-batch'
  }
})
export class FormShipmentLocationBatchMultiPickDrop extends ShipmentEntryForm<FormDataBatchLocationsMultiPickDrop> {

  @ViewChildren('formPickup') formPickups: QueryList<FormShipmentLocation>;
  @ViewChildren('formDropoff') formDropoffs: QueryList<FormShipmentLocation>;
  @ViewChildren('formItems') formItems: QueryList<FormOrderItems>

  readonly uiCollapseState: {shipments: BatchShipmentUICollapseState[], addNew: () => void} = {
    shipments: [],
    addNew: () => {
      this.uiCollapseState.shipments.push({data: this.newShipmentData})
    }
  }
  get countShipments() { return this.uiCollapseState.shipments.length }

  ngOnInit(): void {
    this.extractDataModelDraft();
    super.ngOnInit();
  }

  private get newShipmentData() {
    return {
      id: Utils.generateULID()
    }
  }

  private extractDataModelDraft() {
    let _data = <FormDataBatchLocationsMultiPickDrop>this.context?.modelDraft;
    this.uiCollapseState.shipments = [];
    for (let item of _data?.shipments ?? []) {
      if (item && !item.id) {
        // Chỗ này cần đảm bảo server trả về phải có sẵn id rồi, nếu ko thì id sẽ bị override khi save
        throw Error(`FormDataBatchLocationsShipment model must have id before`);
      }
      this.uiCollapseState.shipments.push({data: item});
    }
    if (this.uiCollapseState.shipments.length == 0) {
      this.uiCollapseState.addNew();
    }
  }

  protected onModelDataChanged(): void {
    this.extractDataModelDraft();
  }

  private validateShipment(index: number, options?: ValidateOptions) {
    let formPick = this.formPickups.get(index);
    let formDrop = this.formDropoffs.get(index);
    let formItems = this.formItems.get(index);
    for (let form of [formPick, formDrop, formItems]) {
      let err = form.validate(options);
      if (err) {
        if (options?.scrollToErrorField) {
          this.uiCollapseState.shipments[index].isExpand = true;
        }
        return err;
      }
    }
    return null;
  }

  /**
   * Validate tất cả các form shipment trước index (từ 0 đến index - 1)
   * Cần tạo error message báo phù hợp
   */
  private validateShipmentsPrev(index: number) {
    if (index > 0) {
      for (let i = 0; i < index; i++) {
        let msg = `In order to save <b>Shipment ${index + 1}</b>, you must complete all previous Shipments. So please complete <b>Shipment ${i + 1}</b> first.`;
        let err = this.validateShipment(i, {showDialogMessage: msg, scrollToErrorField: true});
        if (err) {
          return err;
        }
      }
    }
    return null;
  }

  public validate(options?: ValidateOptions): ValidationErrors {
    for (let i = 0; i < this.countShipments; i++) {
      let err = this.validateShipment(i, options);
      if (err) {
        return err;
      }
    }
    return null;
  }

  private getFormDataAt(index: number): FormDataBatchLocationsShipment {
    let formPick = this.formPickups.get(index);
    let formDrop = this.formDropoffs.get(index);
    let formItems = this.formItems.get(index);
    let shipmentId = this.uiCollapseState.shipments?.[index]?.data?.id;
    if (!shipmentId) {
      shipmentId = Utils.generateULID();
    }
    return {
      id: shipmentId,
      pickInfo: formPick.getFormData(),
      dropInfo: formDrop.getFormData(),
      items: formItems.getFormData().items,
    };
  }

  public getFormData(): FormDataBatchLocationsMultiPickDrop {
    let formData: FormDataBatchLocationsMultiPickDrop = {shipments: []}
    for (let i = 0; i < this.uiCollapseState.shipments.length; i++) {
      let shipmentData = this.getFormDataAt(i)
      formData.shipments.push(shipmentData);
    }
    return formData;
  }

  onBtnRemoveShipment(index: number) {
    // TODO: nếu remove item đã có trong draft rồi thì phải gọi API để update
    DialogService.confirmDeletion({
      message: `Remove <b>Shipment ${index + 1}</b>?`,
      txtBtnOk: 'Remove',
      fnOk: () => this.uiCollapseState.shipments.splice(index, 1)
    })
  }

  onBtnSaveShipment(index: number) {
    let err = this.validateShipmentsPrev(index);
    if (err) {
      return;
    }
    err = this.validateShipment(index);
    if (err) {
      return;
    }
    let formPick = this.formPickups.get(index);
    let formDrop = this.formDropoffs.get(index);
    let formItems = this.formItems.get(index);
    const pickInfo = formPick.getFormData();
    const dropInfo = formDrop.getFormData();
    const itemData = formItems.getFormData();
    this.context.saveShipments({pickInfo, dropInfo, items: itemData.items}, index, (stt, data) => {
      switch (stt) {
        case 'inProgress':
          this.uiCollapseState.shipments[index].isLoading = true;
          break;
        case 'succeeded':
        case 'failed':
          this.uiCollapseState.shipments[index].isLoading = false;
          break;
      }
    });
  }

  onBtnAddShipment() {
    this.uiCollapseState.addNew();
  }

  onBtnUploadListItems(index: number) {
    DialogService.comingSoon();
  }

}