<form nz-form [formGroup]="formInput">
  <div class="bottom20">
    <form nz-form *ngFor="let key of ['settings']" [formGroupName]="key">
      <ng-container *ngFor="let subKey of ['isDoNotInvoice']">
        <div nz-checkbox [formControlName]="subKey">
          {{getLabel(join(key,subKey))}}
        </div>
      </ng-container>
    </form>
  </div>
  <div *ngFor="let groupKey of ['revenueInfos']" [formArrayName]="groupKey">
    <form *ngFor="let item of getArrayControls(groupKey); let revenueInfoIndex = index" nz-form [formGroupName]="revenueInfoIndex">
      <div nz-row class="customer-row" *ngIf="shouldShowCustomerRow">
        <div nz-col nzMd="18" class="customer-name">Customer: {{ getCustomerName(groupKey, revenueInfoIndex)}}</div>
        <div nz-col nzMd="6" class="select-revenue-type">
          <nz-form-item>
            <nz-form-control>
              <nz-select nzBackdrop="true" formControlName="type"
                (ngModelChange)="onRevenueTypeChange(groupKey, revenueInfoIndex, $event)">
                <nz-option *ngFor="let item of revenueTypeArr" 
                  [nzLabel]="item.label" [nzValue]="item.value">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div [ngClass]="{ 'form-cost': shouldShowCustomerRow }">
        <div *ngFor="let key of ['revenues']" [formArrayName]="key">
          <form *ngFor="let item of getArrayControlsOfArray(groupKey, revenueInfoIndex, key); let revenueIndex = index" nz-form [formGroupName]="revenueIndex">
            <div class="shipment-item">
              <ng-container *ngIf="!isCreateShipmentModeForCost_MULTIPLE">
                <div form-input-cost-v3 #costForms [form]="getFormCost(groupKey, revenueInfoIndex, key, revenueIndex)"
                  [selectLocations]="selectLocations" [createShipmentMode]="createShipmentModeForCost"
                  [serviceOptions]="serviceOptionsForCostSection">
                </div>
              </ng-container>
              <ng-container *ngIf="isCreateShipmentModeForCost_MULTIPLE">
                <ng-container *ngIf="isPerShipmentRevenueType(groupKey, revenueInfoIndex)">
                  <div class="shipment-label flex">
                    <div>Shipment {{ getShipmentIndex(groupKey, revenueInfoIndex, key, revenueIndex) }}:</div>
                    <div class="flex left20">
                      <div class="f14 shipment-addr-item">{{ getPickupAddress(groupKey, revenueInfoIndex, key, revenueIndex) }}</div>
                      <div class="flex shipment-addr-item">
                        <div class="point start-point">
                          <div class="point16"></div>
                          <div class="point10"></div>
                          <div class="point4"></div>
                        </div>
                        <div class="line"></div>
                        <div class="point end-point">
                          <div class="point16"></div>
                          <div class="point10"></div>
                          <div class="point4"></div>
                        </div>
                      </div>
                      <div class="f14 shipment-addr-item">{{ getDropoffAddress(groupKey, revenueInfoIndex, key, revenueIndex) }}</div>
                    </div>
                  </div>
                  <div form-input-cost-v3 #costForms [form]="getFormCost(groupKey, revenueInfoIndex, key, revenueIndex)"
                    [selectLocations]="selectLocations"
                    [createShipmentMode]="'single'"
                    [labelTotal]="'Sub Total'"
                    [serviceOptions]="serviceOptionsForCostSection"
                    (grandTotalChange)="updateTotalRevenueMultiShipments()">
                  </div>
                </ng-container>
                <ng-container *ngIf="!isPerShipmentRevenueType(groupKey, revenueInfoIndex)">
                  <div form-input-cost-v3 #costForms [form]="getFormCost(groupKey, revenueInfoIndex, key, revenueIndex)"
                    [shipments]="getShipmentsForCost(groupKey, revenueInfoIndex)" 
                    [createShipmentMode]="createShipmentModeForCost"
                    [labelTotal]="'Sub Total'"
                    [serviceOptions]="serviceOptionsForCostSection"
                    (grandTotalChange)="updateTotalRevenueMultiShipments()">
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
    </form>
  </div>
</form>
<div class="flex top10 grand-total-row" *ngIf="isCreateShipmentModeForCost_MULTIPLE">
  <div class="flex1"></div>
  <div>Total Revenue: </div>
  <div class="grand-total">
    {{ totalRevenueAllShipments }}
  </div>
</div>