import { BaseComponent } from "@abstract/BaseComponent";
import { Component, NgZone } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Problem } from "../../services/objects/problem";
import { SubProblem } from "../../services/objects/subProblem";
import { ProblemManagement } from "../../services/problemManagement";
import { RoutingService } from "../../services/routing.service";
import { ShipmentManagement } from "../../services/shipmentManagement";

@Component({
  selector: '[routing-problem-list-shipment]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class RoutingProblemListShipment extends BaseComponent {

  public listShipments = [];
  public problem: Problem;
  public subProblems: SubProblem[]
  constructor(public activatedRoute: ActivatedRoute,
    private routingService: RoutingService,
    private problemManagement: ProblemManagement,
    private shipmentManagement: ShipmentManagement,
    private ngZone: NgZone,
  ) {
    super(activatedRoute)
  }

  get shouldListShipment() {
    return this.listShipments.length > 0 &&
      (!this.subProblems || !this.subProblems.length);
  }

  ngOnInit(): void {
    this.init();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.problemSubscription.unsubscribe()
  }

  problemSubscription = new Subscription()
  init() {
    this.subscription.add(
      this.problemManagement.problemSubject.subscribe(problem => {
        this.problem = problem;
        this.problemSubscription.unsubscribe();
        this.problemSubscription = new Subscription();
        this.problemSubscription.add(this.problem.dataSubject.subscribe(() => {
          this.ngZone.run(() => {
            this.subProblems = this.problem.getSubProblem()
          })
        }));
      })
    );

    this.subscription.add(this.shipmentManagement.shipmentSubject.subscribe(shipments => {
      this.ngZone.run(() => {
        this.listShipments = [...shipments];
      })
    }))
  }
  get canExcludeShipmentFromRouting() {
    return this.problem?.getId() == null;
  }
}