import { Component } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ResponseAuthCreate2faSecret } from '@wearewarp/types/rest-api/auth';
import { Const } from '@const/Const';
import { BaseFormItem } from '@app/admin/base/form-item';

@Component({
  selector: 'setting-2fa',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../detail.scss']
})
export class Setting2FA extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    enable: {label: 'Enable', required: true, type: 'boolean', initialValue: false },
    qrcode: {label: 'QR Code', readOnly: true},
    otp: {label: 'Enter Code', required: false}
  };

  public isShow = false;
  public otpData: ResponseAuthCreate2faSecret = null;
  public isLoading = true;
  public alreadyEnable = false;
  protected location: Location;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.location = this.injector.get(Location);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get shouldCreateFormImmediately() {
    return false
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    this.getData();
  }

  private getData() {
    let url = `${Const.APIURI_USERS}/${this.authUser._id}`;
    this.api.GET(url).subscribe(
      resp => {
        this.model = resp.data;
        if(this.model?.twofaSecret) this.model.enable = true;
        else this.model.enable = false;
        this.alreadyEnable = !!this.model?.twofaSecret;
        this.createFormInput(this.model);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  public get description() {
    return this.alreadyEnable ? '2FA is already enabled. You can turn it off at any time. But we do not recommend it.' : 'You have not enabled 2FA. Please set up 2FA to connect to your phone for enhanced security.';
  }

  onChangeEnable(value) {
    let isRequired = false
    if(value && !this.alreadyEnable) {
      this.isShow = value;
      this.generateTwoFa();
      isRequired = true;
    }
    if(value === false) {
      this.isShow = value;
      isRequired = false;
    }

    this.formGroupDeclaration.otp.required = isRequired;
    let fc = <FormControl>this.formInput.get('otp');

    if (fc) {
      if (!isRequired) {
        fc.removeValidators(Validators.required);
      } else {
        fc.addValidators(Validators.required);
      }
      fc.updateValueAndValidity();
    }
  }

  private async generateTwoFa () {
    if (this.alreadyEnable) return;
    this.auth.twofaGenSecret().subscribe(
      resp => {
        this.otpData = resp.data;
      }, err => {
        this.showErr(err);
      }
    );
  }

  onBack() {
    this.location.back();
  }

  onSubmit() {
    const formData = this.formData_JSON(true);
    this.startProgress();
    this.auth.twofaSetup({enable: formData.enable, otp: formData.otp, secret: this.otpData?.base32}).subscribe(
      resp => {
        this.showSuccess('2FA has been installed successfully. You will need to re-login to continue.');
        this.stopProgress();
        this.api.logout();
        return window.location.reload();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }
}
