import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DetailModule } from '../../base/detail.module';
import { ClientModule } from '../../clients/module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { ReportViewer } from '.';
import { ReportViewerScreen } from './screen';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { ReportTypeList } from './list';
import { ReportListScreen } from './list_screen';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NzTableModule,
        NzPaginationModule,
        NzSelectModule,
        NzButtonModule,
        NzInputModule,
        NzIconModule,
        NzFormModule,
        NzCollapseModule,
        NzCheckboxModule,
        NzRadioModule,
        NzDividerModule,
        NzDatePickerModule,
        SearchBoxModule,
        DetailModule,
        ClientModule,
        SelectBySearchingModule,
        NzToolTipModule,
        NzTypographyModule,
        NzPopoverModule,
        ScrollingModule,
        NzListModule,
    ],
    declarations: [
        ReportViewer,
        ReportTypeList,
        ReportViewerScreen,
        ReportListScreen,
    ],
    exports: [
        ReportViewer,
        ReportViewerScreen,
        ReportListScreen,
    ],
    providers: [
    ]
})
export class ReportViewerModule { }
