<div class="map-bottom-bar" *ngIf="shouldShowBar">
  <div class="wrapper">
    
    <div class="button-controls">
      <button nz-button nzType="default" nzDanger class="cancel-btn" (click)="onCancel()">Cancel</button>
      <button nz-button nzType="primary" class="submit-btn" (click)="onSubmit()" [disabled]="!canSubmit()" [nzLoading]="isLoading">Submit</button>
    </div>
    <div class="info">
     <div [innerHTML]="statusText"></div>
    </div>
  </div>
</div>