import { Const } from '@const/Const';
import { Utils } from '@services/utils';
import { SVG, G, Path, Container, Polygon, Circle } from '@svgdotjs/svg.js';
import { WarpId } from '@wearewarp/universal-libs';
import _ from 'underscore';

class LocationBox {
    color?: string = '#efefef'
    info: any = null
    coords: any = null
    svg: Container = null
    level: number = 0
    index: number = 0
    id: string = null
    click: any = null
    mouseOver: any = null
    mouseOut: any = null
    w: number = 56
    h: number = 60

    visited: boolean = false

    constructor(info) {
        this.info = info
        this.id = this.locationId(info)
        this.onClick = this.onClick.bind(this)
        this.onMouseOver = this.onMouseOver.bind(this)
        this.onMouseOut = this.onMouseOut.bind(this)
        this.draw = this.draw.bind(this)
    }

    onClick() {
        const payload = {
            id: this.id,
            coords: [
                this.coords,
                [this.w, this.h]
            ]
        }
        this.click && this.click(payload)
    }

    onMouseOver() {
        const payload = {
            id: this.id,
            coords: [
                this.coords,
                [this.w, this.h]
            ]
        }
        this.mouseOver && this.mouseOver(payload)
    }

    onMouseOut() {
        this.mouseOut && this.mouseOut({ id: this.id })
    }

    locationId(info) {
        //if (info.crossdockWarehouseId) return `CD_${info.crossdockWarehouseId}`
        const { addr } = info || {}
        const { metadata } = addr || {}
        const { latitude, longitude, externalId } = metadata || {}

        if (externalId) return `EXT_${externalId}`
        if (info.warehouseId) return `WH_${info.warehouseId}`
        if (!latitude || !longitude) return info.uid
        return `${latitude.toFixed(4)}:${longitude.toFixed(4)}`
    }

    withCoords(coords): LocationBox {
        this.coords = coords
        return this
    }

    withColor(color): LocationBox {
        this.color = color
        return this
    }

    remove() {
        if (this.svg) this.svg.remove()
    }

    draw(svg: Container) {
        const h = this.h
        const w = this.w
        if (this.svg) {
            this.svg.untransform()
            this.svg.translate(this.coords[0], this.coords[1])
            return
        }
        this.svg = svg.group()
        this.svg.translate(this.coords[0], this.coords[1])

        const { addr, locationName } = this.info || {}
        let { state, zipcode, metadata = {} } = addr || {}
        if (metadata.isExternal) {
            state = 'EXT'
            zipcode = 'External'
        }
        // determine size
        const h1 = 20
        const r = 10
        // background
        this.svg.rect(this.w, this.h).attr({ stroke: '#444' }).attr('pointer-events', 'none').stroke({ width: 1.5 }).fill('white').radius(r)


        if (this.color) {
            this.svg.circle(r * 2).fill(this.color).click(this.onClick)
            this.svg.circle(r * 2).fill(this.color).move(this.w - 2 * r, 0).click(this.onClick)
            this.svg.rect(this.w, this.h - r - h1).fill(this.color).move(0, r).click(this.onClick)
            this.svg.rect(this.w - 2 * r, r + 1).fill(this.color).move(r, 0).click(this.onClick)
        }
        const rect = this.svg.rect(this.w, this.h).attr({ stroke: '#444', 'pointer-events': 'all', 'class': 'clickable' }).stroke({ width: 1.5 }).fill('none').radius(r)
        rect.click(this.onClick)
        // group.line(0, h1, w, h1).attr({ stroke: '#666' }).stroke({ width: 1 })
        this.svg.line(0, this.h - h1, this.w, this.h - h1).attr({ stroke: '#444' }).stroke({ width: 1 }).attr('pointer-events', 'none')
        this.svg.text(state).font({ family: 'Helvetica', anchor: 'middle', size: 20 })
            .attr({ 'pointer-events': 'none', x: w / 2, y: h / 2 - h1 / 2, 'alignment-baseline': 'central', 'dominant-baseline': 'central' })
        this.svg.text(zipcode).font({ family: 'Helvetica', anchor: 'middle', size: 13 })
            .attr({ 'pointer-events': 'none', x: w / 2, y: h - h1 / 2, 'alignment-baseline': 'central', 'dominant-baseline': 'central' })


        if (locationName) {
            this.svg.text(locationName).font({ family: 'Helvetica', anchor: 'middle', size: 10, 'color': '#aaa' })
                .attr({ 'pointer-events': 'none', x: w / 2, y: h + 8, 'alignment-baseline': 'central', 'dominant-baseline': 'central' })
        }

        if (!Utils.isObjectNotEmpty(metadata)) {
            this.svg.text('Unrecognized address.').font({ family: 'Helvetica', anchor: 'middle', size: 10, 'fill': '#ff0000' })
                .attr({ 'pointer-events': 'none', x: w / 2, y: h + 28, 'alignment-baseline': 'central', 'dominant-baseline': 'central' })
        }

        rect.mouseover(this.onMouseOver)
        rect.mouseout(this.onMouseOut)

        return this.svg
    }

    inlet(index) {
        const x = this.coords[0] - 3
        const y = this.coords[1] + this.h / 2 + index * 8
        return [x, y]
    }

    outlet(index) {
        const x = this.coords[0] + this.w + 3
        const y = this.coords[1] + this.h / 2 + index * 8
        return [x, y]
    }
}

class Arc {
    start: number[] = null
    end: number[] = null
    level: number = null
    path: Path = null
    arrow: Polygon
    y: number = null

    style?: {
        color?: string,
        dasharray?: string,
        boxStyle?: any,
        running?: string
    } = null
    id: string = null
    name: string = null
    subtitle: string = null

    dotStart: Circle = null
    dotEnd: Circle = null

    inLevel: number = 0
    outLevel: number = 0

    from: string = null
    to: string = null
    idBox: IdBox = null
    d1: number = 20
    d2: number = 20

    hilight: boolean = false

    constructor(id, from, to) {
        this.id = id
        this.from = from
        this.to = to
    }

    withStart(start): Arc {
        this.start = start
        return this
    }

    withEnd(end): Arc {
        this.end = end
        return this
    }

    withStyle(style): Arc {
        this.style = style
        return this
    }

    withLevel(level): Arc {
        this.level = level
        return this
    }

    remove() {
        this.path?.remove()
        this.idBox?.remove()
        this.arrow?.remove()
        this.dotStart?.remove()
        this.dotEnd?.remove()
    }

    draw(svg: Container) {
        let path = ''
        if (this.start[1] == this.end[1] && this.start[1] == this.y) {
            path = `M${this.start[0]} ${this.start[1]} H${this.end[0] - 8}`
        } else {
            const curve1 = Math.min(Math.abs(this.y - this.start[1]) / 2, 10)
            const curve2 = Math.min(Math.abs(this.y - this.end[1]) / 2, 10)

            const up1: boolean = this.y < this.start[1]
            const up2: boolean = this.end[1] < this.y
            const forward: boolean = this.end[0] > this.start[0]
            const dy1 = up1 ? -curve1 : curve1
            const dy2 = up2 ? -curve2 : curve2
            const dx = forward ? curve2 : -curve2
            const dx1 = forward ? curve1 : -curve1
            // this.d1 = 20 + (this.y > this.start[1] ? -this.inLevel : this.inLevel) * 4
            // this.d2 = 20 + (this.y > this.end[1] ? -this.outLevel : this.outLevel) * 4

            path = `M${this.start[0]} ${this.start[1]} h${this.d1} q${curve1} 0 ${curve1} ${dy1} V${this.y - dy1} q0 ${dy1} ${dx1} ${dy1} H${this.end[0] - dx - curve2 - this.d2} q${dx} 0 ${dx} ${dy2} V${this.end[1] - dy2} q0 ${dy2} ${curve2} ${dy2} H${this.end[0] - 8}`
        }
        if (this.path) {
            this.path.plot(path)
            this.arrow.move(this.end[0] - 11, this.end[1] - 4)
            this.dotStart.move(this.start[0] - 3, this.start[1] - 3)
            this.dotEnd.move(this.end[0] - 3, this.end[1] - 3)

            this.idBox.name = this.name || this.id

            this.idBox.coords = this.center
            this.idBox.subtitle = this.subtitle
            this.idBox.hilight = this.hilight
            this.idBox.draw(svg)
        } else {
            this.path = svg.path(path)
                .attr({
                    stroke: this.style?.color ?? '#444',
                    'stroke-dasharray': this.style?.dasharray,
                }).stroke({ width: 2 }).fill('none')
            this.arrow = svg.polygon('0,0 -9,4 -6,0 -9,-4').fill(this.style?.color).move(this.end[0] - 11, this.end[1] - 4)
            this.dotStart = svg.circle(6).attr({ stroke: '#444' }).stroke({ width: 1 }).fill('#688ae5').move(this.start[0] - 3, this.start[1] - 3)
            this.dotEnd = svg.circle(6).attr({ stroke: '#444' }).stroke({ width: 1 }).fill('#eac85f').move(this.end[0] - 3, this.end[1] - 3)

            this.idBox = new IdBox(`SHIPMENT_${this.id}`, this.name || this.id, this.center)
            this.idBox.subtitle = this.subtitle
            this.idBox.hilight = this.hilight
            this.idBox.style = this.style?.boxStyle ?? { color: this.style?.color ?? '#444' }
            this.idBox.draw(svg)
            // idBox.click = this.onClick
            // idBox.mouseOver = this.onMouseOver
            // idBox.mouseOut = this.onMouseOut

        }

        if (this.style.running)
            this.path.attr('stroke-dasharray', '0 4 0').animate(4000).ease('-').attr('stroke-dasharray', '0 2 4 2').loop(0)

        return this.path
    }

    get center() {
        return [(this.start[0] + this.end[0]) / 2, this.y]
    }
}

class IdBox {
    id: any = null
    name: any = null
    subtitle: any = null
    coords: any = null
    click: any = null
    mouseOver: any = null
    mouseOut: any = null
    style: {
        color?: string,
        'text-decoration'?: string
    }
    svg: G = null
    hilight: boolean = false

    constructor(id, name, coords) {
        this.id = id
        this.name = name
        this.coords = coords
        this.onClick = this.onClick.bind(this)
        this.onMouseOver = this.onMouseOver.bind(this)
        this.onMouseOut = this.onMouseOut.bind(this)
    }

    onClick() {
        const payload = {
            id: this.id,
            coords: [
                this.coords,
                [27, 10]
            ]
        }
        this.click && this.click(payload)
    }

    onMouseOver() {
        const payload = {
            id: this.id,
            coords: [
                this.coords,
                [27, 10]
            ]
        }
        this.mouseOver && this.mouseOver(payload)
    }

    onMouseOut() {
        this.mouseOut && this.mouseOut({ id: this.id })
    }

    remove() {
        this.svg?.remove()
    }

    private hilightBox: any = null
    draw(svg: Container) {
        if (this.svg) {
            if (!this.hilight) {
                this.hilightBox?.timeline()?.stop()
            } else {
                this.hilightBox?.attr('opacity', 0).animate(600).attr('opacity', 0.8).ease('<>').loop(0, true)
            }
            this.svg.untransform()
        } else {
            this.svg = svg.group()
            // const w = 54
            const w = 100
            const h = 20
            this.hilightBox = this.svg.rect(w + 12, h + 12).move(-w / 2 - 6, -h / 2 - 6)
                .attr({ stroke: '#333', 'stroke-dasharray': '0 4 0', opacity: 0 })
                .fill('#afb').stroke({ width: 1.5 }).radius(8)
            if (this.hilight)
                this.hilightBox.attr('opacity', 0).animate(600).attr('opacity', 0.8).ease('<>').loop(0, true)

            this.svg.rect(w, h).move(-w / 2, -h / 2)
                .attr({ stroke: this.style?.color ?? '#444' }).fill('#fff').stroke({ width: 1 }).radius(5)
            const rect2 = this.svg.rect(w, h).move(-w / 2, -h / 2)
                .attr({ stroke: this.style?.color ?? '#444', 'fill-opacity': 0.3, 'class': 'clickable' }).fill(this.style.color).stroke({ width: 1 }).radius(5)
            rect2.click(this.onClick).mouseover(this.onMouseOver).mouseout(this.onMouseOut)
            const txt = this.name || this.id
            this.svg.text(txt).attr({ x: 0, y: 0 }).attr({ 'alignment-baseline': 'central', 'dominant-baseline': 'central', 'pointer-events': 'none' })
                .font({ family: 'Helvetica', anchor: 'middle', size: 14, 'text-decoration': this.style?.['text-decoration'] })
            // this.svg.text(function(add) {
            //     add.tspan(txt).move(0, 0).attr({'alignment-baseline': 'central', 'dominant-baseline': 'central'})
            // }).move(0,0).font( {family: 'Helvetica', anchor: 'middle', size: 14, 'alignment-baseline': 'central', 'dominant-baseline': 'central'}).attr('pointer-events', 'none')
            if (this.subtitle) {
                const subtitle = this.subtitle
                this.svg.text(subtitle).attr({ x: 0, y: h / 2 + 8 }).attr({ 'alignment-baseline': 'central', 'dominant-baseline': 'central', 'pointer-events': 'none' })
                    .font({ family: 'Helvetica', anchor: 'middle', size: 14 })
            }
        }
        this.svg.translate(this.coords[0], this.coords[1])
    }
}

class DeliveryNetwork {
    locations: LocationBox[] = []
    locationMap: any = {}
    arcMap: any = {}
    arcs: Arc[] = []
    size: number[] = [0, 0]
    click: any = null
    mouseOver: any = null
    mouseOut: any = null
    shipment: any = null
    children: any = []
    svg: Container = null
    levelW: number = 240
    levelH: number = 80

    inArcByLoc: any = {}
    outArcByLoc: any = {}

    highlight(ids) {
        if (!ids) return
        for (let arc of this.arcs) {
            if (arc.id && ids.indexOf(arc.id) >= 0) {
                arc.hilight = true
            } else {
                arc.hilight = false
            }
        }
    }

    getShipmentStatusStyle(shipment) {
        switch (shipment.status) {
            case Const.OrderStatus.complete:
                return {
                    color: '#238552'
                }
            case Const.OrderStatus.pickupFailed:
            case Const.OrderStatus.canceled:
            case Const.OrderStatus.removed:
                return {
                    color: '#aaa',
                    dasharray: '5,5',
                    boxStyle: {
                        color: '#e7e7e7',
                        'text-decoration': "line-through"
                    }
                }
            case Const.OrderStatus.returned:
                return {
                    color: '#eac85f',
                    boxStyle: {
                        color: '#eac85f',
                        'text-decoration': "line-through"
                    },
                    dasharray: '5,5'
                }
            case '':
            case undefined:
            case Const.OrderStatus.needCarrier:
                return {
                    color: '#aaa'
                }
            case Const.OrderStatus.booked:
                return {
                    color: '#88e'
                }
            case Const.OrderStatus.dropoffFailed:
            case Const.OrderStatus.issue:
            case Const.OrderStatus.lost:
                return {
                    color: '#f88'
                }
            default:
                return {
                    color: '#1890ff',
                    running: true,
                }
        }
    }

    reset() {
        this.locations = [];
        this.locationMap = {};
        this.arcMap = {}
        this.arcs = [];
        this.shipment = {};
        this.children = []
        this.inArcByLoc = {}
        this.outArcByLoc = {}
        this.svg.clear();
        return this
    }

    load(shipment, children, showMain=false) {
        this.shipment = shipment
        this.children = children
        this.onClick = this.onClick.bind(this)
        this.onMouseOut = this.onMouseOut.bind(this)
        this.onMouseOver = this.onMouseOver.bind(this)

        const newLocations: LocationBox[] = shipment.deliveryInfos.map(it => new LocationBox(it))
        let first = null
        let last = null
        let locationIds = []
        for (let loc of newLocations) {
            const old = this.locationMap[loc.id]
            let location = loc
            if (old) {
                old.info = loc.info
                location = old
            } else {
                this.locations.push(loc)
                this.locationMap[loc.id] = loc
            }

            if (location.info.type === 'PICKUP') {
                loc.level = 0
                first = location
                locationIds.push(location.id)
            } else if (location.info.type === 'DROPOFF') {
                loc.level = 0
                last = location
                locationIds.push(location.id)
            }
        }

        first?.withColor('#688ae5')
        last?.withColor('#eac85f')
        const arcIds = []
        if (!children || !children.length || showMain) {
            const arc = new Arc(shipment.id, first.id, last.id)
            if (shipment.code) {
                arc.name = shipment.code
                if (shipment.warpId) arc.subtitle = WarpId.showShipment(shipment.warpId);
            } 
            else if (shipment.warpId) {
                arc.name = WarpId.showShipment(shipment.warpId)
            }
            arc.withStyle(this.getShipmentStatusStyle(shipment))

            arcIds.push(arc.id)
            if (!this.arcMap[arc.id]) {
                this.arcs.push(arc)
                this.arcMap[arc.id] = arc
            }
        }
        if (children && children.length) {
            for (let child of children) {
                const pickup = child.deliveryInfos.filter(it => it.type === 'PICKUP')[0]
                const dropoff = child.deliveryInfos.filter(it => it.type === 'DROPOFF')[0]

                const pickupLoc = new LocationBox(pickup)
                const dropoffLoc = new LocationBox(dropoff)
                locationIds.push(pickupLoc.id)
                locationIds.push(dropoffLoc.id)

                if (!this.locationMap[pickupLoc.id]) {
                    this.locationMap[pickupLoc.id] = pickupLoc
                    this.locations.push(pickupLoc)
                } else {
                    this.locationMap[pickupLoc.id].info = pickup
                }
                if (!this.locationMap[dropoffLoc.id]) {
                    this.locationMap[dropoffLoc.id] = dropoffLoc
                    this.locations.push(dropoffLoc)
                } else {
                    this.locationMap[dropoffLoc.id].info = dropoff
                }

                const arc = new Arc(child.id, pickupLoc.id, dropoffLoc.id)
                arcIds.push(arc.id)
                if (child.name) {
                    arc.name = child.name
                    if (child.warpId) arc.subtitle = `id: ${child.warpId}`
                }
                else if (child.code) {
                    arc.name = child.code;
                    if (child.warpId) arc.subtitle = WarpId.showShipment(child.warpId);
                }
                else if (child.warpId) {
                    arc.name = child.warpId
                } else {
                    arc.name = '...'
                }

                arc.withStyle(this.getShipmentStatusStyle(child))


                const old: Arc = this.arcMap[arc.id]
                if (old) {
                    old.name = arc.name
                    old.subtitle = arc.subtitle
                    old.style = arc.style
                } else {
                    this.arcs.push(arc)
                    this.arcMap[arc.id] = arc
                }
            }
        }


        for (let l of this.arcs) {
            if (arcIds.indexOf(l.id) < 0) {
                l.remove()
                delete this.arcMap[l.id]
            }
        }
        for (let l of this.locations) {
            if (locationIds.indexOf(l.id) < 0) {
                l.remove()
                delete this.locationMap[l.id]
            }
        }

        this.locations = this.locations.filter(it => locationIds.indexOf(it.id) >= 0)
        this.arcs = this.arcs.filter(it => arcIds.indexOf(it.id) >= 0)

        this.outArcByLoc = _.groupBy(this.arcs, 'from')
        this.inArcByLoc = _.groupBy(this.arcs, 'to')

        const zeroes = this.locations.map(it => Object.assign({}, it))
        const tracked = {}
        for (let loc of this.locations) {
            if (loc.id !== first.id) {
                loc.index = 1
            }
        }
        while (zeroes.length > 0) {
            const seed = zeroes.splice(0, 1)[0]
            if (seed.visited) continue
            const queue = []
            queue.push(seed)
            while (queue.length > 0) {
                const removed = queue.splice(0, 1)[0]
                removed.visited = true
                const next = this.outArcByLoc[removed.id]
                if (next && removed.id !== last?.id)
                    for (let i = 0; i < next.length; i++) {
                        const arc = next[i]
                        const prev = tracked[arc.from] || []

                        if (arc.from !== arc.to && prev.indexOf(arc.to) < 0) {
                            const toPrev = (tracked[arc.to] || []).concat(prev)
                            toPrev.push(arc.from)
                            tracked[arc.to] = toPrev
                            const loc = this.locationMap[arc.to]
                            if (loc && (!loc.index || loc.index < removed.index + 1)) {
                                loc.index = removed.index + 1
                            }
                            if (loc) queue.push(loc)
                        }
                    }
            }
        }

        // make sure last always be the last
        const maxIndNotLast = _.max(this.locations.filter(it => it.id !== last?.id).map(it => it.index).filter(it => it))
        if (last && maxIndNotLast && maxIndNotLast >= last.index) {
            last.index = maxIndNotLast + 1
        }

        const byIndex: any = _.values(_.groupBy(this.locations, 'index'))

        for (let l of byIndex) {
            for (let i = 0; i < l.length; i++) {
                l[i].level = i - (l.length - 1) / 2
            }
        }

        const occupiedLevel: any = {}
        const regsterOccupiedLevel = (index, level) => {
            const key = (2 * index).toFixed(0)
            const levels = occupiedLevel[key] || []
            levels.push(level)
            occupiedLevel[key] = levels
        }
        const registerOccupiedArc = (arc: Arc) => {
            const fromIndex = this.locationMap[arc.from].index
            const toIndex = this.locationMap[arc.to].index
            if (toIndex > fromIndex) {
                let start = fromIndex + 0.5
                while (start < toIndex) {
                    regsterOccupiedLevel(start, arc.level)
                    start += 0.5
                }
            }
            else {
                let start = fromIndex
                while (start >= toIndex) {
                    regsterOccupiedLevel(start, arc.level)
                    start -= 0.5
                }
            }
        }
        // initialize levels
        const increment = 0.5
        for (let loc of this.locations) {
            regsterOccupiedLevel(loc.index, loc.level)
            regsterOccupiedLevel(loc.index, loc.level + 0.5)
            regsterOccupiedLevel(loc.index, loc.level + 0.25)
            regsterOccupiedLevel(loc.index, loc.level - 0.5)
            regsterOccupiedLevel(loc.index, loc.level - 0.25)
        }
        for (let loc of this.locations) {
            const next = this.outArcByLoc[loc.id]
            if (next) {
                for (let arc of next) {
                    arc.level = null
                }
                const forward = next.filter(it => this.locationMap[it.to].index > loc.index)
                const backward = next.filter(it => this.locationMap[it.to].index <= loc.index)
                const forwardByToIndex = _.sortBy(_.values(_.groupBy(forward, it => this.locationMap[it.to].index)), it => this.locationMap[it[0].to].index)
                const backwardByToIndex = _.sortBy(_.values(_.groupBy(backward, it => this.locationMap[it.to].index)), it => -this.locationMap[it[0].to].index)
                const groups = forwardByToIndex.concat(backwardByToIndex)
                let lowestInLevel = 0
                let highestInLevel = 0
                let minLevel = null
                let maxLevel = null
                for (let group of groups) {
                    // const sortByLevel = _.sortBy(group, it => this.locationMap[it.to].level)
                    const groupByLevel = _.sortBy(_.values(_.groupBy(group, it => this.locationMap[it.to].level)), it => this.locationMap[it[0].to].level)
                    const groupIndex = this.locationMap[group[0].to].index
                    for (let indexGroup of groupByLevel) {
                        const toLocation = this.locationMap[indexGroup[0].to]
                        const backward = this.locationMap[indexGroup[0].to].index <= loc.index
                        const upward = backward ? loc.level >= 0 : (loc.level + toLocation.level) >= 0
                        const step = upward ? increment : -increment

                        let startLevel = upward ? Math.max(loc.level, toLocation.level) : Math.min(loc.level, toLocation.level)
                        for (let i = 0; i < indexGroup.length; i++) {
                            if (!upward) {
                                indexGroup[i].inLevel = lowestInLevel
                                lowestInLevel -= 1
                            } else {
                                indexGroup[i].inLevel = highestInLevel
                                highestInLevel += 1
                            }

                            let occupied = []
                            if (backward) {
                                for (let i = 2 * loc.index; i >= toLocation.index * 2; i--) {
                                    occupied = occupied.concat(occupiedLevel[i.toFixed(0)] || [])
                                }
                            } else {
                                for (let i = 2 * loc.index + 1; i < toLocation.index * 2; i++) {
                                    occupied = occupied.concat(occupiedLevel[i.toFixed(0)] || [])
                                }
                            }
                            while (occupied.indexOf(startLevel) >= 0) {
                                startLevel += step
                            }
                            indexGroup[i].level = startLevel
                            registerOccupiedArc(indexGroup[i])
                        }

                    }
                }

                // resort in level
                const l = _.sortBy(next, 'inLevel')
                let zero = -1
                for (let i = 0; i < l.length; i++) {
                    if (l[i].level == loc.level) {
                        zero = i
                    }
                }
                const startIndex = zero >= 0 ? -zero : ((1 - next.length) / 2)
                for (let i = 0; i < l.length; i++) {
                    const arc = l[i]
                    arc.inLevel = i + startIndex
                }

                let d = 10
                for (let i = 0; i < l.length; i++) {
                    const arc = l[i]

                    if ((arc.level - loc.level) * this.levelH <= arc.inLevel * 8) {
                        arc.d1 = d
                        d += 4
                    }
                }
                d = 10
                for (let i = l.length - 1; i >= 0; i--) {
                    const arc = l[i]
                    if ((arc.level - loc.level) * this.levelH >= arc.inLevel * 8) {
                        arc.d1 = d
                        d += 4
                    }
                }
            }
        }

        for (let loc of this.locations) {
            const next = this.inArcByLoc[loc.id]
            if (next) {
                const l = _.sortBy(next, 'level')
                let zero = -1
                for (let i = 0; i < l.length; i++) {
                    if (l[i].level == loc.level) {
                        zero = i
                    }
                }
                const startIndex = zero >= 0 ? -zero : ((1 - next.length) / 2)
                for (let i = 0; i < l.length; i++) {
                    const arc = l[i]
                    arc.outLevel = i + startIndex
                }

                let d = 10
                for (let i = 0; i < l.length; i++) {
                    const arc = l[i]

                    if ((arc.level - loc.level) * this.levelH <= arc.outLevel * 3) {
                        arc.d2 = d
                        d += 4
                    }
                }
                d = 10
                for (let i = l.length - 1; i >= 0; i--) {
                    const arc = l[i]
                    if ((arc.level - loc.level) * this.levelH >= arc.outLevel * 3) {
                        arc.d2 = d
                        d += 4
                    }
                }
            }
        }
    }


    processEvent(event) {
        const { id } = event
        const location = this.locationMap[id]
        if (location) {
            event['location'] = location.info
        }
        if (id && id.indexOf('SHIPMENT_') === 0) {
            // get shipment
            const uid = id.split('_')[1]
            let shipment = null
            if (this.shipment.id === uid) {
                shipment = this.shipment
            } else {
                shipment = (this.children || []).filter(it => it.id === uid)[0]
            }
            if (shipment) {
                event['shipment'] = shipment
            }
            if (event['type'] === 'click') {
                this.highlight([uid])
                this.redraw()
            }
        }
        return event
    }

    onClick(event) {
        event['type'] = 'click'
        this.click && this.click(this.processEvent(event))
    }
    onMouseOver(event) {
        event['type'] = 'mouseover'
        this.mouseOver && this.mouseOver(this.processEvent(event))
    }
    onMouseOut(event) {
        event['type'] = 'mouseout'
        this.mouseOut && this.mouseOut(event)
    }

    calculateCooridates() {
        const minLevel = Math.min(0, Math.min(_.min(this.locations.map(it => it.level)), _.min(this.arcs.map(it => it.level))))
        const top = this.locations[0].h / 2 + 10 - minLevel * this.levelH
        for (let loc of this.locations) {
            loc.withCoords([loc.index * this.levelW + 40, top + loc.level * this.levelH - loc.h / 2])
        }
        for (let arc of this.arcs) {
            const fromLoc: LocationBox = this.locationMap[arc.from]
            const toLoc: LocationBox = this.locationMap[arc.to]

            arc.withStart(fromLoc.outlet(arc.inLevel))
                .withEnd(toLoc.inlet(arc.outLevel))

            arc.y = top + (arc.level) * this.levelH
        }
    }

    draw(svg: Container) {
        this.calculateCooridates()
        for (let loc of this.locations) {
            loc.draw(svg)
            loc.click = this.onClick
            loc.mouseOver = this.onMouseOver
            loc.mouseOut = this.onMouseOut
        }
        for (let arc of this.arcs) {
            if (!arc.style)
                arc.withStyle({ color: '#444' })

            arc.draw(svg)

            if (arc.idBox) {
                arc.idBox.click = this.onClick
                arc.idBox.mouseOver = this.onMouseOver
                arc.idBox.mouseOut = this.onMouseOut
            }
        }
    }

    recommendedSize() {
        let maxLevel = 0
        let minLevel = 0
        let maxInd = 0
        for (let loc of this.locations) {
            if (loc.index > maxInd) maxInd = loc.index
            if (loc.level > maxLevel) maxLevel = loc.level
            if (loc.level < minLevel) minLevel = loc.level
        }
        for (let arc of this.arcs) {
            if (arc.level > maxLevel) maxLevel = arc.level
            if (arc.level < minLevel) minLevel = arc.level
        }
        // this.levelW = Math.max(400, Math.floor(800 / maxInd))
        return [maxInd * this.levelW + 80 + this.locations[0].w, (1 + maxLevel - minLevel) * this.levelH + 40]
    }

    withSize(size) {
        this.size = size
    }

    drawTo(elementRef) {
        const size = this.recommendedSize()
        this.size = size
        this.svg = SVG().addTo(elementRef).size(size[0], 0)

        this.draw(this.svg)
        this.svg.size(size[0], size[1])
    }

    redraw() {
        const size = this.recommendedSize()
        this.size = size

        this.draw(this.svg)
        this.svg.size(size[0], size[1])
    }
}

export default DeliveryNetwork