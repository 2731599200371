import { StringUUID } from "@wearewarp/types";
import { getInjector } from "@services/index";
import { Log } from "@services/log";
import { ModelRoutingProblem } from "../../interface";
import { ProblemManagement } from "../problemManagement";
import { RoutingService } from "../routing.service";
import { Websocket } from "../websocket.service";
import { Route } from "./route";

export class SubProblem {
  private data: ModelRoutingProblem;
  private routes: Map<StringUUID, Route> = new Map();
  private routingService: RoutingService;
  private websocketService: Websocket;
  private problemManagement: ProblemManagement

  constructor(data) {
    const injector = getInjector();
    this.routingService = injector.get(RoutingService);
    this.websocketService = injector.get(Websocket);
    this.problemManagement = injector.get(ProblemManagement);
    if (!data) return;
    this.updateData(data);
    this.subscribeWsEvents();
  }

  updateData(newData) {
    this.data = {
      ...this.data,
      ...newData
    };
    this.updateRoutes()
  }

  getId() {
    return this.data.id;
  }
  getData() {
    return this.data;
  }
  getRoutes() {
    return this.routes;
  }
  public getRegion() {
    return this.data.metadata?.region ?? this.data.attributes?.region;
  }

  private updateRoutes() {
    const routesData = this.data?.selected_solution?.routes || [];
    let newMap = new Map<StringUUID, Route>();
    // let index = 0;
    routesData.map(routeItem => {
      const id = routeItem.id;
      let route = this.routes.get(id);
      if (route) route.setData(routeItem);
      else {
        const routeIndex = this.problemManagement.getRouteIndex(id);
        route = new Route(routeItem, routeIndex);
        route.setSubProblem(this);
      }
      newMap.set(id, route);
    });
    this.routes = newMap;
  }

  private subscribeWsEvents() {
    this.websocketService.sendWsData({ target: 'SUBSCRIBE', content: 'NEW_SOLUTION_' + this.data?.id });
    this.websocketService.sendWsData({ target: 'SUBSCRIBE', content: 'ROUTING_PROBLEM_' + this.data?.id });

    const topicHandlers = [
      { prefix: 'TOPIC@NEW_SOLUTION_', fn: this.handleEventNewSolution.bind(this) },
      { prefix: 'TOPIC@ROUTING_PROBLEM_', fn: this.handleEventRoutingProblem.bind(this) },
    ];

    this.websocketService.onMessage.subscribe(data => {
      const target = data?.target;
      for (let handler of topicHandlers) {
        if (target.startsWith(handler.prefix)) {
          let problemId = target.substring(handler.prefix.length);
          if (problemId == this.data.id) {
            handler.fn(data.content);
          }
        }
      }
    })
  }

  destroy() {

  }

  private async handleEventNewSolution(content: string) {
    Log.d('handleEventNewSolution content: ', content);
    const solution = JSON.parse(content);
    this.data.selected_solution = await this.routingService.getSolution(solution.id);
  }

  private async handleEventRoutingProblem(content: string) {
    Log.d('handleEventRoutingProblem content: ', content);
    if (content.startsWith('STATUS@')) {
      const status = content.substring(7);
      //this.data.status = status;
      this.updateData({ status });
      if (status == 'ROUTING_COMPLETE') {
        this.problemManagement.reloadProblem();
        this.routingService.uiControlSubject.next({
          event: "showDialogRoutingDone"
        })
      }
    } else if (content.startsWith("PROGRESS@")) {
      const comps = content.substring(9).split(':');
      const progress = {
        ts: parseInt(comps[0]),
        iteration: parseInt(comps[1]),
        elapsed: parseInt(comps[2])
      };
      // this.data.progress = progress;
      this.updateData({ progress });
    }
  }

  public getStatus() {

    const routes = Array.from(this.routes.values());
    const routeInProgress = routes.filter(route => route.getStatus() == 'NEED_REVIEW').length;
    if (routeInProgress) return 'ROUTING_NEED_REVIEW'
    return this.data?.status
  }

  public getShipmentRouted() {
    let shipmentsRouted = [];
    this.routes.forEach(route => {
      shipmentsRouted = [
        ...shipmentsRouted,
        ...route.getShipmentIds(),
      ]
    })
    return shipmentsRouted;
  }

  /**
   * lấy tất cả các shipment đã được chọn và cho vào region
   */
  public getAllShipmentIds() {
    return this.data.routingShipment?.shipments.map(shipment => shipment.id);
  }

}