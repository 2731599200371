
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Order, Shipment, ShipmentItem } from '@wearewarp/types/data-model';
import { DateUtil } from '@services/date-utils';
import { Const } from '@wearewarp/universal-libs';
import _ from 'underscore'
import Utils from './utils';
import { Utils as ServiceUtils } from "@services/utils";

@Component({
    selector: '[warehouse-item-list-item]',
    templateUrl: './item.html',
    styleUrls: ['./item.scss']
})
export class WarehouseItemListItem extends BaseComponent {
    _item: any;

    clientName: string = null
    outbound: Shipment[] = []
    inbound: Shipment[] = []
    main: Shipment = null
    order: Order = null
    createdDate: string = null
    pickupLocation: any = null
    deliverLocation: any = null
    pickupDate: any = null
    injectionDate: any = null
    releaseDate: any = null
    needsAttention: boolean = false
    identifiedPalletCount: number = null
    totalWeight: number = null
    scheduledPickup: any = null
    status: string = null
    scheduledRelease: any = null
    
    @Output() onItemSelected: EventEmitter<any> = new EventEmitter<any>();
    
    @Input() selected

    @Input() set item(value) {
        this._item = value;
        const dtf = 'M/DD hh:mm A'
        const df = 'MMM DD'
        const tf = 'hh:mm A'
        const tf2 = 'H:mm'

        const { outboundShipment, inboundShipment, mainShipment, order } = value || {}
        this.outbound = outboundShipment || []
        this.inbound = inboundShipment || []
        this.main = mainShipment
        this.order = order
        if (this.main) {
            this.clientName = this.main.metadata?.client?.name
            this.createdDate = DateUtil.formatDate(this.main.insert.when, 'MMM D')
            const pickupdate = Utils.getStatusTimeLine(this.inbound[0], 'pickupSuccessful')
            this.pickupDate = pickupdate ? DateUtil.displayLocalTime(pickupdate, {timezone: Utils.getDeliveryInfo(this.inbound[0], 'PICKUP').addr.metadata?.timeZoneStandard, format: 'ddd MMM DD, H:mm'}) : null

            // this.pickupDate = [this.formatDate(Utils.getStatusTimeLine(this.inbound[0], 'pickupSuccessful'), df), this.formatDate(Utils.getStatusTimeLine(this.inbound[0], 'pickupSuccessful'), tf)]
            this.injectionDate = [this.formatDate(Utils.getStatusTimeLine(this.inbound[0], 'complete'), df), this.formatDate(Utils.getStatusTimeLine(this.inbound[0], 'complete'), tf)]
            this.releaseDate = [this.formatDate(Utils.getStatusTimeLine(this.outbound[0], 'pickupSuccessful'), df), this.formatDate(Utils.getStatusTimeLine(this.outbound[0], 'pickupSuccessful'), tf)]

            this.identifiedPalletCount = _.reduce((this._item.item || []).map(Utils.countIdentified), (a,b) => a + b, 0)
            this.totalWeight = _.reduce((this._item.item || []).map(Utils.countWeight), (a,b) => a + b, 0)
        }

        if (this.inbound && this.inbound.length) {
            this.pickupLocation = Utils.getDeliveryInfo(this.inbound[0], 'PICKUP')
            const scheduledPickup = Utils.getDeliveryInfoWindow(this.pickupLocation)
            if (scheduledPickup) {
                this.scheduledPickup = DateUtil.displayTimeWindow(scheduledPickup, {timezone: this.pickupLocation.addr.metadata?.timeZoneStandard, format: 'ddd MMM DD, H:mm', formatDateOnly: 'ddd MMM DD'})
                // this.scheduledPickup = [
                //     this.formatDate(scheduledPickup.from, df),
                //     this.formatDate(scheduledPickup.from, tf2) + ' - ' + this.formatDate(scheduledPickup.to, tf2)
                // ]
            }
        }
        if (this.outbound && this.outbound.length) {
            this.deliverLocation = Utils.getDeliveryInfo(this.outbound[0], 'DROPOFF')
            if (this.outbound[0].status === Const.ShipmentStatus.complete || this.outbound[0].status === Const.ShipmentStatus.inRouteToDropoff) {
                if (this._item.inventory && this._item.inventory.length)
                    this.needsAttention = true
                if (this.inbound && this.inbound.length && this.outbound[0].status !== Const.ShipmentStatus.complete) {
                    this.needsAttention = true
                }
            }
        }
        this.scheduledRelease = this.formatDate(this._item.scheduledRelease, df)

        const status = this._item?.status || ''
        this.status = status.split('_').join(' ')
    }

    private formatDate(s: string, f: string) {
        if (!s) return null
        return DateUtil.formatDate(s, f)
    }

    onSelect() {
        if (this._item) {
            this.onItemSelected.emit(this._item.id)
        }
    }

    stopPropergate(event) {
        event.stopPropagation();
    }

    copyToClipboard(event, txt) {
        event.stopPropagation()
        ServiceUtils.copyTextToClipboard(txt, (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied to the clipboard ${txt}`
                );
            }
        })
    }
}
