import to from 'await-to-js';
import { Component, Input } from "@angular/core";
import * as pdfjsLib from 'pdfjs-dist';
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";
import { NotesComponentEventFetchDataDone, NotesComponentEventItemAdded, NotesComponentEventItemRemoved } from '@app/admin/components/notes/interface';
import { AttachedFile } from '@wearewarp/types/data-model';
import { AttachedFileUtil } from '@services/attached-file-util';
pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js';

@Component({
  selector: '[confirm-pod]',
  templateUrl: './index.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './index.scss']
})
export class ConfirmPod extends BaseDialog {
  @Input() model: any;
  onDone: (data) => void = (data) => { }

  public isLoading = false;
  public isCarrierBillMatches = false;
  public isGeneratingInvoice = false;
  public podGroups;
  public taskRequiredPOD: string[] = [];
  public get hasInvoice(): boolean {
    return this.model?.hasInvoice;
  }

  public get isMultiStop(): boolean { return this.model?.isMultiStop ?? false }
  public get isParentSubShipment(): boolean { return this.model?.isParentSubShipment ?? false }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getDataV2();
    // this.getData();
  }

  onBtnCancel() {
    this.closeDialog();
  }

  onGenerateInvoice() {
    if (!this.canGenerateInvoice) return;
    const body : any = {};
    if (this.model?.invoiceFileId || (this.model?.invoice && this.model?.invoice.id)) body['forceReGenerate'] = true;
    this.isGeneratingInvoice = true;
    const url = `v2/${Const.APIURI_ORDERS}/invoice/${this.model.id}`;
    this.api.POST(url, body).subscribe(
      resp => {
        this.isGeneratingInvoice = false;
        this.onDone(resp.data);
        this.closeDialog();
      }, err => {
        this.isGeneratingInvoice = false;
        this.showErr(err);
      }
    );
  }

  private initPodGroups() {
    let arr = [];
    const shipments = this.model?.metadata?.shipments || [];
    for (let shipment of shipments) {
      arr.push({ warpId: shipment.warpId, podArr: [], status: shipment.status });
    }
    return arr;
  }

  isCanceled(podGroup): boolean {
    return podGroup.status == Const.OrderStatus.canceled;
  }

  private addToPodGroups(warpId: number, podArr: Array<any>) {
    for (let i = 0; i < this.podGroups.length; i++) {
      if (this.podGroups[i].warpId == warpId) {
        this.podGroups[i].podArr = [...this.podGroups[i].podArr, ...podArr];
        return;
      }
    }
  }

  public canGenerateInvoice: boolean = false;

  private checkCanGenerateInvoice() {
    if (this.model.isInvoiceWithoutPOD) return true;
    if(!this.taskRequiredPOD.length) return true;
    let allPod = []
    for(let podGroup of this.podGroups){
      allPod.push(...podGroup.podArr)
    }
    let isConfirmAll = true;
    for(let taskId of this.taskRequiredPOD){
      let podOfTask = allPod.filter(pod => pod.taskId === taskId);
      if(podOfTask.length > 0){
        podOfTask.forEach(it => {
          if(!this.isConfirmPOD(it)) {
            isConfirmAll = false;
          }
        })
      }else return false;
    }
    if(isConfirmAll) return true;
    return false;
  }

  private getDataV2() {
    this.isLoading = true;
    this.podGroups = this.initPodGroups();
    const url = `v2/${Const.APIURI_ORDERS}/${this.model.id}/pod`;
    this.api.GET(url).subscribe(
      resp => {
        if (resp?.data && resp?.data.list_data) {
          let podGroups: any = [];
          let checkCarrierBillMatches = true;
          resp.data.list_data.map(item => {
            let { tasks = [], isCarrierBillMatches, jobs = [] } = item;
            let taskRequiredPOD = item.taskRequirePod.filter(taskId => tasks.find(t => t.id == taskId)?.type != Const.TaskType.PICKUP);
            this.taskRequiredPOD.push(...taskRequiredPOD);
            if (!isCarrierBillMatches) checkCarrierBillMatches = false;
            let shipmentId = Utils.uniqElementsArray(tasks.map(task => task.shipmentId));
            shipmentId.forEach(shipmentId => {
              let podArr: any = []; //sử dụng để confirm POD;
              let taskArr = tasks.filter(task => (task.shipmentId === shipmentId));
              taskArr.map(task => {
                this.preparePodUrlForTask(task);
                if (task.podArr && task.podArr.length) {
                  task.podArr.map(pod => {
                    pod.taskId = task.id;
                    pod.jobId = task.jobId,
                      podArr.push(pod);
                  });
                }
              });
              const status = item.status;
              const warpId = taskArr[0].metadata?.shipmentWarpId || taskArr[0].shipmentId;
              const jobId = taskArr[0].jobId
              podGroups.push({ warpId, jobId, podArr, status });
            })
          });
          this.podGroups = podGroups;
          this.isCarrierBillMatches = checkCarrierBillMatches;
        } else {
          this.showErr(resp.message);
        }
        Log.d('podGroups: ', this.podGroups);
        this.canGenerateInvoice = this.checkCanGenerateInvoice();
        Log.d('model: ', this.model);
        Log.d('res: ', resp.data.list_data);
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  private getData() {
    this.isLoading = true;
    this.podGroups = this.initPodGroups();

    const shipments = this.model?.metadata?.shipments || []
    //const shipmentIds = this.model?.children.length ? this.model?.children.map(child => child.warpId) || []
    for (let shipment of shipments) {
      const url = `${Const.APIURI_ORDERS_V3}/${shipment.id}/pod`;
      this.api.GET(url).subscribe(
        resp => {
          if (resp?.data?.tasks?.length) {
            resp?.data?.tasks?.forEach(task => {
              this.preparePodUrlForTask(task);
              let warpId = shipment.warpId;
              task.podArr?.map(pod => pod.taskId = task._id); //sử dụng để confirm POD
              this.addToPodGroups(warpId, task.podArr);
            });
          }
          Log.d('podGroups: ', this.podGroups);
          this.canGenerateInvoice = this.checkCanGenerateInvoice();
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
          this.showErr(err);
        }
      );
    }
  }

  viewPodItem(groupIndex, podIndex) {
    let el = document.getElementById(`pod-img-${groupIndex}-${podIndex}`);
    this.scrollToElement(el, { behavior: 'smooth' });
  }

  public attachedFileUrl(podItem: AttachedFile): string {
    //tạm thời không dùng presigned cho PDF để tránh lỗi CORS khi preview
    return AttachedFileUtil.attachedFileUrl(podItem, !this.isPdf(podItem));
  }

  private preparePodUrlForTask(task) {
    if (!Utils.isArrayNotEmpty(task.podArr)) {
      return;
    }
    for (let i = 0; i < task.podArr.length; i++) {
      let podItem = task.podArr[i];
      if (this.isPdf(podItem)) {
        let url = this.attachedFileUrl(podItem);
        podItem.loadingTask = pdfjsLib.getDocument({ url, withCredentials: true });
        podItem.loadingTask.promise.then(function (pdf) {
          pdf.getPage(1).then(function (page) {
            var desiredWidth = 80;  // css class attached-pod
            var viewport = page.getViewport({ scale: 1 });
            var scale = desiredWidth / viewport.width;
            var scaledViewport = page.getViewport({ scale: scale });
            var canvas = <HTMLCanvasElement>document.getElementById(`pod-${i}`);
            var context = canvas.getContext('2d');
            canvas.height = scaledViewport.height;
            canvas.width = scaledViewport.width;
            canvas.height = 98;
            canvas.width = 78;
            var renderContext = {
              canvasContext: context,
              viewport: scaledViewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then().catch(e => console.error('DPF render error ', e));
          });
        }, function (err) {
          console.error('PDF loading error ', err);
        });
      } else {
        podItem.imgUrl = this.attachedFileUrl(podItem);
      }
    }
  }

  formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  isConfirmPOD(item) {
    if (item.podConfirmed?.when) return true;
    return false;
  }

  onBtnConfirm = (item) => {
    let params = { "id": item._id };
    let url = `${Const.APIURI_TASKS}/${item.taskId}/confirm-pod`;
    item.onProgress = true;
    this.api.POST(url, params).subscribe(
      (resp) => {
        item.podConfirmed = resp.data?.podConfirmed;
        item.onProgress = false;
        this.canGenerateInvoice = this.checkCanGenerateInvoice();
        this.showSuccess("Your POD has been confirm successfully.");
      },
      (err) => {
        item.onProgress = false;
        this.showErr(err);
      }
    );
  };

  onBtnUnConfirm = async (item) => {
    let params = { "id": item._id };
    let url = `${Const.APIURI_TASKS}/${item.taskId}/un-confirm-pod`;
    item.onProgress = true;
    let [err] = await to(this.api.POST(url, params).toPromise());
    if (err) {
      this.showErr(err);
    } else {
      item.podConfirmed = undefined;
      this.canGenerateInvoice = this.checkCanGenerateInvoice();
    }
    item.onProgress = false;
  }

  canUnconfirmPOD(podItem): boolean {
    let isMine = () => {
      if (podItem.podConfirmed?.byUser?.id) {
        return podItem.podConfirmed?.byUser?.id == this.authUser.id;
      } else {
        return podItem.podConfirmed?.byUser?._id == this.authUser.warpId;
      }
    }
    return this.isConfirmPOD(podItem) && isMine();
  }

  public isHasIssue(podItem) {
    return podItem.hasIssue && !podItem.podConfirmed?.when;
  }

  public onNoteFetchingDone(podItem, data: NotesComponentEventFetchDataDone) {
    const before = podItem.hasIssue;
    const after = data.countTotal > 0;
    if (before != after) {
      // Âm thầm correct
      podItem.hasIssue = after;
      this.correctPodIssue(podItem);
    }
  }

  private correctPodIssue(podItem) {
    const url = Const.APIV2(`pods/${podItem._id}/correct_issue_status`);
    this.api.POST(url).subscribe();
  }

  public onNoteItemAdded(podItem, data: NotesComponentEventItemAdded) {
    if (!podItem.hasIssue) {
      podItem.hasIssue = true;
    }
  }

  public onNoteItemRemoved(podItem, data: NotesComponentEventItemRemoved) {
    if (data.countBeforeRemove <= 1 && podItem.hasIssue) {
      podItem.hasIssue = false;
    }
  }

  private correctPod(shipmentId) {
    const url = Const.APIV2(`shipments/${shipmentId}/correct_pod`);
    return this.api.POST(url).toPromise();
  }

  public onBtnCorrectPod() {
    const isMultiShipments = this.model?.shipmentIds?.length > 1;
    if (isMultiShipments) {
      const shipmentIds = this.model?.shipmentIds;
      const result = shipmentIds.map(shipmentId => {
        return this.correctPod(shipmentId);
      });

      Promise.all(result)
        .then(() => {
          this.showSuccess('Correct POD successfully.');
        })
        .catch(err => { this.showErr(err) });

    } else {
      this.correctPod(this.model?.shipmentIds[0])
        .then(() => {
          this.showSuccess('Correct POD successfully.');
        })
        .catch(err => { this.showErr(err) });
    }
  }
  gotoPodConfirmation(jobId, tab = 'need_confirm') {
    return `${Const.routeAdminPODNeedConfirm}?tab=${tab}&jobId=${jobId}&search=${jobId}`
  }
  public get genInvoiceButtonTxt(){
    if (this.model?.invoiceFileId || (this.model?.invoice && this.model?.invoice.id)) return 'Re-Generate Invoice';
    else return 'Generate Invoice'
  }
}
