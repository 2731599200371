import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopnavAdminComponent } from './topnav.component';
import { RouterModule } from '@angular/router';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { FreightQuoteExportForm } from '../freight-quote/export-form';
import { DetailModule } from '../base/detail.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzDropDownModule,
    NzButtonModule,
    NzIconModule,
    NzBadgeModule,
    DetailModule,
    NzInputModule,
    NzFormModule,
    NzDatePickerModule,
    SelectBySearchingModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzTabsModule,
  ],
  declarations: [
    TopnavAdminComponent, 
    FreightQuoteExportForm
  ],
  exports: [TopnavAdminComponent]
})
export class TopnavModule { }
