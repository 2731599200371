
import { Component } from '@angular/core';
import { BaseDetail } from '../../base/detail';
import { ActivatedRoute } from '@angular/router';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';

@Component({
  selector: '[warehouse-inventory]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class WarehouseInventory extends BaseDetail {
  protected formGroupDeclaration: FormGroupDeclaration = {
    inventories: {label: '', type: 'formArray', childItem: {
      id: {label: 'id', required: false},
      code: {label: 'Code', required: true},
      name: {label: 'Name', required: false},
      type: {label: 'Type', required: true}
    }, initialValue: [{}]},
  };

  protected beforeBindModel(model): any {
    const inventories = model?.inventories || [];
    if(!inventories.length) model.inventories = [{}];
    return model;
  }

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  public row1: FormRow = {columns: [
    {key: 'id', span: 0, offset: 0},
    {key: 'code', span: 5, offset: 0},
    {key: 'name', span: 7, offset: 0},
    {key: 'type', span: 11, offset: 0},
    {key: 'action', span: 1, offset: 0}
  ]}

  public types = Object.values(Const.InventoryTypes).map((type: any) => {
    return { value: type, label: Const.getInventoryTypeText(type) }
  })

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getApiUrl(method: ApiMethod = ApiMethod.get): string {
    return Const.APIURI_WAREHOUSES;
  }

  onBtnSave() {
    this.updateData();
  }
}
