// Cần khai báo giống như trong file src/styles/color-pallet.scss (giống về số lượng, thứ tự và giá trị)
const COLOR_PALETTE = [
  '#0f30d9', // 1
  '#d55535', // 2
  '#1fbd0d', // 3
  '#aa7433', // 4
  '#3c7a4a', // 5
  '#b0171c', // 6
  '#35ad8d', // 7
  '#ff0000', // 8
  '#f58231', // 9
  '#2e3094', // 10
  '#008080', // 11
  '#f6546a', // 12
  '#661141', // 13
  '#1E4B00', // 14
  '#fdbb1c', // 15
  '#718da5', // 16
  '#a802b8', // 17
  '#440026', // 18
  '#088da5', // 19
  '#95e6ad', // 20
  '#550000', // 21
  '#261758', // 22
  '#801515', // 23
];

const MARKER_COLOR_STOPS = {
  'PICKUP': '#7c4aed', // purple
  'DROPOFF': '#73df54' // green
}
export class RoutingColor {
  static get COLOR_PALETTE() { return COLOR_PALETTE }
  static colorMapRgb = RoutingColor.genColorMap(true);
  static colorMap = RoutingColor.genColorMap();

  static getMarkerColor(type: 'PICKUP' | 'DROPOFF') {
    return MARKER_COLOR_STOPS[type]
  }
  static getColorByNumber(number) {
    let index = RoutingColor.getColorIndex(number);
    const color = number ? COLOR_PALETTE[index] : '#ffffff';
    return color;
  }

  static invertColor(hex, bw) {
    const padZero = (str, len = 2) => {
      var zeros = new Array(len).join('0');
      return (zeros + str).slice(-len);
    };

    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    var r: number | string = parseInt(hex.slice(0, 2), 16),
      g: number | string = parseInt(hex.slice(2, 4), 16),
      b: number | string = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // http://stackoverflow.com/a/3943023/112731
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? '#000000'
        : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  };

  static hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  }

  static genColorMap(rgb = false) {
    const colorMap = {};
    for (let i = 0; i < 200; i++) {
      if (i === 0) {
        colorMap[i] = rgb ? RoutingColor.hexToRgb("#ff00ff") : "#ff00ff";
      } else {
        colorMap[i] = rgb ? RoutingColor.hexToRgb(RoutingColor.getColorByNumber(i)) : RoutingColor.getColorByNumber(i);
      }
    }
    return colorMap;
  }

  static getColorIndex(numberStr) {
    if (!numberStr) return 0;
    return (parseInt(numberStr) - 1) % COLOR_PALETTE.length;
  }

  static getColorIndexFromStr(string) {
    let idx = 0;
    for (let i = 0; i < string.length; i++) {
      idx += isNaN(string.charCodeAt(i)) ? 0 : string.charCodeAt(i);
    }
    return idx % COLOR_PALETTE.length;
  }
}


