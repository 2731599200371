<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Edit Revenue</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <ng-container *ngIf="isCreateShipmentMode_multiPickDrop">
      <div class="flex" style="flex-direction: row-reverse;">
        <div style="align-self: center; width: 250px;">
          <nz-form-item>
            <nz-form-control>
              <nz-select nzBackdrop="true"
                formControlName="type">
                <nz-option *ngFor="let item of revenueTypeArr" 
                  [nzLabel]="item.label" [nzValue]="item.value">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isPerShipmentRevenueType">
      <ng-container *ngIf="!isCreateShipmentMode_multiPickDrop">
        <div #formComp form-input-cost-v3 [form]="formInput.get('costOrder')"
          [selectLocations]="selectLocations" [createShipmentMode]="createShipmentMode"
          [serviceOptions]="serviceOptionsForCostSection">
        </div>
      </ng-container>
      <ng-container *ngIf="isCreateShipmentMode_multiPickDrop">
        <div #formComp form-input-cost-v3 [form]="formInput.get('costOrder')"
          [shipments]="shipments" [createShipmentMode]="createShipmentMode"
          [serviceOptions]="serviceOptionsForCostSection">
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isPerShipmentRevenueType">
      <div *ngFor="let key of ['costShipments']" [formArrayName]="key">
        <form *ngFor="let item of getArrayControls(key); let costIndex = index" nz-form [formGroupName]="costIndex">
          <div class="shipment-label flex">
            <div>Shipment {{ getShipmentWarpId(key, costIndex) }}:</div>
          </div>
          <div form-input-cost-v3 #costForms [form]="getFormShipmentCost(key, costIndex)"
            [selectLocations]="selectLocations"
            [createShipmentMode]="'single'"
            [labelTotal]="isCreateShipmentMode_multiPickDrop ? 'Sub Total': 'Total Revenue'"
            [serviceOptions]="serviceOptionsForCostSection"
            (grandTotalChange)="updateTotalRevenueMultiShipments()">
          </div>
        </form>
      </div>
      <div *ngIf="isCreateShipmentMode_multiPickDrop" class="flex top10 grand-total-row">
        <div class="flex1"></div>
        <div>Total Revenue: </div>
        <div class="grand-total">
          {{ totalRevenueAllShipments }}
        </div>
      </div>
    </ng-container>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>