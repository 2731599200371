<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{ txtHeader }}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div #formComp form-input-cost-v3 [form]="formInput.get('cost')" [isShowOnlyServiceOptions]="true"
      [selectLocations]="selectLocations" [createShipmentMode]="createShipmentMode"
      [serviceOptions]="serviceOptionsForCostSection">
    </div>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" [labelOK]="model ? 'Re-Generate' : 'Generate'" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>