<div class="form-header">
  <div class="form-title">
    <span [innerHTML]="formTitle"></span>
    <i *ngIf="isHistoryExisting" (click)="goToHistory()" class="clickable" nz-icon nzType="history" nzTheme="outline" nz-tooltip nzTooltipTitle="View Changes History"></i>
  </div>
  <i class="ic-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="closeDialog()"></i>
</div>

<form nz-form [formGroup]="formInput" class="form-detail">
  <div nz-row>
    <div nz-col *ngFor="let col of row1.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
      <ng-container [ngSwitch]="col.key">
        <div class="top30" *ngSwitchCase="'isHazmatLocation'">
          <nz-form-item>
            <div nz-checkbox [formControlName]="col.key">{{getLabel(col.key)}}</div>
          </nz-form-item>
        </div>
        <div *ngSwitchDefault>
          <div class="form-label-v2">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <input nz-input [formControlName]="col.key" [type]="getInputType(col.key)" [placeholder]="getPlaceHolder(col.key)">
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="shouldShowClient">
    <ng-container *ngFor="let key of ['clientId']">
      <div style="margin-top: 5px" class="form-label-v2 right20">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item>
        <select-by-searching style="width: 100%;"
          [formControlName]="key"
          [(listData)]="listClients"
          [placeholder]="getPlaceHolder(key)">
        </select-by-searching>
      </nz-form-item>
    </ng-container>
  </ng-container>

  <div class="group no-padding">
    <div class="line-group"></div>
    <div class="group-content" style="margin-top: 24px">
      <ng-container *ngFor="let key of ['addressType']">
        <nz-form-item>
          <div style="margin-top: 5px" class="form-label-v2 right20">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-control>
            <nz-radio-group [formControlName]="key">
              <label *ngFor="let item of addressType" nz-radio [nzValue]="item">{{item}}</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <ng-container *ngFor="let key of ['pickAddr']">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item>
          <nz-form-control>
            <form-address [formControlName]="key"></form-address>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <div nz-row>
        <div nz-col *ngFor="let key of ['serviceOptions']" [nzSpan]="12">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item class="bottom0">
            <nz-form-control>
              <nz-select nzBackdrop="true"  nzMode="multiple" style="width: 100%;" nzShowSearch nzAllowClear
                [nzPlaceHolder]="getPlaceHolder(key)" [nzMaxTagCount]="0" [nzMaxTagPlaceholder]="tagPlaceHolder"
                [formControlName]="key">
                <nz-option-group *ngFor="let group of serviceOptions" [nzLabel]="group.label">
                  <nz-option *ngFor="let item of group.items"
                    [nzValue]="item._id" [nzLabel]="item.name"
                    [nzDisabled]="isDisableServiceOptionItem(item)">
                  </nz-option>
                </nz-option-group>
                <!-- <nz-option *ngFor="let option of serviceOptions" [nzLabel]="option" [nzValue]="option"></nz-option> -->
              </nz-select>
              <ng-template #tagPlaceHolder>{{serviceOptionsSelectDesc}}</ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col *ngFor="let key of ['accessCode']" [nzSpan]="11" [nzOffset]="1">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item class="bottom0">
            <nz-form-control>
              <input nz-input [formControlName]="key" [type]="getInputType(key)" [placeholder]="getPlaceHolder(key)" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="top10 bottom20">
        <nz-tag *ngFor="let item of formInput.get('serviceOptions').value; let i = index"
          nzMode="closeable" nzColor="processing" (nzOnClose)="removeServiceOption(i)">
          {{getServiceOptionName(item)}}
        </nz-tag>
      </div>
      <nz-form-item>
        <div nz-checkbox [formControlName]="'requireAppointment'">{{getLabel('requireAppointment')}}</div>
      </nz-form-item>
    </div>
  </div>
  <div class="group no-padding">
    <div class="line-group top25"></div>
    <div class="group-content" style="margin-top: 24px; padding: 16px; border: 1px solid #D9D9D9;border-radius: 8px;">
      <div class="flex f16 bottom20">
        <div style="width: 150px;">Operating Hours</div>
        <div *ngIf="timezone" class="timezone">{{timezone}}</div>
      </div>
      <ng-container *ngFor="let key of ['operatingHours']" [formArrayName]="key">
        <form *ngFor="let control of getArrayControls(key); let i = index" nz-form [formGroupName]="i" [ngClass]="key">
          <div class="week-day-name">{{dayLables[i]}}</div>
          <ng-container *ngFor="let childKey of ['isOpen', 'fromTime', 'toTime']">
            <ng-container [ngSwitch]="childKey">
              <div *ngSwitchCase="'isOpen'" [ngClass]="childKey">
                <nz-switch [formControlName]="childKey"></nz-switch>
                <div class="text-open">{{getTextDayOpen(i)}}</div>
              </div>
              <nz-time-picker [nzMinuteStep]="15" *ngSwitchDefault nzSize="small"
                [ngClass]="childKey" [formControlName]="childKey"
                nzFormat="HH:mm" [nzPlaceHolder]="getPlaceHolder(fullKey(key, childKey))">
              </nz-time-picker>
            </ng-container>
          </ng-container>
        </form>

      </ng-container>
    </div>
  </div>
  <div class="group no-padding">
    <div class="bold" style="margin-top: 24px; margin-bottom: 16px">Contact Information</div>
    <div class="group-content">
      <div nz-row>
        <div nz-col *ngFor="let col of row5.columns; let i = index" [nzSpan]="col.span" [nzOffset]="col.offset">
          <div *ngIf="isMultiLines(col.key)">
            <div class="form-label-v2">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
            <nz-form-item class="bottom0">
              <nz-form-control>
                <textarea nz-input [formControlName]="col.key"
                  [placeholder]="getPlaceHolder(col.key)"
                  [nzAutosize]="{ minRows: 2, maxRows: 6 }">
                </textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngIf="!isMultiLines(col.key)">
            <div class="form-label-v2">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
            <nz-form-item>
              <nz-form-control>
                <input nz-input [formControlName]="col.key"
                  [type]="getInputType(col.key)" [placeholder]="getPlaceHolder(col.key)"
                  (input)="onInputChanged($event, col.key)"
                  (keypress)="onInputKeyPress($event, col.key)"/>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div form-footer [onProgress]="onProgress"
  [canClickOK]="needUpdate && !onProgress"
  [canClickCancel]="!onProgress"
  (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"></div>
