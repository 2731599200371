import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { DialogService } from "@dialogs/dialog.service";
import { Log } from "@services/log";
import { ReturnLocation } from "./return-location";
import { Observable } from "rxjs";
import { ulid } from "ulid";

@Component({
  selector: '[init-return-leg]',
  templateUrl: './init-return-leg.html',
  styleUrls: ['init-return-leg.scss']
})
// 20230705 tạm thời xử lý 1 điểm pickup, có thời gian sẽ update trường hợp nhiều pickup khác nhau
export class InitReturnLeg extends BaseDialog {
  
  @Input() from: any = null;
  @Input() parent: any = null;
  @Input() onSave: (data) => Observable<any>;
  @Input() onSubmitSucceeded: (resp) => void;
  public originalPickInfo;
  public pickupInfo;
  public returnInfo;
  public displayInfo: any = {};
  public onProgress = false;
  destinationType: 'pick'|'other' = 'pick';
  returnReasonCode: string = '';
  returnReasonMessage: string = '';
  public allReasonReturn = Const.returnReason
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.buildDisplayInfo();
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.onDestinationTypeChange(this.destinationType), 1)
  }

  buildDisplayInfo(){
    const { contacts, addr, warehouseId, locationName, instructions, note } = this.from;
    const primaryContact = (contacts || []).filter(it => it?.type === 'primary')[0] || {fullName: null, phone: null, phoneExtension: null, email: null}
    const secondaryContacts = (contacts || []).filter(it => it?.type === 'secondary')
    this.pickupInfo = {
      addr,
      type: 'PICKUP',
      instructions,
      warehouseId,
      locationName,
      note,
      primaryContact,
      secondaryContacts,
    }
    const { deliveryInfos } = this.parent || {}
    this.originalPickInfo = (deliveryInfos || []).filter(it => it.type === 'PICKUP')[0];
    this.displayInfo = {
      originalPickLocationName: this.originalPickInfo?.locationName || '',
      originalPickAddr: this.getAddressText(this.originalPickInfo?.addr),
    }
  }

  private updateReturnInfo(newInfo){
    this.returnInfo = newInfo;
    this.displayInfo.returnAddr = this.getAddressText(newInfo?.addr);
  }

  onDestinationTypeChange(value) {
    switch (value) {
      case 'pick':
        break;
      case 'other':
        if (!this.returnInfo) {
          this.openLocationForm();
        }
        break;
    }
  }

  editReturnInfo() {
    this.openLocationForm();
  }

  private openLocationForm() {
    DialogService.openFormDialog1(ReturnLocation, {
      nzComponentParams: {
        model: this.returnInfo,
        onOK: data => {
          this.updateReturnInfo(data)
        },
        onCancel: () => {
          if (!this.returnInfo) {
            this.destinationType = 'pick';
          }
        }
      },
      nzClassName: 'modal-dispatch-return-location',
    });
  }

  // isRequireReasonRemark(){
  //   if(this.returnReasonCode === "Other Issue"){
  //     return true;
  //   }
  //   return false;
  // }

  public get needUpdate(): boolean {
    // if (!this.returnReasonCode) return false;
    // // trường hợp là other thì phải yêu cầu nhập lý do (returnReasonMessage)
    // if(this.isRequireReasonRemark() && !this.returnReasonMessage){
    //   return false;
    // }
    return true;
  }

  public onBtnSave() {
    const deliveryInfos = [];
    deliveryInfos.push({...this.pickupInfo, id: ulid(), windows: [], requiresAppointment: true, appointmentInfo: {}});
    if (this.destinationType == 'pick') {
      const newDropInfo = {
        ...this.originalPickInfo, 
        type: Const.TaskType.DROPOFF, 
        id: ulid(),
        windows: [],
        requiresAppointment: true,
        appointmentInfo: {}
      }
      deliveryInfos.push(newDropInfo);
    } else {
      deliveryInfos.push({...this.returnInfo, type: Const.TaskType.DROPOFF, id: ulid()});
    }
    const returnShipment = {
      deliveryInfos,
      parentId: this.parent.id,
      id: ulid(),
      clientId: this.parent.clientId,
      shipmentType: this.parent.shipmentType,
      isCrossDock: true,
      shipmentModeId: this.parent.shipmentModeId,
      equipmentId: this.parent.equipmentId,
      tempRange: this.parent.tempRange,
      shipmentTransitType: Const.ShipmentTransitType.leg,
      itemIds: this.parent.itemIds,
      name: '...'
    }
    const data = {
      shipment: returnShipment,
      // returnReason: {
      //   code: this.returnReasonCode,
      //   note: this.returnReasonMessage,
      // }
    }
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onSubmitSucceeded(resp);
      }, err => {
        this.closeDialog();
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }
  
}
