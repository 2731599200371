
import { Component, Input } from '@angular/core';
import { Const } from '@const/Const';
import { Const as UniversalConst } from '@wearewarp/universal-libs';
import { BaseComponent } from '@abstract/BaseComponent';
import _ from 'underscore';
import { DialogService } from '@dialogs/dialog.service';
import { PlanningRouteDialog } from '../../planning/dialog';
import { BizUtil } from '@services/biz';
import Utils from './utils';
import { DateUtil } from '@services/date-utils';
import { Utils as ServiceUtils } from "@services/utils";
import { merge } from 'rxjs/operators';
import { ModalHelper, UpdateTimeWindows } from '@wearewarp/ng-antd';
import { UIHelper } from '@services/UIHelper';
import { MasterData } from '@services/master.data';

@Component({
    selector: '[warehouse-items]',
    templateUrl: './items.html',
    styleUrls: ['./items.scss']
})
export class WarehouseItems extends BaseComponent {
    @Input() id: string
    private _raw: any[]
    public data: any[] // raw data
    indeterminate = false;
    checkedAll = false;
    setOfCheckedId = new Set<string>();

    filteredStatus: any[] = []
    filteredClient: any[] = []
    filteredDelivery: any[] = []
    totalPallet: number = 0
    selectedPalletCount: number = 0

    canDo: any = {}

    statusFilters = [
        { text: 'PENDING', value: 'PENDING' },
        { text: 'READY FOR PICKUP', value: 'READY_FOR_PICKUP' },
        { text: 'SCHEDULED TO PICKUP', value: 'SCHEDULED_TO_PICKUP' },
        { text: 'PICKED UP', value: 'PICKED_UP' },
        { text: 'ARRIVED AT WARP', value: 'ARRIVED_AT_WARP' },
        { text: 'READY FOR OUTBOUND', value: 'READY_FOR_OUTBOUND' },
        { text: 'DELIVERED', value: 'EN_ROUTE_TO_DELIVERY' },
        { text: 'DELIVERED', value: 'DELIVERED' },
    ]

    clientFilters = []
    deliveryFilters = []
    searchKey: string = null

    constructor(private modalHelper: ModalHelper) {
        super()
        this.filterByStatus = this.filterByStatus.bind(this)
        this.filterByClient = this.filterByClient.bind(this)
        this.filterByDelivery = this.filterByDelivery.bind(this)
        this.filterBySearchKey = this.filterBySearchKey.bind(this)
        this.onRouteCreated = this.onRouteCreated.bind(this)
        this.matchKey = this.matchKey.bind(this)
    }

    filterFnStatus(list: string[]) {
        this.filteredStatus = list
        this.postProcessData()
    }
    filterFnClient(list: string[]) {
        this.filteredClient = list
        this.postProcessData()
    }
    filterFnDelivery(list: string[]) {
        this.filteredDelivery = list
        this.postProcessData()
    }

    filterByStatus(item) {
        if (!this.filteredStatus || !this.filteredStatus.length) {
            return true
        } else {
            return this.filteredStatus.indexOf(item.status) >= 0
        }
    }
    matchKey(item, key) {
        if (_.some(item.POs, a => a.indexOf(key) >= 0)) return true
        if (_.some(item.ids, a => a.indexOf(key) >= 0)) return true
    }
    filterBySearchKey(item) {
        if (!this.searchKey || !this.searchKey.trim().length) return true
        const keys = this.searchKey.split(' ').map(it => it.trim()).filter(it => it && it.length)
        return _.every(keys, key => this.matchKey(item, key))
    }

    onSearchKeyChange(event) {
        setTimeout(() => {
            if (event === this.searchKey)
                this.postProcessData()
        }, 200)
    }

    clearSearch() {
        this.searchKey = null
        this.postProcessData()
    }

    filterByClient(item) {
        if (!this.filteredClient || !this.filteredClient.length) {
            return true
        } else {
            return this.filteredClient.indexOf(item.mainShipment?.clientId) >= 0
        }
    }
    filterByDelivery(item) {
        if (!this.filteredDelivery || !this.filteredDelivery.length) {
            return true
        } else {
            return this.filteredDelivery.indexOf(this.getDeliveryLocation(item)) >= 0
        }
    }

    getDeliveryLocation(item) {
        if (!item.outboundShipment || !item.outboundShipment.length) return null
        const dropoff = Utils.getDeliveryInfo(item.outboundShipment[0], 'DROPOFF') 
        const { addr } = dropoff
        const { state, zipcode, city } = addr || {}
        return `${city}, ${state} ${zipcode}`
    }

    filterData() {
        this.data = this._raw.filter(this.filterByStatus).filter(this.filterByClient).filter(this.filterByDelivery).filter(this.filterBySearchKey)
    }


    expandSet = new Set<number>();
    onExpandChange(row): void {
        if (!this.expandSet.has(row.id)) {
            for (let x of this.data) {
                if (this.expandSet.has(x.id)) {
                    x.expanded = false
                }
            }
            this.expandSet.clear()
            row.expanded = true
            this.expandSet.add(row.id);
        } else {
            row.expanded = false
            this.expandSet.delete(row.id);
        }
    }

    public unScheduled: any[] = []
    public scheduled: any[] = []

    ngOnChanges(): void {
        this.loadData()
    }

    onRefresh() {
        this.loadData()
    }

    determineStatus(row) {
        const outbound = (row.outboundShipment || [])[0]
        const inbound = (row.inboundShipment || [])[0]
        const warehouseTasks = row.warehouseTasks || []

        if (outbound?.status === UniversalConst.ShipmentStatus.complete
            || outbound?.status === UniversalConst.ShipmentStatus.returned) {
            row.status = 'DELIVERED'
            row.index = 100
            return
        }
        if (
            outbound?.status === UniversalConst.ShipmentStatus.arrivedAtDropoff
            || outbound?.status === UniversalConst.ShipmentStatus.inRouteToDropoff
            || outbound?.status === UniversalConst.ShipmentStatus.pickupSuccessful
        ) {
            row.status = 'EN_ROUTE_TO_DELIVERY'
            row.index = 90
            return
        }
        
        const tasksPending = warehouseTasks.filter(it => (it.status === UniversalConst.WarehouseTaskStatus.pending) || !it.status);
        if (!tasksPending.length) {
            row.status = 'READY_FOR_OUTBOUND'
            row.index = 80
            return
        }

        if (inbound?.status === UniversalConst.ShipmentStatus.complete) {
            row.status = 'ARRIVED_AT_WARP'
            row.index = 70
            return
        }

        if (
            inbound?.status === UniversalConst.ShipmentStatus.pickupSuccessful
            || inbound?.status === UniversalConst.ShipmentStatus.inRouteToDropoff
            || inbound?.status === UniversalConst.ShipmentStatus.arrivedAtDropoff
        ) {
            row.status = 'PICKED_UP'
            row.index = 50
            return
        }

        if (inbound?.lastJobId) {
            row.status = 'SCHEDULED_TO_PICKUP'
            row.index = 20
            return
        }
        if (row.scheduledPickup) {
            row.status = 'READY_FOR_PICKUP'
            row.index = 10
            return
        }
        row.status = 'PENDING'
        row.index = 0
    }

    processData(data) {
        const { items, jobs, thirdPartyInfos } = data
        const jobMap = {}
        for (let job of jobs) {
            jobMap[job.id] = job
        }
        let thirdPartyInfoMap = {}
        for (let thirdPartyInfo of thirdPartyInfos) {
            thirdPartyInfoMap[thirdPartyInfo.orderId] = thirdPartyInfo;
        }
        for (let row of items) {
            if (row.outboundShipment && row.outboundShipment[0]?.lastJobId) {
                row.outboundJobId = row.outboundShipment[0]?.lastJobId
                row.outboundJob = jobMap[row.outboundJobId]
            }
            if (row.inboundShipment && row.inboundShipment[0]?.lastJobId) {
                row.inboundJobId = row.inboundShipment[0]?.lastJobId
                row.inboundJob = jobMap[row.inboundJobId]
            }
            if (row.outboundShipment && row.outboundShipment.length) {
                row.deliveredDate = Utils.getStatusTimeLine(row.inboundShipment[0], 'complete')
                row.scheduledRelease = Utils.getShipmentDeliveryInfoTime(row.outboundShipment[0], 'PICKUP')
            }
            if (row.inboundShipment && row.inboundShipment.length) {
                row.injectedDate = Utils.getStatusTimeLine(row.inboundShipment[0], 'complete')
                row.scheduledPickup = Utils.getShipmentDeliveryInfoTime(row.inboundShipment[0], 'PICKUP')
            }

            const POs = (Utils.getDeliveryInfo(row.mainShipment, 'PICKUP')?.refNums || []).concat(Utils.getDeliveryInfo(row.mainShipment, 'DROPOFF')?.refNums || [])
            row.POs = _.uniq(POs)

            const ids: string[] = (row.inboundShipment || []).map(it => it.warpId.toString()).concat(
                (row.outboundShipment || []).map(it => it.warpId.toString())
            )
            if (row.mainShipment) {
                ids.push(row.mainShipment.warpId.toString())
            }
            if (row.order) {
                ids.push(row.order.warpId.toString())
            }
            row.ids = ids

            this.determineStatus(row)
            row.item = (row.item || []).filter(it => it.quantityItemIds?.length);

            row.id = `${row.item?.id || '0'}-${(row.inboundShipment || [])[0]?.id || '0'}-${(row.outboundShipment || [])[0]?.id || '0'}`

            row.palletCount = _.reduce((row.item || []).map(it => Utils.countItems(it, 'Pallet')), (a, b) => a + b, 0);
            row.estimatedCartonCount = this.getEstimatedCartonCount(thirdPartyInfoMap[row?.order?.id]);
        }

        return items
    }


    getEstimatedCartonCount(thirdPartyInfo) {
        if (!thirdPartyInfo) return;
        // console.log(thirdPartyInfo, _.get(thirdPartyInfo, ["info", "message", "transactionSets", 0, "totalWeightAndCharges", 0, "ladingQuantity"]))
        return _.get(thirdPartyInfo, ["info", "message", "transactionSets", <any>0, "totalWeightAndCharges", <any>0, "ladingQuantity"])
    }


    processFacet() {
        // NOT WORKING
        // count by status
        const countByStatus = {}
        const countByClient = {}
        const countByDelivery = {}
        const clients = {}
        let locations: string[] = []
        for (let x of this._raw) {
            if (x.mainShipment?.metadata?.client) {
                clients[x.mainShipment.metadata.client.id] = x.mainShipment.metadata.client.name
            }
            const l = this.getDeliveryLocation(x)
            if (l) locations.push(l)
        }
        locations = _.uniq(locations)
        for (let x of this._raw) {
            if (this.filterByClient(x) && this.filterByDelivery(x)) {
                const c = countByStatus[x.status] || 0
                countByStatus[x.status] = c + 1
            }

            if (this.filterByStatus(x) && this.filterByDelivery(x)) {
                const c2 = countByClient[x.mainShipment?.clientId] || 0
                countByClient[x.mainShipment?.clientId] = c2 + 1
            }

            if (this.filterByStatus(x) && this.filterByClient(x)) {
                const l = this.getDeliveryLocation(x)
                const c = countByDelivery[l] || 0
                countByDelivery[l] = c + 1
            }
        }

        for (let x of this.statusFilters) {
            const name = x.value.split('_').join(' ') + '[' + (countByStatus[x.value] || 0) + ']'
            x.text = name
        }
        this.statusFilters = this.statusFilters.map(x => {
            const name = x.value.split('_').join(' ') + ' [' + (countByStatus[x.value] || 0) + ']'
            return Object.assign({}, x, {text: name, byDefault: this.filteredStatus.indexOf(x.value) >= 0})
        })

        const allClients = _.keys(clients).map(x => Object.assign({
            value: x,
            text: clients[x] + ' [' + (countByClient[x] || 0) + ']',
            byDefault: this.filteredClient.indexOf(x) >= 0
        }))
        this.clientFilters = _.sortBy(allClients, 'text')
        this.deliveryFilters = _.sortBy(locations).map(l => Object.assign({
            value: l,
            text: l + ' [' + (countByDelivery[l] || 0) + ']',
            byDefault: this.filteredDelivery.indexOf(l) >= 0
        }))
    }

    postProcessData() {
        this.data = this._raw
        this.filterData()
        for (let row of this.data) {
            let rowItems = []
            if (row.item) {
                for (let x of row.item) {
                    if (x.internalBarcode) {
                        rowItems.push(x)
                    }
                    else if (x.children && x.children.length) {
                        rowItems = rowItems.concat(x.children)
                    } else {
                        rowItems.push(x)
                    }
                }
            }
            row.shipmentItems = rowItems
        }

        const getSortKey = (item) => {
            return `${item.index < 100 ? '0' : ''}${item.index}-${item.outboundJobId || '00'}-${item.injectedDate || '00'}-${item.inboundJobId || '00'}`
        }
        this.data = _.sortBy(this.data.filter(it => it.inboundShipment?.length || it.outboundShipment?.length), getSortKey)

        this.totalPallet = _.reduce(this.data.map(it => it.palletCount || 0), (a,b) => a+b, 0)
        this.selectedPalletCount = _.reduce(this.data.filter(it => this.setOfCheckedId.has(it.id)).map(it => it.palletCount || 0), (a,b) => a+b, 0)
        this.processFacet()

        this.processCando()
    }

    public loading: boolean = false
    protected loadData() {
        const url = Const.APIV2(`warehouse-inventory/${this.id}/outbound`);
        this.loading = true
        this.api.GET(url).subscribe(res => {
            this.loading = false
            this._raw = this.processData(res)
            this.postProcessData()
        }, err => {
            this.loading = false
            this.showErr(`Error while loading data!!!`)
        })
    }

    onAllChecked(event) {
        if (!event) {
            this.setOfCheckedId.clear()
        } else {
            for (let x of this.data) {
                this.setOfCheckedId.add(x.id)
            }
        }
        this.selectedPalletCount = _.reduce(this.data.filter(it => this.setOfCheckedId.has(it.id)).map(it => it.palletCount || 0), (a,b) => a+b, 0)

    }

    onItemChecked(id) {
        if (this.setOfCheckedId.has(id)) {
            this.setOfCheckedId.delete(id)
        } else {
            this.setOfCheckedId.add(id)
        }

        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))
        this.selectedPalletCount = _.reduce(selected.map(it => it.palletCount || 0), (a,b) => a+b, 0)

        // check action enabled
        this.processCando()
    }

    processCando() {
        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))
        this.canDo = {}
        if (selected.length) {
            if (_.every(selected, it => it.inboundShipment && !it.inboundJobId)) {
                this.canDo.bulkUpdateScheduledPickup = true
                this.canDo.bulkUpdateScheduledInject = true
            }
            if (_.every(selected, it => it.outboundShipment?.length && !it.outboundJobId)) {
                this.canDo.bulkUpdateScheduledRelease = true
                this.canDo.bulkUpdateScheduledDeliver = true

                // same pickup and dropoff
            }

            this.canDo.canRouteInbound = _.every(selected, it => it.scheduledPickup && !it.inboundJobId)
            this.canDo.canRouteOutbound = _.every(selected, it => it.scheduledRelease && !it.outboundJobId)
        }
    }

    getStatus(item) {
        let inboundStatus: string = null
        let outboundStatus: string = null
        const location = BizUtil.getDropInfo(item.inboundShipment[0])
        const delivery = item.outboundShipment && item.outboundShipment.length ? BizUtil.getDropInfo(item.outboundShipment[0]) : null
        if (item.outboundShipment && item.outboundShipment.length) {
            outboundStatus = item.outboundShipment[0].status
        }
        if (item.inboundShipment && item.inboundShipment.length) {
            inboundStatus = item.inboundShipment[0].status
        }

        if (outboundStatus === 'complete') return `Arrived At ${delivery.locationName}`
        if (outboundStatus === UniversalConst.ShipmentStatus.inRouteToDropoff || outboundStatus === UniversalConst.ShipmentStatus.pickupSuccessful || outboundStatus === UniversalConst.ShipmentStatus.arrivedAtDropoff)
            return `En-route to ${Utils.getLocationName(delivery)}`
        if (inboundStatus === UniversalConst.ShipmentStatus.complete || inboundStatus === UniversalConst.ShipmentStatus.arrivedAtDropoff) {
            return `Arrived At ${location.locationName}`
        }
        if (inboundStatus === UniversalConst.ShipmentStatus.inRouteToDropoff || inboundStatus === UniversalConst.ShipmentStatus.pickupSuccessful) {
            return `En-Route To ${location.locationName}`
        }
        return `Pending`
    }

    getMileage(mileage) {
        if (!mileage || isNaN(mileage)) return ''; 
        let mil = mileage / 1609.34;
        return mil.toFixed(2) + ' mi';
    }

    onBtnDownloadCsv() {
        let selectedItems = this.data.filter(it => this.setOfCheckedId.has(it.id))
        const headers = ['Created Date', 'Client Name', 'Warp ID', 'PO#', 'Miles', 'Status', 'Pickup Date', 'Pickup City', 'Pickup Zipcode', 'Delivery Location', 'Estimated Carton Count', 'Total Weight of Order', 'Pallets']
        let lines: any = selectedItems.map(it => [
            DateUtil.formatDate(it.order?.insert?.when, 'M/DD/YYYY'),
            it.mainShipment?.metadata?.client?.name || '',
            it.order?.warpId,
            (BizUtil.getPickInfo(it.mainShipment).refNums || []).join(' ') + (BizUtil.getDropInfo(it.mainShipment).refNums || []).join(' '),
            this.getMileage(it.inboundShipment[0]?.metadata?.traffic?.distance),
            it.status, // status
            DateUtil.formatDate((BizUtil.getPickInfo(it.inboundShipment[0]).windows || [])[0]?.from, 'M/DD/YYYY'), // Pickup date,
            BizUtil.getPickInfo(it.inboundShipment[0]).addr.city, // Pickup City
            BizUtil.getPickInfo(it.inboundShipment[0]).addr.zipcode, // Pickup Zipcode
            BizUtil.getDropInfo(it.outboundShipment && it.outboundShipment.length ? it.outboundShipment[0] : it.mainShipment).locationName || Utils.getLocationName(BizUtil.getPickInfo(it.mainShipment)), // Delivery Location,
            it.estimatedCartonCount,
            _.reduce((it.item || []).map(Utils.countWeight), (a, b) => a + b, 0), // Total Weight of Order,
            _.reduce((it.item || []).map(it => it.qty), (a, b) => a + b, 0), // Pallets
        ]);
        lines.push([])
        lines.push([
            '', '', '', '', '', '', '', '', '', 'Totals:',
            `=SUM(K2:K${selectedItems.length + 1})`,
            `=SUM(L2:L${selectedItems.length + 1})`,
            `=SUM(M2:M${selectedItems.length + 1})`,
        ])

        lines = lines.map(line => line.map(it => it?.toString() || '').map(it => it.indexOf(",") >= 0 ? ('"' + it + '"') : it).join(','))
        const data = headers.join(',') + '\n' + lines.join('\n')
        this.downloadFile(data, `xdock-${Date.now()}.csv`)
    }


    downloadFile(data, filename = 'data') {
        let blob = new Blob(['\ufeff' + data], {
            type: 'text/csv;charset=utf-8;'
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1
        navigator.userAgent.indexOf('Chrome') == -1;

        if (isSafariBrowser) {
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    onBtnManualRouteShipment() {
        if (!this.canDo.canRouteOutbound) return
        let selectedItems = this.data.filter(it => this.setOfCheckedId.has(it.id))
            .filter(it => it.outboundShipment?.length)
            .filter(it => !it.outboundJobId)
        if (!selectedItems.length) {
            this.showErr(`All shipments have been routed!!!`)
            return
        }
    
        let outboundShipments = selectedItems.map(it => it.outboundShipment[0])
        // if ((data.shipmentIds || []).length === 1) {
        //   data = data?.metadata?.shipments[0];
        // }
        DialogService.openDialog(PlanningRouteDialog, {
            nzComponentParams: {
                shipments: outboundShipments,
                onRouteCreated: this.onRouteCreated
            },
            nzClassName: 'modal-no-padding',
            nzCentered: true,
        })
    }

    onRouteCreated(event) {
        this.loadData()
    }

    onBtnManualRouteInbound() {
        if (!this.canDo.canRouteInbound) return
        let selectedItems = this.data.filter(it => this.setOfCheckedId.has(it.id))
            .filter(it => it.inboundShipment?.length)
            .filter(it => !it.inboundJobId)
        if (!selectedItems.length) {
            this.showErr(`All shipments have been routed!!!`)
            return
        }

        let outboundShipments = selectedItems.map(it => it.inboundShipment[0])
        // if ((data.shipmentIds || []).length === 1) {
        //   data = data?.metadata?.shipments[0];
        // }
        DialogService.openDialog(PlanningRouteDialog, {
            nzComponentParams: {
                shipments: outboundShipments,
                onRouteCreated: this.onRouteCreated
            },
            nzClassName: 'modal-no-padding',
            nzCentered: true,
        })
    }

    onBtnCopyInboundIds() {
        let selectedItems = this.data.filter(it => this.setOfCheckedId.has(it.id))
        const ids = selectedItems.map(it => (it.inboundShipment || [])[0]?.warpId).filter(it => it)
        ServiceUtils.copyTextToClipboard(ids.map(it => it.toString()).join(", "), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `${ids.length} Inbound Leg ids have been copied to the clipboard`
                );
            }
        });
    }

    onBtnCopyOutboundIds() {
        let selectedItems = this.data.filter(it => this.setOfCheckedId.has(it.id))
        const ids = selectedItems.map(it => (it.outboundShipment || [])[0]?.warpId).filter(it => it)
        ServiceUtils.copyTextToClipboard(ids.map(it => it.toString()).join(", "), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `${ids.length} Outbound Leg ids have been copied to the clipboard`
                );
            }
        });
    }

    public loadingBol = false;
    onBtnDownloadBols() {
        let selectedItems = this.data.filter(it => this.setOfCheckedId.has(it.id))
        let warehouseJobIds = selectedItems.map(it => it.warehouseJobId);
        this.loadingBol = true;
        this.api.POST(Const.APIURI_WAREHOUSE_DOWNLOAD_BOL, { warehouseJobIds }).subscribe(res => {
            this.downloadAttachedFile(res.data);
            this.loadingBol = false
        }, err => {
            this.loadingBol = false
            this.showErr(err)
        })
    }

    onBtnCopyMainIds() {
        let selectedItems = this.data.filter(it => this.setOfCheckedId.has(it.id))
        const ids = selectedItems.map(it => it.mainShipment?.warpId).filter(it => it)
        ServiceUtils.copyTextToClipboard(ids.map(it => it.toString()).join(", "), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `${ids.length} Main Shipment ids have been copied to the clipboard`
                );
            }
        });
    }

    onBtnCopyOrderIds() {
        let selectedItems = this.data.filter(it => this.setOfCheckedId.has(it.id))
        const ids = selectedItems.map(it => it.order?.warpId).filter(it => it)
        ServiceUtils.copyTextToClipboard(ids.map(it => it.toString()).join(", "), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `${ids.length} Order ids have been copied to the clipboard`
                );
            }
        });
    }

    onEditScheduledPickup() {
        if (!this.canDo.bulkUpdateScheduledPickup) return

        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))
        const info = BizUtil.getPickInfo(selected[0].inboundShipment[0])
        let windows = []
        for (let shipment of selected) {
            const i = BizUtil.getPickInfo(shipment.inboundShipment[0])
            if (i.windows?.length) {
                windows = i.windows
            }
        }

        UpdateTimeWindows.openModal(this.modalHelper, {
          nzTitle: `Update Pickup Time windows for all selected inbound legs`,
          onSubmitError: err => UIHelper.showErr(err),
          onSubmitSucceeded: data => this.onRefresh(),
          nzComponentParams: {
            timezone: info.addr?.metadata?.timeZoneStandard,
            model: { windows },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.onSaveScheduledPickupTime(data),
          }
        });
    }

    onSaveScheduledPickupTime(data) {
        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))

        const updateOne = (row) => {
            const shipment = row.inboundShipment[0]
            const info = BizUtil.getPickInfo(shipment)
            const url = Const.APIV2(`shipments/${shipment.id}/delivery-info/${info.id}`)
            info.windows = data.windows
            return this.api.PUT(url, data)            
        }
        let ret = updateOne(selected[0])
        for (let i = 1; i < selected.length; i++) {
            ret = ret.pipe(merge(updateOne(selected[i])))
        }
        return ret
    }

    onEditReleasePickup() {
        if (!this.canDo.bulkUpdateScheduledRelease) return

        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))
        const info = BizUtil.getPickInfo(selected[0].outboundShipment[0])
        let windows = []
        for (let shipment of selected) {
            const i = BizUtil.getPickInfo(shipment.outboundShipment[0])
            if (i.windows?.length) {
                windows = i.windows
            }
        }

        UpdateTimeWindows.openModal(this.modalHelper, {
          nzTitle: `Update Pickup Time windows for all selected Outbound legs`,
          onSubmitError: err => UIHelper.showErr(err),
          onSubmitSucceeded: data => this.onRefresh(),
          nzComponentParams: {
            timezone: info.addr?.metadata?.timeZoneStandard,
            model: { windows },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.onSaveScheduledReleaseTime(data),
          }
        });
    }

    onSaveScheduledReleaseTime(data) {
        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))

        const updateOne = (row) => {
            const shipment = row.outboundShipment[0]
            const info = BizUtil.getPickInfo(shipment)
            const url = Const.APIV2(`shipments/${shipment.id}/delivery-info/${info.id}`)
            info.windows = data.windows
            return this.api.PUT(url, data)            
        }
        let ret = updateOne(selected[0])
        for (let i = 1; i < selected.length; i++) {
            ret = ret.pipe(merge(updateOne(selected[i])))
        }
        return ret
    }

    onEditScheduledInject() {
        if (!this.canDo.bulkUpdateScheduledInject) return

        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))
        const info = BizUtil.getDropInfo(selected[0].inboundShipment[0])
        let windows = []
        for (let shipment of selected) {
            const i = BizUtil.getDropInfo(shipment.inboundShipment[0])
            if (i.windows?.length) {
                windows = i.windows
            }
        }

        UpdateTimeWindows.openModal(this.modalHelper, {
          nzTitle: `Update Dropoff Time windows for all selected inbound legs`,
          onSubmitError: err => UIHelper.showErr(err),
          onSubmitSucceeded: data => this.onRefresh(),
          nzComponentParams: {
            timezone: info.addr?.metadata?.timeZoneStandard,
            model: { windows },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.onSaveScheduledInjectTime(data),
          }
        });
    }

    onSaveScheduledInjectTime(data) {
        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))

        const updateOne = (row) => {
            const shipment = row.inboundShipment[0]
            const info = BizUtil.getDropInfo(shipment)
            const url = Const.APIV2(`shipments/${shipment.id}/delivery-info/${info.id}`)
            info.windows = data.windows
            return this.api.PUT(url, data)            
        }
        let ret = updateOne(selected[0])
        for (let i = 1; i < selected.length; i++) {
            ret = ret.pipe(merge(updateOne(selected[i])))
        }
        return ret
    }

    onEditScheduledDeliver() {
        if (!this.canDo.bulkUpdateScheduledDeliver) return

        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))
        const info = BizUtil.getDropInfo(selected[0].outboundShipment[0])
        let windows = []
        for (let shipment of selected) {
            const i = BizUtil.getDropInfo(shipment.outboundShipment[0])
            if (i.windows?.length) {
                windows = i.windows
            }
        }

        UpdateTimeWindows.openModal(this.modalHelper, {
          nzTitle: `Update Dropoff Time windows for all selected Outbound legs`,
          onSubmitError: err => UIHelper.showErr(err),
          onSubmitSucceeded: data => this.onRefresh(),
          nzComponentParams: {
            timezone: info.addr?.metadata?.timeZoneStandard,
            model: { windows },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.onSaveScheduledDeliverTime(data),
          }
        });
    }

    onSaveScheduledDeliverTime(data) {
        const selected = this.data.filter(it => this.setOfCheckedId.has(it.id))

        const updateOne = (row) => {
            const shipment = row.outboundShipment[0]
            const info = BizUtil.getDropInfo(shipment)
            const url = Const.APIV2(`shipments/${shipment.id}/delivery-info/${info.id}`)
            info.windows = data.windows
            return this.api.PUT(url, data)            
        }
        let ret = updateOne(selected[0])
        for (let i = 1; i < selected.length; i++) {
            ret = ret.pipe(merge(updateOne(selected[i])))
        }
        return ret
    }

    getTooltipActions(key, condition) {
        const messages = {
            createOutboundRoute: 'There is one or more selected shipments that have not been scheduled for release or there is a shipment that already has an outbound route. Please update them and try again.',
            createInboundRoute: 'There is one or more selected shipments that have not been scheduled for pickup or there is a shipment that already has an inbound route. Please update them and try again.',
            updateScheduledPickupTime: 'There is one or more selected shipments that do not have an inbound leg or have already created an inbound route.',
            updateScheduledInjectionTime: 'There is one or more selected shipments that do not have an inbound leg or have already created an inbound route.',
            updateScheduledReleaseTime: 'There is one or more selected shipments that do not have an outbound leg or have already created an outbound route.',
            updateScheduledDeliveryTime: 'There is one or more selected shipments that do not have an outbound leg or have already created an outbound route.'
        }

        return condition ? messages[key] : ''
    }
}
