<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{ labelTxt }}</div>
  </div>
</ng-template>
<div>
  <div #formLocation form-shipment-location [type]="type"></div>
  <div #formItems form-order-items></div>
</div>
<div class="separator h top30"></div>
<div form-footer class="no-border"
  [canClickOK]="true" 
  [canClickCancel]="true"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Add" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>