import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzInputModule } from "ng-zorro-antd/input";
import { RouterModule } from "@angular/router";
import { FormInputCostV3 } from ".";
import { FormInputCostV3TemplateDefault } from "./template-default";
import { SharedModule } from "@app/admin/shared/shared.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NzFormModule,
        ReactiveFormsModule,
        NzSelectModule,
        NzRadioModule,
        SelectBySearchingModule,
        NzCheckboxModule,
        NzInputModule,
        RouterModule,
        SharedModule,
    ],
    declarations: [
      FormInputCostV3,
      FormInputCostV3TemplateDefault,
    ],
    exports: [
      FormInputCostV3,
      FormInputCostV3TemplateDefault,
    ]
})
export class FormInputCostV3Module {}