import { BaseList } from '../base/list';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Const } from '@app/const/Const';
import { Mapbox } from '@services/mapbox';
import polyline from '@mapbox/polyline';
import { Utils } from '@services/utils';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: 'shipment-parcel-list',
  templateUrl: './list.html',
  styleUrls: ['./list.scss','../list.scss']
})
export class ShipmentParcelList extends BaseList {

  public searchKeyword = '';
  private mapBox: Mapbox;
  public routers = [];
  public points = [];
  public driverLocations = [];
  public modelItemDetails;
  @ViewChild('mapElm') mapElm: ElementRef<HTMLElement>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.mapBox = new Mapbox();
  }

  protected getApiUrl(): string {
    return Const.APIURI_ORDER_ITEMS;
  }

  protected onGetDataSucceeded(resp: any): void {
    for (let item of this.listData) {
      this.processItem(item);
    }
    if (Utils.isArrayNotEmpty(this.listData)) {
      this.modelItemDetails = this.listData[0];
      this.getRouteDataItem(this.modelItemDetails);
    }
  }

  protected processItem(item) {
    item.hyperLinkUrl = `${this.routeAdminShipmentList}/${item.orderId}`;
    item.listCarriers = '';
    item.lastMileServiceName = 'N/A';
    for (let lastMileService of Const.lastMileServices) {
      if (lastMileService.code == item.lastMileServiceCode) {
        item.lastMileServiceName = lastMileService.name;
        break;
      }
    }
    if (!item.status) {
      item.status = Const.ItemStatus.created;
    }
    if (item.deliveryDate) {
      const deliveryFormat = 'MM-DD-YYYY';
      item.deliveryDate = DateUtil.displayLocalTime(item.deliveryDate.timeWindow?.to, {timezone: item.deliveryDate.timezone, format: deliveryFormat});
    }
    const timeDeliveryFormat = 'MMM DD, YYYY h:mm a';
    if (item.timeInjected) {
      item.timeInjected = DateUtil.displayLocalTime(item.timeInjected,{timezone: "America/Los_Angeles", format: timeDeliveryFormat});
    }
    if (item.lastMileAddress) {
      const timezone = item.lastMileAddress.timezone || "America/Los_Angeles";
      if (item.timeDelivered) {
        item.timeDelivered = DateUtil.displayLocalTime(item.timeDelivered, {timezone, format: timeDeliveryFormat});
      }
    }
    let lastUpdateDateTime = new Date(item.update?.when).toISOString();
    const lastUpdateDateFormat = 'MMM DD, YYYY h:mm a';
    item.lastUpdateDate = DateUtil.displayLocalTime(lastUpdateDateTime, {timezone: "America/Los_Angeles", format: lastUpdateDateFormat});
  }

  private setupMap() {
    setTimeout(() => {
      this.mapBox.setupMap(this.routers, this.points, this.driverLocations, Const.MapBox.style.light, Const.MapBox.FitBoundsBy.points);
    }, 200);
  }

  public copyTrackingCode(item) {
    Utils.copyTextToClipboard(item.trackingNumber, (e) => {
      if (e) {
        this.showErr("Cannot copy tracking number to clipboard");
      } else {
        this.showSuccess(
          "Tracking number has already been copied to the clipboard"
        );
      }
    });
  }

  private clearItemSelect() {
    for (let item of this.listData) {
      item.isSelected = false;
    }
  }

  getRouteDataItem(item) {
    this.clearItemSelect();
    item.isSelected = true;
    this.modelItemDetails = item;
    let url = `${Const.APIURI_ORDER_ITEMS}/${item._id}`
    this.api.GET(url).subscribe(
      resp => {
        item.routeData = resp.data?.routeData;
        this.routers = [];
        this.points = [];
        this.driverLocations = [];
        if (item?.routeData) {
          let line = item.routeData.line ? polyline.decode(item.routeData.line) : item.routeData.points;
          let route = line.map(it => [it[1], it[0]]);
          if (route.length > 0) {
            this.routers.push(route);
          }
        }
        if (item?.lastKnownLocation) {
          this.driverLocations.push(item.lastKnownLocation);
        }
        let shipmentPoints = [];
        const pickUpPointList = [];
        const dropOffPointList = [];
        if (item?.pickAddress?.metadata) {
          pickUpPointList.push([item.pickAddress.metadata.longitude, item.pickAddress.metadata.latitude]);
        }
        if (item?.dropAddress?.metadata) {
          dropOffPointList.push([item.dropAddress.metadata.longitude, item.dropAddress.metadata.latitude]);
        }
        if (pickUpPointList.length && dropOffPointList.length) {
          shipmentPoints = [pickUpPointList, dropOffPointList]
          this.points.push(shipmentPoints);
        }
        this.setupMap();
      }, err => {
        this.showErr(err);
      }
    );
  }

  public getAddressTextV2(addr): string {
    if (!addr) return '';
    return `${addr.city}, ${addr.state}, ${addr.zipcode}`;
  }

  public showItemStatus(item){
    const lastStatus = item.histories[item.histories.length -1];
    return lastStatus.statusText || this.getItemStatusText(lastStatus.status, item.lastMileServiceName)
  }
  public getItemStatusText(status: string, warehouseName): string {
    return Const.getItemStatusText(status, warehouseName)
  }

  getEventDescriptionAddress(item) {
    if (item.location?.city && item.location?.stateOrProvinceCode) {
      return `${item.location.city}, ${item.location.stateOrProvinceCode}`;
    }
    return '';
  }

  getTime(time) {
    let timezoneAddr = 'America/Los_Angeles';
    if (this.modelItemDetails?.lastMileAddress?.timezone) {
      timezoneAddr = this.modelItemDetails?.lastMileAddress?.timezone;
    }
    return DateUtil.displayLocalTime(time, {timezone: timezoneAddr, format: Const.FORMAT_GUI_DATETIME});
  }

}
