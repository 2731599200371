
<div class="dashboard-child-container no-padding list">
  <!-- <div class="list-header">
    <search-box #searchBox (doSearch)="doSearch($event)" placeHolder="Search by WARP ID" [searchKeyword]="searchKeyword" style="width: 500px; margin-right: 20px;"></search-box>
  </div> -->

  <div class="list-body">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
      <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="12">
        <nz-table
          #nzTable nzBordered="true"
          [nzData]="listData" nzSize="small"
          [nzTotal]="totalCount" [nzFrontPagination]="false"
          [nzShowPagination]="totalCount > listData.length"
          [nzPageSize]="limit"
          [nzLoading]="isLoading"
          [nzLoadingIndicator]="tplLoading"
          [nzNoResult]="tplNoData"
          [nzSize]="'default'"
          (nzPageIndexChange)="onDataListPageChanged($event)">
          <ng-template #tplNoData>
            <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
          </ng-template>
          <ng-template #tplLoading>
            <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
          </ng-template>
          <thead>
            <tr>
              <th style="width: 100px;">Item ID</th>
              <th>Tracking Number</th>
              <th>Carrier</th>
              <th>Last Mile Service</th>
              <th>Delivery Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of nzTable.data">
              <tr (click)="getRouteDataItem(data)" [ngClass]="{selected: data.isSelected}">
                <td><a [routerLink]="[data.hyperLinkUrl]" target="_blank">{{data._id}}</a></td>
                <td>
                  <code>{{data.trackingNumber}}</code>
                  <i *ngIf="data.trackingNumber" nz-icon nzType="copy" nzTheme="outline" class="clickable" (click)="copyTrackingCode(data)" nz-tooltip nzTooltipTitle="Copy tracking number" style="margin-left: 5px;"></i>
                </td>
                <td>{{data.carrierName || 'N/A'}}</td>
                <td>{{data.lastMileServiceName || 'N/A'}}</td>
                <td>{{data.deliveryDate}}</td>
                <td class="width-fit-content">
                  <div class="item-status" ngClass="item-status-{{data.status}}">
                    {{showItemStatus(data)}}
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
      <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12">
        <div class="item-detail">
          <div *ngIf="modelItemDetails">
            <div style="display: flex; justify-content: space-between;">
              <div class="item-detail-status">{{showItemStatus(modelItemDetails)}}</div>
              <div class="item-detail-tracking">
                Tracking: {{modelItemDetails.trackingNumber}}
                <i *ngIf="modelItemDetails.trackingNumber" nz-icon nzType="copy" nzTheme="outline" class="clickable" (click)="copyTrackingCode(modelItemDetails)" nz-tooltip nzTooltipTitle="Copy tracking number" style="margin-left: 5px;"></i>
              </div>
            </div>
            <div class="item-detail-last-update-loadid">
              <div class="item-detail-last-update">Last Update: {{modelItemDetails.lastUpdateDate}}</div>
              <div>Item ID: <span class="item-detail-load-id">{{modelItemDetails._id}}</span></div>
            </div>
            <div style="display: flex; justify-content: end; margin-bottom: 10px;">
              <div>WARP ID: <span class="item-detail-load-id"><a [routerLink]="[modelItemDetails.hyperLinkUrl]" target="_blank">{{modelItemDetails.orderId}}</a></span></div>
            </div>
          </div>
          <div #mapElm id="dispatch-map" style="width: 100%; height: 400px;"></div>
          <div *ngIf="modelItemDetails" class="box-body">
            <div class="item-detail-row">
              <div class="item-detail-label">Parcel Carrier</div>
              <div class="item-detail-value">{{modelItemDetails.lastMileServiceName}}</div>
            </div>
            <div nz-row class="item-detail-row">
              <div nz-col nzXs="12">
                <div class="item-detail-label">Pickup Zipcode:</div>
                <div class="item-detail-value">{{getAddressTextV2(modelItemDetails.pickAddress)}}</div>
              </div>
              <div nz-col nzXs="12">
                <div class="item-detail-label">Dropoff Zipcode:</div>
                <div class="item-detail-value">{{getAddressTextV2(modelItemDetails.dropAddress)}}</div>
              </div>
            </div>
            <div *ngIf="modelItemDetails.lastMileServiceCode" nz-row class="item-detail-row">
              <div nz-col nzXs="12">
                <div class="item-detail-label">Recipient Zipcode (Last Mile):</div>
                <div class="item-detail-value">{{getAddressTextV2(modelItemDetails.lastMileAddress) || 'N/A'}}</div>
              </div>
            </div>
            <div nz-row class="item-detail-row">
              <div *ngIf="modelItemDetails.timeInjected" nz-col nzXs="12">
                <div class="item-detail-label">Last Update From {{modelItemDetails.lastMileServiceName}}:</div>
                <div class="item-detail-value">{{modelItemDetails.timeInjected}}</div>
              </div>
              <div *ngIf="modelItemDetails.timeDelivered" nz-col nzXs="12">
                <div class="item-detail-label">Time Delivered:</div>
                <div class="item-detail-value">{{modelItemDetails.timeDelivered}}</div>
              </div>
            </div>
            <div *ngIf="modelItemDetails.histories" class="carrier-3rd-histories-timelime">
              <div class="title-box bold f16 top30 bottom20">Travel history</div>
              <div class="body-box">
                <nz-timeline>
                  <ng-container *ngFor="let item of modelItemDetails.histories; let i = index"  >
                    <nz-timeline-item>
                      <div class="f14 regular" nz-row>
                        <div nz-col nzSm="12">{{item.statusText || getItemStatusText(item.status, modelItemDetails.lastMileServiceName)}}</div>
                        <div nz-col nzSm="12">{{getEventDescriptionAddress(item)}}</div>
                      </div>
                      <div class="f14 sub-text">{{getTime(item.changeStatusWhen)}}</div>
                      <div class="f14 sub-text" *ngIf="item.description">{{item.description}}</div>
                    </nz-timeline-item>
                  </ng-container>
                </nz-timeline>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
