<form nz-form [formGroup]="formInput">

  <div *ngIf="'batchType' as key">
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
    <nz-form-item>
      <nz-form-control>
        <nz-select [formControlName]="key" style="width: 500px;">
          <nz-option *ngFor="let item of batchTypes" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div class="form-location" *ngIf="isMultiPick" 
    #formMultiPick form-shipment-location-batch-multi-pick 
    [context]="context">
  </div>
  <div class="form-location" *ngIf="isMultiDrop" 
    #formMultiDrop form-shipment-location-batch-multi-drop 
    [context]="context">
  </div>
  <div class="form-location" *ngIf="isMultiPickDrop" 
    #formMultiPickDrop form-shipment-location-batch-multi-pick-drop 
    [context]="context">
  </div>

</form>
