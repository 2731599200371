import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { RoutingProblemDetail } from './problems/detail';
import { RoutingFilterShipment } from './problems/filter-shipment';
import { RoutingShipmentItem } from './problems/shipment-item';
import { RoutingProblemList } from './problems/list';
import { RoutingProblemMap } from './problems/map';
import { RoutingProblemMapSelectionPopup } from './problems/popup-selection';
import { RoutingProblemVehicles } from './problems/vehicles';
import { RoutingProblemVehiclesAddNew } from './problems/vehicles/add-new';
import { RoutingProblemChild } from './problems/sub-problem';
import { RoutingProblemConfirmRoutes } from './problems/sub-problem/confirm-routes';
import { AllOrder } from './problems/all-orders';
import { FillShipmentWindow } from './problems/fill-shipment-window';
import { TimeWindowModule } from '../base/time-window/module';
import { RoutingService } from './services/routing.service';
import { Websocket } from './services/websocket.service';
import { ProblemManagement } from './services/problemManagement';
import { RoutingProblemListShipment } from './problems/list-shipments';
import { MapService } from './services/map.service';
import { ShipmentManagement } from './services/shipmentManagement';
import { AddressManagement } from './services/objects/addressManagement';
import { RoutingRoute } from './problems/route';
import { RoutingMapBottombar } from './problems/map-bottom-bar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RoutingRouteShipment } from './problems/route-shipment';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

import { RoutingProblemReorderMarkerPopup } from './problems/features/reOrder/marker-popup'
import { RoutingProblemReorderTaskList } from './problems/features/reOrder/selected-task-list';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { UiCommonModule } from '../components/common/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzDropDownModule,
    NzDatePickerModule,
    NzIconModule,
    NzDividerModule,
    NzButtonModule,
    NzTableModule,
    NzPopoverModule,
    NzTagModule,
    NzToolTipModule,
    NzPopconfirmModule,
    NzCheckboxModule,
    TimeWindowModule,
    DragDropModule,
    UiCommonModule,
  ],
  declarations: [
    RoutingProblemList,
    RoutingProblemDetail,
    RoutingFilterShipment,
    RoutingProblemMap,
    RoutingShipmentItem,
    RoutingProblemMapSelectionPopup,
    RoutingProblemConfirmRoutes,
    RoutingProblemChild,
    RoutingProblemVehicles,
    RoutingRouteShipment,
    RoutingProblemVehiclesAddNew,
    AllOrder,
    FillShipmentWindow,
    RoutingProblemListShipment,
    RoutingRoute,
    RoutingMapBottombar,
    RoutingProblemReorderMarkerPopup,
    RoutingProblemReorderTaskList,
  ],
  exports: [
    RoutingProblemDetail,
    RoutingProblemList,
  ],
  providers: [
    RoutingService,
    Websocket,
    AddressManagement,
    ProblemManagement,
    ShipmentManagement,
    MapService
  ]
})
export class RoutingLtlModule { }