import { Component, EventEmitter, ViewChild } from '@angular/core';
import {
  FormDataBatchLocationsItem,
  FormDataBatchShipmentRevenueArray,
  FormDataShipmentEntryBatchShipmentItem,
  FormDataShipmentEntryDraftBatch,
  FormDataShipmentEntryDraftData,
  FormDataShipmentEntryDraftSingle,
  FormDataShipmentLocation,
  FormDataSingleShipmentRevenue,
} from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { ShipmentEntryCreateMode } from '@app/enum';
import { BaseDialog } from '@dialogs/base-dlg';
import { Utils } from '@services/utils';
import { ShipmentEntryBasicInfo } from '../components/shipment-single/basic-info';
import { ShipmentEntrySinglePickup } from '../components/shipment-single/pickup';
import { ShipmentEntrySingleDelivery } from '../components/shipment-single/delivery';
import { ShipmentEntryItems } from '../components/shipment-single/items';
import { Tab, TabHeaderProgress } from '@app/admin/base/tabs/interface';
import { ShipmentEntrySingleRevenue } from '../components/shipment-single/revenue';
import { ShipmentEntryBatchRevenue } from '../components/shipment-batch/batch-revenue';
import { Log } from '@services/log';
import { ShipmentTabs } from '../components/shipment-tabs';
import { ShipmentEntryContext, ShipmentEntryContextProgressCallback } from '../interface';
import { BaseForm } from '@app/admin/base/form-base';
import { ShipmentEntryBatchLocationStops } from '../components/shipment-batch/batch-location-stops';
import { ShipmentEntryBasicInfoBatch } from '../components/shipment-batch/basic-info';
import { ShipmentEntryFormId } from '../enum';
import { ShipmentEntryBuilder } from './shipment-builder';
import { Const } from '@const/Const';
import { DlgCreateSuccessComponent } from '@dialogs/dlg-create-success';
import { ShipmentEntryMode, TaskType } from '@wearewarp/types';
import { BizError } from '@wearewarp/universal-libs';

@Component({
  selector: '[shipment-entry-container]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../../../dialogs/dialogs.scss']
})
export class ShipmentEntryContainer extends BaseDialog {

  private readonly _shipmentEntryContext: ShipmentEntryContext = {
    modelDraft: null,
    modelDraftChange: new EventEmitter<FormDataShipmentEntryDraftData>(),
    getHeaderProgress: this.getShipmentEntryProgress.bind(this),
    saveDeliveryInfo: this.saveDeliveryInfo.bind(this),
    saveDeliveryInfos: this.saveDeliveryInfos.bind(this),
    saveShipments: this.saveShipments.bind(this),
  }

  get shipmentEntryContext() { return this._shipmentEntryContext }

  model: any;
  createMode = ShipmentEntryCreateMode.single;
  onComplete: (data) => void;

  get modelDraft(): FormDataShipmentEntryDraftData {
    return this.shipmentEntryContext.modelDraft;
  }
  set modelDraft(value) {
    this.shipmentEntryContext.modelDraft = value;
    this.shipmentEntryContext.modelDraftChange.emit(value);
  }

  tabs: Tab<any>[] = [];
  isSavingData = false;
  isPatchingData = false;
  private dataBuilder: ShipmentEntryBuilder;

  @ViewChild('shipmentTabs') shipmentTabs: ShipmentTabs;

  get hasDraft() {return this.modelDraft != null}

  get topbarTitle() {
    if (this.model) {
      return `Order ${this.model.warpId}`;
    }
    return `Create ${Utils.capitalizeFirstLetter(this.createMode)} Shipment`;
  }

  get canClose() {
    return !this.isSavingData;
  }

  constructor() {
    super();
  }
  ngOnInit(): void {
    this.dataBuilder = new ShipmentEntryBuilder(this.modelDraft);
    this.initTabs();
  }

  public closeDialog(): void {
    // TODO: check if warning is needed "unsave data might be lost before closing"
    super.closeDialog();
  }

  onBtnClose() {
    this.closeDialog();
  }

  private isBasicInfoComplete(): boolean {
    if (!this.modelDraft?.basicInfo?.shipmentType) {
      return false;
    }
    if (this.modelDraft.basicInfo.shipmentType == Const.ShipmentTypes.fullTruckLoad) {
      return (this.modelDraft.basicInfo.vehicleType?.code != null || (this.modelDraft.basicInfo.equipmentId != null && this.modelDraft.basicInfo.shipmentModeId != null)) ;
    } else {
      return this.modelDraft.basicInfo.clientId != null;
    }
  }

  private assertBeforeSave(ops?: {silent?: boolean}): boolean {
    let silent = ops?.silent;
    switch (this.shipmentTabs.currentTabId) {
      case ShipmentEntryFormId.singlePickup:
      case ShipmentEntryFormId.singleDropoff:
      case ShipmentEntryFormId.singleItems:
      case ShipmentEntryFormId.batchLocations:
        // Cần save basic info trước
        if (!this.isBasicInfoComplete()) {
          if (!silent) {
            const tabIndex_basicInfo = 0;
            let tabTitle = this.tabs[tabIndex_basicInfo].title;
            this.showDialog(`Please complete <b>${tabTitle}</b> first`, () => this.goToTab(tabIndex_basicInfo));
          }
          return false;
        }
        return true;

      case ShipmentEntryFormId.singleRevenue:
      case ShipmentEntryFormId.batchRevenue:
        // Trước khi save revenue thì tất cả các tab trước phải nhập đủ hết dữ liệu
        for (let i = 0; i < this.tabs.length; i++) {
          let tabId = this.tabs[i].id;
          if (tabId == this.shipmentTabs.currentTabId) {
            continue;
          }
          if (this.getShipmentEntryProgress(tabId) != TabHeaderProgress.done) {
            if (!silent) {
              let tabTitle = this.tabs[i].title;
              this.showDialog(`Please complete <b>${tabTitle}</b> first`, () => this.goToTab(i));
            }
            return false;
          }
        }
        break;
    }
    return true;
  }

  onBtnSave() {
    if (!this.assertBeforeSave()) {
      return;
    }
    const form: BaseForm<any> = this.shipmentTabs.getActiveForm();
    let err = form?.validate({scrollToErrorField: true});
    if (err) {
      let message = 'Please make sure all the fields in form are valid.';
      if (err.error && err.message) {
        // form tạo sẵn error message để hiển thị
        message = err.message;
        err = err.error;
      }
      const modal = this.modalService.create({
        nzContent: message,
        nzClosable: false,
        nzMaskClosable: false,
        nzCentered: true,
        nzOkText: 'OK',
        nzCancelText: null,
      });
      modal.afterClose.subscribe(() => {
        form.scrollToFirstInvalidControl();
      });
      return Log.e(`Form error: `, err);
    }
    const formData = form.getFormData();
    Log.d('currentTab formData: ', formData);
    if (this.shipmentTabs.currentTabId == ShipmentEntryFormId.singleRevenue) {
      if (!(<FormDataSingleShipmentRevenue>formData).cost?.grandTotal) {
        return this.confirmCostZeroBeforeSaving(formData);
      }
    }
    if (this.shipmentTabs.currentTabId == ShipmentEntryFormId.batchRevenue) {
      if (!(<FormDataBatchShipmentRevenueArray>formData).revenueInfos?.[0]?.revenues?.[0]?.cost?.grandTotal) {
        return this.confirmCostZeroBeforeSaving(formData);
      }
    }
    this.save(formData, this.shipmentTabs.currentTabId);
  }

  private confirmCostZeroBeforeSaving(formData) {
    return this.confirmYesNo('Revenue is zero, are you sure?', () => {
      this.save(formData, this.shipmentTabs.currentTabId);
    })
  }

  private save(formData, currentTabId: string) {
    let orderData = this.dataBuilder.buildDraftData(formData, currentTabId);
    this.saveDraftData(orderData, {onSuccess: () => {
      if (this.isAllTabsDone()) {
        const lastIndex = this.tabs.length - 1;
        if (this.shipmentTabs.tabCtrl.currentIndex == lastIndex) {
          // Đang ở tab cuối rồi => gọi API tạo order
          this.completeDraft();
        } else {
          // Nhảy về tab cuối để review, phải click button Save ở tab cuối cùng thì mới tạo order
          this.goToTab(lastIndex);
        }
      } else {
        this.goNextTab()
      }
    }});
  }

  private goNextTab() {
    let index = this.findNextActiveTab();
    Log.d(`goNextTab ${index}`);
    this.goToTab(index);
  }

  private goToTab(index: number) {
    this.shipmentTabs.tabCtrl.selectTab(index);
  }

  onBtnCancel() {
    this.closeDialog();
  }

  onTabChanged(tabId: string) {
    Log.d(`onTabChanged ${tabId}`);
  }

  validateCanChangeTab = (currentIndex: number, nextIndex: number) => {
    // const currentForm = this.shipmentTabs.getFormByIndex(currentIndex);
    // let err = currentForm.validate();
    // if (err) {
    //   return false;
    // }
    return true;
  }

  private isAllTabsDone(): boolean {
    if (!this.modelDraft) {
      return false;
    }
    for (let tab of this.tabs) {
      if (this.getShipmentEntryProgress(tab.id) != TabHeaderProgress.done) {
        return false;
      }
    }
    return true;
  }

  // Dựa vào draft data check xem form đã nhập đủ dữ liệu chưa
  private getShipmentEntryProgress(tabId: string): TabHeaderProgress {
    if (!this.modelDraft) {
      return TabHeaderProgress.inProgress;
    }
    let id = <ShipmentEntryFormId>tabId;
    const form: BaseForm<any> = this.shipmentTabs?.getFormById(id);
    let err = form?.validate();
    if (err) {
      // Nếu form đang lỗi thì ko được coi là done
      return TabHeaderProgress.inProgress;
    }
    // Nếu form không lỗi và có modelDraft thì chỉ cần check xem trong modelDraft đã có data của form chưa, nếu có rồi tức là form đã done rồi.
    let isDone = false;
    switch (id) {
      case ShipmentEntryFormId.singleBasicInfo:
      case ShipmentEntryFormId.batchBasicInfo:
        isDone = this.modelDraft.basicInfo?.shipmentType != null;
        break
      case ShipmentEntryFormId.singlePickup:
        isDone = (<FormDataShipmentEntryDraftSingle>this.modelDraft).pickInfo?.type == Const.TaskType.PICKUP;
        break;
      case ShipmentEntryFormId.singleDropoff:
        isDone = (<FormDataShipmentEntryDraftSingle>this.modelDraft).dropInfo?.type == Const.TaskType.DROPOFF;
        break;
      case ShipmentEntryFormId.singleItems:
        isDone = (<FormDataShipmentEntryDraftSingle>this.modelDraft).items?.length > 0;
        break;
      case ShipmentEntryFormId.singleRevenue:
        isDone = (<FormDataShipmentEntryDraftSingle>this.modelDraft).cost?.currency?.type != null;
        break;
      case ShipmentEntryFormId.batchLocations:
        isDone = this.isLocationBatchDone(this.modelDraft);
        break;
      case ShipmentEntryFormId.batchRevenue:
        isDone = Utils.isArrayNotEmpty((<FormDataShipmentEntryDraftBatch>this.modelDraft).revenueInfos);
        break;
      default:
        return TabHeaderProgress.inProgress;
    }
    return isDone ? TabHeaderProgress.done : TabHeaderProgress.inProgress;
  }

  private assertDeliveryInfo(info: FormDataShipmentLocation, expectedType: TaskType): boolean {
    return info?.type == expectedType;
  }

  // Dựa vào draft data check xem tab batch locations/stops đã nhập đủ dữ liệu chưa
  private isLocationBatchDone(draft: FormDataShipmentEntryDraftBatch): boolean {
    if (!draft) {
      return false;
    }
    switch (draft.shipmentEntryMode) {
      case Const.ShipmentEntryMode.multiPick:
        if (!this.assertDeliveryInfo(draft.dropInfo, Const.TaskType.DROPOFF)) {
          return false;
        }
        if (!draft.pickInfos?.length) {
          return false;
        }
        for (let info of draft.pickInfos ?? []) {
          if (!this.assertDeliveryInfo(info, Const.TaskType.PICKUP)) {
            return false;
          }
        }
        return true;
      case Const.ShipmentEntryMode.multiDrop:
        if (!this.assertDeliveryInfo(draft.pickInfo, Const.TaskType.PICKUP)) {
          return false;
        }
        if (!draft.dropInfos?.length) {
          return false;
        }
        for (let info of draft.dropInfos ?? []) {
          if (!this.assertDeliveryInfo(info, Const.TaskType.DROPOFF)) {
            return false;
          }
        }
        return true;
      case Const.ShipmentEntryMode.multiPickDrop:
        if (!draft.shipments?.length) {
          return false;
        }
        for (let s of draft.shipments) {
          if (!this.assertDeliveryInfo(s.pickInfo, Const.TaskType.PICKUP)) {
            return false;
          }
          if (!this.assertDeliveryInfo(s.dropInfo, Const.TaskType.DROPOFF)) {
            return false;
          }
        }
        return true;
    }
    return false;
  }

  private initTabs() {
    const context = this.shipmentEntryContext;
    switch (this.createMode) {
      case ShipmentEntryCreateMode.single:
        this.tabs = [
          {
            id: ShipmentEntryFormId.singleBasicInfo,
            title: 'Customer & Equipments',
            component: ShipmentEntryBasicInfo,
            active: true,
            context,
          },
          {
            id: ShipmentEntryFormId.singlePickup,
            title: 'Pickup',
            component: ShipmentEntrySinglePickup,
            active: false,
            context,
          },
          {
            id: ShipmentEntryFormId.singleDropoff,
            title: 'Delivery',
            component: ShipmentEntrySingleDelivery,
            active: false,
            context,
          },
          {
            id: ShipmentEntryFormId.singleItems,
            title: 'Items',
            component: ShipmentEntryItems,
            active: false,
            context,
          },
          {
            id: ShipmentEntryFormId.singleRevenue,
            title: 'Revenue',
            component: ShipmentEntrySingleRevenue,
            active: false,
            context,
          },

        ]
        break;
      case ShipmentEntryCreateMode.batch:
        this.tabs = [
          {
            id: ShipmentEntryFormId.batchBasicInfo,
            title: 'Equipments',
            component: ShipmentEntryBasicInfoBatch,
            active: true,
            context,
          },
          {
            id: ShipmentEntryFormId.batchLocations,
            title: 'Locations/stops',
            component: ShipmentEntryBatchLocationStops,
            active: false,
            context,
          },
          {
            id: ShipmentEntryFormId.batchRevenue,
            title: 'Revenue',
            component: ShipmentEntryBatchRevenue,
            active: false,
            context,
          }
        ]
        break;
      default:
        break;
    }
    this.autoAssignActiveTab();
  }

  // Gọi hàm này để tìm ra những form nào đã nhập đủ dữ liệu rồi
  // Mục đích để nhảy đến form đầu tiên chưa đủ dữ liệu
  private autoAssignActiveTab() {
    let index = this.findNextActiveTab();
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].active = i == index;
      Log.d(`tab ${this.tabs[i].id} ${this.tabs[i].active ? 'active' : 'inactive'}`);
    }
  }

  private findNextActiveTab(): number {
    for (let i = 0; i < this.tabs.length; i++) {
      let stt = this.getShipmentEntryProgress(this.tabs[i].id);
      if (stt == TabHeaderProgress.inProgress) {
        return i;
      }
    }
    return 0;
  }

  private saveDraftData(data: FormDataShipmentEntryDraftData, callBack?: {onSuccess?: (data) => void, onError?: (err) => void, onProgress?: () => void}) {
    this.isSavingData = true;
    callBack?.onProgress?.();
    // BE lưu clientId và parentClientId, chuyển clientId -> parentClientId, subClientId -> clientId
    if(data.basicInfo?.subClientId){
      data.basicInfo.parentClientId = data.basicInfo.clientId;
      data.basicInfo.clientId = data.basicInfo.subClientId;
      delete data.basicInfo.subClientId;
    }
    Log.d('saveDraftData ', data);
    this.api.POST(`${Const.APIURI_ORDERS}/save-draft`, data).subscribe(
      resp => {
        Log.d('saveDraftData done ', resp);
        this.isSavingData = false;
        this.modelDraft = resp.data;
        this.dataBuilder.setDraftData(this.modelDraft);
        callBack?.onSuccess?.(resp.data);
      }, err => {
        this.showErr(err);
        this.isSavingData = false;
        callBack?.onError?.(err);
      }
    );
  }

  private assertModelDraftBatch(expectedMode: ShipmentEntryMode) {
    if (!this.modelDraft) {
      throw Error('modelDraft does not exists');
    }
    if (!this.modelDraft.shipmentEntryMode) {
      // tạo batch phải vào tab location/stop mới chọn mode nên nếu modelDraft chưa có shipmentEntryMode thì không cần check
      return;
    }
    if (this.modelDraft.shipmentEntryMode != expectedMode) {
      throw Error(`modelDraft.shipmentEntryMode = ${this.modelDraft.shipmentEntryMode} does not match ${expectedMode}`);
    }
  }

  // Chỉ áp dụng cho batch mode
  // Save pickInfo cho trường hợp single pick - multi drop
  // Save dropInfo cho trường hợp single drop - multi pick
  private saveDeliveryInfo(data: FormDataShipmentLocation, onProgress: ShipmentEntryContextProgressCallback) {
    if (!this.assertBeforeSave()) {
      return;
    }
    let draftData = {...<FormDataShipmentEntryDraftBatch>this.modelDraft};
    if (data.type == Const.TaskType.PICKUP) {
      const expectMode = Const.ShipmentEntryMode.multiDrop;
      this.assertModelDraftBatch(expectMode);
      draftData.pickInfo = data;
      draftData.shipmentEntryMode = expectMode;
    } else if (data.type == Const.TaskType.DROPOFF) {
      const expectMode = Const.ShipmentEntryMode.multiPick;
      this.assertModelDraftBatch(expectMode);
      draftData.dropInfo = data;
      draftData.shipmentEntryMode = expectMode;
    } else {
      throw Error(`Unknown type ${data.type}`);
    }
    this.saveDraftData(draftData, {
      onProgress: () => onProgress('inProgress'),
      onSuccess: (data) => onProgress('succeeded', data),
      onError: (err) => onProgress('failed', err)
    });
  }

  // Chỉ áp dụng cho batch mode
  // Save pickInfo cho trường hợp multi pick - single drop
  // Save dropInfo cho trường hợp multi drop - single pick
  private saveDeliveryInfos(data: FormDataBatchLocationsItem, index: number, onProgress: ShipmentEntryContextProgressCallback) {
    if (!this.assertBeforeSave()) {
      return;
    }
    let draftData = {...<FormDataShipmentEntryDraftBatch>this.modelDraft};
    if (data.type == Const.TaskType.PICKUP) {
      this.assertModelDraftBatch(Const.ShipmentEntryMode.multiPick);
      const pickInfos = draftData.pickInfos ?? [];
      if (index < pickInfos.length) {
        pickInfos[index] = data;
      } else {
        pickInfos.push(data);
      }
      draftData.pickInfos = pickInfos;
    } else if (data.type == Const.TaskType.DROPOFF) {
      this.assertModelDraftBatch(Const.ShipmentEntryMode.multiDrop);
      const dropInfos = draftData.dropInfos ?? [];
      if (index < dropInfos.length) {
        dropInfos[index] = data;
      } else {
        dropInfos.push(data);
      }
      draftData.dropInfos = dropInfos;
    } else {
      throw Error(`Unknown type ${data.type}`);
    }
    this.saveDraftData(draftData, {
      onProgress: () => onProgress('inProgress'),
      onSuccess: (data) => onProgress('succeeded', data),
      onError: (err) => onProgress('failed', err)
    });
  }

  // Chỉ áp dụng cho batch mode
  // Save shipment cho trường hợp multi pick - multi drop
  private saveShipments(data: FormDataShipmentEntryBatchShipmentItem, index: number, onProgress: ShipmentEntryContextProgressCallback) {
    if (!this.assertBeforeSave()) {
      return;
    }
    let draftData = {...<FormDataShipmentEntryDraftBatch>this.modelDraft};
    this.assertModelDraftBatch(Const.ShipmentEntryMode.multiPickDrop);
    const shipments = draftData.shipments ?? [];
    if (index < shipments.length) {
      shipments[index] = data;
    } else {
      shipments.push(data);
    }
    this.saveDraftData(draftData, {
      onProgress: () => onProgress('inProgress'),
      onSuccess: (data) => onProgress('succeeded', data),
      onError: (err) => onProgress('failed', err)
    });
  }


  private completeDraft() {
    this.isSavingData = true;
    this.api.POST(`${Const.APIURI_ORDERS}/complete-draft`).subscribe(
      resp => {
        this.isSavingData = false;
        this.showDialogComplete(resp?.data || {});
        this.onComplete?.(resp?.data);
      }, async err => {
        if(err?.data?.moduleErrorCode === BizError.ClientCreditLimitExceeded) {
          this.showErrDialog(`The customer credit has exceeded the limit. Please check your customer settings.`);
        } else {
          this.showErr(err);
        }
        this.isSavingData = false;
      }
    );
  }

  private showDialogComplete(order) {
    this.modalService.create({
      nzContent: DlgCreateSuccessComponent,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzComponentParams: { order }
    });
    this.closeDialog();
  }

  get isSingleMode(){
    return this.createMode == ShipmentEntryCreateMode.single;
  }

  updateSortedTaskIds(data) {
    if (!this.assertBeforeSave({silent: true})) {
      return;
    }
    const draftData = {...this.modelDraft, sortedTaskIds: data};
    this.saveDraftData(draftData);
  }

}
