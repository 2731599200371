import { Component } from '@angular/core';
import { FormControl } from '@angular/forms/forms';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { MasterData } from '@services/master.data';
import { InputHelper } from '@services/input-helper';

@Component({
  selector: '[routing-problem-vehicles-add-new]',
  templateUrl: './add-new.html',
  styleUrls: ['./style.scss']
})
export class RoutingProblemVehiclesAddNew extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    _id: {label: '', readOnly: true},
    vehicleType: {label: 'Vehicle', placeHolder: 'Select', required: true},
    availability: {label: 'Availability (how many vehicles)', type: 'number', placeHolder: 'Enter number', required: true},
  }
  get formInputKeys() {
    return Object.keys(this.formGroupDeclaration).filter(it => it != '_id');
  }

  vehicleModes = [];
  vehicleTypes = [];

  get isCreateNew() {
    return true;
  }

  addItem: (data) => void = (data) => {}

  constructor() {
    super();
    this.vehicleModes = MasterData.getAllShipmentModesV2();
  }

  getListForKey(key: string) {
    switch (key) {
      case 'modeId': return this.vehicleModes;
      case 'typeId': return this.vehicleTypes;
      default: return [];
    }
  }

  getDropdownNoData(key: string): string {
    switch (key) {
      case 'typeId': {
        let value = this.getFormItemValue('modeId');
        if (!value) return `Please select ${this.getLabel('modeId')} first`;
        break;
      }
      default:
        break;
    }
    return 'No data';
  }

  onDropdownSelectChange(key: string, event) {
    switch (key) {
      case 'modeId':
        this.onVehicleModeChanged(true);
        break;
      case 'vehicleType':
        if (event?.code) {
          this.setItemValue(key, event);
        } else {
          this.setItemValue(key, null);
        }
        break;
      default:
        break;
    }
  }

  private onVehicleModeChanged(clearVehicleType = false) {
    let modeId = this.getFormItemValue('modeId');
    let mode = this.vehicleModes.filter(it => it._id == modeId)[0];
    if (!mode) {
      return;
    }
    this.vehicleTypes = mode.equipments ?? [];
    if (clearVehicleType) {
      this.setItemValue('typeId', null);
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'availability':
      case 'capacity':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'availability':
      case 'capacity':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key), {isInteger: true});
      default:
        return super.onInputChanged(event, key);
    }
  }
  
  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    let params = this.getFormData(true);
    this.addItem(params);
    this.closeDialog();
  }

}