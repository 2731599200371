import { Component, ViewChild } from "@angular/core";
import { ValidateOptions } from "@app/admin/base/form-base";
import { FormDataBatchLocations } from "@app/admin/shipment-entry/interface";
import { Const } from "@const/Const";
import { FormShipmentLocationBatchMultiPick } from "./multi-pick";
import { FormShipmentLocationBatchMultiDrop } from "./multi-drop";
import { FormShipmentLocationBatchMultiPickDrop } from "./multi-pick-drop";
import { ValidationErrors } from "@angular/forms/forms";
import { ShipmentEntryFormId } from "@app/admin/shipment-entry/enum";
import { ShipmentEntryForm } from "../shipment-entry-form";

@Component({
  selector: '[form-shipment-location-batch]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FormShipmentLocationBatch extends ShipmentEntryForm<FormDataBatchLocations> {
  @ViewChild('formMultiPick') formMultiPick: FormShipmentLocationBatchMultiPick;
  @ViewChild('formMultiDrop') formMultiDrop: FormShipmentLocationBatchMultiDrop;
  @ViewChild('formMultiPickDrop') formMultiPickDrop: FormShipmentLocationBatchMultiPickDrop;

  public get formId() { return ShipmentEntryFormId.batchLocations }

  protected formGroupDeclaration: FormGroupDeclaration = {
    batchType: {label: 'Type of Batch Shipment', required: true},
  }

  public batchTypes = [
    {id: Const.ShipmentEntryMode.multiDrop, name: 'Single Pickup/Multi Dropoff'},
    {id: Const.ShipmentEntryMode.multiPick, name: 'Multi Pickup/Single Dropoff'},
    {id: Const.ShipmentEntryMode.multiPickDrop, name: 'Multi Pickup/Multi Dropoff'},
  ];

  public get batchType() {
    return this.getItemValue('batchType');
  }

  public get isMultiPick() {
    return this.batchType == Const.ShipmentEntryMode.multiPick;
  }

  public get isMultiDrop() {
    return this.batchType == Const.ShipmentEntryMode.multiDrop;
  }

  public get isMultiPickDrop() {
    return this.batchType == Const.ShipmentEntryMode.multiPickDrop;
  }

  ngOnInit(): void {
    if (this.model?.batchType) {
      this.formGroupDeclaration.batchType.readOnly = true;
    }
    super.ngOnInit();
  }

  protected onModelDataChanged(): void {
  }
  
  private getCurrentForm() {
    switch (this.batchType) {
      case Const.ShipmentEntryMode.multiPick: return this.formMultiPick;
      case Const.ShipmentEntryMode.multiDrop: return this.formMultiDrop;
      case Const.ShipmentEntryMode.multiPickDrop: return this.formMultiPickDrop;
    }
  }

  public getFormData(): FormDataBatchLocations {
    return {
      batchType: this.batchType,
      data: this.getCurrentForm()?.getFormData(),
    };
  }

  public validate(options?: ValidateOptions): ValidationErrors {
    return this.getCurrentForm()?.validate(options);
  }

}
