<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <div txtHintSearch="Search by name, ID or address" warehouse-filter></div>

    <div class="flex1"></div>
    <button *ngIf="requirePermissions([PermissionCode.warehouse.create])" nz-button (click)="onBtnDetail()"><i nz-icon nzType="plus" nzTheme="outline"></i>Add location</button>
    <button nz-button (click)="onBtnExport()" [nzLoading]="isExporting">
      <i nz-icon nzType="file-excel" nzTheme="outline"></i>
      Export
    </button>
  </div>
  <div class="list-body">
    <nz-table
      #nzTable nzBordered="true"
      [nzData]="listData" nzSize="small"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="warpId" nzWidth="120px">WARP ID</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="name">Name</th>
          <th *ngIf="isAdmin" nzSortOrder="null" [nzSortFn]="true" nzColumnKey="clients.name">Customer</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="warehouseType">Warehouse Type</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="requireAppointment" nzWidth="150px">Requires appointment</th>
          <th colspan="2">Address</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a *ngIf="isCrossdockType(item)" (click)="gotoWarehouseJobs(item)">{{item.warpId}}</a>
            <span *ngIf="!isCrossdockType(item)">{{item.warpId}}</span>
          </td>
          <td class="col-client-first">
            <a (click)="onBtnDetail(item)" *ngIf="item.name">{{item.name}}</a>
            <a (click)="onBtnDetail(item)" *ngIf="!item.name">NO NAME</a>
          </td>
          <td *ngIf="isAdmin">
            {{getClientName(item.clients)}}
            <ng-container *ngIf="item?.clients && item?.clients.length > 1" >
              <a [nzTooltipTitle]="getListClientNames(item.clients)" nzTooltipPlacement="top" nz-tooltip nz-typography nzEllipsis>
                +{{ item?.clients?.length - 1 }}
              </a>
            </ng-container>
          </td>
          <td>{{getWarehouseTypeText(item.warehouseType)}}</td>
          <td>{{!!item.requireAppointment ? 'Yes' : ''}}</td>
          <td class="border-right-none">{{getAddressText(item.pickAddr)}}</td>
          <td class="actions w50">
            <nz-space nzSize="middle" *ngIf="requirePermissions([PermissionCode.warehouse.delete])">
              <ng-container *ngIf="isCrossdockType(item)">
                <ng-container *ngIf="requirePermissions([PermissionCode.warehouse.update])">
                  <button *nzSpaceItem (click)="onBtnSettingTasks(item)" nz-button nzType="text" nzShape="circle" nz-tooltip nzTooltipTitle="Setting Cross-Dock’s Tasks">
                    <i nz-icon nzType="setting" nzTheme="outline"></i>
                  </button>
                </ng-container>

                <ng-container *ngIf="requirePermissions([PermissionCode.warehouse_inventory.create])">
                  <button *nzSpaceItem (click)="onBtnInventory(item)" nz-button nzType="text" nzShape="circle" nz-tooltip nzTooltipTitle="Inventories">
                    <i nz-icon nzType="pic-right" nzTheme="outline"></i>
                  </button>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="requirePermissions([PermissionCode.warehouse.delete])">
                <button *nzSpaceItem (click)="onBtnDelItem(item)" nz-button nzType="text" nzShape="circle" nzDanger nz-tooltip nzTooltipTitle="Delete Item">
                  <i nz-icon nzType="delete" nzTheme="outline"></i>
                </button>
              </ng-container>
            </nz-space>
          </td>
        </tr>
      </tbody>
    </nz-table>

  </div>
</div>