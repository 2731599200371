import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import {
  FormDataShipmentCost
} from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import {
  ShipmentEntryMode as ShipmentEntryModeEnum
} from "@wearewarp/types";
import { BaseForm } from "../../../../base/form-base";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Const } from "@const/Const";
import { FuelCostType, VolumeDiscountType } from "@app/enum";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import { MasterData } from "@services/master.data";
import { DialogService } from "@dialogs/dialog.service";
import { BizUtil } from "@services/biz";

function isUsdConversionChanged(value1, value2) {
  if (Utils.isNumber(value1) && Utils.isNumber(value2) && value1 > 0 && value1 !== value2) return true;
  return false;
}

// dùng cho trường hợp có initialValue
function isStringChanged(value1, value2) {
  if (value1 && value2 && value1 !== value2) return true;
  return false;
}

// dùng cho trường hợp có initialValue
function isNumberChanged(value1, value2) {
  if (Utils.isNumber(value1) && Utils.isNumber(value2) && value1 !== value2) return true;
  return false;
}

function isMoney1Changed(value1, value2) {
  if (Utils.isNumber(value1) && Utils.isNumber(value2)) {
    let arr1 = String(value1).split('.');
    let arr2 = String(value2).split('.');
    if (arr1.length > 1 && arr2.length > 1) {
      let text1 = InputHelper._formatMoney(arr1[0], 0);
      text1 += '.' + arr1[1].substring(0, 2);
      let text2 = InputHelper._formatMoney(arr2[0], 0);
      text2 += '.' + arr2[1].substring(0, 2);
      if (text1 != text2) return true;
    } else {
      if (value1 !== value2) return true;
    }
  } else if (value1 != value2) {
    if ((value1 === undefined || value1 === null || value1 === 0) && (value2 === undefined || value2 === null || value2 === 0)) {
      return false
    } else return true;
  }
  return false;
}

@Component({
  selector: '[form-input-cost-v3]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FormInputCostV3 extends BaseForm<FormDataShipmentCost> {

  constructor(){
    super();
  }

  private static _symbol = '$';
  get symbol() {
    return FormInputCostV3._symbol;
  }

  public static makeDeclaration(symbol: string): FormGroupDeclaration {
    return {
      currency: {label: 'Transit Cost', required: false, type: 'formGroup', childItem: {
        type: {label: 'Currency', initialValue: Const.CurrencyConfig.USD.value, isChanged: isStringChanged},
        fxRate: {label: 'FX Rate', type: 'number', required: symbol !== "$" ? true : false, placeHolder: 'FX Rate'},
      }},
      transitCost: {label: 'Transit Cost', required: false, type: 'formGroup', childItem: {
        rate: {label: 'Rate', required: false, type: 'number', placeHolder: `${symbol || '$'}0.00`, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, symbol), isChanged: isMoney1Changed},
        qty: {label: 'Qty', type: 'number', required: false, initialValue: 1, isChanged: isNumberChanged},
        total: {label: 'Total', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol), isChanged: isMoney1Changed}
      }},
      volumeDiscount: {label: 'Volume Discount', type: 'formGroup', childItem: {
        type: {label: 'Discount Type:', initialValue: VolumeDiscountType.percentage, isChanged: isStringChanged},
        percentage: {label: 'Discount (%)', type: 'number', placeHolder: '%', getValue: InputHelper.getValuePercentage, formatValue: InputHelper.formatPercentage},
        flatRate: {label: 'Flat Rate', type: 'number', placeHolder: `${symbol || '$'}0.00`, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, symbol), isChanged: isMoney1Changed},
        qty: {label: '', type: 'number', readOnly: true, submitReadOnly: true},
        total: {label: '', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol), isChanged: isMoney1Changed}
      }},
      subTotal: {label: `Sub Total (${symbol || '$'}):`, type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol), isChanged: isMoney1Changed},
      fuelCost: {label: 'Fuel Cost', type: 'formGroup', childItem: {
        type: {label: 'Fuel Cost Type:', initialValue: FuelCostType.ratePerMile, isChanged: isStringChanged},
        percentage: {label: 'Fuel Cost (%)', type: 'number', placeHolder: '%', getValue: InputHelper.getValuePercentage, formatValue: InputHelper.formatPercentage},
        rpm: {label: 'Fuel Rate:', type: 'number', placeHolder: `${symbol || '$'}0.00`, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, symbol), isChanged: isMoney1Changed},
        qty: {label: 'Fuel Qty:', type: 'number'},  // only if choose RPM (rate per mile)
        total: {label: '', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol), isChanged: isMoney1Changed}
      }},
      serviceOptions: {label: 'Service Options', type: 'formArray', childItem: {
        _id: {label: ''},
        deliveryId: {label: ''},
        rate: {label: '', required: false, type: 'number', placeHolder: symbol, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, symbol), isChanged: isMoney1Changed},
        qty: {label: '', initialValue: 1, type: 'number', getValue: InputHelper.getValueMoney, isChanged: isNumberChanged },
        total: {label: '', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol), isChanged: isMoney1Changed}
      }},
      grandTotal: {label: 'Grand Total', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol), isChanged: isMoney1Changed},
      usdConversion: {label: 'USD Conversion', type: 'number', isChanged: isUsdConversionChanged, readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, symbol)}
    }
  }

  public static get declaration(): FormGroupDeclaration {
    let declaration = this.makeDeclaration(FormInputCostV3._symbol);
    if (declaration.currency?.childItem?.type) {
      (declaration.currency.childItem.type as any).readOnly = true;
      (declaration.currency.childItem.type as any).submitReadOnly = true;
    }
    return declaration;
  }

  public static get declarationForCreate(): FormGroupDeclaration {
    return this.makeDeclaration(FormInputCostV3._symbol);
  }

  protected formGroupDeclaration: FormGroupDeclaration = FormInputCostV3.declaration;

  @Input() set form(value: FormGroup) {
    this.formInput = value;
  }

  @Input() viewTemplate: TemplateRef<any>;

  @Input() serviceOptions = []
  @Input() createShipmentMode: ShipmentEntryModeEnum;
  @Input() labelTotal = 'Total Revenue';
  @Input() isShowOnlyServiceOptions: boolean = false;

  public listDeliveryLocationSelect = [];
  public dropoffNumber = 1;

  public listPickupLocationSelect = [];
  public pickupNumber = 1;

  // trường hợp multi pickup multi dropoff cần truyền listShipments để select ở seviceOptions
  private _shipmentNumber = 0;
  @Input() set shipments(value) {
    if (value?.length > 0) {
      this._shipmentNumber = value.length;
      this.listPickupLocationSelect = [];
      this.listDeliveryLocationSelect = [];
      for (let i=0; i<value.length; i++) {
        let shipment = value[i];
        let pickupInfo = shipment.pickInfo;
        let deliveryInfo = shipment.dropInfo;
        this.listPickupLocationSelect.push({deliveryId: pickupInfo.id, type: Const.TaskType.PICKUP, label: `Shipment ${shipment.index + 1}`});
        this.listDeliveryLocationSelect.push({deliveryId: deliveryInfo.id, type: Const.TaskType.DROPOFF, label: `Shipment ${shipment.index + 1}`});
      }
      this.pickupNumber = this.listPickupLocationSelect.length;
      this.dropoffNumber = this.listDeliveryLocationSelect.length;
    }
  }
  get shipmentNumber() {return this._shipmentNumber}

  @Input() set selectLocations(value) {
    if (value?.length > 0) {
      this.listPickupLocationSelect = value.filter(item => item.type == Const.TaskType.PICKUP);
      this.listDeliveryLocationSelect = value.filter(item => item.type == Const.TaskType.DROPOFF);
      this.pickupNumber = this.listPickupLocationSelect.length;
      this.dropoffNumber = this.listDeliveryLocationSelect.length;
    }
  }

  @Output() serviceOptionsChange = new EventEmitter<any>();
  @Output() grandTotalChange = new EventEmitter<any>();
  lastCurrencyType = 'USD';

  currencies = Object.values(Const.CurrencyConfig);
  volumeDiscountTypes = [
    { value: VolumeDiscountType.percentage, label: '%'},
    { value: VolumeDiscountType.flatRate, label: 'Flat Rate'},
  ]
  fuelCostTypes = [
    { value: FuelCostType.ratePerMile, label: 'RPM'},
    { value: FuelCostType.percentage, label: '%'},
  ]

  ngOnInit(): void {
    if (this.serviceOptions.length == 0) {
      this.serviceOptions = [
        {label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup},
        {label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery},
        {label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition},
      ];
    }

    if (!this.formInput) {
      super.ngOnInit();
      this.setEnable(true);
    }

    let currencyType = this.getItemValue('currency.type');
    if (currencyType && currencyType != Const.CurrencyConfig.USD.value) {
      this.lastCurrencyType = currencyType;
    }

    this.updateCost()
  }

  public createFormInput(bindData) {
    if (this.formInput) {
      const symbol = this.symbol;
      this.formGroupDeclaration = FormInputCostV3.makeDeclaration(symbol);
      if (bindData) this.bindFormData(bindData);
    } else {
      return super.createFormInput(bindData);
    }
  }

  public setEnable(enabled: boolean) {
    super.setEnable(enabled);
    if (!this.model?.currency?.type || this.model?.currency?.type === Const.CurrencyConfig.USD.value) {
      this.formInput?.get('currency')?.get("fxRate").disable();
    }
  }

  protected beforeBindModel(model): any {
    return model;
  }

  public getFormData(): FormDataShipmentCost {
    let data = super.getFormData();
    if (data?.currency?.type && data?.currency?.type != 'USD') {
      // chuyển tất cả rate về chuẩn USD
      data = BizUtil.convertCostToUSD(data);
    }
    return data;
  }

  get labelCurrency() {
    if (this.lastCurrencyType && this.lastCurrencyType != 'USD') return `(${this.lastCurrencyType})`;
    return ''
  }

  onChangeCurrencyType(value) {
    if(value != this.lastCurrencyType) {
      this.lastCurrencyType = value;
      const data = this.getFormData();
      this.createFormInput(data);
      this.setEnable(true);
    }

    const fxRateControl = this.formInput?.get("currency")?.get("fxRate");
    if(value === "USD") {
      fxRateControl.clearValidators();
      fxRateControl.updateValueAndValidity();
      fxRateControl.disable();
      this.setItemValue('currency.fxRate', 1)
    }
    else {
      this.setItemValue('currency.fxRate', this.model?.currency?.fxRate || null)
      fxRateControl.enable();
      fxRateControl.setValidators([Validators.required]);
      fxRateControl.markAsDirty();
      fxRateControl.updateValueAndValidity();
    }
  }

  onVolumeDiscountTypeChange(value) {
    if (value == VolumeDiscountType.flatRate) {
      this.setItemValue('volumeDiscount.percentage', null);
    } else if (value == VolumeDiscountType.percentage) {
      this.setItemValue('volumeDiscount.flatRate', null);
    }
    this.setItemValue('volumeDiscount.total', 0);
    this.updateCost();
  }

  onFuelCostTypeChange(value) {
    if (value == FuelCostType.ratePerMile) {
      this.setItemValue('fuelCost.percentage', null);
    } else if (value == FuelCostType.percentage) {
      this.setItemValue('fuelCost.rpm', null);
      this.setItemValue('fuelCost.qty', null);
    }
    this.setItemValue('fuelCost.total', 0);
    this.updateCost();
  }

  public updateCost() {
    let qty = this.getItemValue('transitCost.qty')

    let rate = this.getItemValue('transitCost.rate');
    let transitCostTotal = qty * rate;
    this.setItemValue('transitCost.total', transitCostTotal);

    // Calculate discount & sub total
    let volumeDiscountType = this.getItemValue('volumeDiscount.type');
    let discountTotal = 0;
    if (volumeDiscountType == VolumeDiscountType.flatRate) {
      const flatRate = this.getItemValue('volumeDiscount.flatRate');
      if (flatRate) discountTotal = flatRate;
    } else if (volumeDiscountType == VolumeDiscountType.percentage) {
      let discountPercent = this.getItemValue('volumeDiscount.percentage') / 100;
      discountTotal = discountPercent * transitCostTotal;
    }
    let subTotal = transitCostTotal - discountTotal;
    this.setItemValue('volumeDiscount.total', discountTotal);
    this.setItemValue('subTotal', subTotal);

    // Calculate grand total
    let fuelCostType = this.getItemValue('fuelCost.type');
    let fuelTotal;
    if (fuelCostType == FuelCostType.ratePerMile) {
      let fuelCostRate = this.getItemValue('fuelCost.rpm');
      let fuelCostQty = this.getItemValue('fuelCost.qty');
      fuelTotal = fuelCostRate * fuelCostQty;
    } else if (fuelCostType == FuelCostType.percentage) {
      let fuelCostPercent = this.getItemValue('fuelCost.percentage') / 100;
      fuelTotal = fuelCostPercent * subTotal;
    }
    this.setItemValue('fuelCost.total', fuelTotal);
    let grandTotal = subTotal + fuelTotal;
    for (let i = 0; i < this.getArrayControls('serviceOptions').length; i++) {
      const serviceOptionId = this.getItemValue(`serviceOptions[${i}]._id`);
      if (!serviceOptionId) continue;
      let rate = this.getItemValue(`serviceOptions[${i}].rate`);
      let qty = this.getItemValue(`serviceOptions[${i}].qty`);
      if (rate && qty) {
        let cost = qty * rate;
        this.setItemValue(`serviceOptions[${i}].total`, cost);
      }
      let serviceOptionCost = this.getItemValue(`serviceOptions[${i}].total`);
      if (!MasterData.isServiceOptionTypeNegative(serviceOptionId)) {
        grandTotal += serviceOptionCost;
      } else {
        grandTotal -= serviceOptionCost;
      }
    }
    if (grandTotal < 0) grandTotal = 0;
    this.setItemValue('grandTotal', grandTotal);
    let fxRate = this.getItemValue('currency.fxRate');
    this.setItemValue('usdConversion', grandTotal * fxRate);
    this.grandTotalChange.emit();
  }

  get fuelCostType() {
    if (!this.formInput) return '';
    return this.formInput.get('fuelCost').get('type').value;
  }

  get volumeDiscountType() {
    if (!this.formInput) return '';
    return this.formInput.get('volumeDiscount').get('type').value;
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'transitCost.qty':
      case 'fuelCost.qty':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key), {isInteger: true});
      case 'currency.fxRate':
      case 'serviceOptions.qty':
        return InputHelper.handleInputChangDecimalNumber(event, <FormControl>this.formInput.get(key));
      case 'transitCost.rate':
      case 'serviceOptions.rate':
      case 'fuelCost.rpm':
      case 'volumeDiscount.flatRate':
        return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true, this.symbol);
      case 'volumeDiscount.percentage':
      case 'fuelCost.percentage':
        return InputHelper.handleInputChangePercentage(event, <FormControl>this.formInput.get(key));
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'transitCost.qty':
      case 'fuelCost.qty':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      case 'currency.fxRate':
      case 'serviceOptions.qty':
        return InputHelper.handleInputKeyPressDecimalNumber(event);
      case 'transitCost.rate':
      case 'serviceOptions.rate':
      case 'fuelCost.rpm':
      case 'volumeDiscount.flatRate':
        return InputHelper.handleInputKeyPressMoney(event);
      case 'volumeDiscount.percentage':
      case 'fuelCost.percentage':
        return InputHelper.handleInputKeyPressPercentage(event);
      default:
        return true;
    }
  }

  onInputFocusOut(event, key) {
    switch (key) {
      case 'currency.fxRate':
      case 'transitCost.rate':
      case 'transitCost.qty':
      case 'volumeDiscount.rate':
      case 'fuelCost.rpm':
      case 'fuelCost.qty':
      case 'fuelCost.percentage':
      case 'volumeDiscount.percentage':
        return this.updateCost();
      case 'volumeDiscount.flatRate':
        let msg = this.validateDiscountValue();
        if (msg) {
          DialogService.showDialog(msg);
          this.setItemValue('volumeDiscount.flatRate', null);
          return;
        }
        return this.updateCost();
    }
    if (/^serviceOptions\[[0-9]+\].rate/.test(key)) {
      let rate = this.getItemValue(key);
      let keyTotal = key.replace(/rate$/, 'total')
      let keyQty = key.replace(/rate$/, 'qty')
      let qty = this.getItemValue(keyQty);
      let serviceOptionCost = qty * rate;
      this.setItemValue(keyTotal, serviceOptionCost);
      this.updateCost();
    }
    if (/^serviceOptions\[[0-9]+\].qty/.test(key)) {
      let qty = this.getItemValue(key);
      let keyTotal = key.replace(/qty$/, 'total')
      let keyRate = key.replace(/qty$/, 'rate')
      let rate = this.getItemValue(keyRate);
      let serviceOptionCost = qty * rate;
      this.setItemValue(keyTotal, serviceOptionCost);
      this.updateCost();
    }
  }

  protected validateDiscountValue(): string {
    let transitCostTotal = this.getItemValue('transitCost.total');
    if (!transitCostTotal) {
      return 'Please enter Rate first';
    }
    let discountValue = this.getItemValue('volumeDiscount.flatRate');
    if (discountValue && discountValue > transitCostTotal) {
      return 'Discount must be less than total transit cost';
    }
    return null;
  }

  // Service Option
  onBtnAddServiceOption(serviceOption) {
    if (!serviceOption) return
    let data = {
      _id: serviceOption
    }
    this.addItemToFormArray('serviceOptions', data);
    let serviceOptionsLength = this.getArrayControls('serviceOptions').length;
    this.onServiceOptionItemChange(serviceOptionsLength - 1, serviceOption);
  }

  onServiceOptionItemChange(currentServiceIndex: number, event) {
    const currentServiceId = this.getItemValue(`serviceOptions[${currentServiceIndex}]._id`);
    let currentService = MasterData.getServiceOptionById(currentServiceId);
    if (!currentService) return;
    if (currentService.type == 'pickup') {
      if (this.pickupNumber == 1) {
        let deliveryId = this.listPickupLocationSelect?.[0]?.deliveryId;
        this.setItemValue(`serviceOptions[${currentServiceIndex}].deliveryId`, deliveryId);
      }
      this.serviceOptionsChange.emit();
      this.updateCost();
      return;
    } else if (currentService.type == 'delivery') {
      if (this.dropoffNumber == 1) {
        let deliveryId = this.listDeliveryLocationSelect?.[0]?.deliveryId;
        this.setItemValue(`serviceOptions[${currentServiceIndex}].deliveryId`, deliveryId);
      }
      this.serviceOptionsChange.emit();
      this.updateCost();
      return;
    } else {
      this.setItemValue(`serviceOptions[${currentServiceIndex}].deliveryId`, '');
      this.updateCost();
    }
    setTimeout(() => this.serviceOptionsChange.emit(), 1);
  }

  onServiceOptionItemLocationChange(currentServiceIndex: number, event) {
    setTimeout(() => this.serviceOptionsChange.emit(), 1);
  }

  onBtnRemoveServiceOption(index: number) {
    let itemId = this.getItemValue(`serviceOptions[${index}]._id`);
    if (itemId) {
      let itemName = this.getServiceOptionName(itemId);
      DialogService.confirmDeletion({
        message: `Remove <b>${itemName}</b>?`,
        fnOk: () => {
          this.removeItemInFormArray('serviceOptions', index);
          this.serviceOptionsChange.emit();
          this.updateCost();
        }
      })
    } else {
      this.removeItemInFormArray('serviceOptions', index);
    }
  }

  public getServiceOptionName(id): string {
    return MasterData.getServiceOptionName(id);
  }
  
  public getValueServiceOptions() {
    return this.getItemValue('serviceOptions');
  }

  public setValueServiceOptions(arr: any[]) {
    this.setItemValue('serviceOptions', []);
    this.setItemValue('serviceOptions', arr);
  }
}