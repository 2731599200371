import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { DrawerService } from "@app/drawers/drawer.service";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { BizUtil } from "@services/biz";
import { InputHelper } from "@services/input-helper";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { RoutingVehicle } from "@wearewarp/types/data-model";
import { NzDrawerRef } from "ng-zorro-antd/drawer";
import { RoutingService } from "../../services/routing.service";
import { RoutingProblemVehiclesAddNew } from "./add-new";

@Component({
  selector: '[routing-problem-vehicles]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class RoutingProblemVehicles extends BaseComponent {
  @ViewChild('tplHeader') tplHeader: TemplateRef<any>;
  @ViewChild('tplContent') tplContent: TemplateRef<any>;
  listVehicles: RoutingVehicle[] = [];
  originListVehicles: RoutingVehicle[] = [];


  isLoading = false;
  get canAddNewItem() { return !this.isLoading }
  get canSave() { return !this.isLoading && this.isListDataChanged() }
  get canCancel() { return !this.isLoading }

  private drawerRef: NzDrawerRef;

  constructor(private routingService: RoutingService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.init();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  init() {
    this.subscription.add(this.routingService.listVehicles.subscribe(data => {
      this.originListVehicles = data;
      this.listVehicles = JSON.parse(JSON.stringify(data))
    }))
  }

  private async setListVehicles() {
    this.isLoading = true;
    await this.routingService.addVehicle(this.listVehicles);
    this.isLoading = false;
  }

  openDialogAddItem() {
    if (this.drawerRef) {
      this.onBtnNew();
    } else {
      this.openDrawer();
      this.drawerRef.afterOpen.subscribe(() => {
        this.onBtnNew();
      });
    }
  }

  openDrawer() {
    this.drawerRef = DrawerService.openDrawer(null, {
      nzWrapClassName: 'routing-ltl-vehicles',
      nzClosable: false,
      nzKeyboard: false,
      nzContent: this.tplContent,
      nzTitle: this.tplHeader,
    });
    this.drawerRef.afterOpen.subscribe()
  }

  closeDrawer() {
    if (!this.canCancel) {
      return;
    }
    this.drawerRef?.close();
    this.drawerRef = undefined;
  }

  onBtnSave() {
    if (!this.canSave) {
      return;
    }
    this.setListVehicles();
  }

  onBtnNew() {
    DialogService.openFormDialog1(RoutingProblemVehiclesAddNew, {
      nzWrapClassName: 'routing-ltl-vehicles-add-new',
      nzComponentParams: {
        addItem: (data: RoutingVehicle) => {
          if (this.listVehicles.length == 0) {
            // Tạo object mới để update UI
            this.listVehicles = [data];
          } else {
            for (let item of this.listVehicles) {
              if (BizUtil.isVehiclesTheSame(data, item)) {
                let name = this.getVehicleMode(data);
                let type = this.getVehicleType(data);
                if (type) {
                  name += `, ${type}`;
                }
                return this.showDialog(`${name} has already existed`);
              }
            }
            this.listVehicles.push(data);
          }
        },
      },
      nzClosable: false,
      nzKeyboard: false,
      nzTitle: null,
      nzFooter: null,
    });
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'availability':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      default:
        return true;
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'availability':
        return InputHelper.handleInputChangeNumberOnly(event, null, { isInteger: true });
      default:
        return;
    }
  }

  deleteItem(index: number) {
    let name = this.getVehicleMode(this.listVehicles[index]);
    this.confirmDeletion({
      message: `Delete the vehicle ${name}?`, fnOk: () => {
        this.listVehicles.splice(index, 1);
      }
    })
  }

  getVehicleMode(vehicle: RoutingVehicle) {
    if (vehicle?.vehicleType?.name) {
      return vehicle?.vehicleType?.name;
    }
    return MasterData.getShipmentModeName(vehicle.modeId);
  }

  getVehicleType(vehicle: RoutingVehicle) {
    return MasterData.getEquipmenName(vehicle.typeId);
  }

  getVehicleCapacity(vehicle: RoutingVehicle) {
    if (vehicle?.vehicleType?.palletCapacity) {
      const capacity = vehicle.vehicleType.palletCapacity;
      return Utils.displayCount(capacity, 'Pallet');
    }
    if (vehicle.capacity) {
      return Utils.displayCount(vehicle.capacity, 'Pallet');
    }
    return '';
  }

  private isListDataChanged() {
    return !Utils.isArraysTheSame(this.listVehicles, this.originListVehicles, (a, b) => {
      return this.isRoutingVehiclesTheSame(a, b);
    })
  }

  private isRoutingVehiclesTheSame(a: RoutingVehicle, b: RoutingVehicle): boolean {
    return a.modeId == b.modeId &&
      a.typeId == b.typeId &&
      a.capacity == b.capacity &&
      a.availability == b.availability;
  }

}