import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import { RoutingRegionSelectMode } from "../../interface";
import { RoutingService } from "../../services/routing.service";
import { RoutingRoute } from "../route";

@Component({
  selector: '[routing-map-bottom-bar]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class RoutingMapBottombar extends BaseComponent {
  private controller;
  public shouldShowBar = false;
  public isLoading = false;

  constructor(
    activatedRoute: ActivatedRoute,
    private routingService: RoutingService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.subscription.add(this.routingService.uiControlSubject.subscribe(eventData => {
      const { event, data } = eventData;
      switch (event) {
        case 'startAdjustRoutingOrder':
          this.startAdjustRoutingOrder(data);
      }
    }))
  }

  initEvent() {
    // this.controller.dataSubject.sub
  }

  public get statusText(){
    if(this.controller.getStatusText) return this.controller.getStatusText();
    return ""
  }

  private getController(eventData){
    this.controller = eventData?.controller;
      if (!this.controller) {
        console.error(`controller not found for route features!`)
        return;
      }
  }

  startAdjustRoutingOrder(data) {
    this.getController(data);
    this.shouldShowBar = true;
  }

  canSubmit() {
    return this.controller.canSave()
  }

  async onSubmit() {
    this.isLoading = true;
    try{
    await this.controller.onSave();
    this.showSuccess(`Change the order of the route successfully.`);
    }
    catch(e){
    this.showErr(e);

    }
    this.isLoading = false;
    this.shouldShowBar = false;
  }

  async onCancel() {
    this.controller.onCancel();
    this.shouldShowBar = false;
  }
}