import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzModalModule } from "ng-zorro-antd/modal";
import { MarkRequiredModule } from "@app/admin/base/mark-required/module";
import { FormOrderItem } from "./item";
import { FormOrderItems } from ".";
import { DetailModule } from "@app/admin/base/detail.module";
import { FormItemBarcodes } from "./barcode";
import { FormShipmentItemServices } from "./services";

@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      NzFormModule,
      NzButtonModule,
      NzIconModule,
      NzTableModule,
      NzSelectModule,
      NzGridModule,
      NzPopoverModule,
      NzPopconfirmModule,
      NzInputModule,
      NzCheckboxModule,
      NzModalModule,
      MarkRequiredModule,
      DetailModule,
    ],
    declarations: [
      FormOrderItem,
      FormOrderItems,
      FormItemBarcodes,
      FormShipmentItemServices,
    ],
    exports: [
      FormOrderItems,
    ]
})
export class FormOrderItemModule {}