import { Component } from "@angular/core";
import { BaseFormDrawer } from "@app/drawers/base-form";
import { Utils } from "@services/utils";

@Component({
  selector: '[all-orders]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class AllOrder extends BaseFormDrawer {
  textTitle = '';
  listOrderIds: any[] = [];
  onRemoveItem: (index: number) => void;
  canExcludeItem = false;

  get shouldShowButtonRemoveItem() {
    return Utils.isFunction(this.onRemoveItem);
  }

  constructor() {
    super();
  }

  onBtnRemove(index) {
    this.onRemoveItem?.(index);
  }

}