<nz-modal
  [nzVisible]="isVisible"
  [nzMaskClosable]="false"
  nzWidth="80%"
  [nzTitle]="getTitle()"
  (nzOnCancel)="handleCancel()"
  nzWrapClassName="map-modal"
>
  <ng-container *nzModalContent>
    <div #mapElm id="batch-big-map" style="width: 100%; height: calc(100vh - 200px);"></div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>
