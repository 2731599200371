import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from "@angular/core";
import { RoutingService } from "@app/admin/routing-tool/services/routing.service";
import { ShipmentManagement } from "@app/admin/routing-tool/services/shipmentManagement";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import { WarpId } from "@wearewarp/universal-libs";
import {
  CdkDragDrop,
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

@Component({
  selector: '[routing-problem-reorder-task-list]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})
export class RoutingProblemReorderTaskList {

  constructor(private shipmentManagement: ShipmentManagement, private routingService: RoutingService) {
  }
  public get selecteds() {
    return this.getFeature()?.selectedTasks || []
  }
  public set selecteds(value) {
    this.getFeature().selectedTasks = value
  }

  private getFeature() {
    return this.routingService.getEditFeature();
  }

  public showWarpId(task) {
    const shipmentId = task.shipmentId;
    const shipment = this.shipmentManagement.getShipment(shipmentId);
    return WarpId.showShipment(shipment.getWarpId())
  }

  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.getFeature().reDraw()
  }
  isValidTask(task) {
    return this.getFeature().isValidTask(task)
  }
  totalTasks(){
    return this.getFeature().getTotalTask()
  }
}