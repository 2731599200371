<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Add/Remove Stops</div>
  </div>
</ng-template>
<div>
  <div>Update to Batch Shipment Type:</div>
  <div style="display: flex; flex-direction: column; margin-top: 20px;">
    <button *ngFor="let item of batchTypes" nz-button (click)="onBtnSelectEditMode(item.id)" class="bottom20">{{ item.name }}</button>
  </div>
</div>
