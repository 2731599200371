import { RoutingColor } from "@app/admin/routing-tool/color";
import { Const } from "@const/Const";
import { getInjector } from "@services/index";
import { BehaviorSubject } from "rxjs";
import { MapService } from "../../map.service";
import { ProblemManagement } from "../../problemManagement";
import { RoutingService } from "../../routing.service";
import { ShipmentManagement } from "../../shipmentManagement";
import { MapLine, MapMarker } from "../map";
import { AddressManagement } from "../addressManagement";


export class BaseRouteFeature {
  routingService: RoutingService;
  mapService: MapService;
  shipmentManagement: ShipmentManagement;
  problemManagement: ProblemManagement
  addressManagement: AddressManagement;
  dataSubject: BehaviorSubject<any> = new BehaviorSubject({})

  constructor() {
    const injector = getInjector();
    this.routingService = injector.get(RoutingService);
    this.mapService = injector.get(MapService)
    this.shipmentManagement = injector.get(ShipmentManagement);
    this.problemManagement = injector.get(ProblemManagement);
    this.addressManagement = injector.get(AddressManagement)
  }

  start() {
    console.error(`need implement start function for route features`);
  }

  public onSave() {
    console.error(`need implement onSave function for route features`);
  }

  public onCancel() {
    console.error(`need implement onCancel function for route features`);
  }
  protected setData(data) {
    const oldData = this.dataSubject.getValue();
    this.dataSubject.next({
      ...oldData,
      ...data
    });
  }
}