<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <div *ngIf="'serviceOptions' as key" class="bottom20">
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
    <div>
      <nz-select nzBackdrop="true" nzMode="multiple"  class="mw500" style="width: 100%;" nzShowSearch nzAllowClear
        nzPlaceHolder="Select service options" [nzMaxTagCount]="0" [nzMaxTagPlaceholder]="tagPlaceHolder"
        [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceOptions.selectedItems"
        (ngModelChange)="serviceOptionsSelectionChange()">
        <nz-option *ngFor="let item of serviceOptions.allItems" [nzLabel]="item.name" [nzValue]="item._id"></nz-option>
      </nz-select>
    </div>
    <ng-template #tagPlaceHolder>{{serviceOptions.sum()}}</ng-template>
    <nz-tag *ngFor="let item of serviceOptions.selectedItems; let i = index" style="margin-top: 5px;"
      [nzMode]="'closeable'" nzColor="processing" (nzOnClose)="serviceOptions.remove(i)">
      {{serviceOptions.name(item)}}
    </nz-tag>
  </div>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>