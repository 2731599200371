import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DetailModule } from '../base/detail.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { ShipmentInvoiceList } from './list';
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { ShipmentFilter } from './filters/index';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzEmptyModule,
    NzInputModule,
    NzFormModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTagModule,
    NzCollapseModule,
    NzBadgeModule,
    SearchBoxModule,
    DetailModule,
    SelectBySearchingModule,
    PdfViewerModule,
    NzTimelineModule,
    NzTreeModule,
    NzPageHeaderModule
  ],
  declarations: [
    ShipmentInvoiceList,
    ShipmentFilter
  ],
  exports: [
    ShipmentInvoiceList,
    ShipmentFilter
  ],
  providers: [
  ]
})
export class ShipmentInvoiceModule { }
