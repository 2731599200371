import { Component } from '@angular/core';
import { ShipmentEntryCreateMode } from '@app/enum';
import { Const } from '@const/Const';
import { BaseDialog } from '@dialogs/base-dlg';
import { Log } from '@services/log';
import { Utils } from '@services/utils';
import { DialogService } from '@dialogs/dialog.service';
import { FormDataShipmentEntryDraftData } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { UIHelper } from '@services/UIHelper';

@Component({
  selector: '[shipment-entry-pre-create]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../../../dialogs/dialogs.scss']
})
export class ShipmentEntryPreCreate extends BaseDialog {

  errTxt = '';
  isLoading = false;
  modelDraft: FormDataShipmentEntryDraftData;
  onOk: (type: ShipmentEntryCreateMode, draftModel?: any) => void

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getDraft();
  }

  get draftType(): string {
    if (!this.modelDraft) {
      return '';
    }
    return this.modelDraft.shipmentEntryMode == Const.ShipmentEntryMode.single ? 'Single' : 'Batch';
  }

  private getDraft() {
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_ORDERS}/get/draft`).subscribe(
      resp => {
        this.isLoading = false;
        this.modelDraft = resp.data;
        Log.d('modelDraft: ', this.modelDraft);
      }, err => {
        this.isLoading = false;
        this.errTxt = Utils.getErrorString(err);
      }
    );
  }

  private deleteDraft() {
    this.api.POST(`${Const.APIURI_ORDERS}/delete-draft`).subscribe(resp => {}, err => Log.e(err));
  }

  private finish(type: ShipmentEntryCreateMode) {
    if (type != ShipmentEntryCreateMode.editDraft && this.modelDraft) {
      this.deleteDraft();
      this.modelDraft = undefined;
    }
    if (type == ShipmentEntryCreateMode.editDraft) {
      type = this.modelDraft.shipmentEntryMode == Const.ShipmentEntryMode.single ? ShipmentEntryCreateMode.single : ShipmentEntryCreateMode.batch;
    }
    this.onOk?.(type, this.modelDraft);
    this.closeDialog();
  }

  onBtnRetry() {
    this.getDraft();
  }

  onBtnClose() {
    this.closeDialog();
  }

  onBtnContinueDraft() {
    this.finish?.(ShipmentEntryCreateMode.editDraft);
  }

  onBtnCreateSingle() {
    this.warnDraftDeleteBeforeAction(() => this.finish?.(ShipmentEntryCreateMode.single));
  }

  onBtnCreateBatch() {
    this.warnDraftDeleteBeforeAction(() => this.finish?.(ShipmentEntryCreateMode.batch));
  }
  
  private warnDraftDeleteBeforeAction(fnOk: () => void) {
    if (this.modelDraft) {
      UIHelper.confirmYesNo('Your previous draft will be deleted. Do you want to create a new one?', () => fnOk());
    } else {
      fnOk();
    }
  }
}