import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";


@Component({
    selector: '[shipment-structure-job-detail]',
    templateUrl: './job_detail.html',
    styleUrls: ['./job_detail.scss']
})
export class JobDetail extends BaseComponent {
    @Input() id: any = null
    job: any = null

    ngOnChanges(): void {
        this.loadJob()
    }

    loadJob() {
        const url = Const.APIV2(`${Const.APIURI_JOBS}/${this.id}`)
        this.api.GET(url).subscribe((res) => {
            this.job = res.data
        })
    }
}