import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseDialog } from "@dialogs/base-dlg";
import { ModalHelper } from "@wearewarp/ng-antd";
import { ThirdPartyInfoDialog } from "./dialog";
import { environment } from "@env/environment";

@Component({
    selector: '[third-party-info-icon]',
    templateUrl: './icon.html',
    styleUrls: ['./index.scss']
})
export class ThirdPartyInfoIcon extends BaseComponent {
    @Input() shipment: any;

    source: any = null

    ngOnInit(): void {
        const {externalId} = this.shipment || {}
        this.source = externalId ? externalId.split('_')[0].toUpperCase() : null
    }

    constructor(
        protected activatedRoute: ActivatedRoute,
        private modalHelper: ModalHelper,
        
    ){  
        super(activatedRoute);
    }

    showDialog() {
        this.modalHelper.open(ThirdPartyInfoDialog, {
          nzClassName: 'modal-no-padding',
          nzCentered: true,
          nzCancelText: null,
          nzComponentParams: {
            order: this.shipment
          }
        })
    }
}