import { MapLayerType, MapSourceType } from "@app/admin/routing-tool/map.interface";
import { AnyLayer, AnySourceData, GeoJSONSource, GeoJSONSourceOptions, LineLayer, LinePaint, LngLatLike, Map as Mapbox, Popup } from "mapbox-gl";
import { MapGeoJsonBase } from "./geoJsonBase";

export class MapLine extends MapGeoJsonBase {

  protected sourceType: MapSourceType  = "LineString";
  protected layerType: MapLayerType = "line"

  protected generateLayer(): AnyLayer {
    return {
      'id': this.getLayerId(),
      'type': <any>this.layerType,
      'source': this.getSourceId(),
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': <LinePaint>this.paint
    }
  }

}