<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div *ngFor="let key of ['fullName', 'phone', 'email']">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
      <ng-container [ngSwitch]="key">
        <div *ngSwitchCase="'phone'" [ngClass]="key">
          <nz-form-item>
            <nz-form-control>
              <nz-input-group nzCompact>
                <input 
                  nz-input 
                  [formControlName]="key"
                  [type]="getInputType(key)" 
                  [placeholder]="getPlaceHolder(key)"
                  (input)="onInputChanged($event, key)" 
                  (keypress)="onInputKeyPress($event, key)"
                  style="width: 70%;" 
                />

                <input 
                  nz-input 
                  [formControlName]="'phoneExtension'"
                  [type]="getInputType('phoneExtension')" 
                  [placeholder]="getPlaceHolder('phoneExtension')"
                  (input)="onInputChanged($event, 'phoneExtension')" 
                  (keypress)="onlyNumberKey($event)"
                  style="width: 30%;"  
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div *ngSwitchDefault [ngClass]="key">
          <nz-form-item>
            <nz-form-control>
              <input 
                nz-input 
                [formControlName]="key"
                [type]="getInputType(key)" 
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)" 
                (keypress)="onInputKeyPress($event, key)" 
              >
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
    </div>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>