import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DialogService } from "@dialogs/dialog.service";
import { Const } from "@const/Const";
import { EditShipmentEntryAddShipment } from "../add-shipment";
import { BaseChildEditShipmentAddRemoveStops } from "../base-child-comp";
import { DataAddRemoveStops } from "../interface";
import { ResponseAdminOrderDetail } from "@wearewarp/types/rest-api/admin";

interface EditShipmentUICollapseState {
  data?: any,
  isExpand?: boolean,
  isLoading?: boolean,
  isAddNew?: boolean,
}

@Component({
  selector: '[form-edit-shipment-batch-multi-pick-drop]',
  templateUrl: './view.html',
  styleUrls: ['../style.scss'],
})
export class FormEditShipmentBatchMultiPickDrop extends BaseChildEditShipmentAddRemoveStops {

  @Input() currentShipmentEntryMode;
  @Input() modelOrder: ResponseAdminOrderDetail;
  @Output() onListStopsChange = new EventEmitter();

  listDataClient = [];

  readonly uiCollapseState: {shipments: EditShipmentUICollapseState[]} = {
    shipments: [],
  }
  get countShipmentInfos() { return this.uiCollapseState.shipments.length }
  originShipmentIds = [];

  ngOnInit(): void {
    this.extractDataModelOrder();
    this.fetchListClient();
  }

  private extractDataModelOrder() {
    if (!this.modelOrder) return;
    if (this.currentShipmentEntryMode == Const.ShipmentEntryMode.single) {
      let shipment = this.modelOrder.metadata?.shipments?.[0];
      if (!shipment) return;
      this.originShipmentIds.push(shipment.id);
      const deliveryInfos = shipment.deliveryInfos || [];
      const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
      const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
      const itemIds : any[] = shipment.itemIds ?? [];
      shipment.items = this.modelOrder.items?.filter(it => itemIds.includes(it.id));
      this.uiCollapseState.shipments.push({
        data: {
          id: shipment.id, 
          warpId: shipment.warpId,
          code: shipment?.code,
          clientId: shipment.clientId, 
          pickInfo, dropInfo, 
          items: shipment.items
        }, 
        isExpand: true
      });
    } else {
      let shipments = this.modelOrder?.metadata?.shipments || [];
      for (let shipment of shipments) {
        this.originShipmentIds.push(shipment.id);
        const deliveryInfos = shipment.deliveryInfos || [];
        const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
        const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
        const itemIds : any[] = shipment.itemIds ?? [];
        shipment.items = this.modelOrder.items?.filter(it => itemIds.includes(it.id));
        this.uiCollapseState.shipments.push({
          data: {
            id: shipment.id, 
            warpId: shipment.warpId,
            code: shipment?.code,
            clientId: shipment.clientId, 
            pickInfo, dropInfo, 
            items: shipment.items
          }
        });
      }
    }
  }

  public getData(): DataAddRemoveStops {
    let currentShipmentIds = this.uiCollapseState.shipments.map(it => it.data?.id);
    let newShipmentIds = currentShipmentIds.filter(it => !this.originShipmentIds.includes(it));
    let deletedShipmentIds = this.originShipmentIds.filter(it => !currentShipmentIds.includes(it));
    return {
      deleted: deletedShipmentIds,
      new: this.uiCollapseState.shipments.filter(it => newShipmentIds.includes(it.data?.id)).map(it => it.data)
    }
  }

  public canUpdate() {
    if (this.uiCollapseState.shipments.length == 0) return false;
    let currentShipmentIds = this.uiCollapseState.shipments.map(it => it.data?.id);
    let newShipmentIds = currentShipmentIds.filter(it => !this.originShipmentIds.includes(it));
    let deletedShipmentIds = this.originShipmentIds.filter(it => !currentShipmentIds.includes(it));
    if (newShipmentIds.length == 0 && deletedShipmentIds.length == 0) return false;
    return true;
  }

  onBtnAddShipmentInfo() {
    DialogService.openDialog(EditShipmentEntryAddShipment, {
      nzMaskClosable: false,
      nzComponentParams: {
        onSave: (data) => {
          this.uiCollapseState.shipments.push({data: data, isAddNew: true });
          this.onListStopsChange.emit();
        }
      },
      nzClassName: "modal-xxl",
      nzWrapClassName: 'add-new-location-form'
    });
  }

  canRemoveShipment(index) { return true }

  onBtnRemoveShipmentInfo(index, shipment) {
    const warpId = this.showShipmentCode(shipment);
    const text = warpId ? `WarpID ${warpId}` : `Shipment ${index + 1}`;
    DialogService.confirmDeletion({
      message: `Remove <b>${text}</b>?`,
      txtBtnOk: 'Remove',
      fnOk: () => {
        this.uiCollapseState.shipments.splice(index, 1);
        this.onListStopsChange.emit();
      }
    })
  }

  private fetchListClient() {
    let url = `${Const.APIURI_CLIENTS}?limit=-1`;
    this.api.GET(url).subscribe(
      resp => {
        // Log.d('fetchListData done ', resp);
        this.listDataClient = resp.data.list_data;
      }, err => {
      }
    );
  }

  getCustomerName(item) {
    let clientId = item?.data?.clientId;
    let client = this.listDataClient.filter(it => it.id == clientId)[0];
    if (client?.name) return client.name;
    return clientId;
  }


}