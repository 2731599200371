<ng-template [nzModalTitle]>
    <div class="flex-space-between">
        <div>Edit Revenue</div>
        <div>
            <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
        </div>
    </div>
</ng-template>

<div class="form-input-cost-item">
    <div nz-row [nzGutter]="{ sm: 16 }">
        <div nz-col nzSm="3" nzMd="3">
            <div class="label-item">Currency</div>
            <div>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select [disabled]="saving || !isCreateNewCost" nzBackdrop="true" [(ngModel)]="data.currency.type">
                            <nz-option *ngFor="let item of currencies" [nzValue]="item.value"
                                [nzLabel]="item.value"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-col nzSm="9" nzMd="9">
            <div nz-row>
                <div nz-col nzSm="16" nzMd="16" style="padding-right: 4px;">
                    <div class="label-item">Rate</div>
                    <div>
                        <input nz-input type="number" [disabled]="saving" [(ngModel)]="data.transitCost.rate" placeholder="Transit Rate"
                            (ngModelChange)="updateTransitCost()">
                    </div>
                </div>
                <div nz-col nzSm="8" nzMd="8" style="padding-left: 12px;">
                    <div class="label-item">Qty</div>
                    <div>
                        <input nz-input type="number" [disabled]="saving" [(ngModel)]="data.transitCost.qty" placeholder="Quantity"
                            (ngModelChange)="updateTransitCost()">
                    </div>
                </div>
            </div>
        </div>

        <div nz-col nzSm="6" nzMd="6">
            <div nz-row>
                <div nz-col nzSm="12" nzMd="12">
                    <div class="label-item">Discount Type</div>
                    <div>
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select [disabled]="saving" nzBackdrop="true" [(ngModel)]="data.volumeDiscount.type"
                                    (ngModelChange)="updateVolumeDiscount()">
                                    <nz-option *ngFor="let item of volumeDiscountTypes" [nzValue]="item.value"
                                        [nzLabel]="item.label"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSm="12" nzMd="12" style="padding-left: 16px;">
                    <ng-container *ngIf="data.volumeDiscount.type == 'percentage'">
                        <div class="label-item">Discount (%)</div>
                        <div>
                            <input nz-input type="number" [disabled]="saving" [(ngModel)]="data.volumeDiscount.percentage"
                                (ngModelChange)="updateVolumeDiscount()">
                        </div>
                    </ng-container>
                    <ng-container *ngIf="data.volumeDiscount.type == 'flatRate'">
                        <div class="label-item">Flat Rate</div>
                        <div>
                            <input nz-input type="number" [disabled]="saving" [(ngModel)]="data.volumeDiscount.flatRate"
                                (ngModelChange)="updateVolumeDiscount()">
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div nz-col nzSm="6" nzMd="6">
            <div class="label-item">Sub Total ($)</div>
            <div class="sub-total">
                <span decimal [value]="data.subTotal" default="0.0"></span>
            </div>
        </div>
    </div>
</div>

<div class="form-input-cost-item">
    <div nz-row [nzGutter]="{ sm: 16 }">
        <div nz-col nzSm="3" nzMd="3">
            <div class="label-item">Fuel Cost Type</div>
            <div>
                <nz-form-item>
                    <nz-form-control>
                        <nz-select [(ngModel)]="data.fuelCost.type" [disabled]="saving">
                            <nz-option *ngFor="let item of fuelCostTypes" [nzValue]="item.value" [nzLabel]="item.label"
                                (ngModelChange)="updateFuelCost()"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-col nzSm="6" nzMd="6">
            <ng-container *ngIf="data.fuelCost.type == 'rpm'">
                <div class="label-item">RPM</div>
                <div>
                    <nz-form-item>
                        <nz-form-control>
                            <input nz-input type="number" [disabled]="saving" [(ngModel)]="data.fuelCost.rpm" (ngModelChange)="updateFuelCost()">
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </ng-container>
            <ng-container *ngIf="data.fuelCost.type == 'percentage'">
                <div class="label-item">Fuel Cost (%)</div>
                <div>
                    <nz-form-item>
                        <nz-form-control>
                            <input nz-input type="number" [disabled]="saving" [(ngModel)]="data.fuelCost.percentage" (ngModelChange)="updateFuelCost()">
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </ng-container>
        </div>
        <div nz-col nzSm="3" nzMd="3">
            <ng-container *ngIf="data.fuelCost.type == 'rpm'">
                <div class="label-item">Fuel Qty</div>
                <div>
                    <input nz-input type="number" [disabled]="saving" [(ngModel)]="data.fuelCost.qty" (ngModelChange)="updateFuelCost()">
                </div>
            </ng-container>
        </div>
        <div nz-col nzSm="6" nzMd="6"></div>
        <div nz-col nzSm="6" nzMd="6">
            <div>&nbsp;</div>
            <div class="sub-total">
                <span decimal [value]="data.fuelCost.total" default="0.0"></span>
            </div>
        </div>
    </div>
</div>

<div class="form-input-cost-item">
    <div class="label-item">Service Options:</div>
    <div nz-row [nzGutter]="{ sm: 16 }" class="form-add-service-options">
        <div nz-col nzSm="7" nzMd="7">
            <nz-form-item>
                <nz-form-control>
                    <nz-select nzBackdrop="true" nzShowSearch [disabled]="saving" [(ngModel)]="selectedNewServiceOption"
                        (ngModelChange)="onBtnAddServiceOption($event)" [ngModelOptions]="{standalone: true}"
                        nzPlaceHolder="Search & select service options">
                        <nz-option-group *ngFor="let group of serviceOptions" [nzLabel]="group.label">
                            <nz-option *ngFor="let item of group.items" [nzValue]="item._id" [nzLabel]="item.name">
                            </nz-option>
                        </nz-option-group>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div>
        <div nz-row [nzGutter]="{ sm: 16 }" *ngIf="addedServiceOptions && addedServiceOptions.length">
            <div nz-col [nzOffset]="12" nzSm="3" nzMd="3" class="label-item">Rate</div>
            <div nz-col nzSm="3" nzMd="3" class="label-item">Qty</div>
        </div>
        <div>

        <div *ngFor="let item of data.serviceOptions" shipment-revenue-edit-option class="service-option"
            [serviceOption]="item" (ngModelChange)="onServiceOptionUpdate($event)" (onDelete)="onDeleteServiceOption($event)"></div>
    </div>
</div>

<div class="flex top20 grand-total-row bottom20">
    <div class="flex1"></div>
    <div>Total Revenue: <span decimal [value]="data.grandTotal" default="0.0"></span></div>
</div>

<!-- <div class="flex top20 grand-total-row" *ngIf="lastCurrencyType !== 'USD'">
        <div class="flex1"></div>
        <div>USD Conversion: </div>
        <div class="grand-total">
            {{ usdConversion }}
        </div>
    </div> -->

<div class="footer flex">
    <div class="flex1"></div>
    <div class="right10">
        <button [disabled]="saving" style="width: 160px;" nz-button (click)="closeDialog()">Cancel</button>
    </div>
    <div>
        <button style="width: 160px;" nz-button nzType="primary" (click)="onSave()">
            <i *ngIf="saving" nz-icon nzType="loading" nzTheme="outline"></i>
            Save
        </button>
    </div>
</div>