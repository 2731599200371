<div class="title">{{txtCountRoutes}}</div>
<div class="shipment flex">
  <div class="flex1">{{txtCountShipments}}</div>
  <div>{{txtDate}}</div>
</div>
<!-- <div class="pallet flex">
  <div class="flex1">48 pallets</div>
  <div>1000 lbs weight</div>
</div> -->
<button nz-button nzType="primary" class="btn-confirm" 
  [nzLoading]="isLoading" [disabled]="!canConfirm" (click)="confirmRoutes()">
  Confirm all routes
</button>

<ng-template #tplConfirmDone>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <img class="ic_complete bottom20">
    <div class="bottom10" style="font-size: 18px; font-weight: 600; line-height: 27px;">All Routes Confirmed</div>
    <div class="bottom10" style="font-size: 16px; font-weight: 400; line-height: 27px;">Please see more details in the Dispatch screen.</div>
    <a *ngFor="let item of dlgConfirmHyperLinks" 
      style="align-self: flex-start;" target="_blank"
      [routerLink]="[item.hyperLinkUrl]">
      {{item.hyperLinkText}}
    </a>
    <button nz-button nzType="primary" style="width: 100%; margin-top: 16px;" (click)="closeDialogConfirmDone()">Close</button>
  </div>
</ng-template>