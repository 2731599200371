<div style="color: #0F172A; font-size: 20px; font-weight: 600;">Add New Vehicle</div>
<form nz-form class="bottom20" [formGroup]="formInput">
  <ng-container *ngFor="let key of formInputKeys">
    <div class="form-label-v2 top20">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <ng-container [ngSwitch]="key">
      <ng-container *ngSwitchCase="'vehicleType'" >
        <div vehicle-selector (valueChange)="onDropdownSelectChange(key, $event)" [quoting]="false" [withOptions]="false"></div>
      </ng-container>
      <input nz-input *ngSwitchDefault
        [formControlName]="key" [placeholder]="getPlaceHolder(key)"
        (input)="onInputChanged($event, key)" (keypress)="onInputKeyPress($event, key)" >

    </ng-container>
  </ng-container>
</form>
<div class="footer">
  <button nz-button class="right10" (click)="closeDialog()">Cancel</button>
  <button nz-button nzType="primary" (click)="onBtnSave()" [disabled]="!needUpdate">Add</button>
</div>