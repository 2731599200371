import { Component } from "@angular/core";
import { FormShipmentLocationTemplateDefault } from "../template-default";

@Component({
  selector: '[form-shipment-location-template-mobile]',
  templateUrl: './view.html',
  styleUrls: ['../template-default/style.scss']
})
export class FormShipmentLocationTemplateMobile extends FormShipmentLocationTemplateDefault {
  getLabel(key: string): string {
    switch (key) {
      case 'requiresAppointment': return '';
      default: return super.getLabel(key);
    }
  }
}