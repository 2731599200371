<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div class="dashboard-child-container no-padding twofa" *ngIf="!isLoading">
  <form class="form-detail" [formGroup]="formInput" nz-form>
    <div class="group">
      <div nz-row [nzGutter]="{ md: 32 }">
        <div nz-col nzSpan="12">
          <div class="group-left">
            <div class="description">
              {{ description }}
            </div>

            <div class="group-field">
              <div nz-row [nzGutter]="{ md: 16 }" *ngFor="let key of ['enable', 'qrcode', 'otp']">
                <ng-container *ngIf="isShow || key === 'enable'">
                  <div nz-col nzSpan="4">
                    <div class="form-label form-label-v2">{{getLabel(key)}}:</div>
                  </div>
                  <div nz-col nzSpan="20">
                    <ng-container [ngSwitch]="key">
                      <nz-form-item>
                        <nz-form-control>
                          <ng-container *ngSwitchCase="'enable'">
                            <nz-switch [formControlName]="key"
                              (ngModelChange)="onChangeEnable($event)"
                            ></nz-switch>
                          </ng-container>

                          <ng-container *ngSwitchCase="'qrcode'">
                            <img [src]="otpData?.qrcode" height="200" />
                          </ng-container>

                          <ng-container *ngSwitchCase="'otp'">
                            <input nz-input [formControlName]="key"
                              type="text" [placeholder]="getPlaceHolder(key)"
                              (input)="onInputChanged($event, key)" (keypress)="onInputKeyPress($event, key)"
                            />
                          </ng-container>
                        </nz-form-control>
                      </nz-form-item>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="group-btn">
              <nz-space nzSize="middle">
                <button *nzSpaceItem nz-button nzType="default" (click)="onBack()">
                  <i nz-icon nzType="left" nzTheme="outline"></i> Back
                </button>
                <button *nzSpaceItem nz-button nzType="primary" [disabled]="onProgress || !needUpdate" [nzLoading]="onProgress" (click)="onSubmit()">
                  <i nz-icon nzType="save" nzTheme="outline"></i> Submit
                </button>
              </nz-space>
            </div>
          </div>
        </div>

        <div nz-col nzSpan="12">
          <div class="download">
            <span>To use two-step security. You need to install the Google Authenticator app on your mobile.</span>
            <br/>
            <span>You can download it here:</span>
            <br/>
            <div class="icon">
              <nz-space nzSize="middle">
                <a *nzSpaceItem href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                  <img src="/assets/img/appstore.webp" title="Link Download Appstore">
                </a>
                <a *nzSpaceItem href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                  <img src="/assets/img/googleplay.webp" title="Link Download Android">
                </a>
              </nz-space>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
