import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { ThirdPartyInfo } from '.';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ThirdPartyInfoDialog } from './dialog';
import { Priority1Info } from './priority1';
import { ThirdPartyInfoIcon } from './icon';
import { ThirdPartyQuoteIcon } from './quote';
import { ThirdPartyQuoteScreen } from './quoting_screen';
import { FreightQuoteFTLModule } from '@app/admin/freight-quote-ftl/module';

@NgModule({
    imports: [
      CommonModule,
      NzGridModule,
      NzIconModule,
      FreightQuoteFTLModule
    ],
    declarations: [
        ThirdPartyInfo,
        ThirdPartyInfoDialog,
        Priority1Info,
        ThirdPartyInfoIcon,
        ThirdPartyQuoteIcon,
        ThirdPartyQuoteScreen
    ],
    exports: [
        ThirdPartyInfo,
        ThirdPartyInfoDialog,
        Priority1Info,
        ThirdPartyInfoIcon,
        ThirdPartyQuoteIcon,
        ThirdPartyQuoteScreen
    ],
    providers: []
})

export class ThirdPartyInfoModule {}

