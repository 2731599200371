<ng-container *ngIf="hasInfo">
  <div *ngFor="let info of infos">
    <div class="item">
      <span class="title">{{getTitle(info)}}</span>
      <span class="field-medium item-space">{{getLocationName(info)}}</span>
      <span class="field">{{getLocationAddress(info)}}</span>
      <span *ngIf="getRefNum(info)" class="field item-space">Ref. No: {{getRefNum(info)}}</span>
      <span *ngIf="getAccessCode(info)" class="field">Access Code: {{getAccessCode(info)}} </span>
      <div *ngIf="hasTimePeriods" class="item-space" style="display: flex">
        <img alt="Time Period" class="icon" src="assets/img/ic_shipment_info_date.svg" />
        <span class="time-period">{{getTimePeriods(info)}}</span>
      </div>
      <div *ngIf="hasServiceOptions(info)" class="item-space" style="display: flex">
        <img alt="Service Options" class="icon" src="assets/img/ic_shipment_info_services.svg" />
        <span style="font-size: 13px">{{getServiceOptions(info)}}</span>
      </div>
      <div class="line-divider"></div>
    </div></div
></ng-container>
