import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DialogService } from "@dialogs/dialog.service";
import { InputHelper } from "@services/input-helper";
import { ShipmentItemUnit } from "@wearewarp/types";
import { FormDataShipmentItemServices } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { Observable } from "rxjs";

@Component({
  selector: '[form-shipment-item-services]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class FormShipmentItemServices extends BaseFormDialog1<FormDataShipmentItemServices> {

  public static open(input: {qtyUnit: ShipmentItemUnit, model?: FormDataShipmentItemServices, fnSave: (data: FormDataShipmentItemServices) => Observable<any>}) {
    DialogService.openFormDialog1(FormShipmentItemServices, {
      nzComponentParams: {
        model: input.model,
        qtyUnit: input.qtyUnit,
        onSave: input.fnSave,
      },
      nzClassName: 'modal-no-padding modal-shipment-item',
    });
  }

  @Input() qtyUnit: ShipmentItemUnit;         // Nếu là TL (Truckload) thì không nhập stackable
  get isTruckLoad(): boolean {
    return this.qtyUnit == Const.ShipmentItemUnit.tl;
  }

  public onSave: (data: FormDataShipmentItemServices) => Observable<any>;
  get isCreateNew(): boolean {
    return !this.model;
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    isStackable: {label: 'Stackable', type: 'boolean', initialValue: false},
    isTemperatureControlled: {label: 'Temperature Controlled', type: 'boolean', initialValue: false},
    temperatureControlled: {label: '', type: 'formGroup', childItem: {
      tempMin: {label: 'Minimum Temperature (F)'},
      tempMax: {label: 'Maximum Temperature (F)'}
    }},
    isHazardous: {label: 'Hazardous Material', type: 'boolean', initialValue: false},
    hazardous: {label: '', type: 'formGroup', childItem: {
      un: {label: 'UN #', type: 'number'},
      packingGroup: {label: 'Packing Group'},
      qty: {label: 'Number of Units'},
      name: {label: 'Hazmat Name'},
      contact: {label: 'Emergency Phone', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
      cfr49: {label: '49CFR'},
      hazmatClass: {label: 'Hazmat Class'},
      hazmatDesc: {label: 'Hazmat Description'},
    }},
    shipRecInfo: { label: '', type: 'formGroup', childItem: {
      deviceId: {label: 'Tracking Device ID'},
    }},
  };

  public row3: FormRow = {columns: [
    {key: 'tempMin', span: 7, offset: 0},
    {key: 'tempMax', span: 7, offset: 1},
  ]}

  public rowsHazardous: Array<FormRow> = [
    {columns: [
      {key: 'un', span: 7, offset: 0},
      {key: 'packingGroup', span: 7, offset: 1},
      {key: 'qty', span: 7, offset: 1},
    ]},
    {columns: [
      {key: 'name', span: 7, offset: 0},
      {key: 'contact', span: 7, offset: 1},
      {key: 'cfr49', span: 7, offset: 1},
    ]},
    {columns: [
      {key: 'hazmatClass', span: 7, offset: 0},
      {key: 'hazmatDesc', span: 15, offset: 1},
    ]},
  ]

  get strTitle() {
    return 'Service';
  }
  get strButtonSubmit() {
    return 'Save';
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.isTruckLoad) {
      this.formGroupDeclaration.isStackable.hidden = true;
    }
    super.ngOnInit();
  }

  getCheckValueFor(key: string): boolean {
    switch (key) {
      case 'temperatureControlled': return this.formInput.get('isTemperatureControlled').value;
      case 'hazardous': return this.formInput.get('isHazardous').value;
      default: return false;
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'un':
      case 'qty':
      case 'contact':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      case 'itemValue':
        return InputHelper.handleInputKeyPressMoney(event);
      case 'tempMin':
      case 'tempMax':
        return InputHelper.handleInputKeyPressTemperature(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'un':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      case 'itemValue':
        return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
      case 'tempMin':
      case 'tempMax':
        return InputHelper.handleInputChangeTemperature(event, <FormControl>this.formInput.get(key));
      case 'contact':
        return InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onBtnSave() {
    let data: any = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  public onBtnCancel() {
    let msg = this.canQuit()
    if (msg) {
      this.confirmYesNo('Do you really want to close?', () => this.closeDialog());
    } else {
      this.closeDialog();
    }
  }

}
