<div>
    <div class="header bottom10">Create new Leg</div>
    <div nz-row [nzGutter]="{md: 32}">
        <div nz-col nzMd="12" class="bottom15">
            <div class="delivery-info-container">
                <span class="info-name-color-box PICKUP">Pickup</span>
                <div class="main-info">
                    <div class="bottom15"></div>
                    <div class="bottom10" short-location-info [info]="pickupInfo"></div>
                    <div class="">
                        <label nz-checkbox [(ngModel)]="pickupInfo.requiresAppointment">Location requires Appointment</label>
                    </div>
                </div>
                <div class="extra-info">
                    <div class="sub-title bottom5">Primary Contact</div>
                    <div class="bottom10" nz-row [nzGutter]="12">
                        <div nz-col nzXs="8">
                            <div>Name</div>
                            <div>
                                <input nz-input [(ngModel)]="pickupInfo.primaryContact.fullName" />
                            </div>
                        </div>
                        <div nz-col nzXs="8">
                            <div>Phone</div>
                            <div>
                                <nz-input-group nzCompact>
                                    <input nz-input [(ngModel)]="pickupInfo.primaryContact.phone" style="width: 70%;" />
                                    <input nz-input [(ngModel)]="pickupInfo.primaryContact.phoneExtension" placeholder="Ext" style="width: 30%;" />
                                </nz-input-group>
                            </div>
                        </div>
                        <div nz-col nzXs="8">
                            <div>Email</div>
                            <div>
                                <input nz-input [(ngModel)]="pickupInfo.primaryContact.email" />
                            </div>
                        </div>
                    </div>
                    <div class="sub-title bottom5">Instructions</div>
                    <div class="bottom10 title">
                        <textarea nz-input placeholder="Instructions"
                            [nzAutosize]="{ minRows: 1, maxRows: 10 }"
                            [(ngModel)]="pickupInfo.instructions"
                        ></textarea>
                    </div>        
                    <div class="sub-title bottom5">Note</div>
                    <div class="bottom15">
                        <textarea nz-input placeholder="Note"
                            [nzAutosize]="{ minRows: 1, maxRows: 10 }"
                            [(ngModel)]="pickupInfo.note"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div nz-col nzMd="12" class="bottom15">
            <div class="delivery-info-container">
                <span class="info-name-color-box DROPOFF">Dropoff</span>
                <div class="main-info">
                    <div class="bottom10">
                        <nz-input-group [nzSuffix]="suffixIcon">
                            <input nz-input
                                [(ngModel)]="locationInputValue"
                                [nzAutocomplete]="autoComplete1"
                                (ngModelChange)="onChangeLocationSearch($event)"
                            >
                        </nz-input-group>
                        <ng-template #suffixIcon>
                            <span nz-icon nzType="search"></span>
                        </ng-template>
                        <nz-autocomplete #autoComplete1 [nzDefaultActiveFirstOption]="false">
                            <nz-auto-option *ngFor="let item of filteredWarehouses" [nzValue]="item.name" (selectionChange)="onLocationSelected($event, item)">
                            <span class="right3" *ngIf="item.warpId">[{{item.warpId}}]</span> <span class="font-medium">{{item.name}}</span>
                            </nz-auto-option>
                            <nz-auto-option *ngIf="finalDropoff" [nzValue]="finalDropoff.value" (selectionChange)="onLocationSelected($event, finalDropoff)">[final] <span class="font-medium">{{ finalDropoff.name }}</span></nz-auto-option>
                            <nz-auto-option *ngIf="firstPickup" [nzValue]="firstPickup.value" (selectionChange)="onLocationSelected($event, firstPickup)">[original pickup] <span class="font-medium">{{ firstPickup.name }}</span></nz-auto-option>
                        </nz-autocomplete>
                    </div>
                    <div *ngIf="!dropoffInfo?.addr" class="bottom10">
                        Select a warehouse for dropoff location<span class="label-mark-required"></span>
                    </div> 
                    <div *ngIf="dropoffInfo?.addr" class="bottom10">
                        <div class="bottom10" short-location-info [info]="dropoffInfo"></div>
                    </div>
                    <div class="">
                        <label nz-checkbox [(ngModel)]="dropoffInfo.requiresAppointment">Location requires Appointment</label>
                    </div>
                </div>
                <div class="extra-info">
                    <div class="sub-title bottom5">Primary Contact</div>
                    <div class="bottom10" nz-row [nzGutter]="12">
                        <div nz-col nzXs="8">
                            <div>Name</div>
                            <div>
                                <input nz-input [(ngModel)]="dropoffInfo.primaryContact.fullName" />
                            </div>
                        </div>
                        <div nz-col nzXs="8">
                            <div>Phone</div>
                            <div>
                                <nz-input-group nzCompact>
                                    <input nz-input [(ngModel)]="dropoffInfo.primaryContact.phone" style="width: 70%;" />
                                    <input nz-input [(ngModel)]="dropoffInfo.primaryContact.phoneExtension" placeholder="Ext" style="width: 30%;" />
                                </nz-input-group>
                            </div>
                        </div>
                        <div nz-col nzXs="8">
                            <div>Email</div>
                            <div>
                                <input nz-input [(ngModel)]="dropoffInfo.primaryContact.email" />
                            </div>
                        </div>
                    </div>
                    <div class="sub-title bottom5">Instructions</div>
                    <div class="bottom15">
                        <textarea nz-input placeholder="Instructions"
                            [nzAutosize]="{ minRows: 1, maxRows: 10 }"
                            [(ngModel)]="dropoffInfo.instructions"
                        ></textarea>
                    </div>
                    <div class="sub-title bottom5">Note</div>
                    <div class="bottom15">
                        <textarea nz-input placeholder="Note"
                            [nzAutosize]="{ minRows: 1, maxRows: 10 }"
                            [(ngModel)]="dropoffInfo.note"
                        ></textarea>
                    </div>                
                </div>
            </div>
        </div>
    </div>
    <div class="bottom10 title">Shipping Items</div>
    <div class="bottom20">
        <div *ngFor="let item of selectedItems">
            <span short-item-info [item]="item"></span>
        </div>
    </div>
    <div nz-row [nzGutter]="{md: 32}">
        <div nz-col>
            <button style="width: 200px" nz-button nzType="danger" (click)="onClickCancel()">Cancel</button>
        </div>
        <div nz-col>
            <button style="width: 200px" [disabled]="!canComplete || inProgress" nz-button nzType="primary" (click)="onClickSave()">
                Save
                <i *ngIf="inProgress" nz-icon nzType="loading" nzTheme="outline"></i>
            </button>
        </div>
    </div>
</div>