<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Edit Optional Field</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div *ngFor="let key of formInputKeys">
      <div class="form-label-v2">{{getFieldLabel(key)}}<span *ngIf="isFieldRequired(key)" mark-required></span></div>
      <nz-form-item>
        <nz-form-control>
          <input nz-input [formControlName]="key" *ngIf="!isFieldMultiLine(key)"
            [placeholder]="getPlaceHolder(key)" >
          <textarea nz-input [formControlName]="key" *ngIf="isFieldMultiLine(key)"
            [placeholder]="getPlaceHolder(key)" 
            [nzAutosize]="{minRows: 2, maxRows: 4}">
          </textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>