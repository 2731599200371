import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { getDashboard } from "@services/index";
import { BaseList } from "../base/list";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@app/services/input-helper";

@Component({
  selector: 'loads-booked',
  templateUrl: 'list.html',
  styleUrls: [
    './style.scss',
    '../list.scss'
  ]
})
export class RevenueBooked extends BaseList {

  public isLoading = false;
  public dataChart = {};

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  protected getApiUrl(): string {
    return '';
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    this.getData();
  }

  private formatResponse(resp) {
    let data =resp.data.list_data || [], result = {}
    for(let item of data) {
      if(!result[item.labelDate]) result[item.labelDate] = {}
      result[item.labelDate]['date'] = item.labelDate
      result[item.labelDate][item.category.replace(/ /, '_').toLowerCase()] = item.value
    }
    return {
      ...resp,
      data: {
        count: Object.values(result).length,
        list_data: Object.values(result)
      }
    }
  }

  protected getData() {
    let url = `${Const.APIURI_STATISTIC}/revenue_booked`;
    let filter = this.queryParams.filter;
    if (!filter) {
      return;
    }
    let qs = new URLSearchParams({filter}).toString();
    url += `?${qs}`;
    this.isLoading = true;
    this.api.GET(url).subscribe(
      resp => {
        let dataChart = {
          revenue: {
            data: resp.data.list_data,
            options: {
              xField: 'labelDate',
              yField: 'value',
              seriesField: 'category',
              color: ['#1979C9', '#D62A0D']
            }
          }
        }
        this.dataChart = dataChart
        let respFormat = this.formatResponse(resp)
        this.getDataDone(respFormat);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money +'');
  };

  $formatDate = (date) => {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }
}
