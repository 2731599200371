<div *ngIf="showNetwork" transit-network [id]="_item.mainShipment.id" [showOrderDetail]="true" [compact]="true"></div>
<div *ngIf="shipmentItems && shipmentItems.length" class="font-bold bottom10">Shipping Items</div>
<div *ngIf="shipmentItems && shipmentItems.length" style="border: solid 1px #eee; background-color: white;" class="bottom10">
    <div class="flex shipping-item font-medium">
        <div style="width: 150px;">
            Code
        </div>
        <div style="width: 160px;">Name</div>
        <div style="width: 150px;">Dimension</div>
        <div style="width: 100px;">Weight</div>
        <div style="width: 100px">Inventory</div>
    </div>
    <div class="flex shipping-item" *ngFor="let x of shipmentItems" warehouse-item-shipment-item [item]="x">
    </div>
</div>
<div class="font-bold" >
    <span class="right15">Inbound PODs</span>
    <button nz-button nzSize="small" [disabled]="isUploading" (click)="onAddPodFile('BOL')">
        <i *ngIf="isUploading" nz-icon nzType="loading" nzTheme="outline"></i>
        Upload BOL
    </button>
</div>
<div *ngIf="this.inboundShipment">
    <input #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(inputFile)" [id]="'uploadPODInput-' + this.inboundShipment.id">
</div>
<div *ngIf="pods && pods.length" class="pods-wrapper ">
    <warehouse-item-pod *ngFor="let pod of pods" [pod]="pod"></warehouse-item-pod>
    <!-- </div> -->
</div>