<div class="flex batch-location-side-indicator-container" *ngIf="uiCollapseState.drop as item">
  <div batch-location-side-indicator></div>
  <div class="flex1 bottom20">
    <nz-collapse class="collapse-form-location">
      <nz-collapse-panel [nzShowArrow]="false" [nzHeader]="tplDrop" [(nzActive)]="item.isExpand">
        <ng-template #tplDrop>
          <div class="flex1 flex">
            <div class="flex1 f16m center-vertical">Delivery Details</div>
            <button *ngIf="!item.isExpand" nz-button nzSize="large" [disabled]="item.isLoading">Edit</button>
            <button *ngIf="item.isExpand" nz-button nzSize="large" [disabled]="item.isLoading" class="right10">Close</button>
            <button *ngIf="item.isExpand" nz-button nzSize="large" nzType="primary" (click)="onBtnSaveDropInfo();$event.stopPropagation();" [nzLoading]="item.isLoading">Save</button>
          </div>
        </ng-template>
        <div #formDropoff form-shipment-location type="DROPOFF" [model]="item.data" [genId]="genIdDrop"></div>
      </nz-collapse-panel>
    </nz-collapse>
    <div *ngIf="!item.isExpand" form-shipment-location-batch-location-summary [form]="formDropoff"></div>
  </div>
</div>

<div class="flex batch-location-side-indicator-container" *ngFor="let item of uiCollapseState.picks; let i = index;">
  <div batch-location-side-indicator [isDone]="false"></div>
  <div class="flex1 bottom20">
    <nz-collapse class="collapse-form-location">
      <nz-collapse-panel [nzShowArrow]="false" [nzHeader]="tplPick" [(nzActive)]="item.isExpand">
        <ng-template #tplPick>
          <div class="flex1 flex">
            <div class="flex1 f16m center-vertical">Pickup {{i + 1}}</div>
            <button *ngIf="countPickInfos > 1" nz-button nzSize="large" nzType="text" nzDanger (click)="onBtnRemovePickInfo(i);$event.stopPropagation();" class="right10">
              <span nz-icon nzType="close-square" nzTheme="outline"></span>
              Remove
            </button>
            <button *ngIf="!item.isExpand" nz-button nzSize="large" [disabled]="item.isLoading">Edit</button>
            <button *ngIf="item.isExpand" nz-button nzSize="large" [disabled]="item.isLoading" class="right10">Close</button>
            <button *ngIf="item.isExpand" nz-button nzSize="large" nzType="primary" (click)="onBtnSavePickInfo(i);$event.stopPropagation();" [nzLoading]="item.isLoading">Save</button>
          </div>
        </ng-template>
        <div #formPickup form-shipment-location type="PICKUP" [model]="item.data"></div>
        <div class="list-items-header">
          <div>Items</div>
          <button nz-button nzType="link" (click)="onBtnUploadListItems(i)">+ Upload Item List (excel/csv)</button>
        </div>
        <div #formItems form-order-items [model]="item.data"></div>
      </nz-collapse-panel>
    </nz-collapse>
    <div *ngIf="!item.isExpand" form-shipment-location-batch-location-summary [form]="formPickup"></div>
  </div>
</div>

<button nz-button nzSize="large" (click)="onBtnAddPickInfo()" style="margin-left: 40px;">Add Another Pickup Location</button>
