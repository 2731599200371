import { Log } from "@services/log";
import { LngLat, LngLatBounds, LngLatLike } from "mapbox-gl";

export class MapBounds {
  private bounds: LngLatBounds = new LngLatBounds();
  private map;
  addTo(map) {
    this.map = map;
    return this
  }

  addCoordinates(coordinates: LngLatLike | LngLatLike[]) {
    if (Array.isArray(coordinates)) {
      coordinates.map(coordinate => this.bounds.extend(coordinate));
    }
    else {
      this.bounds.extend(coordinates)
    }
    return this;
  }

  reset() {
    this.bounds = new LngLatBounds();
    return this
  }

  fitBounds() {
    if(this.bounds.isEmpty()) return this;
    this.map?.fitBounds(this.bounds, { padding: 150, maxZoom: 15 });
    return this;
  }
}