import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { MapService } from "./map.service";
import { Problem } from "./objects/problem";
import { ShipmentManagement } from "./shipmentManagement";

@Injectable()
export class ProblemManagement {
  private problemId;
  private problemInstance: Problem;
  public problemSubject: ReplaySubject<Problem> = new ReplaySubject(1);
  private inManualRouting: Map<string, boolean> = new Map(); //sử dụng để lưu tạm các route đang trong quá trình manualRoute

  constructor(
    private shipmentManagement: ShipmentManagement,
    private mapService: MapService
  ) {
  }

  async initProblem(problemId?) {
    this.problemId = problemId;
    this.problemInstance = new Problem(problemId);
    // this.problemInstance.setId(problemId);
    await this.reloadProblem();
    this.problemSubject.next(this.problemInstance);
  }

  // getProblem() {
  //   return this.problemInstance;
  // }

  // getProblemId() {
  //   return this.problemId;
  // }

  async reloadProblem() {
    this.mapService.reset();
    this.routes.clear();
    await this?.problemInstance.init();
    this.shipmentManagement.reset().addShipments(this.problemInstance.getShipments())
  }

  routes: Map<string, number> = new Map();
  getRouteIndex(routeId) {
    if (this.routes.has(routeId)) return this.routes.get(routeId);
    let newIndex = this.routes.size + 1;
    this.routes.set(routeId,newIndex);
    return newIndex
  }

  problemInManualRouting;
  setInManualRoute(subProblemId){
    this.problemInManualRouting = subProblemId;
  }
  getInManualRoute(subProblemId){
    if(this.problemInManualRouting == subProblemId){
      this.problemInManualRouting = undefined
      return true;
    }
  }

}