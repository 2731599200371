import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { DeliveryMap, Utils } from "../../components/map";
import { Const } from "@const/Const";

@Component({
    selector: '[warehouse-map]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class WarehouseMap extends BaseComponent {
    map: DeliveryMap
    loading = false
    warehouses: any[] = []
    @Input() warehouseType: string = '*'
    @Input() onCloseModal: () => void = () => {};

    ngOnInit(): void {
        this.loadWarehouses()
    }

    loadWarehouses() {
        const url = Const.APIURI_WAREHOUSES + '?limit=-1' + (this.warehouseType === '*' ? '' : `&filter=${JSON.stringify({warehouseType: this.warehouseType})}`)
        this.loading = true

        this.api.GET(url).subscribe((res) => {
            this.warehouses = res.data.list_data.filter(it => it.pickAddr?.metadata?.longitude)
            this.map?.loadWarehouses(this.warehouses)
            const locations = res.data.list_data.filter(it => it.pickAddr?.metadata?.longitude).map(it => Utils.getAdressLatLng(it.pickAddr, false))
            this.map?.refresh()
            this.map?.fitBounds(locations)
            this.map?.resize()
        })
    }

    onSelectWarehouse(wh) {
        this.onCloseModal();
        return this.router.navigate([Const.routeAdminCrossdockWarehouseJob], { queryParams: { warehouseId: wh.id } });
    }
}