import { ApiService } from "@services/api.service";
import { BizUtil } from "@services/biz";
import { getInjector } from "@services/index";
import { Log } from "@services/log";
import { ReplaySubject, Subject } from "rxjs";
import { ModelRoutingProblem, ProblemRoutingState, RoutingRegionSelectMode } from "../../interface";
import { MapService } from "../map.service";
import { RoutingService } from "../routing.service";
import { Route } from "./route";
import { SubProblem } from "./subProblem";

export class Problem {
  private routingService: RoutingService;
  private mapService: MapService;
  private problemId?: string;
  private data: ModelRoutingProblem;
  private children: SubProblem[] = [];
  public isLoading: Subject<boolean> = new Subject();
  public dataSubject: ReplaySubject<ModelRoutingProblem> = new ReplaySubject(1);


  constructor(problemId?: string) {
    const injector = getInjector();
    this.routingService = injector.get(RoutingService);
    this.mapService = injector.get(MapService)
    this.problemId = problemId;
    // this.init();
  }

  setId(problemId) {
    this.problemId = problemId;
  }
  getId() {
    return this.problemId
  }
  async init() {
    if (!this.problemId) {
      this.dataSubject.next({});
      return;
    }
    this.isLoading.next(true);
    this.data = await this.routingService.getProblem(this.problemId);
    Log.d("problem", this.data)
    if (!this.data) {
      this.isLoading.next(false);
      this.dataSubject.next({})
      return
    }
    this.children = this.data?.children?.map(sub => new SubProblem(sub));
    this.isLoading.next(false);
    this.dataSubject.next(this.data);
  }
  async destroy() {
    this.children.map(child => child.destroy())
  }

  getData() {
    return this.data;
  }
  getSelectRegionMode(): RoutingRegionSelectMode {
    return this.data?.selectRegionMode
  }
  getShipments(): any[] {
    return this.data?.shipments || []
  }

  getSubProblem() {
    return this.children || []
  }

  getRouteCount() {
    let count = 0;
    this.children.map(sub => {
      count += sub.getRoutes().size
    });
    return count;
  }

  isInProcess() {
    for (let child of this.children) {
      if (child.getStatus() !== 'ROUTING_COMPLETE') return true;
    }
    return false;
  }

  countShipmentRouted() {
    return this.getShipmentRouted().length
  }

  /**
   * retunr shipmentIds
   * @returns 
   */
  getShipmentRouted() {
    let shipmentIds = []
    for (let child of this.children) {
      shipmentIds = [
        ...shipmentIds,
        ...child.getShipmentRouted()
      ]
    }
    return Array.from(new Set(shipmentIds));
  }

  /**
   * Lấy tất cả các shipments đã được tạo region
   * @returns 
   */
  getShipmentHasRegion() {
    let shipmentIds = []
    for (let child of this.children) {
      shipmentIds = [
        ...shipmentIds,
        ...child.getAllShipmentIds()
      ]
    }
    return Array.from(new Set(shipmentIds));
  }

  getShipmentNoRegion() {
    const shipmentHasRegion = this.getShipmentHasRegion();
    const allShipmentIds = this.getShipments().map(shipment => shipment.id);
    //kiểu dữ liệu của shipmentId trong trường hợp này là string??? nên phải ép kiểu sang string
    //nếu đổi về number sẽ lỗi backend
    return allShipmentIds.filter(shipmentId => !shipmentHasRegion.includes(String(shipmentId)));
  }


  getStatus() {
    const countAllShipments = this.getShipments().length;
    const countShipmentHasBeenRouted = this.countShipmentRouted();
    if (this.data?.selected_solution) {
      return ProblemRoutingState.done;
    } else if (this.isInProcess()) {
      return ProblemRoutingState.inProgress;
    }
    else if (countAllShipments == countShipmentHasBeenRouted) {
      return ProblemRoutingState.didRouteAll;
    }
    //trường hợp route nhưng không hết các shipment thì tạm thời return về inProgress
    return ProblemRoutingState.inProgress
  }

  getRoutes() {
    const subProblems = this.getSubProblem();
    let routes: Route[] = [];
    subProblems.map(subProblem => {
      routes = [...routes, ...Array.from(subProblem.getRoutes().values())]
    });
    return routes;
  }

  addToMap() {
    this.mapService.removeLines();
    const routes = this.getRoutes();
    if (routes.length) {
      routes.map(route => route.addToMap());
      return;
    }
  }
}