import { BaseFormItem } from '@app/admin/base/form-item';
import { Component, Input } from "@angular/core";
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { Const } from '@const/Const';

@Component({
  selector: '[warehouse-task-setting]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../../styles/row-col.scss', '../../../../styles/form-v2.scss']
})
export class WarehouseTaskSetting extends BaseFormItem {
  @Input() closeDialog: (refreshShipment: boolean) => void;
  @Input() warehouseId: string;
  @Input() type: string;
  public taskSettings = [];
  public isLoading: boolean = true;

  protected formGroupDeclaration: FormGroupDeclaration = {
    inbound: {label: 'Inbound', type: 'formArray', hidden: true, initialValue: [{}], childItem: { 
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false, readOnly: true, submitReadOnly: true },
        item: { label: 'Item', type: 'boolean', initialValue: false, readOnly: true },
      }},
    }},
    // sorting: {label: '', type: 'formArray', initialValue: [{}], childItem: { 
    //   taskType: {label: 'Task Type', inputType: 'hidden'},
    //   levels: {label: 'Level', type: 'formGroup', childItem: {
    //     shipment: { label: 'Shipment', type: 'boolean', initialValue: false },
    //     item: { label: 'Item', type: 'boolean', initialValue: false },
    //   }},
    // }},
    outbound: {label: 'Outbound', type: 'formArray', hidden: true, initialValue: [{}], childItem: { 
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false, readOnly: true },
        item: { label: 'Item', type: 'boolean', initialValue: false, readOnly: true },
      }},
    }}
  }

  async ngOnInit() {
    const taskSettings = await this.getWarehouseTasks();
    this.formGroupDeclaration[this.type].hidden = false;
    if(taskSettings.length) {
      super.ngOnInit();
    }
  }

  private async getWarehouseTasks() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE/warehouse-tasks`)
    const response = await this.api.GET(url).toPromise();
    const value = response.value || '[]';
    const settings = JSON.parse(value);
    let inboundTasks = [];
    // let sortingTasks = [];
    let outboundTasks = [];
    for(let setting of settings) {
      const levels = setting.levels;
      const levelObj = {}
      for(let level of levels) {
        levelObj[level] = false;
      }
      if(setting.stages.includes(WarpConst.WarehouseTaskStage.inbound)) {
        inboundTasks.push({
          taskType: setting.type,
          levels: levelObj
        })
      }
      // if(setting.stages.includes(WarpConst.WarehouseTaskStage.sorting)) {
      //   sortingTasks.push({
      //     taskType: setting.type,
      //     levels: levelObj
      //   })
      // }
      if(setting.stages.includes(WarpConst.WarehouseTaskStage.outbound)) {
        outboundTasks.push({
          taskType: setting.type,
          levels: levelObj
        })
      }
    }
    const taskSettings = [];
    if(this.type === WarpConst.WarehouseTaskStage.inbound) {
      taskSettings.push({ type: WarpConst.WarehouseTaskStage.inbound, tasks: inboundTasks });
    }
    if(this.type === WarpConst.WarehouseTaskStage.outbound) {
      taskSettings.push({ type: WarpConst.WarehouseTaskStage.outbound, tasks: outboundTasks });
    }
    this.taskSettings = taskSettings;

    this.isLoading = false;
    return taskSettings;
  }

  getTaskName(type, index, key) {
    const labels = {
      uploadBOL: "Upload BOL",
      uploadProductPhoto: "Upload Product Photo",
      scanPallet: "Scan Pallet",
      addWeight: "Add Weight"
    }
    const value = this.getItemValue(`${type}[${index}].${key}`)
    return labels[value] || '';
  }

  public getLevels(mode, index, key) {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    const tasks = mode.tasks;
    const task = tasks.find(it => it.taskType === value);
    const levels =  task?.levels || {}
    return Object.keys(levels);
  }

  protected beforeBindModel(model): void {
    const warehouseTasks = model.warehouseTasks || [];
    if(warehouseTasks.length) {
      const warehouseTasks = model.warehouseTasks || [];
      let inboundTasks = {};
      // let sortingTasks = {};
      let outboundTasks = {};
      for(let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
        const { tasks = [] } = this.taskSettings.find(it => it.type === key) || {};
        if(key === WarpConst.WarehouseTaskStage.inbound) {
          for(let task of tasks) {
            inboundTasks = this.getTasks(task, inboundTasks, false)
          }
        }
        // if(key === WarpConst.WarehouseTaskStage.sorting) {
        //   for(let task of tasks) {
        //     sortingTasks = this.getTasks(task, sortingTasks, false)
        //   }
        // }
        if(key === WarpConst.WarehouseTaskStage.outbound) {
          for(let task of tasks) {
            outboundTasks = this.getTasks(task, outboundTasks, false)
          }
        }
      }
      for(let task of warehouseTasks) {
        if(task.stage === WarpConst.WarehouseTaskStage.inbound) {
          inboundTasks = this.getTasks(task, inboundTasks)
        }
        // if(task.stage === WarpConst.WarehouseTaskStage.sorting) {
        //   sortingTasks = this.getTasks(task, sortingTasks)
        // }
        if(task.stage === WarpConst.WarehouseTaskStage.outbound) {
          outboundTasks = this.getTasks(task, outboundTasks)
        }
      }

      if(this.type === WarpConst.WarehouseTaskStage.inbound) {
        model.inbound = Object.values(inboundTasks);
      }
      // model.sorting = Object.values(sortingTasks);
      if(this.type === WarpConst.WarehouseTaskStage.outbound) {
        model.outbound = Object.values(outboundTasks);
      }
    }
    return model
  }

  private getTasks(task, obj, value = true) {
    if(!obj[task.taskType]) {
      let levels = {} 
      levels[task.level] = value;
      obj[task.taskType] = {
        taskType: task.taskType,
        levels: levels
      }
    }
    else {
      const levels = obj[task.taskType]['levels'];
      if(!levels[task.level]) {
        let levels = obj[task.taskType]['levels'];
        levels[task.level] = value;
        obj[task.taskType]['levels'] = levels;
      }
    }

    return obj;
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let data: any = super.getFormData_JSON(isCreateNew);
    const settings = { 
      inbound: data.inbound ? data.inbound : this.model.inbound,
      // sorting: data.sorting ? data.sorting : this.model.sorting,
      outbound: data.outbound ? data.outbound : this.model.outbound
    }
    let warehouseTasks = [];
    for(let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
      const tasks = settings[key] || [];
      if(!tasks.length) continue;
      const taskMapped = [];
      for(let task of tasks) {
        const levels = task.levels || {};
        if(Object.values(levels).length) {
          for(let k of ['shipment', 'item']) {
            if(levels[k]) {
              taskMapped.push({
                taskType: task.taskType, 
                level: k,
                stage: key,
                warehouseId: this.warehouseId
              });
            }
          }
        }
      }
      warehouseTasks = [...warehouseTasks, ...taskMapped];
    }

    return {
      warehouseTasks
    };
  }

  onBtnSave() {
    this.startProgress();
    const data: any = this.getFormData_JSON(true);
    let oldWarehouseTasks = this.model?.warehouseTasks || [];
    let otherTasks = oldWarehouseTasks.filter(it => it.warehouseId !== this.warehouseId);
    let typeTasks = oldWarehouseTasks.filter(it => it.warehouseId === this.warehouseId && it.stage !== this.type);
    let params = { warehouseTasks: [...otherTasks, ...typeTasks, ...(data.warehouseTasks || [])] }

    this.api.PUT(Const.APIV2(`${Const.APIURI_SHIPMENTS}/${this.model?.id}`), params).subscribe(
      (resp) => {
        this.stopProgress();
        this.closeDialog(true);
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  onCancel() {
    this.closeDialog(false);
  }
}