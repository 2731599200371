import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

import { DetailModule } from '@app/admin/base/detail.module';
import { SettingVersion } from '.';
import { SettingVersionFile } from './components/app-file';
import { SettingVersionFileHistory } from './components/file-histories';
import { NzAlertModule } from 'ng-zorro-antd/alert';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    NzTableModule,
    NzCollapseModule,
    NzAlertModule,
    DetailModule,
  ],
  declarations: [
    SettingVersion,
    SettingVersionFile,
    SettingVersionFileHistory,
  ],
  exports: [
    SettingVersion,
    SettingVersionFile,
    SettingVersionFileHistory,
  ],
  providers: [
  ]
})
export class SettingVersionModule {}