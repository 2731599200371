import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { WarehouseTaskSetting } from '.';
import { WarehouseTaskSettingDialog } from './dialog';
import { WarehouseTaskSettingIcon } from './icon';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzInputModule,
    NzButtonModule,
    NzFormModule,
    NzGridModule,
    NzIconModule
  ],
  declarations: [
    WarehouseTaskSetting,
    WarehouseTaskSettingDialog,
    WarehouseTaskSettingIcon
  ],
  exports: [
    WarehouseTaskSetting,
    WarehouseTaskSettingDialog,
    WarehouseTaskSettingIcon
  ],
  providers: []
})

export class WarehouseTaskSettingModule { }