<div class="dashboard-child-container user-detail no-padding" [ngClass]="{'m': isMobile}">
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">
    <div class="group">
      <div class="tdm-row cell-spacing">
        <div class="tdm-col" *ngFor="let key of formInputKeys">
          <div>
            <div class="form-register-label">
              {{getLabel(key)}}
              <i *ngIf="key=='pw'" nz-icon nzType="question-circle"
                style="font-size: 12px; margin-left: 3px; position: relative; top: -2px;"
                nzTheme="fill" nzTooltipPlacement="top"
                nzTooltipOverlayClassName="tooltip-info"
                nzTooltipColor="#334155"
                [nzTooltipTitle]="pwPolicy"
                nz-tooltip nz-typography
              ></i>
              <span *ngIf="isRequired(key)" class="label-mark-required"></span>
            </div>
            <nz-form-item class="form-register-value">
              <nz-form-control>
                <ng-container [ngSwitch]="key">
                  <nz-select nzBackdrop="true" *ngSwitchCase="'roleIds'" style="width: 100%" [formControlName]="key" nzAllowClear nzMode="multiple" (ngModelChange)="onRoleChange()">
                    <nz-option *ngFor="let role of allRoles" [nzLabel]="getRoleName(role)" [nzValue]="role._id"></nz-option>
                  </nz-select>

                  <nz-select nzBackdrop="true" *ngSwitchCase="'orgId'" 
                    style="width: 100%" [formControlName]="key" 
                    nzAllowClear [nzLoading]="isLoadingOrganization"
                  >
                    <nz-option *ngFor="let item of organizations" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
                  </nz-select>

                  <nz-input-group *ngSwitchCase="'pw'" [nzSuffix]="tplShowPw">
                    <input [type]="passwordVisible ? 'text' : 'password'" nz-input [formControlName]="key" [placeholder]="txtHintPw"/>
                  </nz-input-group>

                  <ng-template #tplShowPw>
                    <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                  </ng-template>

                  <select-by-searching *ngSwitchCase="'clientId'"
                    [version]="2" type="client" [showSubClient]="true"
                    [formControlName]="key"
                    [placeholder]="getPlaceHolder(key)"
                    [(listData)]="listClients">
                  </select-by-searching>

                  <select-by-searching *ngSwitchCase="'carrierId'"
                    [version]="2"
                    [formControlName]="key" type="carrier"
                    [placeholder]="getPlaceHolder(key)"
                    [(listData)]="listCarriers">
                  </select-by-searching>

                  <!-- nzMode="multiple" causes nzLoading not work -->
                  <nz-select nzBackdrop="true" *ngSwitchCase="'warehouseIds'"
                    nzShowSearch nzAllowClear [nzLoading]="true" nzMode="multiple"
                    [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key">
                    <nz-option *ngFor="let warehouse of allWarehouses" [nzLabel]="getWarehouseName(warehouse)" [nzValue]="warehouse.id"></nz-option>
                  </nz-select>

                  <nz-select nzBackdrop="true" *ngSwitchCase="'driverId'"
                    nzShowSearch nzAllowClear [nzLoading]="isLoadingDriver"
                    [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key">
                    <nz-option *ngFor="let item of allDrivers" [nzLabel]="getDriverDesc(item)" [nzValue]="item.id"></nz-option>
                  </nz-select>

                  <input *ngSwitchDefault nz-input
                    [formControlName]="key"
                    [type]="getInputType(key)"
                    (input)="onInputChanged($event, key)"
                    (keypress)="onInputKeyPress($event, key)"
                  >
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isClientUser && !isEditing">
        <div style="margin-bottom: 16px;">
          <a [href]="" (click)="generateClientStagingKey()" *ngIf="!clientStagingKey && !isCreateNew" style="margin-top: 10px; text-decoration: underline;">Create staging key <i *ngIf="isStagingKeyLoading" style="padding-left: 10px;" nz-icon nzType="loading"></i></a>
          <div *ngIf="clientStagingKey">
            <span style="overflow-wrap: break-word;" nz-typography>
              Staging key: {{displayClientStagingKey()}}
              <span *ngIf="!isStagingKeyLoading">
                <i nz-icon style="cursor: pointer;margin-left: 16px;" nzType="eye" nzTheme="outline" [nz-tooltip]="getVisiableClientStagingKeyToolTip()" (click)="onChangeClientStagingKeyVisiable()"></i>
                <i nz-icon style="margin-left: 16px; cursor: pointer;" nzType="copy" nzTheme="outline" nz-tooltip="Copy staging key" (click)="copyClientStagingKey()"></i>
                <i nz-icon style="margin-left: 16px; cursor: pointer;" nzType="reload" nzTheme="outline" nz-tooltip="Re-generate staging key" (click)="regenerateClientStagingKey()"></i>
                <i nz-icon style="margin-left: 16px; cursor: pointer; color: red;" nzType="delete" nzTheme="outline" nz-tooltip="Delete staging key" (click)="deleteClientStagingKey()"></i>
              </span>
              <i *ngIf="isStagingKeyLoading" style="padding-left: 10px;" nz-icon nzType="loading"></i>
            </span>
          </div>
        </div>
      </ng-container>
      <!-- <ng-container *ngIf="isClientUser && !isEditing && isAdmin">
        <div style="margin-bottom: 16px;">
          <span *ngIf="!clientDeveloperApiKey" style="margin-top: 10px;" nz-typography>Generate new developer <a [href]="" (click)="generateDeveloperApiKeyForClient()">API Key here. </a>  <i *ngIf="isApiKeyLoading" nz-icon nzType="loading"></i></span>
          <ng-container *ngIf="clientDeveloperApiKey">
            <div class="tdm-row cell-spacing">
              <div class="tdm-col">
                <span style="overflow-wrap: break-word;" nz-typography>
                  Developer API key: {{clientDeveloperApiKey}}
                  <i nz-icon style="margin-left: 16px;" nzType="copy" nzTheme="outline" nz-tooltip="Copy API key" (click)="copyDeveloperApiKey()"></i>
                  <i nz-icon style="margin-left: 16px;" nzType="reload" nz-tooltip="Re-generate API key" (click)="regenerateDeveloperApiKey()"></i>
                  <i nz-icon style="margin-left: 16px; color: red;" nzType="delete" nz-tooltip="Delete API key" (click)="deleteDeveloperApiKey()"></i>
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container> -->
      <div *ngIf="!isCreateNew && isMyAccount">
        <span nz-typography>Note: You can turn on/off 2FA(Two Factor Authentication) at the this <a [href]="routeSetting2FA" target="_blank">link</a></span>
      </div>

    </div>

    <!-- Button save & cancel -->
    <div class = "footer-btn">
      <button *ngIf="shouldShowHistory" nz-button (click)="goToHistory(model, routeAdminUserList)">
        <i nz-icon nzType="history" nzTheme="outline"></i>
        View Changes History
      </button>
      <div *ngIf="requirePermissions([PermissionCode.user.create, PermissionCode.user.update])" detail-footer-buttons labelButtonCreate="Create New User"
        [isCreateNew]="isCreateNew" [isEditing]="isEditing"
        [onProgress]="onProgress" [needUpdate]="needUpdate"
        (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()">
      </div>
    </div>
  </form>
</div>
