import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from "ng-zorro-antd/icon";
import { BatchLocationSideIndicator } from '.';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
  ],
  declarations: [
    BatchLocationSideIndicator,
  ],
  exports: [
    BatchLocationSideIndicator,
  ]
})
export class BatchLocationSideIndicatorModule {}