<div class="dashboard-child-container no-padding list">
  <div class="list-header" style="align-items: end;">
    <button *ngIf="requirePermissions([PermissionCode.routing_ltl.create])" nz-button (click)="onBtnAdd()" style="height: 32px;"><i nz-icon nzType="plus" nzTheme="outline"></i>New routing problem</button>
  </div>
  <div class="list-body">
    <nz-table 
      #nzTable nzBordered="true"
      [nzData]="listData" nzSize="small"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th>Problem ID</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="_created">Created</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="dropoff_earliest_ts">Delivery Date</th>
          <th>Clients</th>
          <th>Shipments</th>
          <th colspan="2">Solutions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a [routerLink]="[routeRoutingLTLProblems, item.id]">{{item.id}}</a>
          </td>
          <td>{{getCreatedDate(item)}}</td>
          <td>{{getDeliveryDate(item)}}</td>
          <td>
            <div *ngIf="shouldShowListClient(item)" class="arr-client">
              <a *ngFor="let client of item.clients" class="link" target="_blank"
                [routerLink]="[routeAdminClientList, client?.id]">{{client?.name}}</a>
            </div>
          </td>
          <td>
            <a *ngIf="shouldShowShipmentIds(item)" (click)="seeAllOrder(item)" class="link">{{getShipmentIds(item)}}</a>
            <div *ngIf="!shouldShowShipmentIds(item)">{{item.num_shipments}}</div>
          </td>
          <td class="border-right-none">
            <span *ngIf="item.selected_solution" style="display: inline-block; margin-right: 5px; margin-bottom: 5px; color: green;">
              <span nz-icon nzType="check" nzTheme="outline"></span> Selected
            </span>
          </td>
          <td class="actions w50">
            <button *ngIf="!isAdminReadOnlyRole" style="float: right;"
              nz-button nzType="text" nzShape="circle" nzDanger [nzLoading]="item.loading"
              (click)="onBtnDelItem(item)">
              <i nz-icon nzType="delete" nzTheme="outline"></i>
            </button>
          </td>
        </tr>
      </tbody>

    </nz-table>
  </div>
</div>