<div class="box-container">
  <div (click)="showBigMap()" class="expand-button">
    <div class="expand-button-background">
      <span nz-icon nzType="fullscreen" nzTheme="outline" class="expand-button-icon"> </span>
    </div>
  </div>

  <div #mapElm id="map-container" class="batch-map"></div>
  <batch-big-map-modal
    [isVisible]="isVisibleModal"
    (visibleChange)="visibleModalChange()"
    [dropOffStop]="dropOffStop"
    [pickUpStop]="pickUpStop"
    [otherStop]="otherStop"
    [routeData]="routeData"
  ></batch-big-map-modal>
</div>
