<form #form [formGroup]="formInput" nz-form>
  <div class="form-inner-box">
    <ng-container *ngFor="let key of formInputKeys">
      <ng-container [ngSwitch]="key">
        <div class="form-item" [ngClass]="key">
          <div class="form-label-v2">
            {{getLabel(key)}}
            <span *ngIf="isRequired(key)" class="label-mark-required"></span>
          </div>
          <div *ngSwitchCase="'fromDate'">
            <nz-form-item>
              <nz-form-control>
                <nz-date-picker
                  [formControlName]="key" nzFormat="yyyy-MM-dd"
                  (ngModelChange)="onChange($event, key)">
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngSwitchCase="'toDate'">
            <nz-form-item>
              <nz-form-control>
                <nz-date-picker
                  [formControlName]="key" nzFormat="yyyy-MM-dd"
                  (ngModelChange)="onChange($event, key)">
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>
