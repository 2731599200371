import { Component, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { AddressUSSmartyMetadata } from "@wearewarp/types/data-model";
@Component({
  selector: '[warehouse-form]',
  templateUrl: './warehouse-form.html',
  styleUrls: ['./style.scss', '../../dialogs/dialogs.scss', '../../../styles/form-v2.scss', '../../../styles/time-window.scss']
})
export class WarehouseForm extends BaseFormDialog1 {
  client: any;  // đi từ màn customer
  isHistoryExisting: boolean = false
  get isComeFromClientScreen(): boolean {
    return !!this.client;
  }
  listClients = [];
  addressType = Const.AddressTypeArray;
  serviceOptions = [
    {label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup},
    {label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery},
    {label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition},
  ]
  listTimezones = DateUtil.listTimezones;
  formAddressBindData;

  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: 'Location name', required: true },
    isHazmatLocation: { label: 'Hazmat Location', type: 'boolean' },
    clientIds: {label: 'Customer', required: true},
    addressType: { label: 'Address Type: ' },
    pickAddr: { label: 'Address', required: true },
    serviceOptions: { label: 'Service Options', type: 'array', initialValue: [] },
    accessCode: { label: 'Access Code' },
    operatingHours: { label: 'Operating Hours', type: 'formArray', childItem: {
      isOpen: { label: '', type: 'boolean' },
      fromTime: { label: '', getValue: DateUtil.getHHmm, isChanged: DateUtil.diffHHmm, formatValue: DateUtil.fromHHmm, placeHolder: 'From time' },
      toTime: { label: '', getValue: DateUtil.getHHmm, isChanged: DateUtil.diffHHmm, formatValue: DateUtil.fromHHmm, placeHolder: 'To time' },
    }, initialValue: [
      { isOpen: true }, // monday
      { isOpen: true }, 
      { isOpen: true }, 
      { isOpen: true }, 
      { isOpen: true }, 
      { isOpen: true }, 
      { isOpen: false } // sunday
    ]},
    requireAppointment: {label: 'This location requires appointment', type: 'boolean'},
    phone: { label: 'Phone Number', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone },
    email: { label: 'Email', validators: Validators.email },
    deliveryInstructions: { label: 'Location instructions',  },
    note: { label: 'Notes' },
  }

  public row1: FormRow = {columns: [
    {key: 'name', span: 16, offset: 0},
    {key: 'isHazmatLocation', span: 7, offset: 1},
  ]};
  public dayLables = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  public row5: FormRow = {columns: [
    {key: 'phone', span: 11, offset: 0},
    {key: 'email', span: 11, offset: 2},
    {key: 'deliveryInstructions', span: 24, offset: 0},
    {key: 'note', span: 24, offset: 0},
  ]};

  get serviceOptionsSelectDesc() {
    let count = this.formInput?.get('serviceOptions')?.value?.length ?? 0;
    return `${count} ${count > 1 ? 'options' : 'option'}`;
  }

  get shouldShowClient(): boolean {
    return this.isAdmin && !this.isComeFromClientScreen;
  }

  get formTitle() {
    let str = '';
    if (!this.model) {
      str = 'Create new location';
    } else {
      str = 'Update location';
    }
    if (this.client) {
      str += ` for customer <b>${this.client.name}</b>`;
    }
    return str;
  }
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.client) {
      this.formGroupDeclaration.clientIds.hidden = true;
    }
    this.fetchData()
    super.ngOnInit();
  }

  fetchData(){
    if(!this.model) return;
    let url = `${Const.APIURI_AUDIT_LOGS}/one?entity=warehouses&objectId=${this.model._id}`;
    this.api.GET(url).subscribe(
      resp => {
        this.isHistoryExisting = Utils.isObjectNotEmpty(resp?.data);
      }, err => {
        // skip
      }
    );
  }
  getTextDayOpen(index: number): string {
    let isOpen = this.getItemValue(`operatingHours[${index}].isOpen`);
    return isOpen ? 'Open' : 'Closed';
  }
  
  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSES;
  }
  
  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(true); // always get full data
    if (isCreateNew && this.client) {
      json.clientIds = [this.client.id];
    }
    return json;
  }

  protected beforeBindModel(model): any {
    if (model.client) {
      model.clientIds = [model.client.id];
      this.listClients = [model.client];
    }
    return model;
  }

  protected afterBindModel(): void {
    this.onAddressVerified(this.model.pickAddr?.metadata);
  }

  isMultiLines(key: string): boolean {
    switch (key) {
      case 'note':
      case 'deliveryInstructions':
        return true;
      default:
        return false;
    }
  }

  isDisableServiceOptionItem(item): boolean {
    let arr = this.getItemValue('serviceOptions');
    for (let obj of arr) {
      if (obj.name == item) {
        return true;
      }
    }
    return false;
  }

  removeServiceOption(index: number) {
    let arr = this.formInput.get('serviceOptions').value;
    let newArr = [];  // create a new array to make form recognize the change and UI is updated automatically
    for (let i = 0; i < arr.length; i++) {
      if (i == index) {
        continue;
      }
      newArr.push(arr[i]);
    }
    this.formInput.get('serviceOptions').setValue(newArr);
  }

  public timezone = '';
  onAddressVerified(data: AddressUSSmartyMetadata) {
    if (!data) return;
    this.timezone = data.timeZone;
    if (data.rdi == 'Residential') {
      this.setItemValue('addressType', Const.AddressType.residential);
    } else if (data.rdi == 'Commercial') {
      this.setItemValue('addressType', Const.AddressType.commercial);
    }
  }
  
  goToHistory(){
    this.closeDialog()
    this.router.navigate([this.routeAdminLocationList, 'history', this.model._id], {queryParams: {entity: "warehouses"}});
  }

}