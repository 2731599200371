<form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
  <div style="display: flex; flex-wrap: wrap;">
    <div class="form-item">
      <search-box #searchBox (doSearch)="doSearch($event)" [placeHolder]="txtHintSearch" [searchKeyword]="searchKeyword" style="width: 100%;"></search-box>
    </div>

    <ng-container *ngFor="let key of ['warehouseType', 'orgId']">
      <div class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <ng-container [ngSwitch]="key">
          <nz-select 
            class="searchbox" *ngSwitchCase="'warehouseType'" style="width: 120px;"
            [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" 
            (ngModelChange)="onChange($event, key)"
          >
            <nz-option nzLabel="All" nzValue=""></nz-option>
            <nz-option *ngFor="let item of warehouseTypes" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
          </nz-select>

          <nz-select 
            class="searchbox" *ngSwitchCase="'orgId'" style="width: 120px;"
            [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" 
            (ngModelChange)="onChange($event, key)" [nzLoading]="isLoadingOrganization"
          >
            <nz-option *ngFor="let item of organizations" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
          </nz-select>
        </ng-container>
      </div>
    </ng-container>

    <div class="form-item">
      <span nz-icon nzType="global" nzTheme="outline" (click)="showMap($event)"></span>
    </div>
  </div>
</form>