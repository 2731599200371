import { Component, Input, ViewChild } from "@angular/core";
import { TaskType } from "@wearewarp/types"
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { Log } from "@services/log";
import { FormShipmentLocation } from "../../../forms/shipment-location";
import { FormOrderItems } from "../../../forms/order-items";
import { ValidateOptions } from "@app/admin/base/form-base";
import { ValidationErrors } from "@angular/forms/forms";
import { Utils } from "@services/utils";

@Component({
  selector: 'edit-shipment-entry-add-location',
  templateUrl: './index.html',
  styleUrls: []
})
export class EditShipmentEntryAddLocation extends BaseDialog {

  @ViewChild('formLocation') formLocation: FormShipmentLocation;
  @ViewChild('formItems') formItems: FormOrderItems;

  @Input() type: TaskType;
  public onSave: (data) => void = () => {}

  ngOnInit(): void {
    super.ngOnInit();
  }

  onBtnSave() {
    if (this.validate({showDialogMessage: true, scrollToErrorField: true})) {
      return;
    }
    const deliveryInfo = this.formLocation.getFormData();
    const itemData = this.formItems.getFormData();
    let data = {...deliveryInfo, items: itemData.items};
    if (!data.id) {
      data.id = Utils.generateULID();
    }
    this.onSave(data);
    this.closeDialog();
  }

  public validate(options?: ValidateOptions): ValidationErrors {
    for (let form of [this.formLocation, this.formItems]) {
      let err = form.validate(options);
      if (err) return err;
    }
    return null;
  }

  get labelTxt() {
    let locationType = '';
    switch (this.type) {
      case Const.TaskType.PICKUP:
        locationType = 'Pickup';
        break;
      case Const.TaskType.DROPOFF:
        locationType = 'Delivery';
        break;
    }
    return `Add ${locationType} Location`;
  }

}
