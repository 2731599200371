import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { ListFilterDrawer } from "../../base/list-filter-drawer";
import { MasterData } from "@services/master.data";

@Component({
  selector: '[shipment-invoice-filter]',
  templateUrl: './index.html',
  styleUrls: [
    '../../../app.scss',
    '../../../drawers/drawer.scss',
    '../../../../styles/date-picker-row.scss',
    '../../../../styles/form-v2.scss'
  ]
})
export class ShipmentFilter extends ListFilterDrawer {
  public static get declaration(): FormGroupDeclaration {
    return {
      clientId: { label: 'Customer', notAcceptEmpty: true },
      shipmentType: { label: 'Shipment Type', notAcceptEmpty: true },
      // podStatus: { label: 'POD status', notAcceptEmpty: true },
      // invoiceStatus: { label: 'Invoice status', notAcceptEmpty: true },
      pickupFromDate: { label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v },
      pickupToDate: { label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v },
      dropoffFromDate: { label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v },
      dropoffToDate: { label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v },
      actualPickupFromDate: { label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v },
      actualPickupToDate: { label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v },
      actualDropoffFromDate: { label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v },
      actualDropoffToDate: { label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v },
    }
  }

  protected formGroupDeclaration: FormGroupDeclaration = ShipmentFilter.declaration;

  public listShipmentTypes = Const.ShipmentTypesArray;
  public listClients = [];
  private fields = [
    'pickupFromDate', 'pickupToDate',
    'dropoffFromDate', 'dropoffToDate',
    'actualPickupFromDate', 'actualPickupToDate',
    'actualDropoffFromDate', 'actualDropoffToDate'
  ];
  public allStates = MasterData.getStatesUS();

  public set metadata(value) {
    if (value && value.client) {
      this.selectedClientId = value.client;
      this.listClients = [value.client];
    }
  }

  get needUpdate(): boolean {
    return true;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setEnableFormGroup(true);
  }

  protected beforeBindModel(model: any): void {
    if (model.client) {
      this.listClients = [model.client];
    }
    for (let field of this.fields) {
      if (model[field]) model[field] = new Date(model[field]);
    }
    return model;
  }

  protected afterBindModel(model?: any): void {

  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(isCreateNew);
    for (let field of this.fields) {
      if (json[field]) {
        let date = DateUtil.toBeginOfDay(json[field]).toISOString();
        if (field.includes("ToDate")) date = DateUtil.toEndOfDay(json[field]).toISOString();
        json[field] = date;
      }
    }
    return json;
  }

  get noDataWarehouse(): string {
    if (!this.selectedClientId) return 'Please select customer first';
    return 'No data';
  }

  protected getMetadata() {
    let client = this.listClients.filter(it => it.id == this.selectedClientId)[0];
    return { client }
  }

  private selectedClientId: string;
  onClientChange(clientId: string) {
    this.selectedClientId = clientId;
  }
}
