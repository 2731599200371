<style>
  .ant-btn.confirm-shipment-pod {
    width: 112px;
    height: 28px;
    border-radius: 5px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .shipment.status-complete {
    width: 112px;
  }
  ::ng-deep .col-first .ant-table-row-expand-icon {
    display: none;
  }
  .shipment-right-icons > *:not(:first-child) {
    margin-left: 10px;
  }
  .success {
    color: green;
  }
</style>
<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <search-box
      #searchBox
      (doSearch)="doSearch($event)"
      placeHolder="Search by WARP ID, parcel ID, Ref Number or Tracking Number"
      [searchKeyword]="searchKeyword"
      style="width: 500px; margin-right: 20px"
    ></search-box>
    <button
      nz-button
      [nzType]="hasFilter ? 'primary' : 'default'"
      style="min-width: 80px"
      (click)="onBtnFilter('drawer')"
    >
      <i nz-icon nzType="filter" nzTheme="outline"></i>Filter
    </button>
    <div class="flex1"></div>

    <button nz-button [nzType]="isActionSelected('confirm') ? 'primary' : 'default'"
      (click)="setFilters('confirm', $event)">
      <i nz-icon nzType="diff" nzTheme="outline"></i>Confirm PODs
    </button>
    <button nz-button [nzType]="isActionSelected('generate') ? 'primary' : 'default'"
      (click)="setFilters('generate', $event)">
      <i nz-icon nzType="file-pdf" nzTheme="outline"></i>Generate Invoices
    </button>
    <button nz-button [nzType]="isActionSelected('download') ? 'primary' : 'default'"
      (click)="setFilters('download', $event)">
      <i nz-icon nzType="download" nzTheme="outline"></i>Download Invoices
    </button>
  </div>

  <div class="list-body">
    <div style="float: right; margin-bottom: 20px">
      <button nz-button (click)="showConfirmAction()" [nzLoading]="isProcessing" style="margin-right: 5px;">
        <span nz-icon nzType="play-circle" nzTheme="outline"></span>Process
      </button>
      <button nz-button (click)="onBtnExport()" [nzLoading]="isExporting" style="margin-right: 5px;">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i>Export
      </button>
      <button nz-button (click)="onBtnOTPReportExport()" [nzLoading]="isOTPReportExporting">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i>OTP Export
      </button>
    </div>

    <nz-table #nzTable nzBordered="true" [nzData]="listData" nzSize="small" [nzTotal]="totalCount"
      [nzFrontPagination]="false" [nzShowPagination]="totalCount > listData.length" [nzPageSize]="limit" [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading" [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="_id" nzWidth="110px">WARP ID</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="legs" nzWidth="110px">Legs</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="trackingCode">Tracking Number</th>
          <th nzWidth="100px" *ngIf="isAccounting" nzSortOrder="null" [nzSortFn]="true" nzColumnKey="invoiceSent">
            Invoiced
          </th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.shipments.0.status">Status</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.shipments.0.client.name">Customer</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.shipments.0.equipment.name">Equipment</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="deliveryInfos.refNum">Reference Number</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.dataSort.earliestPickupTime">Pickup Date/Time</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.dataSort.earliestDropoffTime">Delivery Date/Time</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.dataSort.firstPickupLocation.locationName">
            Pickup Location Name
          </th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.dataSort.firstPickupLocation.addr.street"
            style="min-width: 250px">
            Pickup Street Address (with City)
          </th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.dataSort.firstPickupLocation.addr.state">
            Pickup State
          </th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.dataSort.firstDropoffLocation.locationName">
            Delivery Location Name
          </th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.dataSort.firstDropoffLocation.addr.street"
            style="min-width: 250px">
            Delivery Street Address (with City)
          </th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.dataSort.firstDropoffLocation.addr.state">
            Delivery State
          </th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.shipments.0.carrier.basicInfo.name">Carrier Name</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="customerRate">Customer Rate</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="carrierRate">Carrier Rate</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="margin">Margin</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.shipments.0.carrierSaleRep.fullName">Carrier Sales Rep</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.shipments.0.dispatcher.fullName">Dispatcher</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.shipments.0.clientSuccessRep.fullName">Client Success Rep</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="metadata.shipments.0.clientSalesRep.fullName">Client Sales Rep</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of nzTable.data">
          <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
            <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
              <td class="col-first" style="min-width: 200px" [nzIndentSize]="item.level*20" [nzShowExpand]="false"
                [(nzExpand)]="item.expand" (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)">
                <div class="shipment-right-icons">
                  <div class="text-info">
                    <a *ngIf="item.orderId" [routerLink]="getRouterLink(item)">
                      {{showOrderWarpId(item.orderId)}}
                      <ng-container *ngIf="item.shipmentId && item.shipmentIds.length === 1">
                        <br /><span class="shipmentId-small">({{showShipmentWarpId(item.shipmentId)}})</span>
                      </ng-container>
                    </a>
                    <a *ngIf="!item.orderId && item.shipmentId" [routerLink]="getRouterLink(item)">
                      {{showShipmentWarpId(item.shipmentId)}}
                    </a>
                  </div>
                  <!-- <span class="" *ngIf="item.orderId && !item.shipmentId">{{item.orderId}}</span> -->
                  <div class="icon-action">
                    <ng-container *ngIf="isOrder(item)">
                      <i *ngIf="item.warningMessage" nz-icon nzType="warning" nzTheme="outline" class="warning"
                        nz-tooltip [nzTooltipTitle]="item.warningMessage"></i>
                      <a *ngIf="hasRoute(item)" style="color: inherit" target="_blank"
                        (click)="goToDispatch(item)" nz-tooltip
                        nzTooltipTitle="Go to dispatch">
                        <i nz-icon nzType="rocket" nzTheme="outline"></i>
                      </a>
                      <a *ngIf="isExfreightShipment(item)" style="color: inherit"
                        nz-tooltip [nzTooltipTitle]="getExfreightTooltip(item)">
                        <img src="assets/img/exfreight.png" style="width: 14px; height: 20px" />
                      </a>
                      <a *ngIf="isOrderful(item)" style="color: inherit" nz-tooltip
                        [nzTooltipTitle]="getOrderFulTooltip(item)">
                        <img src="assets/img/orderful-logo.png" style="width: 16px; height: 15px" />
                      </a>

                      <i nz-icon [nzType]="item.expand ? 'up' : 'down'" nzTheme="outline"
                        *ngIf="(item.children || []).length>0" style="margin-left: 10px" class="clickable"
                        (click)="toggleChildren(data, item)"></i>
                    </ng-container>

                    <ng-container *ngIf="!isOrder(item)">
                      <a *ngIf="item.bol" style="color: inherit" (click)="downloadAttachedFile(item.bol)" nz-tooltip
                        nzTooltipTitle="BOL">
                        <i *ngIf="!item.bol.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"></i>
                        <i *ngIf="item.bol.isDownloading" nz-icon nzType="loading" nzTheme="outline"></i>
                      </a>
                      <a *ngIf="hasRoute(item)" style="color: inherit" target="_blank"
                        (click)="goToDispatch(item)" nz-tooltip
                        nzTooltipTitle="Go to dispatch">
                        <i nz-icon nzType="rocket" nzTheme="outline"></i>
                      </a>

                    </ng-container>
                  </div>
                </div>
              </td>
              <td style="min-width: 150px">
                <a [routerLink]="getTrackingItems(item)" target="_blank" *ngIf="shouldShowLegIds(item)">
                  {{ getLegIdText(item.displayInfo) }}
                  <ng-container *ngIf="item?.displayInfo.legs?.length > 1">
                    <a [nzTooltipTitle]="getListIds(item?.displayInfo?.legs)" nzTooltipPlacement="top" nz-tooltip
                      nz-typography nzEllipsis>
                      (+{{ item?.displayInfo.legs?.length - 1 }})
                    </a>
                  </ng-container>
                </a>
              </td>
              <td>
                <a [href]="getTrackingLink(item)" target="_blank"><code>{{getTrackingCode(item)}}</code></a>
              </td>
              <td *ngIf="isAccounting">
                <div nz-checkbox *ngIf="!item.parent" [(ngModel)]="item.isInvoiceSent" [nzDisabled]="item.isLoading"
                  (ngModelChange)="onBtnInvoiceSent(item, $event)"></div>
                <div *ngIf="item.isInvoiceSent" class="grey" style="font-size: 10px; font-style: italic">
                  <div>{{displayDateDB(item.invoiceSent?.when)}}</div>
                  <div>{{getFullName(item.invoiceSent?.byUser)}}</div>
                </div>
              </td>
              <td class="width-fit-content text-center">
                <div *ngIf="shouldShowStatus(item)" class="{{ 'status-' + getStatusValue(item.displayInfo) }}"
                  [ngClass]="{'shipment': true, 'order-status': true}">
                  {{showStatusValue(item)}}
                </div>
                <ng-container *ngIf="isWarehouseStatus(item)">
                  <a *ngIf="item.statusHistory" target="_blank" [routerLink]="getLinkWarehouse(item)">
                    {{ getWarehouseName(item) }}
                  </a>
                </ng-container>
                <ng-container *ngIf="!hasInvoice(item) && item.level == 0">
                  <div *ngIf="isShipmentCompleted(item)" class="top15">
                    <nz-badge *ngIf="isGenerateInvoice(item)">
                      <a (click)="onOpenConfirmPod(item)">Generate invoice</a>
                    </nz-badge>
                    <nz-badge *ngIf="isShowConfirmPOD(item) && !isGenerateInvoice(item)"
                      [nzCount]="getCountPodNotConfirmed(item)" [nzOverflowCount]="9">
                      <a (click)="onOpenConfirmPod(item)">Confirm POD ({{countConfirmPOD(item)}})</a>
                    </nz-badge>
                    <a *ngIf="!isShowConfirmPOD(item) && !isGenerateInvoice(item)" target="_blank"
                      (click)="goToDispatch(item)">
                      No POD yet
                    </a>
                  </div>
                  <div *ngIf="isShipmentCanceled(item)" class="top15">
                    <nz-badge *ngIf="!isShowConfirmPOD(item)">
                      <a (click)="onOpenConfirmPod(item)">Generate invoice</a>
                    </nz-badge>
                    <nz-badge *ngIf="isShowConfirmPOD(item)"
                      [nzCount]="getCountPodNotConfirmed(item)" [nzOverflowCount]="9">
                      <a (click)="onOpenConfirmPod(item)">Confirm POD ({{countConfirmPOD(item)}})</a>
                    </nz-badge>
                  </div>
                  <div *ngIf="isShipmentReturned(item)" class="top15">
                    <nz-badge *ngIf="!isShowConfirmPOD(item)">
                      <a (click)="onOpenConfirmPod(item)">Generate invoice</a>
                    </nz-badge>
                    <nz-badge *ngIf="isShowConfirmPOD(item)"
                      [nzCount]="getCountPodNotConfirmed(item)" [nzOverflowCount]="9">
                      <a (click)="onOpenConfirmPod(item)">Confirm POD ({{countConfirmPOD(item)}})</a>
                    </nz-badge>
                  </div>
                </ng-container>
                <div class="top10" *ngIf="hasInvoice(item)">
                  <a (click)="downloadInvoice(item, $event)">
                    <i *ngIf="!item.invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                      class="right10"></i>
                    <i *ngIf="item.invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                      class="right10"></i>Invoice
                  </a>
                </div>
              </td>
              <td>{{getClientName(item)}}</td>
              <td>
                <div *ngIf="item.displayInfo.shipmentType">{{item.displayInfo.shipmentType}}</div>
                <div *ngIf="item.displayInfo.equipment || item.displayInfo.tempRange" class="bottom5">
                  <div *ngIf="item.displayInfo.equipment" class="shipment-mode">{{item.displayInfo.equipment}}</div>
                  <div *ngIf="item.displayInfo.tempRange" class="shipment-mode">{{item.displayInfo.tempRange}}</div>
                </div>
              </td>
              <td>{{item.displayInfo.refNum}}</td>
              <td>{{item.displayInfo.timePick}}</td>
              <td>{{item.displayInfo.timeDrop}}</td>
              <td>{{item.displayInfo.locationNamePick}}</td>
              <td>
                <div>{{item.displayInfo.addrPick}}</div>
              </td>
              <td>{{item.displayInfo.addrPickState}}</td>
              <td>{{item.displayInfo.locationNameDrop}}</td>
              <td>
                <div>{{item.displayInfo.addrDrop}}</div>
              </td>
              <td>{{item.displayInfo.addrDropState}}</td>
              <td>
                <div>{{item.displayInfo.carrier}}</div>
              </td>
              <td>
                <div>{{item.displayInfo.customerRate}}</div>
              </td>
              <td>
                <div>{{item.displayInfo.carrierRate}}</div>
              </td>
              <td>
                <div>{{item.displayInfo.margin}}</div>
              </td>
              <td>
                <div>{{item.displayInfo.carrierSaleRep}}</div>
              </td>
              <td>
                <div>{{item.displayInfo.dispatcher}}</div>
              </td>
              <td>
                <div>{{item.displayInfo.clientSuccessRep}}</div>
              </td>
              <td>
                <div>{{item.displayInfo.clientSalesRep}}</div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>
