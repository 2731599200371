<div class="flex bottom15">
    <div class="right20 top5 shipment-header">
        <span *ngIf="shipment.name" class="shipment-name">{{ shipment.name}}</span>
        <span *ngIf="shipment.parentId">LEG: </span>
        <span *ngIf="!shipment.parentId">Main Shipment: </span>
        <span class="sub-title clickable" (click)="copyWarpId()">{{showShipmentCode(shipment)}}</span>
    </div>

    <div class="order-status {{ 'status-' + shipment.status }} right20" [ngClass]="{'clickable': !isAdminReadOnlyRole}" (click)="onBtnEditStatus(shipment)">
        <!-- show modal to change status -->
        {{ status }}
        <i [ngClass]="{'icon-hidden': isAdminReadOnlyRole}" nz-icon nzType="edit"></i>
    </div>

    <div class="right20 shipment-header">
        <a (click)="downloadBOL($event, shipment)">
            <i *ngIf="!shipment?.bolInfo?.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline" class="right5"></i>
            <i *ngIf="shipment?.bolInfo?.isDownloading" nz-icon nzType="loading" nzTheme="outline" class="right5"></i>
            BOL
        </a>
    </div>

    <div class="right20 shipment-header" *ngIf="trackingCode">
        Tracking: {{ trackingCode }}
        <i nz-icon nzType="copy" nzTheme="outline" class="clickable" (click)="copyTrackingCode()" nz-tooltip nzTooltipTitle="Copy tracking number" style="margin-left: 5px;"></i>
    </div>
    
    <div class="right20 shipment-header">
        <span *ngIf="shipment.externalId" third-party-info-icon [shipment]="shipment"></span>
        <span *ngIf="!shipment.externalId" third-party-quote-icon [shipment]="shipment" (onBooked)="onBooked($event)"></span>
    </div>
</div>
<div nz-row [nzGutter]="{ md: 16 }">
    <div *ngIf="pickup" nz-col [nzMd]="shouldShowRevenue ? 9 : 12" nzSm="12" nzXs="24">
        <div shipment-delivery-info (onUpdated)="onUpdated()" [editMode]="editMode" [shipment]="shipment" [info]="pickup" [isShowActualTime]="isShowActualTime"></div>
    </div>
    <div *ngIf="dropoff" nz-col [nzMd]="shouldShowRevenue ? 9 : 12" nzSm="12" nzXs="24">
        <div shipment-delivery-info (onUpdated)="onUpdated()" [editMode]="editMode" [shipment]="shipment" [info]="dropoff" [isShowActualTime]="isShowActualTime"></div>
    </div>
    <div nz-col nzMd="6" nzSm="6" nzXs="24" *ngIf="shouldShowRevenue">
        <div class="delivery-info-container">
            <span class="info-name-color-box REVENUE">Revenue</span>
            <div shipment-revenue [editMode]="editMode" [cost]="shipment.cost" [shipmentId]="shipment.id" (onCostUpdate)="onCostUpdate($event)"></div>
        </div>        
    </div>
</div>
