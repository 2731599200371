
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';
import { BaseComponent } from '@abstract/BaseComponent';

@Component({
  selector: '[warehouse-detail]',
  templateUrl: './detail.html',
  styleUrls: ['./detail.scss']
})
export class WarehouseDetail extends BaseComponent {
  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  public id: string = null
  warehouse: any = null

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.params.subscribe(params => {
        const { id } = params
        if (id) {
            this.id = id
            this.loadData()
        }
    })
  }

  protected loadData() {
    const url = Const.APIV2(`warehouse-inventory/${this.id}`);
    this.api.GET(url).subscribe(res => {
        this.warehouse = res
    })
  }
}
