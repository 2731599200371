import { Injectable } from "@angular/core";
import { LatLng } from "@wearewarp/types/data-model";

@Injectable()
export class AddressManagement {
  private addresses: Map<string, any> = new Map();

  public add(location, address) {
    const key = this.textLatLng(location);
    this.addresses.set(key, address);
    return this;
  }

  public get(location) {
    const key = typeof location == "string" ? location.replace(/\-(PICKUP|DROPOFF)$/, '') : this.textLatLng(location);
    return this.addresses.get(key);
  }

  public reset() {
    this.addresses.clear();
    return this;
  }

  private textLatLng(location: LatLng): string {
    if (!location) return '';
    if (location.latitude && location.longitude)
      return `${location.latitude}, ${location.longitude}`;
    return `${location[0]}, ${location[1]}`
  }
}