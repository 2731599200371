import { Component } from "@angular/core";
import { ApiMethod } from "@app/enum";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

@Component({
  selector: '[carrier-status]',
  templateUrl: './index.html',
  styleUrls: ['../../../app.scss', '../../../dialogs/dialogs.scss']
})
export class UserStatus extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    isActive: {label: 'Active', type: 'boolean', notAcceptEmpty: true, initialValue: false},
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected buildUrl(method: ApiMethod): string {
    return `${Const.APIURI_USERS}/${this.id}/status`;
  }
}
