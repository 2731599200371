<div *ngIf="textTitle" class="f18m bottom10">{{textTitle}}</div>
<div style="display: flex; flex-direction: column;">
  <div *ngFor="let orderId of listOrderIds; let i = index" class="order-row">
    <a class="link" style="width: fit-content;"
      [routerLink]="[routeAdminShipmentList, orderId]" target="_blank">
      Shipment {{orderId}}
    </a>
    <div class="flex1"></div>
    <button *ngIf="canExcludeItem"
      nz-popover nzPopoverContent="Exclude this shipment" nzPopoverPlacement="right"
      nz-button nzType="text" nzShape="circle" nzDanger
      (click)="onBtnRemove(i)">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>
</div>