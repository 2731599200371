import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';
import { FormUtil } from '@services/form-util';
import { Utils } from '@services/utils';
import { BaseDetail } from '../../base/detail';

type FormControlDeclarationExtend = Omit<FormControlDeclaration, 'childItem'> & {
  span?: number,
  templateType?: 'input' | 'files',
  appKey?: string;
  childItem?: FormGroupDeclarationExtend | FormControlDeclarationExtend
}
type FormGroupDeclarationExtend = {
  [key: string]: FormControlDeclarationExtend;
}
@Component({
  selector: 'setting-version',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../detail.scss']
})
export class SettingVersion extends BaseDetail {

  protected formGroupDeclaration: FormGroupDeclarationExtend = {
    web_admin: {
      label: 'Admin dashboard', required: true, type: 'formGroup', childItem: {
        version: { label: 'Version', required: true, span: 8, templateType: 'input' }
      }
    },
    web_carrier: {
      label: 'Carrier dashboard', required: true, type: 'formGroup', childItem: {
        version: { label: 'Version', required: true, span: 8, templateType: 'input' }
      }
    },
    web_customer: {
      label: 'Customer dashboard', required: true, type: 'formGroup', childItem: {
        version: { label: 'Version', required: true, span: 8, templateType: 'input' }
      }
    },
    web_driver: {
      label: 'Driver app', required: true, type: 'formGroup', childItem: {
        version: { label: 'Version', required: true, span: 8, templateType: 'input' },
      }
    },
    app_driver_android: {
      label: 'Driver app android', required: true, type: 'formGroup', childItem: {
        version: { label: 'Version', required: true, span: 8, templateType: 'input' },
      }
    },
    app_driver_ios: {
      label: 'Driver app ios', required: true, type: 'formGroup', childItem: {
        version: { label: 'Version', required: true, span: 8, templateType: 'input' },
      }
    },
    web_tracking: {
      label: 'Tracking page', required: true, type: 'formGroup', childItem: {
        version: { label: 'Version', required: true, span: 8, templateType: 'input' }
      }
    },
    app_android_crossdock: {
      label: 'App Crossdock Android', required: true, type: 'formGroup', childItem: {
        version: { label: 'Version', required: true, span: 8, templateType: 'input' },
        files: { label: 'APK Files', span: 24, templateType: 'files', appKey: 'app_android_crossdock' }
      }
    }
  };

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getIdForDetailComponent(route: ActivatedRoute) {
    return 'apps-version';
  }

  protected getApiUrl(method: ApiMethod = ApiMethod.get): string {
    return Const.APIURI_SETTINGS;
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
  }

  public getFieldInfoByKey(key) {
    const field = FormUtil.getItemByKey(key, this.formGroupDeclaration) as any;
    return field;
  }

  public getHistories(key) {
    let histories = this.model?.[key]?.["histories"] || [];
    for (let index in histories) {
      const userId = histories[index]?.created?.by;
      histories[index]['byUser'] = this.model[key]['historyByUser']?.filter(u => u._id == userId)[0];
    }
    return histories
  }

  public onFileChange(value, formGroupKey){
    if(value?.versionName){
      this.setItemValue(this.fullKey(formGroupKey, 'version'), value.versionName);
    }
  }

}