<form nz-form [formGroup]="formInput">
  <div class="bottom20">
    <form nz-form *ngFor="let key of ['settings']" [formGroupName]="key">
      <ng-container *ngFor="let subKey of ['isDoNotInvoice']">
        <div nz-checkbox [formControlName]="subKey">
          {{getLabel(join(key,subKey))}}
        </div>
      </ng-container>
    </form>
  </div>
  <div form-input-cost-v3 #costForm [form]="formInput.get('cost')"
    [createShipmentMode]="'single'"
    [selectLocations]="selectLocations"
    [serviceOptions]="serviceOptionsForCostSection">
  </div>
  
</form>
