import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";
import { getInjector } from "@services/index";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { LatLng } from "@wearewarp/types/data-model";
import { RoutingColor } from "../../color";
import { ModelRoutingRoute, ModelRoutingStop } from "../../interface";
import { MapService } from "../map.service";
import { RoutingService } from "../routing.service";
import { ShipmentManagement } from "../shipmentManagement";
import { MapLine, MapMarker } from "./map";
import { RoutingRouteReOrder } from "./routeFeatures/reOrder";
import { RoutingAdjustShipment } from "./routeFeatures/adjustShipment";
import { SubProblem } from "./subProblem";

export class Route {
  private data;
  private routeIndex;
  private routingService: RoutingService
  private mapService: MapService;
  private subProblem: SubProblem;
  private shipmentManagement: ShipmentManagement;

  constructor(data, index?) {
    const injector = getInjector();
    this.routingService = injector.get(RoutingService);
    this.mapService = injector.get(MapService)
    this.shipmentManagement = injector.get(ShipmentManagement)
    this.setData(data);
    this.routeIndex = index;
  }

  setData(data) {
    this.data = data;
    // this.buildRouteDataForUI();
  }
  setSubProblem(subProblem: SubProblem) {
    this.subProblem = subProblem;
  }

  getSubProblemId() {
    return this.subProblem.getId();
  }

  getRouteIndex() {
    return this.routeIndex;
  }

  getId() {
    return this.data.id;
  }

  getStatus() {
    //đối với trường hợp manual route mà chưa reOrder thì bắt buộc phải reOrder
    if (this.data.manual && !this.data.reOrder) return 'NEED_REVIEW'
    return 'COMPLETE';
  }

  getStops(): ModelRoutingStop[] {
    return this.data?.stops || [];
  }
  getShipmentIds() {
    const tasks = this.getTasks();
    return Array.from(new Set<string>(tasks.map(task => {
      const shipment = this.shipmentManagement.getShipment(task.shipmentId);
      return String(shipment?.getId())
    })))
  }

  getShipmentWarpIds() {
    const tasks = this.getTasks();
    return Array.from(new Set<string>(tasks.map(task => {
      const shipment = this.shipmentManagement.getShipment(task.shipmentId);
      return shipment?.getWarpId()
    })))
  }

  public getRouteDataForUI() {
    let colorIndex = RoutingColor.getColorIndex(this.routeIndex) + 1;  // để lấy màu theo css khai báo trong file src/styles/color-pallet.scss (đánh số từ 1)
    let miles = BizUtil.getDistanceFromCost(this.data.cost, { unit: 'mi' }).toFixed(1) + ' mi';
    let seconds = BizUtil.getTimeFromCost(this.data.cost);
    let time = DateUtil.displayDuration(seconds * 1000, { hour: 'h', minute: 'm', skipSecond: true, noSpaceBeforeUnit: true });
    let shipmentIds = this.getShipmentWarpIds();
    let tasks = this.getTasks();
    let firstStop = tasks[0];
    let firstLocationName = BizUtil.getLocationName(firstStop?.info);
    let firstLoc: LatLng = firstStop?.location;
    let firstLocAddr = MasterData.getAddressText(firstStop?.info?.addr);
    let lastStop = tasks[tasks.length - 1];
    let lastLocationName = BizUtil.getLocationName(lastStop?.info);
    let lastLoc: LatLng = lastStop?.location;
    let lastLocAddr = MasterData.getAddressText(lastStop?.info?.addr);
    return {
      ...this.data,
      routeIndex: this.routeIndex,
      colorIndex, miles, time, shipmentIds, tasks,
      stopCount: this.getStops().length,
      shipmentIdsText: shipmentIds.join(', '),
      firstStop: {
        typs: firstStop?.type,
        address: firstLocAddr,
        location: firstLoc,
        locationName: firstLocationName,
      },
      lastStop: {
        typs: lastStop?.type,
        address: lastLocAddr,
        location: lastLoc,
        locationName: lastLocationName,
      },
    };
  }

  public getTasks() {
    if (!this.data?.tasks) {
      return [];
    }
    const tasks = [...this.data.tasks];
    for (let task of tasks) {
      let shipment = this.shipmentManagement.getShipment(task.shipmentId);
      let info = shipment?.getDeliveryInfos()?.filter(it => it.type == task.type)?.[0];
      if (info) {
        task.info = info;
      }
    }
    return tasks;
  }

  public addToMap({ color } = { color: RoutingColor.getColorByNumber(this.getRouteIndex()) }) {
    if (!this.mapService.mapReady.getValue()) {
      Log.d(`Map is not ready.`)
      return;
    }
    //không hiển thị các line của route chưa complete
    if (this.getStatus() != 'COMPLETE') return;

    const tasks = this.getTasks();
    return this.mapService.addLine({
      coordinates: tasks.map(task => {
        const latlng = task.location;
        return [latlng.longitude, latlng.latitude]
      }),
      id: this.getId()
    }).setPaint({
      'line-color': color,
      'line-width': 2
    });
  }

  /**
   * Route Features
   */

  public startAdjustOrder() {
    return new RoutingRouteReOrder(this);
  }

  public startAdjustShipment() {
    return new RoutingAdjustShipment(this);
  }
}