<div class="flex">
    <h3 class="flex1">
        {{ _view?.name ?? '' }}
    </h3>
    <div class="bottom10">
        <button class="right15" nz-button (click)="downloadData()">Refresh</button>
        <button nz-button (click)="downloadCsv()">Download</button>
    </div>
</div>

<div class="flex filters bottom10">
    <div class="filter right15">
        <div class="title bottom10">Preview</div>
        <div>
            <label nz-checkbox [(ngModel)]="preview">Preview 100 rows</label>
        </div>
    </div>
    <ng-container *ngIf="_view?.filters?.length">
    <div class="filter right15" *ngFor="let filter of _view.filters">
        <div class="title bottom5">{{ filter.name }}</div>
        <div>
            <ng-container *ngIf="filter.inputType=='DatePicker'">
                <nz-date-picker [(ngModel)]="filter.value" (ngModelChange)="onFilterChange($event, filter)"></nz-date-picker>
            </ng-container>
            <ng-container *ngIf="filter.inputType=='MultiSelect'">
                <nz-select [(ngModel)]="filter.value" nzMode="multiple" style="min-width: 80px;">
                    <nz-option *ngFor="let p of filter.params" [nzLabel]="p" [nzValue]="p"></nz-option>
                </nz-select>
            </ng-container>
        </div>
    </div>
    </ng-container>
</div>

<div class="summary">
    {{ data.length }} Rows
    <i *ngIf="loadingData" class="left10" nz-icon nzType="loading" nzTheme="outline"></i>
</div>
<div class="table" cdkVirtualScrollingElement>
    <cdk-virtual-scroll-viewport itemSize="30" class="tbody">
        <ng-container *ngIf="_reportFormat">
            <div class="flex tr" [style.left]="offsetX" [style.width]="_reportFormat.cssWidth">
                <div class="th" [style.width]="indexWidth"></div>
                <div class="th actions"></div>
                <div class="th" [style.width]="col.cssWidth" *ngFor="let col of _reportFormat.columns; let id = index">
                    {{ col.name }}
                    <div class="btn-inc" *ngIf="id > 0">
                        <span class="clickable" (click)="onChangeColumnWidth(_reportFormat.columns[id-1], 20)" nz-icon nzType="caret-right" nzTheme="outline"></span>
                    </div>
                    <div class="btn-dec">
                        <span (click)="onChangeColumnWidth(col, -20)" class="clickable" nz-icon nzType="caret-left" nzTheme="outline"></span>
                    </div>
                    <div class="btn-inc-last" *ngIf="id == _reportFormat.columns.length - 1">
                        <span class="clickable" (click)="onChangeColumnWidth(col, 20)" nz-icon nzType="caret-right" nzTheme="outline"></span>
                    </div>
                </div>
            </div>

            <div class="tr flex" [class]="{'selected': selectedIndex == id}" [style.width]="_reportFormat.cssWidth" *cdkVirtualFor="let line of data; let id = index" (click)="onSelectRow(id)">
                <div class="td index" [style.width]="indexWidth">
                    {{ id + 1 }}
                </div>
                <div class="td actions">
                    <span *ngIf="routeLink">
                        <a [routerLink]="[routeLink, line.uniqueKey]" target="_blank">
                            <span nz-icon nzType="export" nzTheme="outline"></span>
                        </a>
                    </span>
                </div>
                <div class="td" [title]="col.name" [style.width]="col.cssWidth" *ngFor="let col of _reportFormat.columns">
                    {{ line.raw[col.key] ?? '' }}
                </div>
            </div>
        </ng-container>
    </cdk-virtual-scroll-viewport>
</div>