<!-- List shipments có thể nhiều hàng trăm item nên dùng cdk-virtual-scroll-viewport -->
<!-- <cdk-virtual-scroll-viewport *ngIf="shouldListShipment" [itemSize]="15" class="routing-list-shipment-viewport">
  <div *cdkVirtualFor="let item of listShipments; let index = index;" class="routing-shipment-item-box">
    <div routing-shipment-item [model]="item" [canRemove]="canExcludeShipmentFromRouting"></div>
  </div>
</cdk-virtual-scroll-viewport> -->

<!-- cdk-virtual-scroll bị lỗi không update render chưa fix được nên tạm thời dùng list bình thường. -->
<div *ngIf="shouldListShipment" class="routing-list-shipment-viewport">
  <div *ngFor="let item of listShipments; let index = index;" class="routing-shipment-item-box">
    <div routing-shipment-item [model]="item" [canRemove]="canExcludeShipmentFromRouting"></div>
  </div>
</div>