<ng-container *ngIf="viewTemplate">
  <ng-container *ngTemplateOutlet="viewTemplate"></ng-container>
</ng-container>

<ng-container *ngIf="!viewTemplate">
  <div form-shipment-location-template-default *ngIf="shouldUseTemplateDefault"
    [formComponent]="self" 
    [locationHelper]="locationHelper" 
    [serviceOptionsHelper]="serviceOptionsHelper">
  </div>
  <div form-shipment-location-template-mobile *ngIf="shouldUseTemplateMobile"
    [formComponent]="self" 
    [locationHelper]="locationHelper" 
    [serviceOptionsHelper]="serviceOptionsHelper">
  </div>
</ng-container>