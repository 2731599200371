
import { Component, Input } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Order, Shipment, ShipmentItem } from '@wearewarp/types/data-model';
import { DateUtil } from '@services/date-utils';
import { Const } from '@wearewarp/universal-libs';
import _ from 'underscore'

@Component({
    selector: 'warehouse-item-pod',
    templateUrl: './pod.html',
    styleUrls: ['./pod.scss']
})
export class WarehouseItemPod extends BaseComponent {
    @Input() pod: any;
    compact: boolean = true
    pdf: boolean = false
    viewDrawer: boolean = false

    toggleCompact() {
        this.compact = !this.compact
        if (!this.compact && this.pdf) {
            this.openDrawer()
        }
    }

    onConfirmed(event) {        
    }

    ngOnChanges(): void {
        this.pdf = this.isPdf(this.pod.file)
    }

    openDrawer(): void {
        this.viewDrawer = true;
    }
    
    closeDrawer(): void {
        this.viewDrawer = false;
        this.compact = true
    }
    
    download() {
        this.downloadAttachedFile(this.pod.file)
    }
}