


<div cdkDropList (cdkDropListDropped)="drop($event)">
    <div    
        cdkDrag
        *ngFor="let location of locations; let i = index"
        class="{{dragAndDropClassName}}"  
        id="{{getLocationIndex(i)}}" 
        [attr.data-sort]="getSortValue(location)"
        [cdkDragDisabled]="!isDraggable(location)"
    >
        <div class="location-name"> 
            {{getLocatioName(location)}}
            <span *ngIf="isPickupLocation(location)" class="pickup-tag">Pickup{{getShipmentIndex(location)}}</span>
            <span *ngIf="isDropoffLocation(location)" class="dropoff-tag">Dropoff{{getShipmentIndex(location)}}</span>
            <img cdkDragHandle *ngIf="isDraggable(location)" class="drag-icon {{cursorDragAndDropClassName}}" [src]="this.getIcon?.dragIcon" />
        </div>
        <div class="address">{{getLocationAddress(location)}}</div>
        <div>
            <div class="items">{{getItemText(location)}}</div>
            <div nz-row class="windows-time-row">
                <div nz-col nzSpan="2">
                    <img class="windows-time-icon" [src]="this.getIcon?.windowTimesIcon" />
                </div>
                <div nz-col nzSpan="22">
                    <div class="windows-time">
                        <div class="windows-time-text">{{getTimePeriods(location)}}</div>
                    </div>
                </div>
            </div>  
            <div *ngIf="location?.serviceOptions?.length" class="service-options">
                <img [src]="this.getIcon?.serviceOptionsIcon" /> 
                {{getServiceOptions(location)}}
            </div>
        </div>
        <nz-divider class="item-divider"></nz-divider> 
    </div>
</div>