import { Component, Input, ViewChild } from "@angular/core";
import { TaskType } from "@wearewarp/types"
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { Log } from "@services/log";
import { FormShipmentLocation } from "../../../forms/shipment-location";
import { FormOrderItems } from "../../../forms/order-items";
import { ValidateOptions } from "@app/admin/base/form-base";
import { ValidationErrors } from "@angular/forms/forms";
import { Utils } from "@services/utils";


@Component({
  selector: 'edit-shipment-entry-add-shipment',
  templateUrl: './index.html',
  styleUrls: ['../style.scss']
})
export class EditShipmentEntryAddShipment extends BaseDialog {

  @ViewChild('formPickup') formPickup: FormShipmentLocation;
  @ViewChild('formDropoff') formDropoff: FormShipmentLocation;
  @ViewChild('formItems') formItems: FormOrderItems;

  @Input() type: TaskType;
  public onSave: (data) => void = () => {}

  ngOnInit(): void {
    super.ngOnInit();
  }

  onBtnSave() {
    if (this.validate({showDialogMessage: true, scrollToErrorField: true})) {
      return;
    }
    const pickInfo = this.formPickup.getFormData();
    const dropInfo = this.formDropoff.getFormData();
    const itemData = this.formItems.getFormData();
    if (!pickInfo.id) pickInfo.id = Utils.generateULID();
    if (!dropInfo.id) dropInfo.id = Utils.generateULID();
    let data = { id: Utils.generateULID(), pickInfo, dropInfo, items: itemData.items};
    this.onSave(data);
    this.closeDialog();
  }

  public validate(options?: ValidateOptions): ValidationErrors {
    for (let form of [this.formPickup, this.formDropoff, this.formItems]) {
      let err = form.validate(options);
      if (err) return err;
    }
    return null;
  }

}
