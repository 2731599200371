<div class="flex-column" style="height: 100%;">
  <div class="flex">
    <input #inputRegion nz-input class="flex1 right10" 
      placeholder="Enter Region (number)" 
      [(ngModel)]="region" 
      (input)="onInputChanged($event)" 
      (keypress)="onInputKeyPress($event)"
      (keyup)="onKeyUp($event)">
    <button nz-button nzType="primary" (click)="onBtnSetRegion()" [disabled]="!canSetRegion" [nzLoading]="onProgress">
      <span nz-icon nzType="save" nzTheme="outline"></span>
      Save (⏎)
    </button>
  </div>
  <div class="top15">{{countShipmentText}}</div>

  <textarea nz-input class="flex1 top15" disabled [ngModel]="listShipmentIdsText"></textarea>

  <button nz-button class="top15" style="align-self: flex-end;" [disabled]="onProgress" (click)="onBtnClose()">
    <span nz-icon nzType="close" nzTheme="outline"></span>
    Cancel (Esc)
  </button>
</div>