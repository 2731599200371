<div class="header" *ngIf="location.locationName">
    <span *ngIf="location.warehouse">[{{ location.warehouse?.warpId }}] </span>
    {{ location.locationName }}</div>
<div class="content" *ngIf="!location?.addr?.metadata?.isExternal">
    <!-- <div class="bottom5" *ngIf="location.warehouseId && !location.crossdockWarehouseId">Warehouse #{{location.warehouseId}}</div> -->
    <div>{{location.addr.street}}</div>
    <div>{{location.addr.city}}, {{location.addr.state}} {{location.addr.zipcode}}</div>
</div>

<div *ngIf="contextMenu" class="content">
    <div *ngIf="location.warehouseId" class="bottom10">
        <a [routerLink]="[routeAdminCrossdockWarehouseJob]" [queryParams]="{warehouseId:location.warehouseId}" target="_blank">View Warehouse</a>
    </div>
    <div class="bottom10" *ngIf="_uploadedPhotos?.length">
        {{ _uploadedPhotos.length }} photos <button nz-button (click)="onViewPhoto()" nzType="text">View</button>
    </div>
    <div class="bottom10">
        <button (click)="onDonwloadWarehouseDocuments()" style="width: 100%" nz-button>Download Documents</button>
    </div>
    <div class="bottom10" *ngIf="!viewOnly">
        <button (click)="onClick('NEW_OUTBOUND')" style="width: 100%" nz-button>New Outbound Leg</button>
    </div>
    <div class="bottom10" *ngIf="!viewOnly">
      <button (click)="onClick('INITIALIZE_RETURN')" style="width: 100%" nz-button>Initialize Return Leg</button>
  </div>
</div>    