import { DateUtil } from "@services/date-utils";
import { TimeWindow } from "@wearewarp/types/data-model";

export interface FormDataWindowTime {
  range: Date[],
}

export class WindowTimeHelper {

  static modelToFormData(model: TimeWindow[], timezone: string): FormDataWindowTime[] {
    let windows: FormDataWindowTime[] = [];
    for (let item of model) {
      let from = DateUtil.convertLocalTime2(item.from, timezone);
      let to = DateUtil.convertLocalTime2(item.to, timezone);
      windows.push({range: [from, to]});
    }
    return windows;
  }

  static formDataToModel(formData: FormDataWindowTime[], timezone: string): TimeWindow[] {
    let windows: TimeWindow[] = [];
    for (let item of formData ?? []) {
      let range: Date[] = item.range;
      if (!range) {
        continue;
      }
      let from = DateUtil.convertLocalTime(range?.[0], timezone)?.toISOString();
      let to = DateUtil.convertLocalTime(range?.[1], timezone)?.toISOString();
      windows.push({from, to});
    }
    return windows;
  }

}