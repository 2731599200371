
<ng-template #tplHeader>
  <div class="center-vertical">
    <i nz-icon nzType="close" nzTheme="outline" class="icon-close" (click)="closeDrawer()"></i>
    <div class="flex1">
      <div class="txt-title">Vehicles</div>
    </div>
    <button nz-button class="right10" (click)="closeDrawer()" [disabled]="!canCancel">Cancel</button>
    <button nz-button nzType="primary" (click)="onBtnSave()" [disabled]="!canSave">Save</button>
  </div>
</ng-template>

<ng-template #tplContent>
  <div>
    <nz-table
      #nzTable nzBordered="true"
      [nzData]="listVehicles" nzSize="small"
      [nzTotal]="listVehicles.length"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzShowPagination]="false">
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Capacity</th>
          <th colspan="2">Availability</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listVehicles; let index = index">
          <td>{{getVehicleMode(item)}}</td>
          <td>{{getVehicleType(item)}}</td>
          <td>{{getVehicleCapacity(item)}}</td>
          <td class="border-right-none">
            <input nz-input style="width: 100px;"
              [(ngModel)]="item.availability" placeholder="Enter number"
              (input)="onInputChanged($event, 'availability')" 
              (keypress)="onInputKeyPress($event, 'availability')">
          </td>
          <td style="width: 80px;">
            <button nz-button nzDanger nzType="text" (click)="deleteItem(index)">Delete</button>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <button *ngIf="canAddNewItem" nz-button nzType="dashed" (click)="onBtnNew()" class="top20">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Add New Vehicle
    </button>
  </div>
</ng-template>

