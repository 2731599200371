<ng-container *ngIf="isEmpty">
  <empty-info></empty-info>
</ng-container>
<ng-container *ngIf="customerAndEquipment">
  <customer-equipment-info [model]="customerAndEquipment"></customer-equipment-info>
</ng-container>
<div *ngIf="deliveryInfos?.length" style="height: 180px; position: relative;">
  <map-container [(map)]="map" (mapChange)="onMapChange()" mapStyle="streets-v11"></map-container>
</div>
<ng-container *ngIf="quote?.options?.length">
  <div *ngFor="let option of quote.options" class="quote-option flex">
    <div class="title flex1">{{ option.source }} Rate</div>
    <div class="sub-title">${{ option.rate.avg?.toFixed(1) }}</div>
  </div>
  <div class="line-divider"></div>
</ng-container>
<delivery-info [model]="deliveryInfos"></delivery-info>
<items-info [items]="listItems"></items-info>
