import { Component, Input } from "@angular/core";
import { FormShipmentLocation } from "../../shipment-location";
import { AddressUS, TimeWindow } from "@wearewarp/types/data-model";
import { MasterData } from "@services/master.data";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: '[form-shipment-location-batch-location-summary]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
})
export class FormShipmentLocationBatchLocationSummary {
  @Input() form: FormShipmentLocation;

  locationName(form: FormShipmentLocation): string {
    return form.getItemValue('locationName');
  }

  locationAddress(form: FormShipmentLocation): string {
    let addr: AddressUS = form.getItemValue('addr');
    return MasterData.getAddressText(addr);
  }

  hasAddress(form: FormShipmentLocation): boolean {
    let addr: AddressUS = form.getItemValue('addr');
    return addr?.street?.length > 0 && addr?.city?.length > 0 && addr?.state?.length > 0 && addr?.zipcode?.length > 0;
  }

  hasTimeWindows(form: FormShipmentLocation): boolean {
    let windows = form.getItemValue('windows');
    return windows?.[0].range?.[0] != null && windows?.[0].range?.[1] != null;
  }

  timeWindows(form: FormShipmentLocation): string[] {
    let addr: AddressUS = form.getItemValue('addr');
    let windows = form.getItemValue('windows') ?? [];
    let arr = [];
    for (let w of windows) {
      let from: Date = w?.range?.[0];
      let to: Date = w?.range?.[1];
      if (!from || !to) {
        continue;
      }
      from = DateUtil.convertLocalTime(from, addr?.metadata?.timeZoneStandard);
      to = DateUtil.convertLocalTime(to, addr?.metadata?.timeZoneStandard);
      let window: TimeWindow = {from: from.toISOString(), to: to.toISOString()};
      let str = DateUtil.displayTimeWindow(window, {timezone: addr?.metadata?.timeZoneStandard});
      arr.push(str);
    }
    return arr;
  }

}
