import { Component, ElementRef, Input, TemplateRef } from "@angular/core";
import { TaskType } from "@wearewarp/types"
import { FormDataShipmentLocation } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { BaseForm } from "../../../../base/form-base";
import { FormArray, FormControl, Validators } from "@angular/forms";
import { LocationFilterHeper } from "./helper/location";
import { Const } from "@const/Const";
import { ServiceOptionsHelper } from "./helper/service-options";
import { ExtendValidators } from "@app/admin/base/validator";
import { Utils } from "@services/utils";
import { BizUtil } from "@services/biz";
import { FormDataWindowTime, WindowTimeHelper } from "./helper/window";

@Component({
  selector: '[form-shipment-location]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FormShipmentLocation extends BaseForm<FormDataShipmentLocation> {
  // Có thể dùng 1 trong những template đã được implement sẵn hoặc là implement 1 template mới
  @Input() predefinedTemplate: 'default' | 'mobile' = 'default';
  @Input() viewTemplate: TemplateRef<any>;
  @Input() type: TaskType;
  @Input() genId: () => string = () => Utils.generateULID()

  public get shouldUseTemplateDefault() { return this.predefinedTemplate == 'default' }
  public get shouldUseTemplateMobile() { return this.predefinedTemplate == 'mobile' }

  protected formGroupDeclaration: FormGroupDeclaration = {
    id: {label: '', readOnly: true, submitReadOnly: true, notAcceptEmpty: true},
    shipmentId: {label: '', readOnly: true, submitReadOnly: true, notAcceptEmpty: true},
    clientId: {label: '', readOnly: true, submitReadOnly: true, notAcceptEmpty: true},
    type: {label: '', required: true, readOnly: true, submitReadOnly: true, notAcceptEmpty: true},
    locationName: {label: 'Location Name', notAcceptNull: true},
    warehouseId: {label: ''},
    addr: {label: 'Address', required: true},
    windows: {label: 'Windows', type: 'formArray', required: true, initialValue: [{}], childItem: {
      // from: {label: '', type: 'time', required: true, placeHolder: 'from date time', isChanged: DateUtil.diffHHmm},
      // to: {label: '', type: 'time', required: true, placeHolder: 'to date time', isChanged: DateUtil.diffHHmm},
      range: {label: '', required: true, placeHolder: ['Start date time', 'End date time'], validators: ExtendValidators.validateTimeWindow}    // dùng với <nz-range-picker>
    }},
    requiresAppointment: {label: 'Location requires appointment', type: 'boolean', initialValue: false },
    appointmentInfo: {label: 'Requested Date', type: 'formGroup', required: false, initialValue: [{}], childItem: {
      range: {label: '', required: false, placeHolder: ['Start date time', 'End date time'], validators: []}
    }},
    serviceOptions: {label: 'Service Options'},
    accessCode: {label: 'Access Code', notAcceptNull: true},
    instructions: {label: 'Instruction (External)', notAcceptNull: true, multiline: true},
    note: {label: 'Note (Internal)', notAcceptNull: true, multiline: true},
    refNums: {label: 'Reference number (can be multiple)', type: 'formArray', childItem: {label: 'Ref', notAcceptEmpty: true}, initialValue: ['']},
    primaryContact: {label: 'Primary Contact', type: 'formGroup', childItem: {
      fullName: {label: 'Contact Name'},
      phone: {label: 'Phone Number'},
      phoneExtension: {label: '', placeHolder: 'Ext'},
      email: {label: 'Contact Email', validators: Validators.email}
    }},
    secondaryContact: {label: 'Secondary Contact', type: 'formGroup', childItem: {
      fullName: {label: 'Contact Name'},
      phone: {label: 'Phone Number'},
      phoneExtension: {label: '', placeHolder: 'Ext'},
      email: {label: 'Contact Email', validators: Validators.email}
    }},
    settings: { label: 'Settings' , initialValue: Const.DefaultLocationSettings }
  }

  public locationHelper: LocationFilterHeper;
  public serviceOptionsHelper: ServiceOptionsHelper;

  constructor(protected hostElement: ElementRef<HTMLElement>) {
    super(hostElement);
  }

  ngOnInit(): void {
    if (!this.type) {
      throw Error('type is required');
    }
    this.locationHelper = new LocationFilterHeper(data => this.setLocation(data));
    this.serviceOptionsHelper = new ServiceOptionsHelper(this.type, () => this.getItemValue('serviceOptions'), data => this.setItemValue('serviceOptions', data));
    this.formGroupDeclaration.type.initialValue = this.type;
    if (this.model) {
      this.formGroupDeclaration.id.initialValue = this.model.id;
      if (Utils.isArrayNotEmpty(this.model.serviceOptions)) {
        this.serviceOptionsHelper.bindSelectedItems(this.model.serviceOptions);
      }
    }
    super.ngOnInit();
    if (this.model) this.onRequiresAppointmentChanged(this.model.requiresAppointment);
  }

  onRequiresAppointmentChanged(value: boolean) {
    let windowsChildKeys = [
      // 'from', 'to',
      'range'
    ];
    const isRequired = !value;
    this.formGroupDeclaration.windows.required = isRequired;
    for (let childKey of windowsChildKeys) {
      (<FormGroupDeclaration>this.formGroupDeclaration.windows.childItem)[childKey].required = isRequired;
    }
    const fa = <FormArray>this.formInput.get('windows');
    for (let i = 0; i < fa.length; i++) {
      let fg = fa.at(i);
      for (let childKey of windowsChildKeys) {
        let fc = <FormControl>fg.get(childKey);
        if (fc) {
          // Nếu requires appointment thì ko bắt buộc nhập windows nữa 
          if (!isRequired) {
            fc.removeValidators(Validators.required);
            fc.removeValidators(ExtendValidators.validateTimeWindow);
          } else {
            fc.addValidators(Validators.required);
            fc.addValidators(ExtendValidators.validateTimeWindow);
          }
          fc.updateValueAndValidity();
        }
      }
    }
  }

  private setLocation(locationObject: any) {
    this.setItemValue(`addr`, locationObject?.pickAddr);
    this.setItemValue(`primaryContact.fullName`, locationObject?.contactName);
    this.setItemValue(`primaryContact.phone`, locationObject?.phone);
    this.setItemValue(`primaryContact.phoneExtension`, locationObject?.phoneExtension);
    this.setItemValue(`primaryContact.email`, locationObject?.email);
    this.setItemValue(`secondaryContact.fullName`, locationObject?.secondaryContact?.contactName);
    this.setItemValue(`secondaryContact.phone`, locationObject?.secondaryContact?.phone);
    this.setItemValue(`secondaryContact.phoneExtension`, locationObject?.secondaryContact?.phoneExtension);
    this.setItemValue(`secondaryContact.email`, locationObject?.secondaryContact?.email);
    if (this.type == Const.TaskType.PICKUP && locationObject?.pickDetails) {
      this.setItemValue(`accessCode`, locationObject?.pickDetails?.accessCode);
      this.setItemValue(`instructions`, locationObject?.pickDetails?.instructions);
      this.setItemValue(`note`, locationObject?.pickDetails?.note);
    } else if (this.type == Const.TaskType.DROPOFF && locationObject?.dropDetails) {
      this.setItemValue(`accessCode`, locationObject?.dropDetails?.accessCode);
      this.setItemValue(`instructions`, locationObject?.dropDetails?.instructions);
      this.setItemValue(`note`, locationObject?.dropDetails?.note);
    }
    if (locationObject?.requireAppointment) {
      this.setItemValue(`requiresAppointment`, true);
      this.onRequiresAppointmentChanged(true);
    } else {
      this.setItemValue(`requiresAppointment`, false);
      this.onRequiresAppointmentChanged(false);
    }
    // TODO: handle service options at location level
    // if (Utils.isArrayNotEmpty(locationObject?.serviceOptions)) {
    //   let serviceOptionAddition = [];
    //   for (let item of locationObject?.serviceOptions) {
    //     if (this.serviceOptions.pickInfo.allItems.map(obj => obj._id).includes(item)) {
    //       if (!this.serviceOptions.pickInfo.selectedItems.includes(item)) {
    //         this.serviceOptions.pickInfo.selectedItems.push(item);
    //       }
    //     }
    //     if (MasterData.ShipmentServiceOptionsAddition.map(obj => obj._id).includes(item)) {
    //       serviceOptionAddition.push(item);
    //     }
    //   }
    //   this.serviceOptionsSelectionChange('pickInfo');
    //   // Nếu tồn tại service option có type là addition => update service option tại formCost
    //   if (Utils.isArrayNotEmpty(serviceOptionAddition)) {
    //     this.serviceOptionsAdditionChange(serviceOptionAddition);
    //   }
    // }
    if (!locationObject) {
      this.setItemValue(`locationName`, '');
    } else {
      this.setItemValue(`warehouseId`, locationObject?.id);
    }
  }

  public getFormData(): FormDataShipmentLocation {
    let data = super.getFormData();
    let timezone = data.addr?.metadata?.timeZoneStandard;
    let windows = WindowTimeHelper.formDataToModel(<FormDataWindowTime[]>(data.windows ?? []), timezone);
    data.windows = windows;

    let appointmentInfo = WindowTimeHelper.formDataToModel(<FormDataWindowTime[]>(data.appointmentInfo ? [data.appointmentInfo] : []), timezone);
    data.appointmentInfo = appointmentInfo.length ? appointmentInfo[0] : { from: null, to: null };

    let refNums = [];
    for (let item of data.refNums ?? []) {
      if (item && item.trim().length > 0) {
        refNums.push(item);
      }
    }
    data.refNums = refNums;
    data.contacts = [];
    if (BizUtil.isContactNotEmpty(data.primaryContact)) {
      data.contacts.push({...data.primaryContact, type: Const.ContactType.primary});
    }
    if (BizUtil.isContactNotEmpty(data.secondaryContact)) {
      data.contacts.push({...data.secondaryContact, type: Const.ContactType.secondary});
    }
    delete data.primaryContact;
    delete data.secondaryContact;
    if (!data.id) {
      data.id = this.genId();
      this.setItemValue('id', data.id);
    }
    return data;
  }

  protected beforeBindModel(model: FormDataShipmentLocation): FormDataShipmentLocation {
    if (model && !model.id) {
      // Chỗ này cần đảm bảo server trả về phải có sẵn id rồi, nếu ko thì id sẽ bị override khi save
      throw Error(`FormDataShipmentLocation model must have id before`);
    }
    let timezone = model.addr?.metadata?.timeZoneStandard;
    let windows = WindowTimeHelper.modelToFormData(model.windows ?? [], timezone);
    let data: any[] = [{ from: model.appointmentInfo?.from, to: model.appointmentInfo?.to }];
    let appointmentInfo = WindowTimeHelper.modelToFormData(data, timezone);
    model.appointmentInfo = <any>appointmentInfo[0];

    model.windows = windows.length ? <any>windows : [{}];
    for (let item of model.contacts ?? []) {
      switch (item.type) {
        case Const.ContactType.primary:
          model.primaryContact = item;
          break;
        case Const.ContactType.secondary:
          model.secondaryContact = item;
          break;
      }
    }
    return model;
  }

}