import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { DrawerService } from "@app/drawers/drawer.service";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { BizUtil } from "@services/biz";
import { InputHelper } from "@services/input-helper";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { RoutingVehicle } from "@wearewarp/types/data-model";
import { NzDrawerRef } from "ng-zorro-antd/drawer";
import { RoutingService } from "../../services/routing.service";
import { Route } from "../../services/objects/route";
import { ShipmentManagement } from "../../services/shipmentManagement";
import { Shipment } from "../../services/objects/shipment";
import { RoutingRouteShipmentAddNew } from "./add-new";
import { ProblemManagement } from "../../services/problemManagement";
import { NzModalRef } from "ng-zorro-antd/modal";

@Component({
  selector: '[routing-route-shipment]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class RoutingRouteShipment extends BaseComponent {
  @ViewChild('tplHeader') tplHeader: TemplateRef<any>;
  @ViewChild('tplContent') tplContent: TemplateRef<any>;
  originShipments: Shipment[] = [];


  isLoading = false;
  get canAddNewItem() { return !this.isLoading }
  get canSave() { return !this.isLoading && this.isListDataChanged() && this.shipments.length }
  get canCancel() { return !this.isLoading }

  private drawerRef: NzDrawerRef;
  private route: Route
  public shipments: Shipment[]
  public newShipment: any;
  public newShipmentOptions: any[] = [];

  constructor(
    private routingService: RoutingService,
    private shipmentManagement: ShipmentManagement,
    private problemManagement: ProblemManagement
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  setData(data) {
    this.route = data.route;
  }
  async searchNewShipment($event) {
    if (!$event) return;
    const result = await this.routingService.getListShipmentForAddMore({
      condition: {
        warpIds: [$event]
      }
    });
    if (!result.length) return;
    this.newShipmentOptions = result.map(item => new Shipment(item.id, item))
  }

  openDrawer() {
    this.drawerRef = DrawerService.openDrawer(null, {
      nzWrapClassName: 'routing-route-shipment',
      nzClosable: false,
      nzKeyboard: false,
      nzContent: this.tplContent,
      nzTitle: this.tplHeader,
    });
    this.drawerRef.afterOpen.subscribe()
    this.init()
  }

  init() {
    const shipmentIds = this.route.getShipmentIds()
    this.shipments = shipmentIds.map(shipmentId => this.shipmentManagement.getShipment(shipmentId));
    this.originShipments = [...this.shipments];
  }

  closeDrawer() {
    if (!this.canCancel) {
      return;
    }
    this.drawerRef?.close();
    this.drawerRef = undefined;
  }


  onBtnNew() {
    DialogService.openFormDialog1(RoutingRouteShipmentAddNew, {
      nzWrapClassName: 'routing-ltl-vehicles-add-new',
      nzComponentParams: {
        addItem: (data: Shipment[]) => {
          console.log(data)
        },
      },
      nzClosable: false,
      nzKeyboard: false,
      nzTitle: null,
      nzFooter: null,
    });
  }

  onSelectNewShipment(shipment: Shipment) {
    const isExist = this.shipments.find(s => s.getId() == shipment.getId());
    if (isExist) {
      this.showWarning("", `Shipment #${shipment.getId()} is already in the route.`);
      return;
    }
    if (shipment.getData().lastJobId) {
      this.showWarning("", `Shipment #${shipment.getId()} is already in the another route: 
      <b>${shipment.getData().lastJobId}</b>.<br />Please remove it from the old route before adding it to this route.`);
      return;
    }
    
    this.shipments = [shipment, ...this.shipments];
    this.newShipment = undefined;
    this.newShipmentOptions = [];
    this.showSuccess("Added shipment to route.");
  }

  onRemoveShipment(shipment) {
    this.shipments = [...this.shipments.filter(s => s.getId() !== shipment.getId())]
    this.showSuccess("Removed shipment from route.");

  }

  async onBtnSave() {
    if (!this.canSave) {
      return;
    }
    let currentShipmentIds = this.originShipments.map(s => s.getId());
    let nextShipmentIds = this.shipments.map(s => s.getId());
   
    let newShipments = this.shipments.filter(s => !currentShipmentIds.includes(s.getId()));
    let removeShipments = this.originShipments.filter(s => !nextShipmentIds.includes(s.getId()));
    await this.routingService.adjustShipment({
      routeId: this.route.getId(),
      newShipmentIds: newShipments.map(s => s.getId()),
      removeShipmentIds: removeShipments.map(s => s.getId())
    });
    this.closeDrawer()
    this.showDialogRoutingDone()
    this.problemManagement.setInManualRoute(this.route.getSubProblemId());
    await this.problemManagement.reloadProblem();

  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'availability':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      default:
        return true;
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'availability':
        return InputHelper.handleInputChangeNumberOnly(event, null, { isInteger: true });
      default:
        return;
    }
  }

  private isListDataChanged() {
    return !Utils.isArraysTheSame(this.shipments, this.originShipments, (a, b) => {
      return this.isShipmentTheSame(a, b);
    })
  }

  private isShipmentTheSame(a: Shipment, b: Shipment): boolean {
    return a.getId() == b.getId()
  }

  @ViewChild('tplRoutingDone') tplRoutingDone: TemplateRef<any>;
  private dlgRoutingDone: NzModalRef;
  private isDialogShowing = false;
  private showDialogRoutingDone() {

    if (this.isDialogShowing) return;
    this.isDialogShowing = true;

    this.dlgRoutingDone = this.modalService.create({
      nzContent: this.tplRoutingDone,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzWrapClassName: 'dialog-confirm-route-done'
    });
  }
  closeDialogRoutingDone() {
    this.dlgRoutingDone?.close();
    this.isDialogShowing = false;
  }


}