<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="f20 bottom30">Update status for user <b>{{model?.name}}</b></div>

  <div class="bottom20" *ngFor="let key of formInputKeys">
    <div class="form-register-value">
      <div *ngIf="key=='isActive'">
        <div nz-checkbox [formControlName]="key">{{getLabel(key)}}</div>
      </div>
    </div>
  </div>

</form>

<div form-footer [onProgress]="onProgress"
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress"
  [nzIconOK]="'save'" [labelOK]="'Save'" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"></div>
