import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { VolumeDiscountType } from "@app/enum";
import { DialogService } from "@dialogs/dialog.service";
import { ShipmentRevenueEdit } from "./shipment_revenue_edit";


@Component({
    selector: '[shipment-revenue]',
    templateUrl: './shipment_revenue.html',
    styleUrls: ['./shipment_revenue.scss']
})
export class ShipmentRevenue extends BaseComponent {
    @Input() cost: any = null
    @Input() shipmentId: string = null
    @Input() editMode: boolean = false
    @Output() onCostUpdate = new EventEmitter()

    getVolumeDiscountType(item) {
        switch (item.volumeDiscount?.type) {
            case VolumeDiscountType.percentage: return '%';
            case VolumeDiscountType.flatRate: return 'Flat Rate';
            default: return '';
        }
    }

    onBtnEdit(): void {
        DialogService.openFormDialog1(ShipmentRevenueEdit, {
            nzComponentParams: {
                closeOnSuccess: true,
                cost: this.cost,
                shipmentId: this.shipmentId,
                isCreateNewCost: !this.cost,
                onDone: (data) => this.onCostUpdate.emit(data),
            },
            nzClassName: "modal-xl",
        });
    }

}