<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="bottom30 flex">
    <div class="f20" style="flex: 1; height: 50px">Please select filter conditions</div>
    <button *ngIf="canClear" nz-button class="btn1" (click)="onBtnClear()">
      <i nz-icon nzType="clear" nzTheme="outline"></i>Clear filter
    </button>
  </div>

  <div class="bottom20" *ngFor="let key of ['clientId', 'shipmentType'],">
    <div class="form-label-v2" *ngIf="getLabel(key)">
      {{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span>
    </div>
    <div>
      <ng-container [ngSwitch]="key">
        <select-by-searching
          *ngSwitchCase="'clientId'"
          [version]="2"
          [formControlName]="key"
          [(listData)]="listClients"
          [placeholder]="getPlaceHolder(key)"
          (modelChange)="onClientChange($event)"
        >
        </select-by-searching>

        <nz-select
          *ngSwitchCase="'shipmentType'"
          style="width: 100%"
          nzAllowClear
          nzShowSearch
          [formControlName]="key"
          [nzNotFoundContent]="noDataWarehouse"
        >
          <nz-option
            *ngFor="let item of listShipmentTypes"
            [nzLabel]="getShipmentTypeName(item)"
            [nzValue]="item"
          ></nz-option>
        </nz-select>

        <!-- <nz-select *ngSwitchCase="'podStatus'" style="width: 100%" nzAllowClear [formControlName]="key">
          <nz-option [nzLabel]="'Not confirmed yet'" [nzValue]="'not_confirmed'"></nz-option>
          <nz-option [nzLabel]="'Has been confirmed'" [nzValue]="'confirmed'"></nz-option>
        </nz-select>

        <nz-select *ngSwitchCase="'invoiceStatus'" style="width: 100%" nzAllowClear [formControlName]="key">
          <nz-option [nzLabel]="'Not created'" [nzValue]="'not_created'"></nz-option>
          <nz-option [nzLabel]="'Created'" [nzValue]="'created'"></nz-option>
        </nz-select> -->

        <input
          *ngSwitchDefault
          nz-input
          [formControlName]="key"
          [type]="getInputType(key)"
          [placeholder]="getPlaceHolder(key)"
        />
      </ng-container>
    </div>
  </div>

  <div class="form-label-v2">Intended Pickup date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker
      *ngFor="let key of ['pickupFromDate', 'pickupToDate']; let i = index"
      class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key"
      nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)"
    >
    </nz-date-picker>
  </div>

  <div class="form-label-v2">Intended Delivery date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker
      *ngFor="let key of ['dropoffFromDate', 'dropoffToDate']; let i = index"
      class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key"
      nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)"
    >
    </nz-date-picker>
  </div>

  <div class="form-label-v2">Actual Pickup date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker
      *ngFor="let key of ['actualPickupFromDate', 'actualPickupToDate']; let i = index"
      class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key"
      nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)"
    >
    </nz-date-picker>
  </div>

  <div class="form-label-v2">Actual Delivery date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker
      *ngFor="let key of ['actualDropoffFromDate', 'actualDropoffToDate']; let i = index"
      class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key"
      nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)"
    >
    </nz-date-picker>
  </div>
</form>

<div
  form-footer
  [onProgress]="onProgress"
  [canClickOK]="needUpdate && !onProgress"
  [canClickCancel]="!onProgress"
  [nzIconOK]="'filter'"
  [labelOK]="'OK'"
  (onOK)="onBtnSave()"
  (onCancel)="onClose()"
></div>
