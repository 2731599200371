import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { Mapbox } from "@services/mapbox";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { DeliveryInfo, LatLng } from "@wearewarp/types/data-model";
import polyline from '@mapbox/polyline';
import mapboxgl, { LngLatLike } from "mapbox-gl";
import { MasterData } from "@services/master.data";

@Component({
  selector: '[edit-shipment-entry-map]',
  templateUrl: './index.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush 
})
export class EditShipmentEntryMap extends BaseComponent {

  public mapBox: MapBoxV2;
  public isZoom: boolean = false;
  public dropOffStop: Array<[number, number]> = [];
  public pickUpStop: Array<[number, number]> = [];

  private _infos: DeliveryInfo[] = [];

  @Input() get infos(): DeliveryInfo[] {return this._infos}
  set infos(value: DeliveryInfo[]) {
    let isEqual = true;
    if (value?.length != this?._infos?.length) {
      isEqual = false;
    } else if (value?.length && value?.length == this?._infos?.length) {
      for (let index = 0; index < value.length; index++) {
        const addr1 = value[index]?.addr || {};
        const addr2 = this?._infos[index]?.addr || {};
        if (typeof addr1 != typeof addr2) {
          isEqual = false;
        } else if (!this.objectsEqual(this.processLatLong(addr1), this.processLatLong(addr2))) {
          isEqual = false;
        }
      }
    }
    this._infos = value;
    if (!isEqual && value?.length > 1) {
      this.fetchRouteData();
    }
  }
 
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.mapBox = new MapBoxV2();
    this.setupMap();
  }
  
  ngOnDestroy() {
    if (this.mapBox) {
      this.mapBox.remove();
      this.mapBox = null;
    }
  }

  get map() {
    return this.mapBox.getMapbox();
  }

  private setupMap() {
    this.mapBox.setupMap();
  }

  objectsEqual (o1, o2) {
    return JSON.stringify(o1) === JSON.stringify(o2) ? true : false;
  }

  processLatLong(address) {
    const latitude = address?.metadata?.latitude || address?.location?.latitude;
    const longitude = address?.metadata?.longitude || address?.location?.longitude;
    return {latitude, longitude};
  } 

  processLocations(): LatLng[] {
    const locations: LatLng[]= [];
    this.pickUpStop = [];
    this.dropOffStop = [];
    if (this.infos?.length > 1) {
      for (let index = 0; index < this.infos.length; index++) {
        const info = this.infos[index];
        const {latitude, longitude} = this.processLatLong(info?.addr);
        if (latitude && longitude) {
          locations.push({
            latitude,
            longitude 
          });
          if (info.type == Const.TaskType.PICKUP) {
            this.pickUpStop.push([longitude, latitude]);
          }
          if (info.type == Const.TaskType.DROPOFF) {
            this.dropOffStop.push([longitude, latitude]);
          }
        }
      }
    }
    return locations;
  }

  showDataOnTheMap(resp) {
    const routeData = resp?.data;
    if (routeData) {
      let line = routeData.line ? polyline.decode(routeData.line) : routeData.points;
      let route = line.map(it => [it[1], it[0]]);
      const routes = [];
      if (route.length > 0) {
        routes.push(route);
      }
      if (routes.length > 0) {
        this.mapBox.clearMap();
        setTimeout(() => {
          if (this.isZoom) {
            this.mapBox.addRoute(routes, false);
          } else {
            this.isZoom = true;
            this.mapBox.addRoute(routes, true);
          }
          // Log.d('pickUpStop', this.pickUpStop);
          // Log.d('dropOffStop', this.dropOffStop);
  
          this.mapBox.addPoints({points: this.pickUpStop, color: Const.MAP_LOCATION_COLOR_PICK, des: 'pickup'});
          this.mapBox.addPoints({points: this.dropOffStop, color: Const.MAP_LOCATION_COLOR_DROP, des: 'dropoff'});
        }, 200);
      }
    }
  }
  // api route-data trên server đang để branch: feature/route-data-with-location-list
  fetchRouteData() {
    const locations: LatLng[] = this.processLocations();
    // Log.d('locations ', locations);
    if (locations?.length > 1) {
      let url = `${Const.APIURI_ORDERS_V5}/route-data`;
      this.api.POST(url, {locations}).subscribe(
        resp => {
          // Log.d('fetchRouteData done ', resp);
          this.showDataOnTheMap(resp);
        }, err => {
          this.showErr(err);
        }
      );
    }
  }
}

class MapBoxV2 extends Mapbox {
  constructor(center = null) {
    super(center);
  }

  public setupMap(routes = [], points = [], driverLocations = [], style = Const.MapBox.style.light, byFitBounds = Const.MapBox.FitBoundsBy.routes) {
    const colorPick = Const.MAP_LOCATION_COLOR_PICK;
    const colorDrop = Const.MAP_LOCATION_COLOR_DROP;
    let center = <LngLatLike>Const.MAP_LOCATION_LA;
    if (routes.length > 0) {
      center = [routes[0][0][0], routes[0][0][1]];
    } else if (points.length) {
      center = [points[0][0][0][0], points[0][0][0][1]];
    }
    if (!this.map) {
      mapboxgl.accessToken = MasterData.mapboxToken;
      this.map = new mapboxgl.Map({
        container: 'edit-shipment-entry-map',
        style: Const.MAPBOX_STYLE(style),
        center: <any>center,
        zoom: 12
      });
      this.map.isMapReady = false;
      this.map.ready = () => this.map.isMapReady;
      this.map.once('load', () => this.map.isMapReady = true);
    } else {
      this.clearLayerSoureImg();
      this.addLayerSourceImage(routes, driverLocations, points, colorPick, colorDrop);
    }
    let features = [];
    this.map.on('style.load', () => {
      this.addLayerSourceImage(routes, driverLocations, points, colorPick, colorDrop);
    });
    if (routes.length > 0 && byFitBounds == Const.MapBox.FitBoundsBy.routes) {
      this.fitBoundsMap(this.map, routes);
    } else if (points.length) {
      this.fitBoundsMapPoints(this.map, points);
    }
  }
}
