import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Const } from '@const/Const';
import { ApiService } from '@services/api.service';

@Injectable({providedIn: 'root'})
export class ShipmentDetailService {
  private routeCostData = new BehaviorSubject({});
  public routeCostData$ = this.routeCostData.asObservable();

  constructor(private api: ApiService) {

  }

  changeRouteCostData(data) {
    this.routeCostData.next(data)
  }

}
