<div class="dashboard-child-container no-padding list">
  <div class="list-header small">
    <div filter-revenue #filterRevenue></div>
  </div>

  <div nz-row class="booked-body">
    <div nz-col nzXs="24" nzMd="24" class="booked-chart">
      <ng-container *ngTemplateOutlet="tplChart; context: { data: dataChart['revenue'], key: 'revenue' }"></ng-container>
      <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </div>

    <ng-template #tplChart let-data="data" let-key="key">
      <div>
        <div *ngIf="data" chart-line id="chart-data-{{key}}" [options]="data"></div>
      </div>
      <div class="booked-table">
        <div class="booked-title">List of Revenue & Revenue Average Booked</div>

        <nz-table
          #nzTable nzBordered="true" [nzData]="listData" nzSize="small"
          [nzTotal]="totalCount" [nzFrontPagination]="true"
          [nzShowPagination]="totalCount > listData.length" [nzPageSize]="limit"
          [nzLoading]="isLoading" [nzLoadingIndicator]="tplLoading"
          [nzNoResult]="tplNoData" [nzSize]="'default'"
          (nzPageIndexChange)="onDataListPageChanged($event)"
        >
          <ng-template #tplNoData>
            <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
          </ng-template>
          <ng-template #tplLoading>
            <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
          </ng-template>
          <thead>
            <tr>
              <th nzColumnKey="date">Date</th>
              <th nzColumnKey="revenue">Revenue</th>
              <th nzColumnKey="average">Average Revenue</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listData; let i = index">
              <td>{{ item.date }}</td>
              <td>{{ $asMoney(item.revenue) }}</td>
              <td>{{ $asMoney(item.average_revenue) }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </ng-template>
  </div>
</div>
