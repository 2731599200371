<div class="flex-column" style="height: 100%;">
  <div class="flex-column" style="flex: 1">
    <div class="title">{{addressInfo.locationName}}</div>
    <div class="content">
      <div class="text"><b>Address:</b> {{addressInfo?.addressText}}</div>
      <div class="text"><b>Location:</b> {{addressInfo?.location?.latitude}}, {{addressInfo?.location?.longitude}}</div>
    </div>
    <div class="tasks">
      <div class="task-title">Tasks:</div>
      <div class="task-list">
        <nz-table #rowSelectionTable nzShowSizeChanger [nzData]="tasks" nzSize="small" nzShowPagination="false">
          <thead>
            <tr>
              <th [nzChecked]="isSelectedAll()" [nzIndeterminate]="isIndeterminate()"
                (nzCheckedChange)="onAllChecked($event)" [nzWidth]="50"></th>
              <th>Type</th>
              <th>WARP ID</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of rowSelectionTable.data">
              <td [nzChecked]="isSelected(data)" (nzCheckedChange)="onSelect(data, $event)"
                [nzDisabled]="!canChange(data)"></td>
              <td><nz-tag [nzColor]="data.type == 'DROPOFF'? 'green': 'purple'">{{data.type }}</nz-tag> </td>
              <td>{{ showWarpId(data) }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>

  <button nz-button nzSize="small" class="btn-close" (click)="onBtnClose()">
    <span nz-icon nzType="close" nzTheme="outline"></span>
  </button>
</div>