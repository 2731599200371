<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div *ngIf="countRefNums == 0">Item has no reference number. <a (click)="onBtnAddFormArray()">Add now</a></div>
    <div *ngIf="'refNums' as key" class="bottom10 {{key}}">
      <div *ngIf="countRefNums > 0" class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
      <ng-container [formArrayName]="key">
        <div *ngFor="let f of getArrayControls(key); let arrIndex = index" style="display: flex;">
          <nz-form-item>
            <nz-form-control>
              <input nz-input [formControlName]="arrIndex" style="margin-right: 20px">
              <a (click)="onBtnRemoveFormArray(arrIndex)" class="danger right10">
                <i nz-icon nzType="close-square" nzTheme="outline"></i> Remove
              </a>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
      <button *ngIf="countRefNums > 0" nz-button nzType="dashed" (click)="onBtnAddFormArray()">
        <span nz-icon nzType="plus" nzTheme="outline"></span>
      </button>
    </div>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>