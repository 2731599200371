<div [class]="['pod-wrapper', compact ? 'compact': 'full']">
    <img *ngIf="!pdf" class="pod" [src]="pod.fullPath" (click)="toggleCompact()">
    <div *ngIf="pdf" class="pdf-viewer" (click)="toggleCompact()">
        <pdf-viewer [fit-to-page]="1" [zoom-scale]="'page-height'" [src]="pod.fullPath" style="height: 100%"></pdf-viewer>
    </div>
    <div class="info">
        <span [class]="['right10', 'task-type', pod.metadata?.taskType || pod.type]">{{ pod.metadata?.taskType || pod.type }}</span>
        <span class="confirming-pod-form right10">
            Confirm
            <nz-switch (ngModelChange)="onConfirmed($event)" nzSize="small" [(ngModel)]="pod.confirmed"></nz-switch>
        </span>
        <span (click)="download()" class="download-button">Download</span>
    </div>
</div>

<nz-drawer
[nzClosable]="true"
[nzVisible]="viewDrawer"
nzWidth="calc(100vw - 280px)"
nzSize="large"
nzPlacement="right"
nzTitle=""
(nzOnClose)="closeDrawer()"
>
<ng-container *nzDrawerContent>
    <pdf-viewer [fit-to-page]="1" [zoom-scale]="'page-height'" [src]="pod.fullPath" style="height: 100%"></pdf-viewer>
</ng-container>
</nz-drawer>
