<div class="dashboard-child-container no-padding">
  <div *ngIf="shouldShowFirstLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">
    <nz-collapse>
      <nz-collapse-panel *ngFor="let panel of formInputKeys" [nzHeader]="getLabel(panel)" [nzActive]="true" nz-form
        [formGroupName]="panel">

        <div *ngFor="let key of getChildItemKeys(panel)">
          <nz-form-item>
            <nz-form-label>
              {{getLabel(fullKey(panel, key))}}
              <span *ngIf="isRequired(fullKey(panel, key)) && getLabel(fullKey(panel, key)).length > 0"
                class="label-mark-required"></span>
            </nz-form-label>
            <nz-form-control [nzSpan]="getFieldInfoByKey(fullKey(panel, key)).span || 24">
              <input nz-input [formControlName]="key"
                *ngIf="getFieldInfoByKey(fullKey(panel, key)).templateType == 'input'" />

              <setting-version-file [formControl]="getItemByKey(fullKey(panel, key))"
                (ngModelChange)="onFileChange($event, panel)"
                [appKey]="getFieldInfoByKey(fullKey(panel, key)).appKey"
                *ngIf="getFieldInfoByKey(fullKey(panel, key)).templateType == 'files'">
              </setting-version-file>
            </nz-form-control>
          </nz-form-item>
          <setting-version-file-history *ngIf="getFieldInfoByKey(fullKey(panel, key)).templateType == 'files'"
            [data]="getHistories(panel)">
          </setting-version-file-history>
        </div>

      </nz-collapse-panel>
    </nz-collapse>

    <!-- Button save & cancel -->
    <div detail-footer-buttons labelButtonCreate="Create New" [isCreateNew]="isCreateNew" [isEditing]="isEditing"
      style="margin-top: 20px;" [onProgress]="onProgress" [needUpdate]="needUpdate" (onEdit)="onBtnEdit()"
      (onSave)="onBtnSave()" (onCancel)="onBtnCancel()">
    </div>
  </form>
</div>