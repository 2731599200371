<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Add/Remove Stops</div>
  </div>
</ng-template>
<div style="display: flex; flex: 1;background-color: #F0F2F5;">
  <div style="flex: 1; padding-right: 20px; background: #FFFFFF;">
    <div class="form-location" *ngIf="isMultiPick" 
      #formMultiPick form-edit-shipment-batch-multi-pick 
      [currentShipmentEntryMode]="currentShipmentEntryMode"
      [modelOrder]="modelOrder"
      (onListStopsChange)="onListStopsChange($event)">
    </div>
    <div class="form-location" *ngIf="isMultiDrop" 
      #formMultiDrop form-edit-shipment-batch-multi-drop 
      [currentShipmentEntryMode]="currentShipmentEntryMode"
      [modelOrder]="modelOrder"
      (onListStopsChange)="onListStopsChange($event)">
    </div>
    <div class="form-location" *ngIf="isMultiPickDrop" 
      #formMultiPickDrop form-edit-shipment-batch-multi-pick-drop 
      [currentShipmentEntryMode]="currentShipmentEntryMode"
      [modelOrder]="modelOrder"
      (onListStopsChange)="onListStopsChange($event)">
    </div>
  </div>
  <div style="width: 330px;background: #FFFFFF;">
    <div [infos]="locations" edit-shipment-entry-map ></div>
    <drag-and-drop-locations [batchShipmentType]="targetShipmentEntryMode" [locations]="locations" (onChange)="onChangeLocations($event)"></drag-and-drop-locations>
  </div>
</div>
<div class="separator h top30"></div>
<div form-footer class="no-border"
  [onProgress]="onProgress"
  [canClickOK]="canUpdate && !onProgress" 
  [canClickCancel]="!onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>