import { TabContentComponent } from "@app/admin/base/tabs/interface";
import { ShipmentEntryContext } from "../interface";
import { AfterViewInit, Directive, OnDestroy, OnInit } from "@angular/core";
import { FormDataShipmentEntryDraftData } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";

@Directive()
export class ShipmentEntryTabContent implements TabContentComponent, OnInit, OnDestroy, AfterViewInit {
  private _id: string;
  private _context: any;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  setId(id: string) {
    this._id = id;
  }

  get id(): string {
    return this._id;
  }

  setContext(context: any) {
    this._context = context;;
  }

  get context(): ShipmentEntryContext {
    return this._context;
  }

  get modelDraft(): FormDataShipmentEntryDraftData | undefined {
    return this.context?.modelDraft;
  }

  onTabActive(active: boolean) {
  }

}