import { Component } from "@angular/core";
import { DrawerService } from "@app/drawers/drawer.service";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Utils } from "@services/utils";
import { TimeWindow } from "@wearewarp/types/data-model";
import { AllOrder } from "../all-orders";

@Component({
  selector: '[fill-shipment-window]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class FillShipmentWindow extends BaseFormDialog1 {
  public window: {pick?: TimeWindow, drop?: TimeWindow} = {};
  public timezone: {pick?: string, drop?: string} = {};

  set data(value: {pick: any[], pickTz: string, drop: any[], dropTz: string}) {
    let fnSort = (a, b) => a > b ? 1 : (a < b ? -1 : 0);
    this.viewData = {
      pick: {
        listWarpIds: Utils.uniqElementsArray(value.pick.map(it => it.warpId)).sort(fnSort),
        listIds: Utils.uniqElementsArray(value.pick.map(it => it.id)),
        type: 'pickup',
        taskType: Const.TaskType.PICKUP,
        textListShipments: '',
        text: '',
        titleSeeAll: '',
        isLoading: false,
        isDone: false,
      },
      drop: {
        listWarpIds: Utils.uniqElementsArray(value.drop.map(it => it.warpId)).sort(fnSort),
        listIds: Utils.uniqElementsArray(value.drop.map(it => it.id)),
        type: 'delivery',
        taskType: Const.TaskType.DROPOFF,
        textListShipments: '',
        text: '',
        titleSeeAll: '',
        isLoading: false,
        isDone: false,
      }
    }
    this.timezone = {pick: value.pickTz, drop: value.dropTz}
    this.updateText();
  }

  // key: 'pick'|'drop' dùng trong html
  private viewData: {
    [key: string]: {
      listIds: string[],
      listWarpIds: string[],
      type: string,           // dùng để hiển thị lên UI (pickup, delivery)
      taskType: string,       // dùng để query database (PICKUP, DROPOFF)
      textListShipments: string,
      text: string,
      titleSeeAll: string,
      isLoading: boolean,
      isDone: boolean,
    }
  };

  constructor() {
    super();
  }

  private showListShipmentIds(listIds: any[]) {
    if (!Utils.isArrayNotEmpty(listIds)) {
      return '';
    }
    let str = `${listIds[0]}`;
    if (listIds.length > 1) {
      str += ` (+${listIds.length - 1})`;
    }
    return str;
  }

  private updateText() {
    for (let key of Object.keys(this.viewData)) {
      let list = this.viewData[key].listWarpIds;
      let type = this.viewData[key].type;
      this.viewData[key].textListShipments = this.showListShipmentIds(list);
      this.viewData[key].text = `Fill ${type} window for ${Utils.displayCount(list.length, 'shipment')}`;
      this.viewData[key].titleSeeAll = list.length > 1 ? `${list.length} shipments need ${type} window` : `${list.length} shipment needs ${type} window`;
    }
  }

  getText(key: string): string {
    return this.viewData[key]?.text ?? '';
  }

  getTextListShipments(key: string): string {
    return this.viewData[key]?.textListShipments ?? '';
  }

  countShipment(key): number {
    return this.viewData[key]?.listIds?.length ?? 0
  }

  isLoading(key: string): boolean {
    return this.viewData[key]?.isLoading ?? false;
  }

  seeAll(key: string) {
    let list = this.viewData[key].listWarpIds;
    let title = this.viewData[key].titleSeeAll;
    DrawerService.openFormDrawer(AllOrder, {
      nzContentParams: {
        textTitle: title,
        listOrderIds: list,
        canExcludeItem: true,
        onRemoveItem: (index) => {
          this.confirmDeletion({
            message: `Exclude shipment <b>${list[index]}</b>?`,
            fnOk: () => {
              list.splice(index, 1);
              this.updateText();
            },
            center: false,
            txtBtnOk: 'Exclude'
          });
        }
      },
      nzMaskClosable: true,
    });
  }

  onTimeWindowChange(key: string, event: TimeWindow) {
    // console.log('timeWindowChange: ', key, ', value: ', event);
    // console.log(this.window);
  }

  canSave(key) {
    return !!this.window[key]?.from && !!this.window[key].to;
  }

  isDone(key) {
    return this.viewData[key]?.isDone == true;
  }

  onSave(key: string) {
    let params = {
      shipmentIds: this.viewData[key].listIds,
      type: this.viewData[key].taskType,
      window: this.window[key],
    };
    this.viewData[key].isLoading = true;
    this.api.POST(Const.APIURI_LTL_ROUTING('shipments/timewindow'), params).subscribe(
      resp => {
        this.updateSuccess(params);
        this.viewData[key].isLoading = false;
        this.viewData[key].isDone = true;
      }, err => {
        this.showErr(err);
        this.viewData[key].isLoading = false;
      }
    );
  }

  onClose(key: string) {
    this.viewData[key].listIds = [];
    if (!this.viewData.pick?.listIds?.length && !this.viewData.drop?.listIds?.length) {
      this.closeDialog();
    }
  }

  isDisabledForm(key: string) {
    return this.viewData[key]?.isDone == true;
  }

}