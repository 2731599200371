import { Component } from '@angular/core';
import { FormControl } from '@angular/forms/forms';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { MasterData } from '@services/master.data';
import { InputHelper } from '@services/input-helper';

@Component({
  selector: '[routing-route-shipment-add-new]',
  templateUrl: './add-new.html',
  styleUrls: ['./style.scss']
})
export class RoutingRouteShipmentAddNew extends BaseFormDialog1 {

  addItem: (data) => void = (data) => {}

  constructor() {
    super();
 }

  
  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    let params = this.getFormData(true);
    this.addItem(params);
    this.closeDialog();
  }

}