<div class="flex bottom10">
  <div class="problem-id flex1">{{getId()}}</div>
  <div class="problem-status">{{getStatus()}}</div>
</div>

<div class="center-vertical bottom20">
  <div class="region border-color-{{getRegion()}} color-{{getRegion()}}">Region {{getRegion()}}</div>
  <div class="flex1" style="display: flex; justify-content: flex-end; align-items: center;">
    <ng-container *ngIf="!isRoutingCompleted && !isRoutingNeedReview">
      <i *ngIf="isRoutingInProgress" class="loading md primary" nz-icon nzType="loading" nzTheme="outline"></i>
      <nz-button-group>
        <button *ngIf="canDoRoute" nz-button nzType="primary" class="btn-start-route left10"
          (click)="onBtnStartRouting()" [disabled]="isRoutingInProgress || isUpdatingVehicles">
          <img class="play_circle">
          {{btnTextReroute}}
        </button>
        <button *ngIf="canDoRoute" nz-button nzType="default" class="btn-start-route"
          [disabled]="isRoutingInProgress || isUpdatingVehicles" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <span nz-icon nzType="more" nzTheme="outline"></span>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="onManualRoute()">Manual Route</li>
          </ul>
        </nz-dropdown-menu>
      </nz-button-group>
      <button *ngIf="!canDoRoute" nz-button nzType="default" class="btn-start-route left10"
        (click)="onBtnStopRouting()">
        <img class="pause_circle">
        Stop Routing
      </button>
    </ng-container>
  </div>
</div>

<div *ngIf="!alreadyHadRoute" class="flex1 bottom10 clickable" style="width: fit-content;" nz-popover
  nzPopoverTrigger="click" [nzPopoverOverlayClassName]="'popover-list-shipments'" [nzPopoverTitle]="'WARP ID'"
  [nzPopoverContent]="tplListShipments">
  {{shipmentCount}}
  <span nz-icon nzType="info-circle" nzTheme="outline" style="margin-left: 3px; font-size: 13px;"></span>
</div>
<ng-template #tplListShipments>
  <div>{{listShipmentIdsText}}</div>
</ng-template>

<ng-container *ngIf="alreadyHadRoute">
  <div *ngFor="let route of routes" class="route">
    <div routing-route [route]="route"></div>
  </div>
</ng-container>

<div>
  <div class="label1" *ngIf="workload">Workload: {{workload}}.</div>
  <div class="label1" *ngIf="totalWeight">Total Weight: {{totalWeight}} lbs.</div>
  <div class="label1">Select vehicle</div>
  <div style="position: relative;">
    <nz-select nzBackdrop="true" style="width: 100%;" nzAllowClear [(ngModel)]="selectedVehicleId" [nzLoading]="isUpdatingVehicles"
      [nzDisabled]="!canDoRoute" [nzDropdownRender]="tplBtnAddMore" [nzOptionHeightPx]="56"
      [nzDropdownClassName]="'select-vehicle'" (ngModelChange)="onVehicleSelected($event)">
      <nz-option *ngFor="let item of listVehicles" [nzValue]="item._id" nzCustomContent
        [nzLabel]="getVehicleName(item)">
        <div>{{getVehicleName(item)}}</div>
        <div style="font-size: 11px; color: #6b6b6b;">{{getVehicleDesc(item)}}</div>
      </nz-option>
    </nz-select>
    <ng-template #tplBtnAddMore>
      <div class="add-more-vehicle" (click)="onBtnAddMoreVehicles()">
        <a>
          <span nz-icon nzType="plus"></span>
          Add more vehicle
        </a>
      </div>
    </ng-template>
  </div>
  <div *ngIf="vehicleOptions?.length" class="vehicle-option">
    <label class="right10">With</label>
    <label *ngFor="let option of vehicleOptions"
      nz-checkbox
      [ngModel]="vehicleSelectOptions.indexOf(option) >= 0"
      (ngModelChange)="onOptionChange(option, $event)"
    >
      <normalized-string [str]="option"></normalized-string>
    </label>
  </div>
  <div class="label2">{{getSelectedVehicleDesc()}}</div>
</div>

<div *ngIf="unassignedShipments.length > 0" class="unrouted-shiments">
  <div class="unrouted-shiments-text">{{unassignedShipmentsText}}</div>
  <div class="arr-items-inline">
    <a *ngFor="let shipmentId of unassignedShipments" class="unrouted-shiments-text" target="_blank"
      [routerLink]="[routeAdminShipmentList, shipmentId]">{{shipmentId}}</a>
  </div>
  <div class="unrouted-shiments-text">Please check if window and distance of the shipment are feasible to route.</div>
  <div class="unrouted-shiments-text">or click the button below to solve the problem manually.</div>
  <div class="solve-manually">
    <button nz-button nzType="primary" (click)="solveUnroutedManually()">Solve Manually</button>
  </div>
  
</div>

<div *ngIf="isRoutingNeedReview" class="unrouted-shiments">
  <div class="unrouted-shiments-text">This problem was routed manually. Next, you need to re-order the stops in this route.</div>
</div>

<ng-template #tplRoutingManualDone>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <img class="ic_complete bottom20">
    <div class="bottom10" style="font-size: 18px; font-weight: 600; line-height: 27px; text-align: center;">All shipments have been <br />routed manually.</div>
    <div style="font-size: 14px; font-weight: 400; line-height: 27px;">Next, you need to re-order the stops in this route.</div>
    <button nz-button nzType="primary" style="width: 100%; margin-top: 16px;" (click)="closeDialogRoutingDone()">Close</button>
  </div>
</ng-template>