<div class="form-header">
  <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
  <div class="form-title">
    <div>
      <div class="form-title-main">{{formTitle}} <i *ngIf="isHistoryExisting" (click)="goToHistory()" class="clickable" nz-icon nzType="history" nzTheme="outline" nz-tooltip nzTooltipTitle="View Changes History"></i></div>
      <div class="form-title-other">{{formTitleOther}}</div>
    </div>
    <ng-container *ngIf="requirePermissions([PermissionCode.warehouse.update])">
      <div *ngIf="!isEditOrCreate" class="form-title-button">
        <button nz-button (click)="onBtnEdit()">Edit</button>
      </div>
    </ng-container>
    <ng-container *ngIf="requirePermissions([PermissionCode.warehouse.create])">
      <div *ngIf="isEditOrCreate" class="form-title-button">
        <div form-footer class="no-border" [onProgress]="onProgress" 
          [canClickOK]="needUpdate && !onProgress"
          [canClickCancel]="!onProgress" 
          (onOK)="onBtnSave()" 
          (onCancel)="onClose()"></div>
      </div>
    </ng-container>
  </div>
</div>
<div class="separator h top10"></div>
<ng-container *ngIf="!isEditOrCreate">
  <div class="location-detail">
    <div class="group no-padding">
      <div class="bold f16">Address Details</div>
      <div nz-row [nzGutter]="{ sm: 16}" class="location-detail-row">
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Address Type</div>
          <div *ngIf="model.addressType" class="location-detail-row-value">{{capitalizeFirstLetter(model.addressType) || '-'}}</div>
        </div>
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Address</div>
          <div class="location-detail-row-value">{{getAddressText(this.model.pickAddr) || '-'}}</div>
        </div>
      </div>
      <div nz-row [nzGutter]="{ sm: 16}" class="location-detail-row">
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Service Options</div>
          <div class="location-detail-row-value">{{getServiceOptions_Full(model.serviceOptions) || '-'}}</div>
        </div>
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Other Details</div>
          <div class="location-detail-row-value flex">
            <div *ngIf="model.isHazmatLocation" class="hazmat-location width-fit-content right10"><i nz-icon nzType="warning" nzTheme="outline" style="margin-right: 5px;"></i>Hazmat Location</div>
            <div *ngIf="model.requireAppointment" class="require-appointment width-fit-content"><i nz-icon nzType="carry-out" nzTheme="outline" style="margin-right: 5px;"></i>Appointment Required</div>
            <div *ngIf="!model.isHazmatLocation && !model.requireAppointment">-</div>
          </div>
        </div>
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Warehouse Type</div>
          <div class="location-detail-row-value">{{getWarehouseTypeText(model.warehouseType)}}</div>
        </div>
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Organization</div>
          <div class="location-detail-row-value">{{getOrganizationText(model.orgId)}}</div>
        </div>
      </div>
    </div>
    <!--Manifest feature-->
    <ng-container *ngIf="isCrossDock">
      <div class="top20"></div>
      <div class="group no-padding">
        <div class="bold f16">Manifest Feature</div>
        <div *ngIf="isCrossDockSupportManifestFeature" class="top10">Manifest Feature Enabled</div>
        <div *ngIf="!isCrossDockSupportManifestFeature" class="top10">Manifest Feature Disabled</div>
      </div>
    </ng-container>
    <div class="top20"></div>
    <div class="group no-padding">
      <div class="bold f16">Pickup Details</div>
      <div nz-col nzSm="12" style="margin-bottom: 8px">
        <div class="location-detail-row-label">Access Code</div>
        <div class="location-detail-row-value">{{model.pickDetails?.accessCode || 'N/A'}}</div>
      </div>
      <div *ngIf="!isMultipleClients">
        <div nz-row [nzGutter]="{ sm: 16}" class="location-detail-row">
          <div nz-col nzSm="12">
            <div class="location-detail-row-label">Instructions</div>
            <div class="location-detail-row-value">{{model.pickDetails?.instructions || '-'}}</div>
          </div>
        </div>
        <div nz-row class="location-detail-row">
          <div nz-col nzSm="24">
            <div class="location-detail-row-label">Internal Notes</div>
            <div class="location-detail-row-value">{{model.pickDetails?.note || '-'}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="isMultipleClients">
        <div *ngFor="let item of model.pickDetails?.customSetting">
          <span style="font-weight: 500; color: #555555">Customer Name: {{getNameClient(item.id)}}</span>
          <div nz-row [nzGutter]="{ sm: 16}" class="location-detail-row">
            <div nz-col nzSm="12">
              <div class="location-detail-row-label">Instructions</div>
              <div class="location-detail-row-value">{{item.instructions || '-'}}</div>
            </div>
            <div nz-col nzSm="12" class="location-detail-row">
              <div>
                <div class="location-detail-row-label">Internal Notes</div>
                <div class="location-detail-row-value">{{item.note || '-'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top20"></div>
    <div class="group no-padding">
      <div class="bold f16">Delivery Details</div>
      <div nz-col nzSm="12" style="margin-bottom: 8px;">
        <div class="location-detail-row-label">Access Code</div>
        <div class="location-detail-row-value">{{model.dropDetails?.accessCode || 'N/A'}}</div>
      </div>
      <div *ngIf="!isMultipleClients">
        <div nz-row [nzGutter]="{ sm: 16}" class="location-detail-row">
          <div nz-col nzSm="12">
            <div class="location-detail-row-label">Instructions</div>
            <div class="location-detail-row-value">{{model.dropDetails?.instructions || '-'}}</div>
          </div>
    
        </div>
        <div nz-row class="location-detail-row">
          <div nz-col nzSm="24">
            <div class="location-detail-row-label">Internal Notes</div>
            <div class="location-detail-row-value">{{model.dropDetails?.note || '-'}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="isMultipleClients">
        <div *ngFor="let item of model.dropDetails?.customSetting">
          <span style="font-weight: 500; color: #555555">Customer Name: {{getNameClient(item.id)}}</span>
          <div nz-row [nzGutter]="{ sm: 16}" class="location-detail-row">
            <div nz-col nzSm="12">
              <div class="location-detail-row-label">Instructions</div>
              <div class="location-detail-row-value">{{item.instructions || '-'}}</div>
            </div>
            <div nz-col nzSm="12" class="location-detail-row">
              <div>
                <div class="location-detail-row-label">Internal Notes</div>
                <div class="location-detail-row-value">{{item.note || '-'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top20"></div>
    <div class="group no-padding">
      <div class="bold f16">Contact Information:</div>
      <div class="bold f14 top10">Primary Contact</div>
      <div nz-row [nzGutter]="{ sm: 16}" class="location-detail-row">
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Contact Person</div>
          <div class="location-detail-row-value">{{model.contactName || '-'}}</div>
        </div>
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Contact Phone Number:</div>
          <div class="location-detail-row-value">{{showPhoneNumberValue(model.phone) || '-'}} {{ showPhoneExt(model) }}</div>
        </div>
      </div>
      <div nz-row class="location-detail-row">
        <div nz-col nzSm="24">
          <div class="location-detail-row-label">Contact Email</div>
          <div class="location-detail-row-value">{{model.email || '-'}}</div>
        </div>
      </div>
      <div class="bold f14b top10">Secondary Contact</div>
      <div nz-row [nzGutter]="{ sm: 16}" class="location-detail-row">
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Contact Person</div>
          <div class="location-detail-row-value">{{model.secondaryContact?.contactName || '-'}}</div>
        </div>
        <div nz-col nzSm="12">
          <div class="location-detail-row-label">Contact Phone Number:</div>
          <div class="location-detail-row-value">{{showPhoneNumberValue(model.secondaryContact?.phone) || '-'}} {{ showPhoneExt(model.secondaryContact) }}</div>
        </div>
      </div>
      <div nz-row class="location-detail-row">
        <div nz-col nzSm="24">
          <div class="location-detail-row-label">Contact Email</div>
          <div class="location-detail-row-value">{{model.secondaryContact?.email || '-'}}</div>
        </div>
      </div>
    </div>
    <div class="top20"></div>
    <div class="group no-padding">
      <div class="bold f16">Operating Hours</div>
      <div *ngFor="let item of model.operatingHours; let i = index" nz-row style="margin-top: 10px;">
        <div nz-col nzSm="5">{{dayLables[i]}}</div>
        <div *ngIf="!item.isOpen" nz-col nzSm="5">Closed</div>
        <div *ngIf="item.isOpen" nz-col nzSm="5">{{item.fromTime}} - {{item.toTime}}</div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isEditOrCreate">
  <form nz-form [formGroup]="formInput" class="form-detail">
    <div class="bold f16 bottom10">Location Details:</div>
    <div nz-row>
      <div nz-col *ngFor="let col of row1.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
        <ng-container [ngSwitch]="col.key">
          <div *ngSwitchCase="'name'">
            <div class="form-label-v2">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
            <nz-form-item>
              <nz-form-control>
                <input nz-input [formControlName]="col.key" [type]="getInputType(col.key)" [placeholder]="getPlaceHolder(col.key)">
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngSwitchCase="'clientIds'">
            <ng-container *ngIf="shouldShowClient">
              <div class="form-label-v2 right20">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
              <nz-form-item>
                <select-by-searching style="width: 100%;"
                  [nzMode]="'multiple'"
                  [version]="2"
                  [formControlName]="col.key" 
                  [(listData)]="listClients"
                  (ngModelChange)="onListClientsChanged($event)"
                  [placeholder]="getPlaceHolder(col.key)">
                </select-by-searching>
              </nz-form-item>
            </ng-container>
          </div>
          <div *ngSwitchCase="'warehouseType'">
            <div class="form-label-v2">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzBackdrop="true" [formControlName]="col.key" (ngModelChange)="onChangeWarehouseType($event)">
                  <nz-option *ngFor="let item of warehouseTypes" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngSwitchCase="'orgId'">
            <div class="form-label-v2">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzBackdrop="true" [formControlName]="col.key" nzShowSearch nzAllowClear>
                  <nz-option *ngFor="let item of organizations" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </div>
    </div>
    <!--Manifest Feature-->
    <ng-container *ngIf="isCrossDock">
      <div class="bold f16 bottom10">Manifest Feature:</div>
      <div class="top10 bottom20">
        <nz-form-item>
          <div [formControlName]="'supportManifestFunction'" nz-checkbox>{{ getLabel('supportManifestFunction') }}</div>
        </nz-form-item>
      </div>
    </ng-container>
    <div class="bold f16 bottom10">Address Details:</div>
    <div nz-row>
      <div nz-col *ngFor="let col of row2.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
        <ng-container [ngSwitch]="col.key">
          <div *ngSwitchCase="'addressType'">
            <div class="form-label-v2">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzBackdrop="true" style="width: 100%;"
                  [nzPlaceHolder]="getPlaceHolder(col.key)" [formControlName]="col.key">
                  <nz-option *ngFor="let item of addressType" [nzLabel]="item" [nzValue]="item"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngSwitchCase="'pickAddr'">
            <div class="form-label-v2">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
            <nz-form-item>
              <nz-form-control>
                <form-address [formControlName]="col.key"></form-address>
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </div>
    </div>
    <div nz-row>
      <div nz-col *ngFor="let key of ['serviceOptions']" [nzSpan]="11">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item class="bottom0">  
          <nz-form-control>
            <nz-select nzBackdrop="true"  nzMode="multiple" style="width: 100%;" nzShowSearch nzAllowClear 
              [nzPlaceHolder]="getPlaceHolder(key)" [nzMaxTagCount]="0" [nzMaxTagPlaceholder]="tagPlaceHolder"
              [formControlName]="key">
              <nz-option-group *ngFor="let group of serviceOptions" [nzLabel]="group.label">
                <nz-option *ngFor="let item of group.items"
                  [nzValue]="item._id" [nzLabel]="item.name"
                  [nzDisabled]="isDisableServiceOptionItem(item)">
                </nz-option>
              </nz-option-group>
            </nz-select>
            <ng-template #tagPlaceHolder>{{serviceOptionsSelectDesc}}</ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12" [nzOffset]="1">
        <div>Other Options</div>
        <div class="top10 flex">
          <div *ngFor="let key of ['isHazmatLocation','requireAppointment']" class="right40">
            <nz-form-item>
              <div nz-checkbox [formControlName]="key">{{getLabel(key)}}</div>
            </nz-form-item>
          </div>
        </div>
      </div>
    </div>
    <div class="top10 bottom20">
      <nz-tag *ngFor="let item of formInput.get('serviceOptions').value; let i = index"
        nzMode="closeable" nzColor="processing" (nzOnClose)="removeServiceOption(i)">
        {{getServiceOptionName(item)}}
      </nz-tag>
    </div>

    <form nz-form *ngFor="let key of ['pickDetails', 'dropDetails']" [formGroupName]="key">
      <div class="bold f16 bottom10 top10">{{getLabel(key)}}</div>
      <div nz-row>
        <div nz-col *ngFor="let col of row3.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
          <div *ngIf="!isMultipleClients">
            <div *ngIf="isMultiLines(col.key)">
              <div class="form-label-v2">{{getLabel(fullKey(key, col.key))}}<span *ngIf="isRequired(fullKey(col.key))"
                  class="label-mark-required"></span></div>
              <nz-form-item class="bottom0">
                <nz-form-control>
                  <textarea nz-input [formControlName]="col.key" [placeholder]="getPlaceHolder(fullKey(key,col.key))"
                    [nzAutosize]="{ minRows: 2, maxRows: 6 }">
                  </textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div *ngIf="!isMultiLines(col.key)">
            <div class="form-label-v2">{{getLabel(fullKey(key, col.key))}}<span *ngIf="isRequired(fullKey(col.key))"
                class="label-mark-required"></span></div>
            <nz-form-item>
              <nz-form-control>
                <input nz-input [ngClass]="col.key" [formControlName]="col.key" [type]="getInputType(fullKey(key,col.key))"
                  [placeholder]="getPlaceHolder(fullKey(key,col.key))" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>  
      </div>
      <div *ngIf="isMultipleClients">
        <div *ngFor="let childKey of ['customSetting']" [formArrayName]="childKey">
          <form *ngFor="let item of getArrayControls(fullKey(key, childKey)); let i = index" [formGroupName]="i">
            <b style="color: rgb(78, 78, 78); margin-top: 10px;">Customer Name:
              {{getNameClient(item.value.id)}}
            </b>
            <div nz-row>
    
              <div nz-col *ngFor="let col of row3.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
                <div *ngIf="isMultiLines(col.key)">
                  <div class="form-label-v2">{{getLabel(fullKey(key,childKey, col.key))}}<span
                      *ngIf="isRequired(fullKey(col.key))" class="label-mark-required"></span></div>
                  <nz-form-item class="bottom0">
                    <nz-form-control>
                      <textarea nz-input [formControlName]="col.key" [nzAutosize]="{ minRows: 2, maxRows: 6 }">
                          </textarea>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </form>
    <div class="bold f16 bottom10 top10">Contact Information:</div>
    <div class="bold f14 top10">Primary Contact</div>
    <div nz-row>
      <div nz-col *ngFor="let col of row4.columns; let i = index" [nzSpan]="col.span" [nzOffset]="col.offset">
        <div class="form-label-v2">{{getLabel(col.key)}}<span *ngIf="isRequired(col.key)" class="label-mark-required"></span></div>
        <ng-container [ngSwitch]="col.key">
          <div *ngSwitchCase="'phone'" [ngClass]="col.key">
            <nz-form-item>
              <nz-form-control>
                <nz-input-group nzCompact>
                  <input nz-input [formControlName]="col.key" 
                    [type]="getInputType(col.key)" 
                    [placeholder]="getPlaceHolder(col.key)"
                    (input)="onInputChanged($event, col.key)" 
                    (keypress)="onInputKeyPress($event, col.key)"
                    style="width: 70%;"
                  />

                  <input nz-input [formControlName]="'phoneExtension'" 
                    [type]="getInputType('phoneExtension')" 
                    [placeholder]="getPlaceHolder('phoneExtension')"
                    (input)="onInputChanged($event, 'phoneExtension')" 
                    (keypress)="onlyNumberKey($event)"
                    style="width: 30%;"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div *ngSwitchDefault [ngClass]="col.key">
            <nz-form-item>
              <nz-form-control>
                <input nz-input [formControlName]="col.key" 
                  [type]="getInputType(col.key)" 
                  [placeholder]="getPlaceHolder(col.key)"
                  (input)="onInputChanged($event, col.key)" 
                  (keypress)="onInputKeyPress($event, col.key)"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </div>
    </div>
    <form nz-form *ngFor="let key of ['secondaryContact']" [formGroupName]="key">
      <div class="bold f14">Secondary Contact</div>
      <div nz-row>
        <div nz-col *ngFor="let col of row4.columns; let i = index" [nzSpan]="col.span" [nzOffset]="col.offset">
          <div class="form-label-v2">{{getLabel(fullKey(key, col.key))}}<span *ngIf="isRequired(fullKey(key, col.key))" class="label-mark-required"></span></div>
          <ng-container [ngSwitch]="col.key">
            <div *ngSwitchCase="'phone'" [ngClass]="col.key">
              <nz-form-item>
                <nz-form-control>
                  <nz-input-group nzCompact>
                    <input nz-input [formControlName]="col.key" 
                      [type]="getInputType(fullKey(key, col.key))" 
                      [placeholder]="getPlaceHolder(fullKey(key, col.key))"
                      (input)="onInputChanged($event, fullKey(key, col.key))" 
                      (keypress)="onInputKeyPress($event, fullKey(key, col.key))"
                      style="width: 70%;"
                    />

                    <input nz-input [formControlName]="'phoneExtension'" 
                      [type]="getInputType(fullKey(key, 'phoneExtension'))" 
                      [placeholder]="getPlaceHolder(fullKey(key, 'phoneExtension'))"
                      (input)="onInputChanged($event, fullKey(key, 'phoneExtension'))" 
                      (keypress)="onlyNumberKey($event)"
                      style="width: 30%;"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div *ngSwitchDefault [ngClass]="col.key">
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="col.key" 
                    [type]="getInputType(fullKey(key, col.key))" 
                    [placeholder]="getPlaceHolder(fullKey(key, col.key))"
                    (input)="onInputChanged($event, fullKey(key, col.key))" 
                    (keypress)="onInputKeyPress($event, fullKey(key, col.key))"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
    <div class="flex f16 bottom20">
      <div class="bold f16">Operating Hours:</div>
      <div *ngIf="timezone" class="timezone">{{timezone}}</div>
    </div>
    <div>
      <ng-container *ngFor="let key of ['operatingHours']" [formArrayName]="key">
        <form *ngFor="let control of getArrayControls(key); let i = index" nz-form [formGroupName]="i" [ngClass]="key">
          <div class="week-day-name">{{dayLables[i]}}</div>
          <ng-container *ngFor="let childKey of ['isOpen', 'fromTime', 'toTime']">
            <ng-container [ngSwitch]="childKey">
              <div *ngSwitchCase="'isOpen'" [ngClass]="childKey">
                <nz-switch [formControlName]="childKey"></nz-switch>
                <div class="text-open">{{getTextDayOpen(i)}}</div>
              </div>
              <nz-time-picker [nzMinuteStep]="15" *ngSwitchDefault nzSize="small"
                [ngClass]="childKey" [formControlName]="childKey" 
                nzFormat="HH:mm" [nzPlaceHolder]="getPlaceHolder(fullKey(key, childKey))">
              </nz-time-picker>
            </ng-container>
          </ng-container>
        </form>
      </ng-container>
    </div>
  </form>
</ng-container>

