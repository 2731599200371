import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { MasterData } from "@services/master.data";
import { OrderUtil } from "@services/order";
import { FormDataShipmentEntryBasicInfo } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { EditShipmentEntryEquipment } from "../../edit-shipment/equipment";
import { BaseComponent } from "@abstract/BaseComponent";
import { ModelOrderBasicInfo } from "@app/interfaces/order";
import { BizUtil } from "@services/biz";
import { ShipmentDetailService } from "@app/admin/shipment-entry/detail/shipment-detail-service";

@Component({
  selector: "customer-equipment-info",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class CustomerAndEquipmentInfo extends BaseComponent {
  @Input("hasTitle") hasTitle: boolean = false;
  private _customerAndEquipment:ModelOrderBasicInfo;
  @Input("model") set customerAndEquipment(value:ModelOrderBasicInfo){
    this._customerAndEquipment = value;
    if(value){
      setTimeout(()=>{
        this.fetchClientInfo();
      },0)
    }
  };
  get customerAndEquipment():ModelOrderBasicInfo{
    return this._customerAndEquipment;
  }
  @Input() modelOrder;
  @Input() isTurnOnEditMode = Const.defaultTurnOnEditMode;
  @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();
  client
  parentClient
  public displayInfo: any = {}

  constructor(protected shipmentDetailService: ShipmentDetailService) {
    super();
  }

  ngOnInit(): void {
      super.ngOnInit();
      this.shipmentDetailService.routeCostData$.subscribe((res: any) => {
        this.displayInfo.routeMileage = this.getRouteMileage(res);
        this.displayInfo.routeHours = this.getRoutehours(res);
      });
  }

  get shipmentType() {
   return OrderUtil.getShipmentTypeName(this.customerAndEquipment?.shipmentType);
  }

  get isUsingCrossdock() {
    return this.customerAndEquipment?.isCrossDock;
  }

  get businessNumber() {
    return this.customerAndEquipment?.businessNumber;
  }

  get orderId() {
    return this.customerAndEquipment.orderId;
  }

  get equipmentWarning() {
    return 'This is LTL, equipment might be incorrect, please check on dispatch screen';
  }

  getTruckTypeInfo() {
    if (this.customerAndEquipment?.vehicleType?.name) {
      const vehicleName = BizUtil.getVehicleName(this.customerAndEquipment?.vehicleType);
      if (vehicleName) {
        return vehicleName;
      }
    }
    let text = '';
    let shipmentMode = MasterData.getShipmentModeNameById(this.customerAndEquipment?.shipmentModeId) ?? '';
    if (shipmentMode) {
      text += shipmentMode;
    }
    let equipment = MasterData.getEquipmenNameById(this.customerAndEquipment?.equipmentId);
    if (equipment) {
      text += ` - ${equipment}`;
    }
    return text;
  }

  onBtnEditEquipment() {
    DialogService.openFormDialog1(EditShipmentEntryEquipment, {
      nzComponentParams: {
        closeOnSuccess: true,
        myModel: this.customerAndEquipment,
        modelOrder: this.modelOrder,
        onSave: data => this.saveBasicInfo(data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal",
    });
  }

  private saveBasicInfo(data: FormDataShipmentEntryBasicInfo) {
    const url = Const.APIV2(`${Const.APIURI_ORDERS}/${this.orderId}/basic-info`);
    return this.api.PUT(url, data);
  }
  private fetchClientInfo() {
    let clientId =this._customerAndEquipment?.clientId;
    let parentClientId =this._customerAndEquipment?.parentClientId;
    this.client = null;
    this.parentClient = null;
    if(clientId){
      let url = `${Const.APIURI_CLIENTS}/${clientId}`;
      this.api.GET(url).subscribe(
        resp => {
          this.client = resp.data;
        }
      );
    }
    if(parentClientId){
      let url = `${Const.APIURI_CLIENTS}/${parentClientId}`;
      this.api.GET(url).subscribe(
        resp => {
          this.parentClient = resp.data;
        }
      );
    }
  }

  private getRouteMileage(data) {
    if (!data) return 'N/A'
    const totalDistance = data.distance;
    if (!totalDistance) return 'N/A'
    return (totalDistance / 1609.34).toFixed(2).toLocaleString()
  }

  private getRoutehours(data) {
    if (!data) return 'N/A'
    const totalTime = data.time;
    if (!totalTime) return 'N/A'
    return (totalTime / 3600.00).toFixed(2).toLocaleString()
  }

  isLTLShipment() {
    return this.customerAndEquipment?.shipmentType === Const.ShipmentTypes.lessThanTruckload;
  }
}
