import { RoutingColor } from "@app/admin/routing-tool/color";
import { Const } from "@const/Const";
import { getInjector } from "@services/index";
import { MapService } from "../../map.service";
import { RoutingService } from "../../routing.service";
import { ShipmentManagement } from "../../shipmentManagement";
import { MapLine, MapMarker } from "../map";
import { Route } from "../route";
import { BaseRouteFeature } from "./baseFeature";


export class RoutingAdjustShipment extends BaseRouteFeature {
  private route: Route;
  private selectedTasks: any[] = []
  
  constructor(route) {
    super();
    this.route = route;
    this.start();
  }

  public start() {
    this.routingService.isEditing = true;
    this.routingService.uiControlSubject.next({
      event: "adjustRouteShipment",
      data: {
        route: this.route
      }
    })
  }


  public canSave() {
    return this.selectedTasks?.length == this.route.getTasks().length
  }

  public async onSave() {
    const tasks = this.selectedTasks
    await this.routingService.routeReSort({ routeId: this.route.getId(), tasks });
    await this.problemManagement.reloadProblem();
    this.routingService.isEditing = false;
  }
  public async onCancel() {
    await this.problemManagement.reloadProblem();
    this.routingService.isEditing = false;
  }

  public getStatusText() {
    const tasks = this.route.getTasks();
    const totalPickupTasks = tasks.filter(task => task.type == Const.TaskType.PICKUP).length;
    const totalDropoffTasks = tasks.length - totalPickupTasks;
    const selectedPickupTasks = this.selectedTasks.filter(task => task.type == Const.TaskType.PICKUP).length;
    const selectedDropoffTasks = this.selectedTasks.length - selectedPickupTasks;

    return `
      <b>PICKUP:</b> ${selectedPickupTasks}/${totalPickupTasks} shipments <br />
      <b>DROPOFF:</b> ${selectedDropoffTasks}/${totalDropoffTasks} shipments`
  }
}