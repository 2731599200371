// TODO configurable
export const TAGS = [
    {
        key: 'PRIORITY',
        name: 'Priority',
        color: '#2db7f5'
    },
    {
        key: 'AT_RISK',
        name: 'At Risk',
        color: '#f50',
    },
]