import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";
import { Utils } from "@services/utils";
import { startOfMonth } from 'date-fns'

@Component({
  selector: '[filter-revenue]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
    '../../../../styles/form-v2.scss'
  ]
})
export class FilterRevenue extends BaseFormItem {
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  protected formGroupDeclaration: FormGroupDeclaration = {
    fromDate: {label: 'From date', type: 'date', required: true },
    toDate: {label: 'To date', type: 'date', required: true },
  };

  public loaded: number = 0

  @ViewChild('form') form: ElementRef<HTMLFormElement>;
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      this.loaded = p.loaded || 0
    })
  }

  ngOnInit(): void {
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    if (params.filter) {
      this.model = JSON.parse(params.filter);
    } else {
      this.model = this.buildDefaultValue();
    }
    super.ngOnInit();
    this.loadData();
  }

  private buildDefaultValue() {
    return {
      fromDate: startOfMonth(new Date()),
      toDate: new Date(),
    };
  }

  private buildQuery(): any {
    let q = {}
    let condition: any = this.getFormData_JSON(true);
    const f = JSON.stringify(condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    return q;
  }

  loadData() {
    this.routeWithQueryUrl(this.buildQuery());
  }

  onChange(value, key) {
    setTimeout(() => {
      this.loadData();
    }, 1); // phải kết thúc hàm onChange thì trong form mới có dữ liệu
  }
}
