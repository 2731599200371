import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { Log } from '@services/log';
import { ModelRoutingProblem, ModelRoutingRoute, ProblemRoutingState } from "../../interface";
import { SubProblem } from "../../services/objects/subProblem";
import { RoutingService } from "../../services/routing.service";
import { Route } from "../../services/objects/route";
import { ActivatedRoute } from "@angular/router";
import { ProblemManagement } from "../../services/problemManagement";

@Component({
  selector: '[routing-route]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../../../../styles/color-pallet.scss']
})
export class RoutingRoute extends BaseComponent {
  constructor(
    activatedRoute: ActivatedRoute,
    private routingService: RoutingService,
    private problemManagement: ProblemManagement
  ) {
    super(activatedRoute)
  }

  @Input()
  public route: Route;
  public get data() {
    return this.route.getRouteDataForUI()
  }
  public canEdit = false;

  ngOnInit() {
    this.initSubscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public initSubscribe() {
    this.subscription.add(this.problemManagement.problemSubject.subscribe(problem => {
      problem.dataSubject.subscribe(() => {
        this.canEdit = problem.getStatus() != ProblemRoutingState.done
      })
    }))
  }

  showShipmentCount(list: any[]) {
    return Utils.displayCount(list.length, 'Shipment');
  }

  showStopCount(stopCount) {
    return Utils.displayCount(stopCount, 'Stop');
  }

  onAdjustOrder() {
    const featureController = this.route.startAdjustOrder();
    this.routingService.uiControlSubject.next({
      event: "startAdjustRoutingOrder",
      data: {
        route: this.route,
        controller: featureController
      }
    });
  }
  onAdjustShipments(){
    const featureController = this.route.startAdjustShipment();
    this.routingService.uiControlSubject.next({
      event: "startAdjustRoutingShipment",
      data: {
        route: this.route,
        controller: featureController
      }
    });
  }
}
