<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">{{strTitle}}</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form class="form-detail shipment-item" [formGroup]="formInput" nz-form style="padding: 26px;">

  <div class="flex-column">
    <ng-container *ngFor="let key of formInputKeys">
      <div *ngIf="isBool(key)" nz-checkbox [formControlName]="key"
        style="margin-left: 0; margin-bottom: 15px; width: fit-content;">
        {{getLabel(key)}}
      </div>
      <div *ngIf="!isBool(key) && getCheckValueFor(key) === true">
        <form nz-form *ngIf="key == 'temperatureControlled'" [formGroupName]="key">
          <div nz-row>
            <div nz-col *ngFor="let col of row3.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
              <div class="form-label-v2 single-line fix-height">{{getLabel(fullKey(key, col.key))}}<span *ngIf="isRequired(fullKey(key, col.key)) && getLabel(fullKey(key, col.key)).length > 0" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="col.key"
                    [type]="getInputType(fullKey(key, col.key))"
                    [placeholder]="getPlaceHolder(fullKey(key, col.key))"
                    (input)="onInputChanged($event, col.key)"
                    (keypress)="onInputKeyPress($event, col.key)">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
        <form nz-form *ngIf="key == 'hazardous'" [formGroupName]="key">
          <div nz-row *ngFor="let row of rowsHazardous">
            <div nz-col *ngFor="let col of row.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
              <div class="form-label-v2 single-line fix-height">{{getLabel(fullKey(key, col.key))}}<span *ngIf="isRequired(fullKey(key, col.key)) && getLabel(fullKey(key, col.key)).length > 0" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="col.key"
                    [type]="getInputType(fullKey(key, col.key))"
                    [placeholder]="getPlaceHolder(fullKey(key, col.key))"
                    (input)="onInputChanged($event, col.key)"
                    (keypress)="onInputKeyPress($event, col.key)">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
      <ng-container *ngIf="key == 'shipRecInfo'">
        <div [formGroup]="formInput.get(key)">
          <div class="form-label-v2">{{getLabel(fullKey(key, 'deviceId'))}}</div>
          <nz-form-item class="right16">
            <nz-form-control>
              <input class="form-item" nz-input [formControlName]="'deviceId'"
              [placeholder]="getPlaceHolder(key)" >
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" [canClickCancel]="true" [onProgress]="onProgress"
  [nzIconOK]="null" [labelOK]="strButtonSubmit" (onOK)="onBtnSave()"
  [nzIconCancel]="null" (onCancel)="onBtnCancel()"></div>
