<ng-container *ngFor="let key of ['pick', 'drop']">
  <div *ngIf="countShipment(key) > 0" class="window-row">
    <div class="bottom10">{{getText(key)}}: <a (click)="seeAll(key)">{{getTextListShipments(key)}}</a></div>
    <div time-window 
      [(ngModel)]="window[key]" 
      [timezone]="timezone[key]" 
      [showTimezone]="true" [isDisabled]="isDisabledForm(key)"
      (ngModelChange)="onTimeWindowChange(key, $event)">
    </div>
    <div style="display: flex; justify-content: flex-end;">
      <button *ngIf="!isDone(key)" nz-button nzType="primary" class="btn-save"
        (click)="onSave(key)" [disabled]="!canSave(key)" [nzLoading]="isLoading(key)">
        <i nz-icon nzType="save" nzTheme="outline"></i>
        Save
      </button>
      <button *ngIf="isDone(key)" nz-button class="btn-save"
        (click)="onClose(key)">
        <i nz-icon nzType="close" nzTheme="outline"></i>
        Done
      </button>
    </div>
  </div>
</ng-container>

