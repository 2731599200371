<form nz-form [formGroup]="formInput" #formHtml>

  <div *ngIf="shouldShowLabel" class="flex row-label">
    <div *ngFor="let group of titleGroups" class="flex group-fields">
      <div *ngIf="group.title" class="f12 {{group.childs[0].key}}-label">
        {{group.title}}
        <span *ngIf="group.isRequired" mark-required></span>
      </div>
      <ng-container *ngIf="!group.title">
        <div *ngFor="let child of group.childs" class="f12 {{child.key}}-label">
          {{child.title}}
          <span *ngIf="child.isRequired" mark-required></span>
        </div>
      </ng-container>
    </div>
  </div>

  <div [formGroup]="formInput" class="flex-column">
    <div class="flex">
      <div *ngFor="let group of titleGroups" class="flex group-fields">
        <div *ngFor="let child of group.childs; let i = index;" class="group-fields-item">
          <nz-form-item>
            <nz-form-control class="{{child.key}}">
              <ng-container [ngSwitch]="child.key">
                <nz-select *ngSwitchCase="'qtyUnit'" [ngClass]="child.key"
                  nzBackdrop="true" [formControlName]="child.key" (ngModelChange)="onModelChange(child.key)">
                  <nz-option *ngFor="let item of qtyUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
                </nz-select>
                <nz-select *ngSwitchCase="'weightUnit'" [ngClass]="child.key"
                  nzBackdrop="true" [formControlName]="child.key">
                  <nz-option *ngFor="let item of weightUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
                </nz-select>
                <nz-select *ngSwitchCase="'sizeUnit'" [ngClass]="child.key"
                  nzBackdrop="true" [formControlName]="child.key">
                  <nz-option *ngFor="let item of sizeUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
                </nz-select>
                <ng-container *ngSwitchCase="'name'">
                  <input nz-input [formControlName]="child.key" [ngClass]="child.key"
                    [type]="getInputType(child.key)" 
                    [placeholder]="getPlaceHolder(child.key)"
                    (input)="onInputChanged($event, child.key)" 
                    (keypress)="onInputKeyPress($event, child.key)">
                  <button [disabled]="!canAddChild()" (click)="addChild()" nz-button nzType="default" style="padding: 0px; border: none;">
                    <span class="icon-expand"></span>
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="'barcodes'">
                  <button class="{{child.key}}"
                    nz-button nzType="default"
                    (click)="onBtnBarcodes()" 
                    nz-popover 
                    nzPopoverPlacement="topLeft" 
                    [nzPopoverTrigger]="countBarcode == 0 ? null : 'hover'" 
                    [nzPopoverContent]="barcodeContentTemplate">
                    {{barcodesLabel}}
                  </button>
                  <ng-template #barcodeContentTemplate>
                    <div style="min-width: 300px;">
                      <nz-table [nzData]="barcodes" [nzShowPagination]="false">
                        <tbody>
                          <tr *ngFor="let item of barcodes">
                            <td><code>{{item}}</code></td>
                          </tr>
                        </tbody>
                      </nz-table>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'button-service'">
                  <div class="flex">
                    <ng-container>
                      <button class="{{child.key}}" 
                        nz-button nzType="default" 
                        (click)="onBtnItemServices()"
                        nz-popover 
                        nzPopoverPlacement="topLeft"
                        [nzPopoverTrigger]="!itemServiceDesc ? null : 'hover'" 
                        [nzPopoverContent]="tplServices">
                        <span nz-icon nzType="plus" nzTheme="outline"></span>
                        Services
                      </button>
                      <ng-template #tplServices>
                        <div>{{itemServiceDesc}}</div>
                      </ng-template>
                    </ng-container>
                  </div>
                </ng-container>
                <input *ngSwitchDefault [ngClass]="child.key"
                  nz-input [formControlName]="child.key"
                  [type]="getInputType(child.key)"
                  [placeholder]="getPlaceHolder(child.key)"
                  (input)="onInputChanged($event, child.key)"
                  (keypress)="onInputKeyPress($event, child.key)">
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div *ngIf="hasChildren" class="children">
      <div class="indent"></div>
      <div>
        <div *ngFor="let item of children; let i = index;" class="item-row">
          <div class="child-{{i}}" #formChild
            form-order-item [model]="item" 
            [index]="i" [level]="level + 1" 
            (addMoreSibling)="addChild()">
          </div>
          <button *ngIf="canDelChild(i)" nz-button nzType="text" class="btn-del-item" [ngClass]="{'btn-del-item-first-row': i == 0}"
            nz-popconfirm [nzOkDanger]="true" nzOkText="Remove"
            [nzPopconfirmTitle]="messageDeleteChild(i)"
            (nzOnConfirm)="deleteChild(i)"
            nzPopconfirmPlacement="top"
            [nzIcon]="iconTpl">
            <span nz-icon nzType="delete" nzTheme="outline" class="danger"></span>
            <ng-template #iconTpl><span nz-icon nzType="question-circle-o"></span></ng-template>
          </button>
        </div>
        <button *ngIf="canAddChild()" nz-button nzType="dashed" (click)="addChild()" class="btn-add-item">
          <span nz-icon nzType="plus"></span>
        </button>
      </div>
    </div>
  
  </div>

</form>