import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { MapService } from "./map.service";
import { Shipment } from "./objects/shipment";

@Injectable()
export class ShipmentManagement {
  private shipments: Map<string, Shipment> = new Map();
  public shipmentSubject: ReplaySubject<Shipment[]> = new ReplaySubject(1);

  constructor(
    private mapService: MapService
  ) {

  }
  public addShipments(shipments) {
    for (let shipment of shipments) {
      const id = String(shipment.id);
      if (this.shipments.has(id)) continue;
      const newInstance = new Shipment(id, shipment);
      this.shipments.set(id, newInstance);
    }
    this.shipmentSubject.next(this.getShipments());
    return this
  }

  public getShipments() {
    return Array.from(this.shipments.values());
  }

  public getShipmentsData() {
    return this.getShipments().map(shipment => shipment.getData())
  }

  public getShipment(shipmentId) {
    shipmentId = String(shipmentId)
    if (!this.shipments.has(shipmentId)) {
      console.log(`shipment id not found in shipmentManagement.`, shipmentId);
      return;
    }
    return this.shipments.get(shipmentId);
  }

  public removeShipment(shipmentId) {
    this.shipments.delete(shipmentId);
    this.shipmentSubject.next(this.getShipments());
    return this;
  }

  public addToMap() {
    this.removeFromMap();
    this.shipments.forEach(shipment => shipment.addToMap());
  }
  
  public removeFromMap() {
    // this.shipments.forEach(shipment => shipment.removeFromMap());
    this.mapService.removeMarkers()
  }
  public reset() {
    this.removeFromMap()
    this.shipments.clear();
    this.shipmentSubject.next(this.getShipments());
    return this;
  }

}