<div class="top-bar">
  <div class="pageTitle" style="width: 100%;">

    <ng-container *ngIf="topbarTitleTemplate">
      <ng-container *ngTemplateOutlet="topbarTitleTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="!topbarTitleTemplate">
      {{topbarTitleString}}
      <ng-container *ngIf="topbarTitleString == 'Dispatch'">
        <!-- <nz-badge (click)="showNotificationDispath()" [nzCount]="badgeNotificationDispatchNumber" [nzOverflowCount]="9" class="badge-notification">
          <i nz-icon nzType="bell" nzTheme="outline" class="icon-notification"></i>
        </nz-badge> -->
        <div style=" width: 100%;height: 100%;display: flex; justify-content: center;">
          <nz-tabset [(nzSelectedIndex)]="selectDispatchTabIndex" (nzSelectedIndexChange)="onDispatchTabChange()" style="width: 330px;" nzAnimated ="false" [nzSize]="'large'" type="card">
            <nz-tab nzTitle="All Loads"></nz-tab>
            <nz-tab nzTitle="My Loads"></nz-tab>
            <nz-tab nzTitle="Task Queue"></nz-tab>
          </nz-tabset>
        </div>
      </ng-container>
      <ng-container *ngIf="topbarTitleString == 'Freight Quote'">
        <button class="left20" nz-button type="button" (click)="onBtnShowFreightQuoteExportForm()">
          <i nz-icon nzType="file-excel" nzTheme="outline"></i>
          Export
        </button>
      </ng-container>
    </ng-container>

  </div>
  <ng-container *ngIf="topbarTitleString == 'Cross Dock Management'">
    <div class="list-tab">
      <ng-container *ngFor="let tab of tabs">
        <div class="tab-item" (click)="setTabCurrent(tab.key)">
          <div class="tab-text" [ngClass]="{active: getTabActive(tab.key)}">
            {{tab.name}}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="user-info">
    <div style="width: 30px;"></div>
    <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzPlacement="bottomRight" style="cursor: pointer;">
      <div class="center-children">
        <div class="ava-box center-children" [ngClass]="{'online': isOnline}">
          <i nz-icon nzType="user" nzTheme="outline"></i>
        </div>
        <div class="user-name" [ngClass]="{'online': isOnline}">{{userName}}</div>
         <i class="fa fa-fw fa-angle-down"></i>
      </div>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <ng-container *ngFor="let item of navMenuItems">
          <li nz-menu-item *ngIf="!item.separator && !item.items" (click)="menuItemClick(item)" class="topnav-dropdown-item">
            <i *ngIf="item.icon" class="fa fa-fw" [ngClass]="item.icon" style="margin-right: 10px;"></i>
            <i *ngIf="item.nzIcon" nz-icon [nzType]="item.nzIcon" nzTheme="outline" style="margin-right: 10px;"></i>
            <a *ngIf="item.link" [routerLink]="item.link" style="color: inherit;">{{item.label}}</a>
            <span *ngIf="!item.link">{{item.label}}</span>
          </li>
          <li nz-menu-divider *ngIf="item.separator"></li>
          <li nz-submenu nzTitle="{{item.label}}"  class="topnav-dropdown-item" [ngClass]="item.class" *ngIf="!item.separator && item.items">
            <ul>
              <li nz-menu-item *ngFor="let subItem of item.items" [ngClass]="subItem.class" (click)="menuItemClick(subItem)">
                <img [src]="subItem.img" style="width:20px; height:20px; margin-right:10px;" *ngIf="item.class=='language'">
                {{subItem.label}}
                <i class="fa fa-check" *ngIf="subItem.hilight" style="margin-left: 10px; color: #5a5a5a;"></i>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
