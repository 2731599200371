import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
    selector: '[shipment-revenue-edit-option]',
    templateUrl: './shipment_revenue_edit_option.html',
    styleUrls: ['./shipment_revenue_edit.scss']
})
export class ShipmentRevenueEditOption extends Component {
    @Input() serviceOption: any = null
    @Output() ngModelChange = new EventEmitter()
    @Output() onDelete = new EventEmitter()

    onUpdateValue() {
        this.serviceOption.total = (this.serviceOption.rate || 0) * (this.serviceOption.qty || 0)
        this.ngModelChange.emit(this.serviceOption)
    }

    onBtnDelete() {
        this.onDelete.emit(this.serviceOption.id)
    }
}