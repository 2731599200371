import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { environment } from '@env/environment';
import { Utils } from '@services/utils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[report-viewer-screen]',
  template: '<report-viewer *ngIf="id" [id]="id"></report-viewer>',
})
export class ReportViewerScreen extends BaseComponent {
    id: string = null
    constructor(protected activatedRoute: ActivatedRoute) {
        super();
        activatedRoute.params.subscribe(params => {
            this.id = params['id'];
        })
    }
}