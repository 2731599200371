import { Component, EventEmitter, Output } from "@angular/core";
import { FormDataShipmentEntryBasicInfo } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { BaseForm } from "@app/admin/base/form-base";
import { Input } from "@angular/core";
import { ShipmentEntryCreateMode } from "@app/enum";

@Component({
  selector: '[form-shipment-entry-basic-info]',
  templateUrl: './view.html',
})
export class FormShipmentEntryBasicInfo extends BaseForm<FormDataShipmentEntryBasicInfo> {

  @Input() type: ShipmentEntryCreateMode;
  @Output() onChange = new EventEmitter<string>();

  protected formGroupDeclaration: FormGroupDeclaration = {
    vehicleType: {label: 'Type of Truck', required: true},
    // shipmentModeId: {label: 'Type of Truck', required: true}, // Type of Truck = Shipment Mode
    // equipmentId: {label: 'Equipment', required: true},
    clientId: {label: 'Customer', placeHolder:'Select', required: true},
    subClientId: {label: 'Sub Account', placeHolder:'Select', required: false},
    shipmentType: {label: 'Shipment Type', required: true, submitReadOnly: true, initialValue: 'FTL'},
    isCrossDock: {label: 'Using Cross Dock', type: 'boolean', initialValue: false},
    isNotAutoRouteFTL: {label: 'Stop auto routing FTL', type: 'boolean', initialValue: false},
    businessNumber: {label: 'Business Number (EDI)', required: false},
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
