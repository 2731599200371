import { Component, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { BaseComponent } from "@abstract/BaseComponent";
import { DataorchService } from "@services/dataorch.service";
import _ from "underscore";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { getUserPreferences } from "@services/userPref.service";
import dayjs from "dayjs";

@Component({
    selector: 'report-viewer',
    templateUrl: 'index.html',
    styleUrls: [
        './index.scss',
    ]
})
export class ReportViewer extends BaseComponent {
    @ViewChild(CdkVirtualScrollViewport, { static: true })
    viewPortScrolling: CdkVirtualScrollViewport;
    
    dataorch: DataorchService
    _id: any = null
    data: any[] = []
    _filter: any = {}
    routeLink: string = ''
    preview = true
    indexWidth: string = '20px'
    selectedIndex: number = -1

    _view: any = null
    set view(v) {
        this._view = v
    }

    @Input() set id(v) {
        this._id = v
        this.loadView()
    }

    _reportFormat: any = null
    offsetX: string = '0px'

    set reportFormat(v) {
        // process data
        for (let col of v.columns) {
            col.width = col.width ?? 100;
            col.cssWidth = `${col.width}px`
        }
        const width = _.reduce(v.columns.map(it => it.width), (a,b) => a + b)
        v.width = width
        v.cssWidth = `${width}px`
        this._reportFormat = v
    }

    processColumnWidth() {
        for (let col of this._reportFormat.columns) {
            col.width = col.width ?? 100;
            col.cssWidth = `${col.width}px`
        }
        const width = _.reduce(this._reportFormat.columns.map(it => it.width), (a,b) => a + b)
        this._reportFormat.width = width
        this._reportFormat.cssWidth = `${width}px`  
    }

    get reportFormat() {
        return this._reportFormat
    }

    loadingView = false
    async loadView() {
        this.loadingView = true
        const reportView = await this.dataorch.getReportViews(this._id).toPromise()
        this.view = reportView

        await this.loadFormat(reportView.formatId)

        this.downloadData()
    }

    loadingFormat = false
    async loadFormat(formatId) {
        this.loadingFormat = true
        const reportFormat = await this.dataorch.getReportFormat(formatId).toPromise()
        this.reportFormat = reportFormat
        const { metadata } = reportFormat || {}
        const { object_type } = metadata || {}
        switch (object_type) {
            case 'JOB':
                this.routeLink = this.routeAdminDispatchList
                break;
            case 'ORDER':
                this.routeLink = this.routeAdminOrderList
            default:
                break;
        }
        try {
            getUserPreferences().getPref("table.format." + reportFormat.type).subscribe((format) => {
                if (format) {
                    const comps = format.split(";")
                    let colWidth = {}
                    for (let comp of comps) {
                        const keys = comp.split("=")
                        colWidth[keys[0]] = parseInt(keys[1])
                    }
                    for (let col of this._reportFormat.columns) {
                        if (colWidth[col.key]) {
                            col.width = colWidth[col.key]
                        }
                    }
                }
                this.processColumnWidth()
            })
        } catch (e) {
            console.log(e)
        }
        this.loadingFormat = false
    }

    loadingData = false
    async downloadData() {
        this.loadingData = true
        this.dataorch.loadReport(this.reportFormat.type, this.reportFilters, this.preview ? 100 : 0).subscribe(res => {
            this.loadingData = false
            this.data = res
            this.selectedIndex = -1
            const count = res.length
            const l = (count < 10 ? 1 : Math.ceil(Math.log10(count + 1))) * 10
            this.indexWidth = (l + 20) + 'px'
        }, err => {
            this.loadingData = false
        })
    }

    processFilter(f) {
        if (f.value == null || f.value == undefined) return null
        console.log(f)
        if (f.operation == 'string' && !f.value.length) return null
        if (f.operation == 'in' && !f.value.length) return null
        if (f.inputType == 'DatePicker') {
            const d = dayjs(f.value)
            return {
                valueType: f.valueType,
                key: f.key,
                [f.operation]: d.format('YYYY-MM-DD')
            }
        }
        if (f.inputType == 'MultiSelect') {
            return {
                valueType: f.valueType,
                key: f.key,
                [f.operation]: f.value
            }
        }
        return null
    }

    get reportFilters() {
        const customFilters = (this._view.filters || []).map(this.processFilter).filter(it => it)
        const filters: any = {
            'filters': customFilters
        }
        if (this._view.sorts) {
            filters['sorts'] = this._view.sorts
        }
        if (this._view.predefinedFilters) {
            filters['filters'] = filters['filters'].concat(this._view.predefinedFilters)
        }

        return Object.assign(filters, this._filter)
    }

    async downloadCsv() {
        this.dataorch.loadReportCsv(this.reportFormat.type, this.reportFilters).subscribe(res => {
            this.downloadFile(res, this._reportFormat.type)
        })
    }

    downloadFile(data, filename = 'data') {
        let blob = new Blob(['\ufeff' + data], {
            type: 'text/csv;charset=utf-8;'
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1
        navigator.userAgent.indexOf('Chrome') == -1;

        if (isSafariBrowser) {
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }


    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    ngAfterViewInit() {
        this.viewPortScrolling.elementScrolled().subscribe(() => {
            const offsetX = this.viewPortScrolling.measureScrollOffset("left")
            this.offsetX = `${-offsetX}px`
        });
    }
    onChangeColumnWidth(c, inc) {
        c.width += inc
        c.cssWidth = `${c.width}px`
        // recalculate total
        const width = _.reduce(this._reportFormat.columns.map(it => it.width), (a,b) => a + b)
        this._reportFormat.width = width
        this._reportFormat.cssWidth = `${width}px`

        this.updatePref()
    }

    updatePref() {
        const format = this._reportFormat.columns.filter(it => it.width && it.width != 100).map(it => `${it.key}=${it.width}`).join(";")
        getUserPreferences().setPref("table.format." + this._reportFormat.type, format)
    }

    onFilterChange(event, filter) {
        console.log(filter)
    }

    onSelectRow(id) {
        this.selectedIndex = id
    }
}