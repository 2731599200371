import { Map as Mapbox, Popup } from "mapbox-gl";

export class MapPopup extends Popup {

  private map: Mapbox;

  public setMap(map) {
    this.map = map;
  }

  public show({ location, content }) {
    this.setHTML(content).setLngLat(location).addTo(this.map);
  }
}