import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalHelper } from "@wearewarp/ng-antd";
import { ThirdPartyInfoDialog } from "./dialog";
import { environment } from "@env/environment";

@Component({
    selector: '[third-party-quote-screen]',
    templateUrl: 'quoting_screen.html',
    styleUrls: ['./quoting_screen.scss']
})
export class ThirdPartyQuoteScreen extends BaseComponent {
    @Input() shipment: any;
    @Input() onBookComplete: any;

    quoting: boolean = false
    quotingResponse: any =  null

    ngOnInit(): void {
        this.initiateQuoting()
    }

    initiateQuoting() {
        const { id } = this.shipment
        const url = environment.backendUrl + `/v2/pricing/quote/shipments/${ id }`
        this.quoting = true
        this.api.POST(url).subscribe((res) => {
            this.quoting = false
            this.quotingResponse = res
        }, (res) => {
            this.quoting = false
            this.showErr(res.text)
        })
    }
}