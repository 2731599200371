import Sockette from 'sockette';
import { Mutex } from 'async-mutex';
import { ReplaySubject, Subject } from "rxjs";

import { Log } from '@services/log';

const mutex = new Mutex();
const SOCKET_URL = "wss://gw.wearewarp.com/api-routing/solution-ws";

export class Websocket {
  private ws: Sockette;
  private wsConnected: boolean = false;
  public onConnected: ReplaySubject<any> = new ReplaySubject(1);
  public onMessage: Subject<any> = new Subject();

  constructor() {
    this.initRoutingSocket();
  }

  private initRoutingSocket() {
    this.ws = new Sockette(SOCKET_URL, {
      timeout: 5e3,
      maxAttempts: 10,
      onopen: e => {
        Log.d('Websocket Connected');
        this.setWsConnected(true);
        this.onConnected.next();
      },
      onmessage: (e) => {
        Log.d('Websocket onmessage ', e);
        let data = e.data;
        let result = JSON.parse(data);
        this.handleWsData(result);
      },
      onreconnect: e => Log.d('Websocket Reconnecting...', e),
      onmaximum: e => Log.d('Websocket Stop Attempting!', e),
      onclose: e => {
        Log.d('Websocket Closed!', e)
        this.setWsConnected(false);
      },
      onerror: e => console.error('Websocket Error:', e)
    });
  }

  public releaseRoutingSocket() {
    this.ws?.close();
    this.ws = null;
    this.setWsConnected(false);
  }

  private setWsConnected(value: boolean) {
    mutex.acquire().then((done) => {
      this.wsConnected = value;
      done();
    }).catch(e => {
      console.error('mutex.acquire error. ', e);
    });
  }

  public async isWsConnected() {
    return mutex.acquire().then((done) => {
      done();
      return Promise.resolve(this.wsConnected);
    }).catch(e => {
      console.error('mutex.acquire error. ', e);
    });
  }

  public sendWsData(data) {
    if (!this.ws) {
      return console.error('the websocket have not been initialed');
    }
    this.onConnected.subscribe(() => {
      // Log.d("ws send", data)
      this.ws.json(data);
    })
  }


  private handleWsData(result) {
    this.onMessage.next({
      content: result.content,
      target: result.target
    });
  }
}