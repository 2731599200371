import { Component, OnInit } from "@angular/core";

@Component({
  selector: "empty-info",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class EmptyInfo implements OnInit {

  constructor() {}

  ngOnInit(): void {
    
  }
}
