import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Utils } from "@services/utils";
import { ContactType } from "@wearewarp/types";
import { Observable } from "rxjs";
import { Validators } from '@angular/forms';
import { InputHelper } from '@services/input-helper';
@Component({
  selector: 'edit-shipment-entry-contact-info',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class EditShipmentEntryContactInfo extends BaseFormDialog1 {

  @Input() onSave: (data) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  @Input() contactType: ContactType;
  @Input() headerText = 'Edit Contact';

  protected formGroupDeclaration: FormGroupDeclaration = {
    type: {label: '', hidden: true},
    // company: {label: 'Company Name'},
    fullName: {label: 'Contact Name'},
    phone: {label: 'Phone Number', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
    phoneExtension: {label: '', placeHolder: 'Ext'},
    email: {label: 'Contact Email', validators: Validators.email}
  }


  ngOnInit(): void {
    super.ngOnInit();
  }

  get getLabelType() {
    return this.contactType ? Utils.capitalizeFirstLetter(this.contactType) : '';
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  onBtnSave() {
    if (!this.needUpdate) return;
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }
    
}
