import { Component, ElementRef, EventEmitter, HostListener, Input, NgZone, Output, ViewChild } from '@angular/core';
import mapboxgl, { MapboxOptions, LngLatLike, Map as Mapbox, Marker, Popup, LngLatBounds, MarkerOptions, GeoJSONSource } from 'mapbox-gl';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { DeliveryInfo } from '@wearewarp/types/data-model';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';
import { AppConst } from '@app/const.generated';
import { BizUtil } from '@services/biz';
import { Log } from '@services/log';
import { getDashboard } from '@services/index';
import { Utils } from '@services/utils';
import { ModelRoutingShipment, ModelRoutingStop, RoutingRegionSelectMode } from '../../interface';
import { RoutingProblemMapSelectionPopup } from '../popup-selection';
import { RoutingProblemReorderMarkerPopup } from '../features/reOrder/marker-popup'
import { RoutingColor } from '../../color';
import { InputHelper } from '@services/input-helper';
import { MapService } from '../../services/map.service';
import { ModelMapMarker } from '../../map.interface';
import { ProblemManagement } from '../../services/problemManagement';
import { Problem } from '../../services/objects/problem';
import { RoutingService } from '../../services/routing.service';
import { ShipmentManagement } from '../../services/shipmentManagement';

interface MarkerInputData {
  shipment: any, pickInfo: DeliveryInfo, dropInfo?: DeliveryInfo
}

// const MARKER_COLOR_PICK = Const.MAP_LOCATION_COLOR_PICK;
// const MARKER_COLOR_DROP = Const.MAP_LOCATION_COLOR_DROP;
const MARKER_COLOR_PICK = '#7c4aed';    // purple
const MARKER_COLOR_DROP = '#73df54';    // green

@Component({
  selector: '[routing-problem-map]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../../../../styles/color-pallet.scss']
})
export class RoutingProblemMap extends BaseComponent {
  private problem: Problem
  public isEnabledDrawMode = false;
  public popupSelectionVisible: boolean = false;
  public selectedShipmentIds: any[] = []


  @ViewChild('popupRegion') popupRegion: RoutingProblemMapSelectionPopup;

  constructor(
    private mapService: MapService,
    private problemManagement: ProblemManagement,
    private shipmentManagement: ShipmentManagement,
    private routingService: RoutingService,
    private ngZone: NgZone
  ) {
    super();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 's':
      case 'S':
        if (!this.shouldShowSelectButton) return; //nếu chưa hiển thị button select marker thì không set hiệu lực cho hot key này
        return this.onBtnSelect();
      // case 'r':
      // case 'R':
      //   return this.onBtnRegion();
      case 'Escape':
        return this.popupRegion?.onBtnClose();
    }
  }
  ngOnInit(): void {
    this.mapService.createMap({ container: 'routing-problem-map' });
    this.init();
  }
  ngOnDestroy(): void {
    this.routingService.setEditFeature(undefined)
  }

  init() {
    this.subscription.add(
      this.problemManagement.problemSubject.subscribe(problem => {
        this.problem = problem;
      })
    );
    this.subscription.add(
      this.mapService.mapDrawEvent.subscribe(({ event }) => {
        this.ngZone.run(() => {
          if (event !== "area_updated") return;
          this.updateSelectedShipments()
          this.popupSelectionVisible = true;
        })

      })
    )
  }
  get shouldShowSelectButton() {
    return !!this.problem?.getId();
  }

  get selectRegionMode() {
    return this.problem?.getSelectRegionMode();
  }

  get txtButtonSelect() {
    let str = 'Select ';
    str += this.selectRegionMode == RoutingRegionSelectMode.byPickUp ? 'by Pickup' : 'by Dropoff';
    return str;
  }

  get reOrderFeature() {//sử dụng cho popup khi re-order task
    return this.routingService.getEditFeature()
  }

  onBtnSelect() {
    if (!this.isEnabledDrawMode) {
      this.startDraw();
    }
    else {
      this.stopDraw()
    }
  }

  private startDraw() {
    this.isEnabledDrawMode = true;
    this.mapService.startDraw()

    const shipmentsNoRegion = this.problem.getShipmentNoRegion();
    this.mapService.removeMarkers();
    this.mapService.removeLines();
    shipmentsNoRegion.map(shipmentId => this.shipmentManagement.getShipment(shipmentId).addToMap());

  }

  private stopDraw() {
    this.isEnabledDrawMode = false;
    this.mapService.stopDraw();
    this.mapService.reset();
    this.shipmentManagement.addToMap()
    this.problem.addToMap()
  }

  private updateSelectedShipments() {
    const filerType = this.selectRegionMode == RoutingRegionSelectMode.byPickUp ? 'PICKUP' : "DROPOFF"
    const selectedMarkers = this.mapService.getMarkersInSelectedArea({ type: filerType });
    let selectedShipmentIds = []
    for (let marker of selectedMarkers) {
      const shipmentIds = marker.getExtraData()?.data.filter(item => item.type == filerType).map(item => item.shipment_id);
      selectedShipmentIds = [
        ...selectedShipmentIds,
        ...shipmentIds
      ];
    }
    const shipmentRouted = this.problem.getShipmentHasRegion();
    this.selectedShipmentIds = selectedShipmentIds.filter(shipmentId => !shipmentRouted.includes(shipmentId));
  }

  public hidePopupSelection() {
    this.popupSelectionVisible = false;
    this.selectedShipmentIds = [];
    this.stopDraw()
    this.popupRegion.onVisibleChange(false);
  }

  public async fnUpdateRegion(ops: { region: string, shipments: any[] }) {
    try {
      let regionMap = {};
      for (let shipmentId of ops.shipments) {
        regionMap[`${shipmentId}`] = ops.region;
      }
      await this.routingService.updateRegion({
        problemId: this.problem.getId(),
        regionMap
      });
      this.problem.init();
      this.onUpdateRegionDone();
    }
    catch (e) {
      this.onUpdateRegionDone(e)
    }
  }
  public onUpdateRegionDone(err?: any) {
    if (!err) {
      this.hidePopupSelection();
    }
    this.popupRegion.onSetRegionDone(err);
  }


}