import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { Log } from '@services/log';
import { ModelRoutingProblem, ModelRoutingRoute } from "../../interface";
import { SubProblem } from "../../services/objects/subProblem";
import { RoutingService } from "../../services/routing.service";
import { Route } from "../../services/objects/route";
import { ProblemManagement } from "../../services/problemManagement";
import { RoutingVehicle, VehicleOption, VehicleType } from "@wearewarp/types/data-model";
import { NzModalRef } from "ng-zorro-antd/modal";

const ALLOW_RUN_PROBLEM_STATUSES = [
  'ROUTING_COMPLETE', 'ROUTING_DONE', 'ERROR', 'REQUEST_ERROR', 'ERROR_COST_MATRIX', null, undefined
];
const UNROUTEABLE_STATUSES = [
  'CANCELLED_BEFORE_PICKUP', 'CANCELLED_AFTER_PICKUP', 'UNDELIVERABLE', 'UNSERVICEABLE', 'DISPOSABLE', 'GEOCODE_FAILED'
];

@Component({
  selector: '[routing-problem-child]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../../../../styles/color-pallet.scss']
})
export class RoutingProblemChild extends BaseComponent {
  listVehicleTypes: VehicleType[];
  vehicleSelectOptions: VehicleOption [] = [];
  private instance: SubProblem;
  public listVehicles: any[]
  totalWeight: number;
  workload: number

  @Input()
  set model(instance: SubProblem) {
    this.instance = instance;
    const value = instance.getData();
    this.workload = value.workload
    this.totalWeight = value.totalWeight
    this.status = value.status;
    this.getRoutes();

    this.shipmentCount = Utils.displayCount(value.num_shipments, 'Shipment');
    this.listShipmentIdsText = instance.getShipmentRouted().join(', ') || instance.getAllShipmentIds().join(", ")
    if (Utils.isArrayNotEmpty(value?.vehicles)) {
      this.selectedVehicleId = value.vehicles?.[0]._id;
      this.vehicleSelectOptions = value?.vehicles?.[0]?.vehicleType?.options ?? []
    }

    this.unassignedShipments = value?.selected_solution?.unassigned_shipments ?? [];
    if (this.unassignedShipments.length == 1) {
      this.unassignedShipmentsText = 'There is 1 unrouted shipment';
    } else if (this.unassignedShipments.length > 1) {
      this.unassignedShipmentsText = `There are ${this.unassignedShipments.length} unrouted shipments`;
    } else {
      this.unassignedShipmentsText = '';
    }

    this.checkIfNeedReOrder()
  }

  unassignedShipments = [];
  unassignedShipmentsText = '';

  routes: Route[] = [];
  selectedVehicleId: string;
  shipmentCount: string;
  listShipmentIdsText: string;
  status: string;
  get canDoRoute(): boolean {
    if (this.isRoutingCompleted) return false;
    return !this.instance.getStatus() || ALLOW_RUN_PROBLEM_STATUSES.includes(this.instance.getStatus());
  }

  get alreadyHadRoute(): boolean {
    return this.instance.getRoutes().size > 0
  }

  get vehicleCount(): number {
    let item = this.getSelectedVehicle();
    return Number(item?.availability ?? 0);
  }
  get vehicleUsed(): number {
    return this.instance.getRoutes().size ?? 0;
  }
  get btnTextReroute() {
    return this.instance.getStatus() == 'ROUTING_COMPLETE' ? 'Route Again' : 'Start Routing';
  }
  get isRoutingCompleted() {
    return this.instance.getStatus() == 'ROUTING_COMPLETE';
  }
  get isRoutingNeedReview() {
    return this.instance.getStatus() == 'ROUTING_NEED_REVIEW';

  }
  get isRoutingInProgress() {
    return this.instance.getStatus() == 'IN_PROGRESS' || this.instance.getStatus() == 'ROUTING_REQUEST_SENDING';
  }

  constructor(
    private routingService: RoutingService,
    private problemManagement: ProblemManagement,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listVehicleTypes = MasterData.getAllVehicleTypes();
    this.subscription.add(this.routingService.listVehicles.subscribe(data => {
      this.listVehicles = [...data];
    }));
  }
  ngOnDestroy(): void {

  }

  public getId() {
    return this.instance.getData().id
  }
  public getStatus() {
    return this.instance.getStatus();
  }
  public getRegion() {
    return this.instance.getRegion()
  }

  public get vehicleOptions(): VehicleOption[] {
    const vehicleType = this.listVehicleTypes.find(item => item.code == this.selectedVehicleId);
    if (vehicleType) {
      return vehicleType.options ?? []
    }
    return [];
  }

  public getRoutes() {
    const routes = Array.from(this.instance.getRoutes().values());
    //this.routes = routes.map(route => route.getRouteDataForUI());
    this.routes = routes;
    return this.routes
  }

  private getSelectedVehicle(): RoutingVehicle {
    for (let item of this.listVehicles || []) {
      if (this.selectedVehicleId && item._id == this.selectedVehicleId) {
        if (item?.vehicleType?.code) {
          const vehicleType = this.listVehicleTypes.find(item => item.code == this.selectedVehicleId);
          const selectableOptions: VehicleOption[] = vehicleType?.options || [];
          this.vehicleSelectOptions = this.vehicleSelectOptions.filter(it => selectableOptions.indexOf(it) >= 0);
          item.vehicleType.options = this.vehicleSelectOptions;
        }
        return item;
      }
    }
    return null;
  }

  showShipmentCount(list: any[]) {
    return Utils.displayCount(list.length, 'Shipment');
  }

  showStopCount(stopCount) {
    return Utils.displayCount(stopCount, 'Stop');
  }

  onBtnAddMoreVehicles() {
    this.routingService.uiControlSubject.next({
      event: "addMoreVehicle"
    })
  }

  onBtnStartRouting() {
    if (!this.selectedVehicleId) {
      return this.showDialog('Please select vehicle');
    }
    this.routingService.startRouting(this.instance.getData())
  }

  onBtnStopRouting() {
    this.routingService.stopRouting(this.instance.getData());
  }

  isUpdatingVehicles = false;
  onOptionChange(type, event) {
    if (event) {
        if (this.vehicleSelectOptions.indexOf(type) < 0)
            this.vehicleSelectOptions.push(type)
    } else {
        this.vehicleSelectOptions = this.vehicleSelectOptions.filter(it => it !== type)
    }
    const vehicleType = this.listVehicleTypes.find(item => item.code == this.selectedVehicleId);
    const selectableOptions: VehicleOption[] = vehicleType?.options || [];
    this.vehicleSelectOptions = this.vehicleSelectOptions.filter(it => selectableOptions.indexOf(it) >= 0);
    this.onVehicleSelected(this.selectedVehicleId);
  }
  onVehicleSelected(event) {
    let defaultVehicleData = {
      deploy_cost: 12345,
      loopback_allowed: false,
      max_assignment_duration: 72000,
      travel_distance_unit_cost: 1609.34,
      max_travel_time: 28800,
      travel_time_unit_cost: 20,
      service_time_unit_cost: 20,
      wait_time_unit_cost: 800,
      capacity: 20,
      availability: 0,
      id: 1,
    };
    let item = this.getSelectedVehicle();
    let vehicle = Object.assign(defaultVehicleData, item);
    this.isUpdatingVehicles = true;
    this.api.PUT(Const.APIURI_LTL_ROUTING(`problems/${this.instance.getData().id}`), { vehicles: [vehicle] }).subscribe(
      resp => {
        this.isUpdatingVehicles = false;
      }, err => {
        this.showErr(err);
        this.isUpdatingVehicles = false;
      }
    );
  }

  getVehicleName(model: RoutingVehicle) {
    if (model?.vehicleType?.name) {
      return model.vehicleType.name;
    }
    if (model.modeId == 'custom') {
      return 'Custome Vehicle';
    }
    let str = MasterData.getShipmentModeName(model.modeId);
    if (model.typeId) {
      str += `, ${MasterData.getEquipmenName(model.typeId)}`;
    }
    return str;
  }

  getSelectedVehicleDesc() {
    let item = this.getSelectedVehicle();
    return this.getVehicleDesc(item);
  }

  getVehicleDesc(v: RoutingVehicle) {
    let capacity = Number(v?.capacity ?? 0);
    if (v?.vehicleType?.palletCapacity) {
      capacity = v.vehicleType.palletCapacity;
    }
    let availability = Number(v?.availability ?? 0);
    let str = `Capacity: ${Utils.displayCount(capacity, 'Pallet')}, Availability: ${availability}`;
    if (this.vehicleUsed > 0) {
      str += `, Used: ${this.vehicleUsed}/${availability}`;
    }
    return str;
  }

  async onManualRoute() {
    if (!this.selectedVehicleId) {
      return this.showDialog('Please select vehicle');
    }
    const subProblemId = this.instance.getData()?.id;
    await this.routingService.createManualRoute({
      subProblemId: subProblemId,
      shipmentIds: this.instance.getAllShipmentIds(),
      vehicleId: this.selectedVehicleId,
      vehicleOptions: this.vehicleSelectOptions ?? []
    });
    this.problemManagement.setInManualRoute(subProblemId);

    await this.problemManagement.reloadProblem();
    this.showDialogRoutingManualDone();
  }

  checkIfNeedReOrder() {
    const subProblemId = this.instance.getData()?.id;
    if (this.problemManagement.getInManualRoute(subProblemId)) {
      const route = this.getRoutes()[0]
      const featureController = route.startAdjustOrder();
      this.routingService.uiControlSubject.next({
        event: "startAdjustRoutingOrder",
        data: {
          route: route,
          controller: featureController
        }
      });
    }
  }

  async solveUnroutedManually() {
    await this.onManualRoute();
  }

  @ViewChild('tplRoutingManualDone') tplRoutingManualDone: TemplateRef<any>;
  private dlgRoutingDone: NzModalRef;
  private isDialogShowing = false;
  private showDialogRoutingManualDone() {
    if (this.isDialogShowing) return;
    this.isDialogShowing = true;

    this.dlgRoutingDone = this.modalService.create({
      nzContent: this.tplRoutingManualDone,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzWrapClassName: 'dialog-confirm-route-done'
    });
  }

  closeDialogRoutingDone() {
    this.dlgRoutingDone?.close();
    this.isDialogShowing = false;
  }
}
