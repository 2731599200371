import { AnyLayer, AnyPaint, AnySourceData, GeoJSONSource, GeoJSONSourceOptions, LineLayer, LinePaint, LngLatLike, Map as Mapbox, Popup } from "mapbox-gl";

export class MapGeoJsonBase {

  protected map: Mapbox;
  protected id: string;
  protected coordinates: number[][];
  protected paint: AnyPaint = {}

  protected sourceType: "Point" | "MultiPoint" | "LineString" | "MultiLineString" | "Polygon" | "MultiPolygon" | "GeometryCollection" = "Point"; //type của feature trong source
  protected layerType = ""; //type của layer
  protected deduplicateId = Math.random();
  constructor(id?: string) {
    this.id = id;
  }

  /**
   * Cập nhật data cho source.
   * @param coordinates danh sách toạ độ [long, lat]
   * @returns 
   */
  public setCoordinates(coordinates: number[][]) {
    this.coordinates = coordinates;
    const newData = this.generateSourceData();
    if (this.map) {
      this.getSource().setData(newData)
    }
    return this;
  }

  public getCoordinates(): LngLatLike[] {
    return <LngLatLike[]>this.coordinates
  }

  public setPaint(paint: AnyPaint) {
    this.paint = {
      ...this.paint,
      ...paint
    }
    for (let propertyKey in paint) {
      this.map.setPaintProperty(this.getLayerId(), propertyKey, paint[propertyKey]);
    }
    return this
  }

  public addTo(map) {
    this.map = map;
    const sourceData = this.generateSource();
    const layerData = <AnyLayer>this.generateLayer();
    this.map.addSource(this.getSourceId(), sourceData);
    this.map.addLayer(layerData);
    return this;
  }

  public remove() {
    this.map.removeLayer(this.getLayerId());
    this.map.removeSource(this.getSourceId());
  }

  protected getSourceId() {
    return `${this.sourceType}-source-${this.id}-${this.deduplicateId}`;
  }
  protected getLayerId() {
    return `${this.layerType}-layer-${this.id}-${this.deduplicateId}`;
  }

  protected getSource(): GeoJSONSource {
    return this.map.getSource(this.getSourceId()) as GeoJSONSource
  }

  protected getLayer(): AnyLayer {
    return this.map.getLayer(this.getLayerId());
  }

  protected generateSource(): AnySourceData {
    return {
      'type': 'geojson',
      data: this.generateSourceData()
    }
  }

  protected getProperties(): any {
    return {}
  }

  protected generateSourceData(): GeoJSON.Feature | GeoJSON.FeatureCollection {
    return {
      'type': 'Feature',
      'properties': this.getProperties(),
      'geometry': {
        'type': <any>this.sourceType,
        'coordinates': this.coordinates
      }
    }
  }

  protected generateLayer(): AnyLayer {
    return {
      'id': this.getLayerId(),
      'type': <any>this.layerType,
      'source': this.getSourceId(),
    }
  }

}