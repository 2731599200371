
<form nz-form [formGroup]="formInput">
  <div *ngIf="!isShowOnlyServiceOptions" class="form-input-cost-item">
    <div nz-row [nzGutter]="{ sm: 16 }">
      <div nz-col nzSm="3" nzMd="3">
        <form nz-form *ngFor="let key of ['currency']" [formGroupName]="key">
          <div class="label-item">{{getLabel(join(key, 'type'))}}</div>
          <div>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzBackdrop="true" [formControlName]="'type'"
                  (ngModelChange)="onChangeCurrencyType($event)">
                  <nz-option *ngFor="let item of currencies" [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <ng-container *ngIf="lastCurrencyType !== 'USD'">
            <div class="label-item">{{getLabel(join(key, 'fxRate'))}}</div>
            <div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="'fxRate'"
                    [ngClass]="{'readonly': isReadOnly(join(key, 'fxRate'))}"
                    [type]="getInputType(join(key, 'fxRate'))"
                    [placeholder]="getPlaceHolder(join(key, 'fxRate'))"
                    (input)="onInputChanged($event, join(key, 'fxRate'))"
                    (keypress)="onInputKeyPress($event, join(key, 'fxRate'))"
                    (focusout)="onInputFocusOut($event, join(key, 'fxRate'))">
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>
        </form>
      </div>

      <div nz-col nzSm="9" nzMd="9">
        <form nz-form *ngFor="let key of ['transitCost']" [formGroupName]="key">
          <div nz-row>
            <div nz-col nzSm="16" nzMd="16" style="padding-right: 4px;">
              <div class="label-item">{{getLabel(join(key, 'rate'))}}</div>
              <div>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input [formControlName]="'rate'"
                      [ngClass]="{'readonly': isReadOnly(join(key, 'rate'))}"
                      [type]="getInputType(join(key, 'rate'))"
                      [placeholder]="getPlaceHolder(join(key, 'rate'))"
                      (input)="onInputChanged($event, join(key, 'rate'))"
                      (keypress)="onInputKeyPress($event, join(key, 'rate'))"
                      (focusout)="onInputFocusOut($event, join(key, 'rate'))" >
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-col nzSm="8" nzMd="8" style="padding-left: 12px;">
              <div class="label-item">{{getLabel(join(key, 'qty'))}}</div>
              <div>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input [formControlName]="'qty'"
                      [ngClass]="{'readonly': isReadOnly(join(key, 'qty'))}"
                      [type]="getInputType(join(key, 'qty'))"
                      [placeholder]="getPlaceHolder(join(key, 'qty'))"
                      (input)="onInputChanged($event, join(key, 'qty'))"
                      (keypress)="onInputKeyPress($event, join(key, 'qty'))"
                      (focusout)="onInputFocusOut($event, join(key, 'qty'))" >
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div nz-col nzSm="6" nzMd="6">
        <form nz-form *ngFor="let key of ['volumeDiscount']" [formGroupName]="key">
          <div nz-row>
            <div nz-col nzSm="12" nzMd="12">
              <div class="label-item">{{getLabel(join(key, 'type'))}}</div>
              <div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select nzBackdrop="true" [formControlName]="'type'"
                      (ngModelChange)="onVolumeDiscountTypeChange($event)">
                      <nz-option *ngFor="let item of volumeDiscountTypes" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-col nzSm="12" nzMd="12" style="padding-left: 16px;">
              <ng-container *ngIf="volumeDiscountType == 'percentage'">
                <div class="label-item">{{getLabel(join(key, 'percentage'))}}</div>
                <div>
                  <nz-form-item>
                    <nz-form-control>
                      <input nz-input formControlName="percentage"
                        [ngClass]="{'readonly': isReadOnly(join(key, 'percentage'))}"
                        [type]="getInputType(join(key, 'percentage'))"
                        [placeholder]="getPlaceHolder(join(key, 'percentage'))"
                        (input)="onInputChanged($event, join(key, 'percentage'))"
                        (keypress)="onInputKeyPress($event, join(key, 'percentage'))"
                        (focusout)="onInputFocusOut($event, join(key, 'percentage'))">
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </ng-container>
              <ng-container *ngIf="volumeDiscountType == 'flatRate'">
                <div class="label-item">{{getLabel(join(key, 'flatRate'))}}</div>
                <div>
                  <nz-form-item>
                    <nz-form-control>
                      <input nz-input formControlName="flatRate"
                        [ngClass]="{'readonly': isReadOnly(join(key, 'flatRate'))}"
                        [type]="getInputType(join(key, 'flatRate'))"
                        [placeholder]="getPlaceHolder(join(key, 'flatRate'))"
                        (input)="onInputChanged($event, join(key, 'flatRate'))"
                        (keypress)="onInputKeyPress($event, join(key, 'flatRate'))"
                        (focusout)="onInputFocusOut($event, join(key, 'flatRate'))" >
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </ng-container>
            </div>
          </div>
        </form>
      </div>
      
      <div nz-col nzSm="6" nzMd="6">
        <div class="label-item">{{getLabel('subTotal')}}</div>
        <div>
          <nz-form-item>
            <nz-form-control>
              <input nz-input formControlName="subTotal">
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isShowOnlyServiceOptions" class="form-input-cost-item">
    <form nz-form *ngFor="let key of ['fuelCost']" [formGroupName]="key">
      <div nz-row [nzGutter]="{ sm: 16 }">
        <div nz-col nzSm="3" nzMd="3">
          <div class="label-item">{{getLabel(join(key, 'type'))}}</div>
          <div>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzBackdrop="true" [formControlName]="'type'"
                  (ngModelChange)="onFuelCostTypeChange($event)">
                  <nz-option *ngFor="let item of fuelCostTypes" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSm="6" nzMd="6">
          <ng-container *ngIf="fuelCostType == 'rpm'">
            <div class="label-item">{{getLabel(join(key, 'rpm'))}}</div>
            <div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input formControlName="rpm"
                    [ngClass]="{'readonly': isReadOnly(join(key, 'rpm'))}"
                    [type]="getInputType(join(key, 'rpm'))"
                    [placeholder]="getPlaceHolder(join(key, 'rpm'))"
                    (input)="onInputChanged($event, join(key, 'rpm'))"
                    (keypress)="onInputKeyPress($event, join(key, 'rpm'))"
                    (focusout)="onInputFocusOut($event, join(key, 'rpm'))" >
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>
          <ng-container *ngIf="fuelCostType == 'percentage'">
            <div class="label-item">{{getLabel(join(key, 'percentage'))}}</div>
            <div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input formControlName="percentage"
                    [ngClass]="{'readonly': isReadOnly(join(key, 'percentage'))}"
                    [type]="getInputType(join(key, 'percentage'))"
                    [placeholder]="getPlaceHolder(join(key, 'percentage'))"
                    (input)="onInputChanged($event, join(key, 'percentage'))"
                    (keypress)="onInputKeyPress($event, join(key, 'percentage'))"
                    (focusout)="onInputFocusOut($event, join(key, 'percentage'))">
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>
        </div>
        <div nz-col nzSm="3" nzMd="3">
          <ng-container *ngIf="fuelCostType == 'rpm'">
            <div class="label-item">{{getLabel(join(key, 'qty'))}}</div>
            <div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input formControlName="qty"
                    [ngClass]="{'readonly': isReadOnly(join(key, 'qty'))}"
                    [type]="getInputType(join(key, 'qty'))"
                    [placeholder]="getPlaceHolder(join(key, 'qty'))"
                    (input)="onInputChanged($event, join(key, 'qty'))"
                    (keypress)="onInputKeyPress($event, join(key, 'qty'))"
                    (focusout)="onInputFocusOut($event, join(key, 'qty'))">
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>
        </div>
        <div nz-col nzSm="6" nzMd="6"></div>
        <div nz-col nzSm="6" nzMd="6">
          <div>&nbsp;</div>
          <div>
            <nz-form-item>
              <nz-form-control>
                <input nz-input formControlName="total">
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="form-input-cost-item">
    <div class="label-item">Service Options:</div>
    <div nz-row [nzGutter]="{ sm: 16 }" class="form-add-service-options">
      <div nz-col nzSm="7" nzMd="7">
        <nz-form-item>
          <nz-form-control>
            <nz-select nzBackdrop="true" nzShowSearch 
              [(ngModel)]="selectedNewServiceOption"
              (ngModelChange)="onBtnAddServiceOption($event)"
              [ngModelOptions]="{standalone: true}"
              nzPlaceHolder="Search & select service options">
              <nz-option-group *ngFor="let group of serviceOptions" [nzLabel]="group.label">
                <nz-option *ngFor="let item of group.items"
                  [nzValue]="item._id" [nzLabel]="item.name"
                  [nzDisabled]="isDisableAddServiceOptionItem(item)">
                </nz-option>
              </nz-option-group>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div>
      <div nz-row [nzGutter]="{ sm: 16 }" *ngIf="shouldShowServiceOptionLabel">
        <div nz-col [nzOffset]="12" nzSm="3" nzMd="3" class="label-item">Rate</div>
        <div nz-col nzSm="3" nzMd="3" class="label-item">Qty</div>
      </div>
      <ng-container *ngFor="let key of ['serviceOptions']" formArrayName="serviceOptions">
        <form *ngFor="let item of getArrayControls(key); let i = index" nz-form [formGroupName]="i" class="service-option">
          <div nz-row [nzGutter]="{ sm: 16 }">
            <div nz-col nzSm="7" nzMd="7">
              <nz-form-item>
                <nz-form-control style="position: relative;">
                  <nz-select [nzShowArrow]="false"
                    [formControlName]="'_id'" nzShowSearch
                    (ngModelChange)="onServiceOptionItemChange(i, $event)">
                    <nz-option-group *ngFor="let group of serviceOptions" [nzLabel]="group.label">
                      <nz-option *ngFor="let item of group.items"
                        [nzValue]="item._id" [nzLabel]="item.name"
                        [nzDisabled]="isDisableServiceOptionItem(item, i)">
                      </nz-option>
                    </nz-option-group>
                  </nz-select>
                  <div class="icon-remove-service-option">
                    <span nz-icon nzType="close-circle" nzTheme="outline" (click)="onBtnRemoveServiceOption(i)"></span>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSm="5" nzMd="5">
              <ng-container *ngIf="shouldShowSelectLocation">
                <ng-container *ngIf="getServiceOptionItemType(i) == 'pickup'">
                  <nz-form-item>
                    <nz-form-control>
                      <nz-select nzBackdrop="true" nzPlaceHolder="Select a Pickup Location"
                        formControlName="deliveryId"
                        (ngModelChange)="onServiceOptionItemLocationChange(i, $event)">
                        <nz-option *ngFor="let item of listPickupLocationSelect"
                          [nzValue]="item.deliveryId" [nzLabel]="item.label"
                          [nzDisabled]="isDisableServiceOptionLocationItem(item, i)">
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <ng-container *ngIf="getServiceOptionItemType(i) == 'delivery'">
                  <nz-form-item>
                    <nz-form-control>
                      <nz-select nzBackdrop="true" nzPlaceHolder="Select a Delivery Location"
                        formControlName="deliveryId"
                        (ngModelChange)="onServiceOptionItemLocationChange(i, $event)">
                        <nz-option *ngFor="let item of listDeliveryLocationSelect"
                          [nzValue]="item.deliveryId" [nzLabel]="item.label"
                          [nzDisabled]="isDisableServiceOptionLocationItem(item, i)">
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
              </ng-container>
            </div>
            <div nz-col nzSm="3" nzMd="3">
              <input nz-input [formControlName]="'rate'"
                [ngClass]="{'readonly': isReadOnly(join(key, 'rate'))}"
                [type]="getInputType(join(key, 'rate'))"
                [placeholder]="getPlaceHolder(join(key, 'rate'))"
                (input)="onInputChanged($event, join(key, 'rate'))"
                (keypress)="onInputKeyPress($event, join(key, 'rate'))"
                (focusout)="onInputFocusOut($event, key + '[' + i + ']' + '.' + 'rate')">
            </div>
            <div nz-col nzSm="3" nzMd="3">
              <input nz-input [formControlName]="'qty'"
                [ngClass]="{'readonly': isReadOnly(join(key, 'qty'))}"
                [type]="getInputType(join(key, 'qty'))"
                [placeholder]="getPlaceHolder(join(key, 'qty'))"
                (input)="onInputChanged($event, join(key, 'qty'))"
                (keypress)="onInputKeyPress($event, join(key, 'qty'))"
                (focusout)="onInputFocusOut($event, key + '[' + i + ']' + '.' + 'qty')">
            </div>
            <div nz-col nzSm="6" nzMd="6">
              <nz-form-item>
                <nz-form-control>
                  <input
                    nz-input
                    [ngModel]="getServiceOptionMoneyValue(i)"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="true"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>

  <div class="flex top20 grand-total-row">
    <div class="flex1"></div>
    <div>{{ labelTotal }}{{ labelCurrency }}: </div>
    <div class="grand-total">
      {{ grandTotal }}
    </div>
  </div>

  <div class="flex top20 grand-total-row" *ngIf="lastCurrencyType !== 'USD'">
    <div class="flex1"></div>
    <div>USD Conversion: </div>
    <div class="grand-total">
      {{ usdConversion }}
    </div>
  </div>

</form>


