
import { Component, Input } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';

@Component({
    selector: '[warehouse-item-shipment-item]',
    templateUrl: './shipment_item.html',
    styleUrls: ['./shipment_item.scss', './item_content.scss']
})
export class WarehouseItemShipmentItem extends BaseComponent {
    @Input() item: any = null
    weight: number
    compact: boolean = true

    edittingWeight: boolean = false

    constructor() {
        super()
        this.confirmMarkMissing = this.confirmMarkMissing.bind(this)
    }

    turnOnEdittingWeight() {
        this.edittingWeight = true
        this.weight = this.item.actualWeight || this.item.weightPerUnit
    }

    cancelEdittingWeight() {
        this.edittingWeight = false
    }

    toggleCompact() {
        this.compact = !this.compact
    }

    confirmWeight() {
        this.item.actualWeight = this.weight
        const url = Const.APIV2(`shipment_items/${this.item.id}/actual-weight`)
        this.api.PUT(url, {weight: parseFloat(this.item.actualWeight)}).subscribe((res) => {

        }, (err) => {
            this.showErr(`Error while updating weight for item`)
        })
        this.edittingWeight = false
    }
    markMissing() {
        this.modalService.create({
            nzTitle: 'Mark Item Missing Confirmation',
            nzContent: `Please confirm you want to mark item ${this.item.name} as missing`,
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: 'OK',
            nzOnOk: () => this.confirmMarkMissing(),
            nzCancelText: 'Cancel'
        });      
    }

    confirmMarkMissing() {
        const url = Const.APIV2(`shipment_items/${this.item.id}/missing`)
        this.api.PUT(url, {isMissing: true}).subscribe((res) => {
            this.item.isMissing = true
        })
    }
}