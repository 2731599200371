import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { Subscription } from "rxjs";
import { ModelRoutingProblem, ProblemRoutingState } from "../../interface";
import { Problem } from "../../services/objects/problem";
import { ProblemManagement } from "../../services/problemManagement";
import { RoutingService } from "../../services/routing.service";

@Component({
  selector: '[routing-problem-confirm-routes]',
  templateUrl: './confirm-routes.html',
  styleUrls: ['./style.scss', '../../../../../styles/color-pallet.scss']
})
export class RoutingProblemConfirmRoutes {
  private problem: Problem;
  public problemStatus: ProblemRoutingState;
  public subscription: Subscription = new Subscription();
  public isLoading: boolean = false;
  txtDate = '';
  txtCountRoutes = '';
  txtCountShipments = '';
  constructor(
    private problemManagement: ProblemManagement,
    private routingService: RoutingService,
    private modalService: NzModalService) {

  }

  ngOnInit() {
    this.init();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.problemSubscription.unsubscribe();
  }

  problemSubscription = new Subscription();
  init() {
    this.subscription.add(
      this.problemManagement.problemSubject.subscribe(problem => {
        this.problemSubscription.unsubscribe();
        this.problemSubscription = new Subscription();

        this.problem = problem;
        this.problemSubscription.add(this.problem.dataSubject.subscribe(() => {
          this.problem = problem;
          this.problemStatus = this.problem.getStatus();
          let countRoutes = this.problem.getRouteCount();
          let countShipments = this.problem.countShipmentRouted();
          this.txtCountRoutes = Utils.displayCount(countRoutes, 'Route') + ' created';
          this.txtCountShipments = Utils.displayCount(countShipments, 'Shipment');
          this.txtDate = DateUtil.format(this.problem.getData()?.dropoff_earliest_ts, 'dddd, MMM DD');
        }))
      })
    );
  }


  get canConfirm() {
    return this.problemStatus == ProblemRoutingState.didRouteAll && !this.routingService.isEditing
  }

  async confirmRoutes() {
    if (this.problemStatus != ProblemRoutingState.didRouteAll) {
      return;
    }
    this.isLoading = true;
    let previewState = this.problemStatus;
    this.problemStatus = ProblemRoutingState.confirming;
    let problemId = this.problem.getData().id;
    try {
      const data = await this.routingService.confirmRoutes(problemId);
      await this.problem.init()
      // this.routingState = ProblemRoutingState.done;
      this.showDialogConfirmDone(data);
      this.isLoading = false;
    }
    catch (e) {
      this.problemStatus = previewState;
      this.isLoading = false;
    }
  }



  @ViewChild('tplConfirmDone') tplConfirmDone: TemplateRef<any>;
  private dlgConfirmDone: NzModalRef;
  public dlgConfirmHyperLinks = [];

  private showDialogConfirmDone(result: { jobs: any[], tasks: any[], clients: any[] }) {
    let jobs = result.jobs ?? [];
    let clients = result.clients ?? [];
    let dicClients = {};
    for (let client of clients) {
      dicClients[client._id] = client;
    }
    this.dlgConfirmHyperLinks = [];
    for (let job of jobs) {
      for (let shipment of job.shipments) {
        shipment.client = dicClients[shipment.clientId];
      }
      this.dlgConfirmHyperLinks.push(BizUtil.createHyperLinkForJob(job));
    }
    this.dlgConfirmDone = this.modalService.create({
      nzContent: this.tplConfirmDone,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzWrapClassName: 'dialog-confirm-route-done'
    });
  }

  closeDialogConfirmDone() {
    this.dlgConfirmDone?.close();
  }
}