<div nz-row [nzGutter]="{ sm: 16 }">
    <div nz-col nzSm="12" nzMd="12">
        <button class="right10" nz-button nzType="default" nzSize="small" color="danger" nzShape="circle" (click)="onBtnDelete()">
            <span nz-icon nzType="delete" nzTheme="outline" class="clickable danger"></span>
        </button>
        {{ serviceOption.name }}
    </div>
    <div nz-col nzSm="3" nzMd="3">
        <input nz-input type="number" [(ngModel)]="serviceOption.rate" placeholder="Rate" (ngModelChange)="onUpdateValue()">
    </div>
    <div nz-col nzSm="3" nzMd="3">
        <input nz-input type="number" [(ngModel)]="serviceOption.qty" (ngModelChange)="onUpdateValue()"
        placeholder="Rate">
    </div>
    <div nz-col nzSm="6" nzMd="6">
        <div class="sub-total">
            <span decimal [value]="serviceOption.total" default="0.0"></span>
        </div>
    </div>
</div>
