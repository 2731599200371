<div class="dashboard-child-container no-padding list problem-detail">
  <div class="list-header">
    <div #filterComp routing-filter-shipment [disabled]="!isCreateNew"></div>
    <div class="vehicle-used">
      <div style="display: flex; align-items: flex-end;">
        <!-- <div style="font-size: 28px; font-weight: 700;">0</div>
        <div style="font-size: 22px; margin-bottom: 3px; flex: 1; min-width: 120px;">/0</div> -->
        <img class="ic-truck1">
        <div class="flex1"></div>
        <button nz-button nzType="text" (click)="onBtnVehicles()" *ngIf="requirePermissions([PermissionCode.routing_ltl.create, PermissionCode.routing_ltl.update])">
          <i class="edit-icon" nz-icon nzType="edit" nzTheme="outline" style="align-self: center;"></i>
        </button>
      </div>
      <div style="color: #A8A8A8; margin-top: 16px;">Manage Vehicles</div>
    </div>
    <div routing-problem-vehicles #vehicleComponent>
    </div>
  </div>

  <div class="flex problem-dynamic-height">
    <div class="routing-list-shipment">
      <div class="flex">
        <div class="bottom10 flex1">{{countShipments}}</div>
        <button *ngIf="shouldFillDateTime" nz-button style="margin-top: -11px;" (click)="onBtnFillTimeWindow()">
          <i nz-icon nzType="calendar" nzTheme="outline"></i>
          Fill time window
        </button>
      </div>

      <div *ngIf="shouldShowNoData" class="nodata" style="padding: 16px;">
        {{noDataText}}
      </div>

      <div *ngIf="shouldShowSideLoading" class="nodata">
        <i nz-icon nzType="loading" nzTheme="outline"></i>
      </div>

      <div routing-problem-list-shipment class="routing-shipment-item-box">
      </div>

      <!-- List sub problems không nhiều nên không cần dùng cdk-virtual-scroll-viewport (do cdkVirtualFor không update chính xác khi subProblems thay đổi) -->
      <div *ngIf="shouldShowSubProblems" class="routing-list-shipment-viewport">
        <div *ngIf="shouldShowConfirmRoutes" routing-problem-confirm-routes class="routing-shipment-item-box">
        </div>
        <div *ngFor="let item of problem.getSubProblem(); let index = index;" class="routing-shipment-item-box">
          <div routing-problem-child [model]="item"></div>
        </div>
      </div>

    </div>

    <div class="routing-map-container" style="position: relative;">
      <div class="bottom10">Map</div>

      <!-- Map component -->
      <div #map class="routing-map" routing-problem-map>
      </div>

      <!-- Buttons -->
      <div *ngIf="shouldShowButtonsCreateCancel" style="position: absolute; top: 40px; left: 16px;">
        <button (click)="onBtnCreate()" nz-button nzType="primary" [nzLoading]="isCreatingProblem"
          [disabled]="!canCreateProblem">Create routing problem</button>
        <button (click)="onBtnCancel()" nz-button style="margin-left: 16px;">Cancel</button>
      </div>

    </div>
  </div>

</div>


<ng-template #tplRoutingDone>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <img class="ic_complete bottom20">
    <div class="bottom10" style="font-size: 18px; font-weight: 600; line-height: 27px;">Routing Completed.</div>
    <div style="font-size: 16px; font-weight: 400; line-height: 27px;">Please review then confirm all the routes.
    </div>
    <button nz-button nzType="primary" style="width: 100%; margin-top: 16px;"
      (click)="closeDialogRoutingDone()">Close</button>
  </div>
</ng-template>
<div routing-route-shipment #routeShipmentComponent></div>