import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: 'report-pod-list',
  templateUrl: 'list.html',
  styleUrls: [
    './style.scss',
    '../../list.scss'
  ]
})
export class ReportPODList extends BaseComponent {

  public isLoading = false;
  public displayInfo: any = {};
  public dataChart;
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    this.getData();
  }

  private getData() {
    let url = `${Const.APIURI_STATISTIC}/report_pod_accouting`;
    let filter = this.queryParams.filter;
    if (!filter) {
      return;
    }
    let qs = new URLSearchParams({filter}).toString();
    url += `?${qs}`;
    this.isLoading = true;
    this.api.GET(url).subscribe(
      resp => {
        this.buildDisplayInfo(resp);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private buildDisplayInfo(resp) {
    this.displayInfo = {
      numberOfPodUploaded: resp?.data?.numberOfPodUploaded ?? 0,
      numberOfPodConfirmed: resp?.data?.numberOfPodConfirmed ?? 0,
      numberOfPodUploadedWrong: resp?.data?.numberOfPodUploadedWrong ?? 0,
    }
    let data = [
      { labelPod: 'Number of Pod Uploaded', value: this.displayInfo.numberOfPodUploaded },
      { labelPod: 'Number of Pod Uploaded Wrong', value: this.displayInfo.numberOfPodUploadedWrong },
      { labelPod: 'Number of Pod Confirmed', value: this.displayInfo.numberOfPodConfirmed },
    ]
    this.dataChart = {
      data,
      options: {
        xField: 'value',
        yField: 'labelPod',
        seriesField: 'labelPod',
        legend: false,
        height: 400,
      }
    }
  }

}