import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from "@angular/core";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import { RoutingRegionSelectMode } from "../../interface";
import { ShipmentManagement } from "../../services/shipmentManagement";

@Component({
  selector: '[routing-problem-map-selection-popup]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class RoutingProblemMapSelectionPopup {
  private _shipmentIds: any[] = [];
  @Input() get shipmentIds() { return this._shipmentIds }
  set shipmentIds(value: any[]) {
    this._shipmentIds = value;
    this.countShipmentText = Utils.displayCount(this.shipmentIds.length, 'Shipment');
    if (this.shipmentIds.length == 0) {
      this.listShipmentIdsText = `Please select ${this.selectMode == RoutingRegionSelectMode.byPickUp ? 'PICKUP' : 'DROPOFF'} locations on the map`;
    } else {
      this.listShipmentIdsText = this.shipmentIds.map(shipmentId => this.shipmentManagement.getShipment(shipmentId).getWarpId()).join(", ");
    }
  }
  @Input() selectMode: RoutingRegionSelectMode;
  @Output() close = new EventEmitter();
  @Output() updateRegion = new EventEmitter<any>();

  @ViewChild('inputRegion') inputRegion: ElementRef<HTMLInputElement>;

  region: string;
  countShipmentText = '';
  listShipmentIdsText = '';
  onProgress = false;

  get canSetRegion() {
    return !!this.region;
  }

  constructor(private shipmentManagement: ShipmentManagement) {
  }

  onVisibleChange(isVisible: boolean) {
    if (isVisible) {
      this.inputRegion.nativeElement.focus();
    }
  }

  onBtnClose() {
    this.close.emit();
  }

  onBtnSetRegion() {
    if (!this.canSetRegion) {
      return;
    }
    this.onProgress = true;
    this.updateRegion.emit({ region: this.region, shipments: this.shipmentIds });
  }

  onInputChanged(event) {
    InputHelper.handleInputChangeNumberOnly(event, null, { isInteger: true });
  }

  onInputKeyPress(event) {
    return InputHelper.handleInputKeyPressNumberOnly(event);
  }

  onSetRegionDone(err?) {
    this.onProgress = false;
  }

  onKeyUp(event: KeyboardEvent) {
    if (InputHelper.isEnter(event)) {
      this.onBtnSetRegion();
    } else if (InputHelper.isEsc(event)) {
      this.onBtnClose();
    }
  }
}