<div style="display: flex;">
  <button nz-button nzType="primary" style="margin-bottom: 20px;" (click)="inputFile.click()"
      [disabled]="isDisabled" [nzLoading]="isLoading" >Upload New Version</button>
  <div *ngIf="value?.file" style="margin-top: 4px; margin-left: 20px;">
    <i nz-icon nzType="paper-clip" nzTheme="outline"></i> {{fileDesc}}
    <button nz-button (click)="delFile()" nzType="text" nzDanger [disabled]="isLoading">
      <i nz-icon nzType="close"></i>
    </button>
  </div>
</div>
<nz-alert nzType="success" *ngIf="value?.versionCode"
    nzMessage="Version code: {{value.versionCode}}, Version Name: {{value.versionName}}"></nz-alert>
<input #inputFile type="file" hidden (change)="onFileSelected(inputFile.files)">