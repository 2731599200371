<div class="content-panel">
  <div *ngFor="let item of items; let i = index;" class="item-row">
    <div #formItem form-order-item [model]="item" [index]="i" (addMoreSibling)="addItem()"></div>
    <button *ngIf="canDeleteItem(i)" nz-button nzType="text" class="btn-del-item"  [ngClass]="{'btn-del-item-first-row': i == 0}"
      nz-popconfirm [nzOkDanger]="true" nzOkText="Remove"
      [nzPopconfirmTitle]="messageDeleteItem(i)"
      (nzOnConfirm)="deleteItem(i)"
      nzPopconfirmPlacement="top"
      [nzIcon]="iconTpl">
      <span nz-icon nzType="delete" nzTheme="outline" class="danger"></span>
      <ng-template #iconTpl><span nz-icon nzType="question-circle-o"></span></ng-template>
    </button>
  </div>
  
  <button nz-button nzType="dashed" (click)="addItem()" class="btn-add-item">
    <span nz-icon nzType="plus"></span>
  </button>
</div>
