import { Component, Input } from "@angular/core";
import { FuelCostType, VolumeDiscountType } from "@app/enum";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { MasterData } from "@services/master.data";
import { ulid } from "ulid";

@Component({
    selector: 'shipment-revenue-edit',
    templateUrl: './shipment_revenue_edit.html',
    styleUrls: ['shipment_revenue_edit.scss']
})
export class ShipmentRevenueEdit extends BaseFormDialog1 {
    @Input() cost: any = {}
    @Input() shipmentId: string = null
    @Input() onDone
    @Input() onCancel
    @Input() isCreateNewCost: boolean = false;

    data: any = {}
    saving: boolean = false

    currencies = Object.values(Const.CurrencyConfig);
    volumeDiscountTypes = [
        { value: VolumeDiscountType.percentage, label: '%' },
        { value: VolumeDiscountType.flatRate, label: 'Flat Rate' },
    ]
    fuelCostTypes = [
        { value: FuelCostType.ratePerMile, label: 'RPM' },
        { value: FuelCostType.percentage, label: '%' },
    ]

    serviceOptions: any[] = []
    selectedNewServiceOption;

    addedServiceOptions: any[] = []

    ngOnInit(): void {
        if (this.serviceOptions.length == 0) {
            this.serviceOptions = [
                { label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup },
                { label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery },
                { label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition },
            ];
        }

        this.data = Object.assign({
            currency: {
                type: 'USD'
            },
            transitCost: {},
            volumeDiscount: {},
            fuelCost: {},
            serviceOptions: []
        }, JSON.parse(JSON.stringify(this.cost || {})))
        if (this.data.transitCost?.qty == null) {
          this.data.transitCost.qty = 1;    // Per Nathan's request https://wearewarp.slack.com/archives/C033R5QKPT7/p1689356593014329
        }

        for (let s of this.data.serviceOptions) {
            let option = null
            s.id = ulid()
            for (let group of this.serviceOptions) {
                for (let item of group.items) {
                    if (item._id === s._id) {
                        option =item
                    }
                }
            }
            if (option) {
                s.name = option.name
            }
        }        
    }

    updateTransitCost() {
        const { transitCost } = this.data || {}
        const { rate, qty } = transitCost || {}
        const total = (rate || 0) * (qty || 0)
        this.data.transitCost.total = total
        this.updateVolumeDiscount()
    }

    updateVolumeDiscount() {
        const { transitCost, volumeDiscount } = this.data || {}
        const { type, percentage, flatRate } = volumeDiscount || {}
        let discount = 0
        if (type === VolumeDiscountType.percentage) {
            discount = (percentage || 0) * (transitCost?.total || 0) / 100
        } else if (type === VolumeDiscountType.flatRate) {
            discount = flatRate || 0.0
        }
        this.data.volumeDiscount.total = discount
        this.data.subTotal = (this.data.transitCost.total || 0) - (this.data.volumeDiscount.total || 0)
        this.updateGrandTotal()
    }

    updateFuelCost() {
        const { fuelCost, transitCost } = this.data || {}
        const { type, percentage, rpm, qty } = fuelCost || {}
        if (type === FuelCostType.ratePerMile) {
            this.data.fuelCost.total = (rpm || 0) * (qty || 0)
        } else if (type === FuelCostType.percentage) {
            this.data.fuelCost.total = (transitCost.total || 0) * (percentage || 0) / 100
        }
        this.updateGrandTotal()
    }

    onServiceOptionUpdate(option) {
        this.data.serviceOptions = this.data.serviceOptions.map(it => it.id === option ? option : it)
        this.updateGrandTotal()
    }
    
    updateGrandTotal() {
        const { subTotal, fuelCost, serviceOptions } = this.data || {}
        const total = (subTotal || 0) + (fuelCost.total || 0) + (serviceOptions || []).map(it => it.total || 0).reduce((a,b)=> a+ b, 0)
        this.data.grandTotal = total
    }

    onBtnAddServiceOption(serviceOption) {
        if (!serviceOption) return
        let option = null
        for (let group of this.serviceOptions) {
            for (let item of group.items) {
                if (item._id === serviceOption) {
                    option =item
                }
            }
        }
        if (!option) return
        let data = Object.assign({
            id: ulid(),
            qty: 1
        }, option)
        if (!this.data.serviceOptions) {
            this.data.serviceOptions = []
        }
        this.data.serviceOptions.push(data);
    }

    onDeleteServiceOption(option) {
        this.data.serviceOptions = this.data.serviceOptions.filter(it => it.id !== option)
        this.updateGrandTotal()
    }

    onSave() {
        const payload = {
            currency: this.data.currency,
            transitCost: this.data.transitCost,
            volumeDiscount: this.data.volumeDiscount,
            fuelCost: this.data.fuelCost,
            subTotal: this.data.subTotal,
            grandTotal: this.data.grandTotal,
            serviceOptions: (this.data.serviceOptions || []).map(it => Object.assign({
                _id: it._id,
                total: it.total,
                rate: it.rate,
                qty: it.qty
            }))
        }

        const url = Const.APIV2(Const.APIURI_SHIPMENTS + `/${this.shipmentId}`)
        this.saving = true
        this.api.PUT(url, {cost: payload }).subscribe((res) => {
            this.saving = false
            this.closeDialog()
            this.onDone()
        }, (err) => {
            this.saving = false
            const message = err?.data?.message || err.message;
            this.showErr(message)
        })
    }
}