<div class="delivery-info-container">
    <span [class]="['info-name-color-box', info.type]">{{name}}</span>
    <div class="main-info" (click)="onToggleExtra()">
        <div class="bottom15 flex flex-space-between">
            <div class="group-address">
                <span short-location-info [info]="info"></span>
                <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditAddressInfo()">
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                </span>
            </div>

            <span *ngIf="isShowSettingTask(shipment, info.warehouseId)" warehouse-task-setting-icon [shipment]="shipment" [warehouseId]="info.warehouseId" (onUpdated)="onReload()"></span>
        </div>
        <div class="flex">
            <div class="flex1">
                <span class="right3" nz-icon nzType="calendar" nzTheme="outline"></span>
                <span class="time-period" *ngFor="let window of windows">{{window}}</span>
                <span *ngIf="!windows || !windows.length" class="time-period grey">No time window</span>
                <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditWindowsTime()"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
            </div>    
            <div class="flex1">
                <div *ngIf="!info.requiresAppointment || !scheduledAppointment || editMode">
                    <img alt="Time Period" class="icon" src="assets/img/appointment-date.svg" />
                    <span *ngIf="info.requiresAppointment" [class]="{'font-medium':true, 'right3': true, 'danger': !scheduledAppointment }">
                        Requires Appointment
                        <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditBookAppointment()">[book]</span>
                    </span>
                    <span *ngIf="!info.requiresAppointment" class="grey right3">Appointment not required</span>
                    <span *ngIf="editMode">
                        <nz-switch (ngModelChange)="appointmentRequireChanged($event)" nzSize="small" [(ngModel)]="info.requiresAppointment"></nz-switch>
                    </span>
                </div>
                <div *ngIf="info.requiresAppointment">
                    <span class="success" *ngIf="scheduledAppointment">Scheduled: {{ scheduledAppointment }}</span>
                    <span *ngIf="!scheduledAppointment && editMode" class="danger right5" (click)="onBtnEditBookAppointment()">Not Scheduled</span>
                    <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditBookAppointment()"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
                </div>
            </div>    
        </div>
    </div>
    <div class="extra-info" *ngIf="showExtra || editMode">
        <div class="bottom10">
            <img src="assets/img/service-options-icon.svg">
            <span class="label">Service Options: </span>
            <span *ngIf="serviceOptions && serviceOptions.length">
                <code class="refNum" *ngFor="let r of serviceOptions">{{ r }}</code>
            </span>
            <span *ngIf="!serviceOptions || !serviceOptions.length">N/A</span>
            <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditServiceOptions()"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
        </div>
        <div class="bottom10">
            <span class="right3" nz-icon nzType="contacts" nzTheme="outline"></span>
            <span class="label">Primary Contact: </span>
            <span *ngIf="primaryContact" short-contact-info [contact]="primaryContact"></span>
            <span *ngIf="!primaryContact">N/A</span>
            <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditContactInfo('primary')"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
        </div>

        <div class="bottom10">
            <span class="right3" nz-icon nzType="contacts" nzTheme="outline"></span>
            <span class="label">Secondary Contact: </span>
            <span *ngIf="secondaryContact" short-contact-info [contact]="secondaryContact"></span>
            <span *ngIf="!secondaryContact">N/A</span>
            <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditContactInfo('secondary')"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
        </div>

        <div class="bottom10">
            <span class="right3" nz-icon nzType="profile" nzTheme="outline"></span>
            <span class="label">Reference No: </span>
            <span *ngIf="info.refNums && info.refNums.length">
                <code (click)="copyValue(r)" class="refNum clickable" *ngFor="let r of info.refNums">{{ r }}</code>
            </span>
            <span *ngIf="!info.refNums || !info.refNums.length">N/A</span>
            <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditReferenceNumber()"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
        </div>
        <div class="bottom10">
            <span class="right3" nz-icon nzType="key" nzTheme="outline"></span>
            <span class="label">Access Code: </span>
            <span *ngIf="info.accessCode">{{ info.accessCode }}</span>
            <span *ngIf="!info.accessCode">N/A</span>
            <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditAccessCode()"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
        </div>
        <div class="bottom10">
            <span class="label">Instructions: </span>
            <span *ngIf="info.instructions">{{ info.instructions }}</span>
            <span *ngIf="!info.instructions">N/A</span>
            <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditInstruction()"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
        </div>
        <div class="bottom10" *ngIf="isShowActualTime">
            <div class="label">Actual: </div>
            <div>Arrived: {{ actualTime.arrived || 'N/A'}}</div>
            <div>Departed: {{ actualTime.departed || 'N/A'}}</div>
        </div>
        <div class="bottom10">
            <span class="label">Notes: </span>
            <span *ngIf="info.note">{{ info.note }}</span>
            <span *ngIf="!info.note">N/A</span>
            <span *ngIf="editMode" class="clickable edit-button" (click)="onBtnEditNote()"><span nz-icon nzType="edit" nzTheme="outline"></span></span>
        </div>
        <div>
            <span *ngIf="sortLabel" class="right10" nz-icon nzType="printer" nzTheme="outline"></span>
            <span *ngFor="let label of sortLabel"><span class="right10" nz-popover [nzPopoverTitle]="label.type + ' ' + label.format + ' Label'" [nzPopoverContent]="label.data">{{ label.format }}</span></span>
        </div>
    </div>
</div>