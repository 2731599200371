<nz-table #basicTable [nzData]="data" nzShowPagination="false">
    <thead>
        <tr *ngIf="!isMobile">
            <th>Version</th>
            <th>URL</th>
            <th>QR Code</th>
            <th *ngIf="showUploadedBy">Uploaded</th>
        </tr>
        <tr *ngIf="isMobile">
          <th colspan="2" style="text-align: left">Version</th>
        </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of basicTable.data; let index = index">
        <tr *ngIf="!isMobile">
            <td>
              {{item.versionName}} ({{item.versionCode}})
              <i *ngIf="index == 0" class="latest-text">latest</i>
            </td>
            <td><a href="{{item.url}}">{{item.url}} ({{fileSize(item.size)}})</a></td>
            <td>
                <a [href]="item.qrUrl" target="_blank" style="cursor: pointer;">
                    <img [src]="item.qrUrl" height="150px" />
                </a>
            </td>
            <td *ngIf="showUploadedBy">{{showDate(item?.created?.when)}}
                <br />by {{item?.byUser?.fullName}}
            </td>
        </tr>
        <tr *ngIf="isMobile">
          <td>
            <a href="{{item.url}}">{{item.versionName}} ({{item.versionCode}})</a>
            <i *ngIf="index == 0" class="latest-text">latest</i>
          </td>
          <td style="text-align: right;">
            <a href="{{item.url}}">
              <i nz-icon nzType="download" nzTheme="outline" class="right5"></i>
              Download ({{fileSize(item.size)}})
            </a>
          </td>
        </tr>
      </ng-container>
    </tbody>
</nz-table>