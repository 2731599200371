import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { environment } from "@env/environment";

@Component({
    selector: '[priority1-info]',
    templateUrl: './priority1.html',
    styleUrls: ['./index.scss']
})  
export class Priority1Info extends BaseComponent {
    @Input() info: any = null

    bolLink: string = null
    palletLabelLink: string = null
    priority1Id: string = null
    scheduledDelivery = null
    identifiers = null
    status = null
    carrier = null

    ngOnInit(): void {
        const { rate, dispatchShipment, trackedShipment
        } = this.info || {}
        const { shipmentIdentifiers, capacityProviderBolUrl, capacityProviderPalletLabelUrl, estimatedDeliveryDate, id } = dispatchShipment || {}

        this.identifiers = shipmentIdentifiers
        this.bolLink = capacityProviderBolUrl
        this.palletLabelLink = capacityProviderPalletLabelUrl
        this.priority1Id = id
        this.scheduledDelivery = estimatedDeliveryDate?.split('T')?.[0]
        this.status = trackedShipment?.status
        const { carrierCode, carrierName } = trackedShipment || {}
        if (carrierCode) {
            this.carrier = `[${carrierCode}] ${carrierName}`
        }
    }
}