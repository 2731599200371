<form nz-form [formGroup]="formInput">
    <div>
        <div class="mw392 flex1" *ngFor="let key of ['shipmentType']">
            <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            <nz-form-item>
                <nz-form-control>
                    <nz-radio-group nzBackdrop="true" nzButtonStyle="solid"
                    (ngModelChange)="onDropdownSelectChange(key, $event)"
                    [formControlName]="key" >
                    <label nz-radio-button *ngFor="let item of listShipmentTypes"
                        [nzValue]="item" [nzDisabled]="isDisableShipmentType(item)" >{{getShipmentTypeName(item)}}</label>
                </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <!-- <div class="flex">
      <div class="flex1 mw392" *ngFor="let key of ['shipmentModeId', 'equipmentId']">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item class="right16">
            <nz-form-control>
                <nz-select nzBackdrop="true" [formControlName]="key"
                [nzNotFoundContent]="getDropdownNoData(key)"
                (ngModelChange)="onDropdownSelectChange(key, $event)">
                    <nz-option *ngFor="let item of getListForKey(key)"
                        [nzValue]="item.id" [nzLabel]="item.name">
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
      </div>
    </div> -->

    <div class="flex">
      <div class="flex1 mw392" *ngFor="let key of ['vehicleType']">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item class="right16">
            <div [value]="vehicleType" style="min-width: 300px;" vehicle-selector (valueChange)="onDropdownSelectChange(key, $event)" [quoting]="false" [withOptions]="true"></div>
        </nz-form-item>
      </div>
    </div>

    <div class="flex">
        <div *ngFor="let key of ['clientId']" class="flex1 mw392">
            <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            <nz-form-item class="right16">
                <nz-form-control>
                    <select-by-searching [formControlName]="key"
                      [version]="2"
                      [(listData)]="listClients"
                      (listDataChange)="onListClientUpdate($event)"
                      [placeholder]="getPlaceHolder(key)"
                      (ngModelChange)="onDropdownSelectChange(key, $event)">
                    </select-by-searching>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div class="flex1" style="display: flex; align-items: center; justify-content: left;" *ngFor="let key of ['clientAddress']">
            <div class="form-label-v2"></div>
            <div *ngIf="selectedClient">{{selectedClient?.address?.formatedAddress}}
                <a style="margin-left: 16px;" [routerLink]="[routeAdminClientList, selectedClient.id]" target="_blank">View Contact</a>
            </div>
        </div>
    </div>
<!--    sub client-->
  <ng-container *ngIf="listSubClients&&listSubClients.length>0">
    <div class="flex">
      <div *ngFor="let key of ['subClientId']" class="flex1 mw392">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item class="right16">
          <nz-form-control>
            <nz-select (ngModelChange)="onDropdownSelectChange(key,$event)"
                       [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" [nzShowSearch]="true" nzAllowClear>
              <nz-option *ngFor="let subClient of listSubClients" [nzLabel]="subClient.name"
                         [nzValue]="subClient.id">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div *ngIf="selectedSubClient" class="flex1" style="display: flex; align-items: center; justify-content: left;">
        <div class="form-label-v2"></div>
        <div >{{selectedSubClient?.address?.formatedAddress}}
          <a style="margin-left: 16px;" [routerLink]="[routeAdminClientList, selectedSubClient.id]" target="_blank">View Contact</a>
        </div>
      </div>
    </div>
  </ng-container>

    <ng-container *ngIf="isLTL">
        <div *ngFor="let key of ['isCrossDock']">
            <nz-form-item>
                <nz-form-control>
                    <div nz-checkbox [formControlName]="key">{{getLabel(key)}}</div>
                </nz-form-control>
            </nz-form-item>
        </div>
    </ng-container>
    <ng-container *ngIf="isFTL">
        <div *ngFor="let key of ['isNotAutoRouteFTL']">
            <nz-form-item>
                <nz-form-control>
                    <div nz-checkbox [formControlName]="key">{{getLabel(key)}}</div>
                </nz-form-control>
            </nz-form-item>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedClient?.isShowBusinessNumber">
      <div *ngFor="let key of ['businessNumber']" class="mw392">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
        <nz-form-item>
          <nz-form-control>
            <input 
              nz-input 
              [formControlName]="key"
              [type]="getInputType(key)" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)" 
            >
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>

</form>
