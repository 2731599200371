import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Observable } from "rxjs";

export type EditShipmentEntryTextFieldKeys = 'accessCode' | 'instructions' | 'note';

interface EditShipmentEntryTextFieldModel {
  [key: string]: string
}

// Dùng chung cho các trường input là text
@Component({
  selector: 'edit-shipment-entry-text-field',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class EditShipmentEntryTextField extends BaseFormDialog1<EditShipmentEntryTextFieldModel> {

  @Input() keys: Array<EditShipmentEntryTextFieldKeys> = [];
  @Input() isFieldMultiLine: (key: EditShipmentEntryTextFieldKeys) => boolean = this.isMultiline.bind(this);
  @Input() isFieldRequired: (key: EditShipmentEntryTextFieldKeys) => boolean = this.isRequired.bind(this)
  @Input() getFieldLabel: (key: EditShipmentEntryTextFieldKeys) => string = this.getLabel.bind(this);
  @Input() onSave: (data: EditShipmentEntryTextFieldModel) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;

  protected formGroupDeclaration: FormGroupDeclaration = {}

  ngOnInit(): void {
    for (let key of this.keys) {
      this.formGroupDeclaration[key] = {label: '', notAcceptNull: true, multiline: this.isFieldMultiLine(key)}
    }
    super.ngOnInit();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }
    
}
