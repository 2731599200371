<div *ngIf="info?.rate" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">Rate</div>
    <div nz-col>${{info?.rate}}</div>
</div>
<ng-container *ngIf="identifiers">
    <div nz-row *ngFor="let identifier of identifiers" [nzGutter]="{ md: 16 }" class="bottom10">
        <div nz-col nzXs="10" class="text-right">{{identifier.type}} #</div>
        <div nz-col>{{identifier.value}}</div>
    </div>    
</ng-container>
<div *ngIf="priority1Id" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">Priority1 Id</div>
    <div nz-col>{{priority1Id}}</div>
</div>
<div *ngIf="scheduledDelivery" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">Scheduled Delivery</div>
    <div nz-col>{{scheduledDelivery}}</div>
</div>
<div nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">Documents</div>
    <div nz-col>
        <div *ngIf="bolLink">
            <a target="_blank" [href]="bolLink">Bill of Lading</a>
        </div>
        <div *ngIf="palletLabelLink">
            <a target="_blank" [href]="palletLabelLink">Pallet Label</a>
        </div>
    </div>
</div>
<div *ngIf="status" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">Priority1 status</div>
    <div nz-col>{{status}}</div>
</div>
<div *ngIf="status" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">Priority1 carrier</div>
    <div nz-col>{{carrier}}</div>
</div>
