import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-utils';
import { MasterData } from '@services/master.data';
import { Utils } from '@services/utils';
import { Const } from "@const/Const";
import { InputHelper } from '@services/input-helper';

export class BaseChildEditShipmentAddRemoveStops extends BaseComponent {

  public get naText(){
    return "N/A"
  }

  getTimePeriods(deliveryInfo){
    if (deliveryInfo?.requiresAppointment && Utils.isObjectNotEmpty(deliveryInfo?.appointmentInfo)) {
      const text = DateUtil.displayTimeWindow(deliveryInfo.appointmentInfo, {
        timezone: deliveryInfo?.addr?.metadata?.timeZoneStandard,
        format: "MM/DD/YYYY h:mm A",
      });
      return text + ' (Appointment Scheduled)';
    }
    let timeWindowsText = deliveryInfo?.windows?.map((window) =>
      DateUtil.displayTimeWindow(window, {
        timezone: deliveryInfo?.addr?.metadata?.timeZoneStandard,
        format: "MM/DD/YYYY h:mm A",
      })
    )?.join('<br/>');
    return timeWindowsText || this.naText;
  }

  isAppointmentRequired(deliveryInfo) {
    // Nếu có dữ liệu appointmentInfo thì cũng ko hiển thị ==> mà hiển thị thay thế windowsTime
    return deliveryInfo.requiresAppointment && !Utils.isObjectNotEmpty(deliveryInfo.appointmentInfo);
  }

  getServiceOptions(deliveryInfo){
    const serviceOptions = (deliveryInfo?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt));
    return serviceOptions || this.naText;
  }

  getPrimaryContact(deliveryInfo){
    return (deliveryInfo?.contacts || [])?.filter(it => it?.type == Const.ContactType.primary)?.[0]?.fullName || this.naText;
  }

  getSecondaryContact(deliveryInfo){
    return (deliveryInfo?.contacts || [])?.filter(it => it?.type == Const.ContactType.secondary)?.[0]?.fullName || this.naText;
  }

  getReferenceNo(deliveryInfo){
    return (deliveryInfo?.refNums || [])?.join(', ') || this.naText;
  }

  getAccessCode(deliveryInfo){
    return deliveryInfo?.accessCode || this.naText;
  }

  getInstructions(deliveryInfo){
    return deliveryInfo?.instructions || this.naText;
  }

  getNotes(deliveryInfo){
    return deliveryInfo?.note || this.naText;
  }

  formatItemValue(itemValue){
    return InputHelper.formatMoney2(itemValue);
  }

  getItemBarcode(item){
    if (Utils.isArray(item?.barcode)) return item.barcode?.join(', ') || this.naText;
    else return item?.barcode || this.naText;
  }

  getItemService(item){
    return item?.service || this.naText
  }

}