import { BizUtil } from "@services/biz";
import { LatLng, AddressUS, DeliveryInfo } from '@wearewarp/types/data-model';
import { DateUtil } from "@services/date-utils";
import { getInjector } from "@services/index";
import { MasterData } from "@services/master.data";
import { RoutingColor } from "../../color";
import { LtlRoutingDeliveryInfoViewData } from "../../interface";
import { MapService } from "../map.service";
// import { RoutingService } from "../routing.service";
// import { ShipmentManagement } from "../shipmentManagement";
import { AddressManagement } from "./addressManagement";


export class Shipment {
  private id: number | string;
  private data: any;
  private mapService: MapService;
  private addressManagement: AddressManagement;
  private pickInfo: DeliveryInfo;
  private dropInfo: DeliveryInfo;

  constructor(id, data) {
    const injector = getInjector();
    //this.routingService = injector.get(RoutingService);
    // this.shipmentManagement = injector.get(ShipmentManagement);
    this.addressManagement = injector.get(AddressManagement);
    this.mapService = injector.get(MapService);
    this.data = data;
    this.id = id;
    this.addAddressInfo()
    this.initViewData();
  }

  public getData() {
    return this.data
  }

  public getId() {
    return this.id
  }
  public getWarpId() {
    return this.data.warpId;
  }

  public getPickInfo() {
    return this.pickInfo
  }
  public getDropInfo() {
    return this.dropInfo
  }

  public getDeliveryInfos() {
    return [this.pickInfo, this.dropInfo]
  }

  public getTags() {
    return this.data.tags
  }

  public addToMap() {
    const pickLocation: LatLng = <LatLng>this.pickInfo?.location;
    const dropLocation: LatLng = <LatLng>this.dropInfo?.location;

    this.mapService.addMarker({
      location: [pickLocation.longitude, pickLocation.latitude],
      data: this.pickInfo
    }).setPaint({
      color: RoutingColor.getMarkerColor('PICKUP')
    })
    this.mapService.addMarker({
      location: [dropLocation.longitude, dropLocation.latitude],
      data: this.dropInfo
    }).setPaint({
      color: RoutingColor.getMarkerColor('DROPOFF')
    });
    return this
  }
  public removeFromMap() {
    this.mapService.removeMarkerData({
      filterFn: (data) => {
        return data.shipment_id == this.dropInfo.shipment_id || data.shipment_id == this.pickInfo.shipment_id
      }
    })
  }

  private addAddressInfo() {
    let pickInfo = BizUtil.getPickInfo(this.getData());
    let dropInfo = BizUtil.getDropInfo(this.getData());
    let pickLocation = BizUtil.getDeliveryInfoLocation(pickInfo);
    let dropLocation = BizUtil.getDeliveryInfoLocation(dropInfo);
    this.pickInfo = {
      ...pickInfo,
      location: pickLocation,
      type: 'PICKUP',
      addressText: this.getAddressText(pickInfo.addr),
      shipment_id: this.getId(),
      warpId: this.getWarpId()
    };
    this.dropInfo = {
      ...dropInfo,
      location: dropLocation,
      type: 'DROPOFF',
      addressText: this.getAddressText(dropInfo.addr),
      shipment_id: this.getId(),
      warpId: this.getWarpId()
    }
    this.addressManagement.add(pickLocation, this.pickInfo);
    this.addressManagement.add(dropLocation, this.dropInfo);

  }

  private getAddressText(addr: AddressUS): string {
    return MasterData.getAddressText(addr);
  }
  private textLatLng(location: LatLng): string {
    if (!location) return '';
    return `${location.latitude}, ${location.longitude}`;
  }

  private initViewData() {
    this.data['viewData'] = {
      palletDesc: this.handleOrderItems(),
      pick: this.handleDeliveryInfo(this.getPickInfo()),
      drop: this.handleDeliveryInfo(this.getDropInfo())
    }
  }
  private handleDeliveryInfo(info: DeliveryInfo): LtlRoutingDeliveryInfoViewData {
    let { window, isAppointment } = BizUtil.getTimeWindowForDeliveryInfo(info);
    let timeZoneStandard = info?.addr?.metadata?.timeZoneStandard;
    let timezoneShort = DateUtil.timezoneStandardToUsShort(timeZoneStandard);
    let addr = MasterData.getAddressText(info.addr);
    let windowStr = DateUtil.displayTimeWindow(window, { timezone: timeZoneStandard, format: 'MM/DD/YYYY h:mm A', formatDateOnly: 'MM/DD/YYYY' });
    let isWindowError = false;
    let errMsg;
    if (!windowStr) {
      errMsg = `${info.type} date time is missing`;
      windowStr = errMsg;
      timezoneShort = '';
      isWindowError = true;
    }
    return {
      address: addr,
      window: windowStr,
      tz: timeZoneStandard,
      tzShort: timezoneShort,
      locationName: BizUtil.getLocationName(info),
      isAppointment, isWindowError, errMsg,
    };
  }

  private handleOrderItems() {
    let desc = '';
    for (let item of this.getData().listItems) {
      if (desc.length > 0) {
        desc += '. ';
      }
      desc += BizUtil.getOrderItemDesc(item, { defaultQtyUnit: 'Pallet' });
    }
    return desc;
  }

}