import { Map as Mapbox, Popup } from "mapbox-gl";
import MapboxDraw, { MapboxDrawOptions } from "@mapbox/mapbox-gl-draw";
import { polygon, point } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { Polygon } from "geojson";

const DEFAULT_OPTIONS = {
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: true,
  },
  defaultMode: 'draw_polygon',
  userProperties: true,
}

export class MapDraw extends MapboxDraw {
  private map: Mapbox;
  private polygons: Polygon[];
  private eventFns: Map<string, Function> = new Map();
  private isEnabled: boolean = false;

  constructor(options?: MapboxDrawOptions) {
    super({
      ...DEFAULT_OPTIONS,
      ...options
    })
  }

  addListenEvent() {
    const listEvents = [
      'draw.create',
      'draw.update',
      'draw.delete',
      'draw.combine',
      'draw.uncombine',
      'draw.selectionchange',
      'draw.modechange',
      'draw.actionable',
    ];
    for (let event of listEvents) {
      this.map.on(event, this.onMapEvent.bind(this));
    }
  }

  addTo(map) {
    this.map = map;
    this.addListenEvent()
    return this;
  }

  onEvent(eventName: 'area_updated' | 'modechange', callbackFn) {
    this.eventFns.set(eventName, callbackFn);
    return this
  }

  start() {
    if (this.isEnabled) return;
    this.isEnabled = true;
    this.map?.addControl(this);
  }
  stop() {
    if (!this.isEnabled) return;
    this.isEnabled = false;
    this.map?.removeControl(this);
  }

  public isDrawing() {
    return this.isEnabled
  }

  private onMapEvent(event: MapboxDraw.DrawEvent) {
    switch (event.type) {
      case 'draw.create':
      case 'draw.update':
      case 'draw.delete':
        // Vẽ xong 1 polygon
        //  this.showPopupSelection(e);
        this.getPolygons(event);
        if (this.eventFns.has('area_updated')) {
          this.eventFns.get('area_updated')({ polygons: this.polygons })
        }
        break;
      case 'draw.modechange':
        const mode = (<MapboxDraw.DrawModeChangeEvent>event).mode;
        if (this.eventFns.has('modechange')) {
          this.eventFns.get('modechange')({ mode })
        }
        //  this.isSelectState = (<MapboxDraw.DrawModeChangeEvent>e).mode == 'draw_polygon';
        break;
    }
  }

  private getPolygons(event) {
    const features = event.features;
    this.polygons = features.map(feature => polygon(feature.geometry.coordinates));
  }

  /**
   * kiểm tra xem 1 location có nằm trong vùng vừa vẽ hay không
   * @param location [long, lat]
   */
  public isPointInSelectedArea(location: number[]) {
    for (let polygon of this.polygons) {
      if (booleanPointInPolygon(point(location), polygon)) return true;
    }
    return false;
  }

}