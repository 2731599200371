import { Component, ViewChild } from '@angular/core';
import { FormDataShipmentEntryBasicInfo } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { BaseForm } from '@app/admin/base/form-base';
import { ComponentForm } from '../../../interface';
import { ShipmentEntryTabContent } from '../../tab-content-component';
import { FormShipmentEntryBasicInfo } from '../../forms/basic-info';

@Component({
  selector: '[shipment-entry-basic-info]',
  templateUrl: './index.html'
})
export class ShipmentEntryBasicInfo extends ShipmentEntryTabContent implements ComponentForm<FormDataShipmentEntryBasicInfo> {
  @ViewChild('formComp') formComp: FormShipmentEntryBasicInfo;
  getForm(): BaseForm<FormDataShipmentEntryBasicInfo> {
    return this.formComp;
  }

  get formModel() {
    let origin = this.modelDraft?.basicInfo;
    let clientId= origin?.clientId;
    let subClientId;
    if(origin?.parentClientId){
      clientId = origin?.parentClientId;
      subClientId = origin?.clientId;
    }
    return {...origin, clientId, subClientId};
  }
}
