<div class="bottom15">
    <a [routerLink]="[routeAdminLocationList]">Back</a>
</div>
<div class="info bottom5" *ngIf="warehouse">
    <div class="h2 bottom15">{{ warehouse.name }}</div>
    <div *ngIf="warehouse.pickAddr">
        Address: 
        <span>{{ warehouse.pickAddr.street }}, </span>
        <span>{{ warehouse.pickAddr.city }}, </span>
        <span>{{ warehouse.pickAddr.state }} {{ warehouse.pickAddr.zipcode }}</span>
    </div>
</div>

<div warehouse-items [id]="this.id"></div>