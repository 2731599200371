import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Mapbox } from "@services/mapbox";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import polyline from "@mapbox/polyline";
import { MapboxStaticService } from "@app/admin/components/mapbox-static/service";

@Component({
  selector: "batch-big-map-modal",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchBigMapModal extends BaseComponent {
  public mapBox: Mapbox;
  public isZoom: boolean = false;
  @Input() dropOffStop: Array<[number, number]> = [];
  @Input() pickUpStop: Array<[number, number]> = [];
  @Input() otherStop: Array<[number, number]> = [];
  @Input() routeData: any;
  @Output() visibleChange = new EventEmitter<boolean>();

  private _isVisible;

  @Input() get isVisible() {
    return this._isVisible;
  }

  set isVisible(value) {
    this._isVisible = value;
    if (value) {
      setTimeout(() => {
        this.mapBox = new Mapbox();
        this.showDataOnTheMap(this.routeData);
      }, 0);
    } else {
      if (this.mapBox) {
        this.mapBox.clearMap();
        this.mapBox = null;
      }
    }
  }

  get map() {
    return this.mapBox.getMapbox();
  }

  constructor(private mapboxStaticService: MapboxStaticService) {
    super();
  }

  handleCancel() {
    this.visibleChange.emit();
  }

  public getTitle() {
    return "Route in Map";
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.mapboxStaticService.clear();
  }

  showDataOnTheMap(routeData) {
    this.mapboxStaticService.attachMap(document.getElementById("batch-big-map"))
    if (routeData) {
      let line = routeData.line ? polyline.decode(routeData.line) : routeData.points;
      let route = line.map((it) => [it[1], it[0]]);
      const routes = [];
      if (route.length > 0) {
        routes.push(route);
      }
      if (routes.length > 0) {
        this.setupMap(routes, [
          { points: this.pickUpStop, color: Const.MAP_LOCATION_COLOR_PICK, des: "pickup" },
          { points: this.dropOffStop, color: Const.MAP_LOCATION_COLOR_DROP, des: "dropoff" },
        ]);
      }
    }
  }

  private setupMap(route: any[], points: any[]) {
    this.mapBox.setupMap(route, points, [], Const.MapBox.style.light, Const.MapBox.FitBoundsBy.routes, "batch-big-map");
    setTimeout(() => {
      this.mapBox.addPoints({ points: this.otherStop, color: Const.MAP_LOCATION_COLOR_DEFAULT, des: "other" });
    }, 1);
  }
}
