import { Component, OnInit, Input } from "@angular/core";
import { DeliveryMap } from "@app/admin/components/map";
import { ApiService } from "@services/api.service";
import { Pricing2Service } from "@services/pricing2.service";
import {
  FormDataShipmentEntryBasicInfo,
  FormDataShipmentEntryDraftSingle,
  FormDataShipmentLocation
} from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";

@Component({
  selector: "shipment-info-single",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class ShipmentInfoSingle implements OnInit {
  map: DeliveryMap
  isEmpty: boolean = true;
  customerAndEquipment: FormDataShipmentEntryBasicInfo;
  deliveryInfos: Array<FormDataShipmentLocation> = [];
  listItems: Array<any> = [];
  pricingService: Pricing2Service
  private _modelDraft: FormDataShipmentEntryDraftSingle;
  @Input() get modelDraft(): FormDataShipmentEntryDraftSingle { return this._modelDraft};
  set modelDraft(value) {
    this._modelDraft = value;
    if (this.modelDraft?.basicInfo){
      this.setCustomerAndEquipment(this.modelDraft.basicInfo);
    }
    let deliveryInfos = [];
    if (this.modelDraft?.pickInfo) {
      deliveryInfos.push(this.modelDraft?.pickInfo);
    }
    if (this.modelDraft?.dropInfo) {
      deliveryInfos.push(this.modelDraft?.dropInfo);
    }
    this.setDeliveryInfos(deliveryInfos);
    this.setItems(this.modelDraft?.items ?? []);
    this.price()
  }
  constructor(private api: ApiService,) {
    this.pricingService = new Pricing2Service(api)
  }

  onMapChange() {
    if (this.deliveryInfos?.length && this.map) {
      setTimeout(() => {
        this.map?.loadShipments([{ deliveryInfos: this.deliveryInfos }])
        this.map?.refresh()
        this.map?.fitBoundsToShipment()  
      }, 10)
    }
  }

  ngOnInit(): void {
  }

  setCustomerAndEquipment(data) {
    this.customerAndEquipment = data;
    this.checkEmptyState();
  }

  setDeliveryInfos(data: Array<FormDataShipmentLocation>) {
    this.deliveryInfos = data;

    this.map?.loadShipments([{ deliveryInfos: this.deliveryInfos }])
    this.map?.refresh()
    this.map?.fitBoundsToShipment()

    this.checkEmptyState();
  }

  setItems(listData) {
    this.listItems = listData;
    this.checkEmptyState();
  }

  checkEmptyState() {
    this.isEmpty =
      this.customerAndEquipment == null &&
      (this.deliveryInfos == null || this.deliveryInfos?.length == 0) &&
      (this.listItems == null || this.listItems?.length == 0);
  }

  // call pricing
  quote: any = null
  price() {
    if (!this._modelDraft?.basicInfo?.clientId) return
    if (!this.deliveryInfos?.length) return
    if (this._modelDraft.basicInfo?.shipmentType === 'LTL') {
      if (!this.listItems?.length) return
    }
    const deliveryInfos = this.deliveryInfos.map(it => {
      const windows: any[] = it.windows
      for (let w of windows) {
        if (!w.from && w.range) {
          w.from = w.range[0]
          w.to = w.range[1]
        }
      }
      return Object.assign({}, it, {windows})
    })
    const payload = {
      deliveryInfos,
      items: this.listItems.map(it => Object.assign({}, it, {quantity: it.qty})),
      shipmentType: this._modelDraft.basicInfo?.shipmentType,
      subject: `CUSTOMER_${this._modelDraft.basicInfo.clientId}`
    }
    this.pricingService.quote(payload).subscribe((res) => {
      const options = []
      for (let quote of (res?.quotes || [])) {
        for (let option of quote?.options || []) {
          if (option?.rate.avg) {
            options.push(option)
          }
        }
      }
      this.quote = {options}
    }, (err) => {
      this.quote = {}
    })
  }
}
