import { Component, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { BaseComponent } from "@abstract/BaseComponent";
import { DataorchService } from "@services/dataorch.service";
import _ from "underscore";

@Component({
    selector: 'report-list',
    templateUrl: 'list.html',
    styleUrls: [
        './list.scss',
    ]
})
export class ReportTypeList extends BaseComponent {
    dataorch: DataorchService
    routeReportViewer = Const.routeReportViewer

    formats: any[] = []
    views: any[] = []

    ngOnInit(): void {
        this.listFormatViews()
    }

    loading = false
    listFormatTypes() {
        this.loading = true
        this.dataorch.listtReportTypes().subscribe((res) => {
            this.formats = res
            this.loading = false
        })
    }

    listFormatViews() {
        this.loading = true
        this.dataorch.listReportViews().subscribe((res) => {
            this.views = res
            this.loading = false
        })
    }

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }
}