<div style="width: 150px;" [class]="{'missing': item.isMissing}">
    <code *ngIf="item.internalBarcode">{{item.internalBarcode}}</code>
    <code *ngIf="!item.internalBarcode" class="not-identified">Not identified</code>
</div>
<div style="width: 160px;" [class]="{'missing': item.isMissing}">{{item.name}}</div>
<div style="width: 150px;" [class]="{'missing': item.isMissing}">{{item.length || '-'}} x {{ item.width || '-'}} x {{item.height  || '-'}} {{ item.sizeUnit }}</div>
<div style="width: 100px;" *ngIf="!edittingWeight" [class]="{'missing': item.isMissing}">
    <span class="font-medium" *ngIf="item.actualWeight">{{ item.actualWeight }} {{item.actualUnit || item.weightUnit}}</span>
    <span *ngIf="!item.actualWeight && item.weightPerUnit" class="not-identified">{{ item.weightPerUnit.toFixed(0) }} {{ item.weightUnit}}</span>
</div>
<div style="width: 100px;" *ngIf="edittingWeight">
    <input nz-input nzSize="small" [(ngModel)]="weight" >
</div>
<div *ngIf="item.isMissing" class="missing">
    <span class="danger">Missing</span>
</div>
<div class="flex1" *ngIf="!edittingWeight && !item.isMissing">
    <span class="clickable" (click)="turnOnEdittingWeight()">Edit Weight</span>
    <nz-divider nzType="vertical"></nz-divider>
    <span *ngIf="item.inventory" class="clickable">Confirm Released</span>
    <span *ngIf="!item.internalBarcode" class="danger clickable" (click)="markMissing()">Mark Missing</span>
</div>
<div class="flex1" *ngIf="edittingWeight && !item.isMissing">
    <span class="clickable" (click)="confirmWeight()">Confirm</span>
    <nz-divider nzType="vertical"></nz-divider>
    <span class="clickable danger" (click)="cancelEdittingWeight()">Cancel</span>
</div>
<div *ngIf="item.images" class="flex">
    <div *ngFor="let image of item.images">
        <img [class]="['pod', compact ? 'compact': 'full']" [src]="image" (click)="toggleCompact()">
    </div>
</div>
