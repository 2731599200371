import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Inject, Injector, Input } from '@angular/core';
import { ApiService } from '@services/api.service';
import { AttachedFileUtil } from '@services/attached-file-util';
import { DateUtil } from '@services/date-utils';

@Component({
    selector: 'setting-version-file-history',
    templateUrl: './index.html',
    styleUrls: ['./style.scss'],
})
export class SettingVersionFileHistory extends BaseComponent {

    _data = [];
    @Input() get data() {
        return this._data
    }
    set data(value) {
        if (Array.isArray(value)) {
            this._data = value.reverse();
        }
        else this._data = value
    }
    @Input() showUploadedBy = true;
    
    constructor() {
      super();
    }
    ngOnInit(): void {
      super.ngOnInit();
    }

    showDate(date) {
        return DateUtil.dateToString(date)
    }

    fileSize(size) {
      return AttachedFileUtil.displayFileSize(size);
    }
}